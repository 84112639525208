<!-- LoadingContainer Component -->
<script lang="ts">
  import LoadingOverlay from './LoadingOverlay.svelte'

  export let loading: boolean
  export let message = 'Loading Data...'
  export let abortController: AbortController = null
  export let cancellable = !!abortController
  export let error = null
  export let hideRetry = false
  export let zIndex: Nullable<number> = null
  export let opaque = false

  export let overlayClass = ''
  export { css as class }
  let css = ''

  $: _showOverlay = loading || error

  let contentRect: Nullable<DOMRectReadOnly>
</script>

<LoadingOverlay
  {contentRect}
  bind:loading
  bind:message
  bind:abortController
  bind:cancellable
  bind:error
  bind:hideRetry
  bind:zIndex
  on:cancelClick
  on:click
  on:retryClick
  class={overlayClass}
/>

<div
  class="content {css}"
  class:loading={_showOverlay}
  class:opaque={opaque && _showOverlay}
  bind:contentRect
>
  <slot />
</div>

<style lang="scss">
  @use '../../theme/variables' as vars;

  .content {
    height: 100%;
    width: 100%;
    opacity: 1;

    &.loading {
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
      user-select: none;
    }

    &.opaque {
      opacity: 0;
    }
  }
</style>
