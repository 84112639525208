<!-- FilterEditNode Component -->
<script lang="ts" context="module">
  import Combobox from '@components/Combobox.svelte'
  import type FilterItem from '@lib/Filter/FilterItem'
  import FilterEditItemValue from './FilterEditItemValue.svelte'
</script>

<script lang="ts">
  export let value: FilterItem

  $: filterOptions = value?.getOperations?.()

  function getSelection(node: FilterItem) {
    return filterOptions?.find(
      (item) => item.id == node?.type?.constructor.name
    )?.id
  }

  function handleFilterChange(event: CustomEvent<string>) {
    const result = value.swapFilter(event.detail)
    if (result) {
      value = value
    }
  }
</script>

<div class="filterEditNode">
  <Combobox
    selected={getSelection(value)}
    data={filterOptions}
    comboboxConfig={{
      css: 'noMargin filterComboBox',
      placeholder: 'Filter Type',
    }}
    on:change={(event) => handleFilterChange(event)}
  />
  {#key value.column}
    <FilterEditItemValue bind:value={value.type} />
  {/key}
</div>

<style lang="scss">
  .filterEditNode {
    display: flex;
    flex-grow: 1;

    :global(.filterComboBox) {
      min-width: 15em;
      flex-grow: 1;
      max-width: 20em;
    }
  }
</style>
