<!-- The global toolbar component -->
<!-- To add items to the toolbar from anywhere, use the ToolbarHook component -->
<script lang="ts" context="module">
  import { DhxToolbar } from '@lib/dhtmlx'
  import type { ToolbarConfig } from '@models/Toolbar'
  import { Toolbar } from '@models/Toolbar'
  import { onDestroy, onMount } from 'svelte'
  import { writable } from 'svelte/store'

  export const globalToolbarStore = writable<Toolbar>()
  export let hidden = false
</script>

<script lang="ts">
  let toolbar: Toolbar = undefined

  let toolbarConfig: ToolbarConfig = {
    css: 'headerToolbar',
  }

  $: if (toolbar)
    if (hidden) toolbar?.dhx.hide(undefined)
    else toolbar?.dhx.show(undefined)

  toolbarConfig.id = toolbarConfig.id
    ? toolbarConfig.id
    : `toolbar-${Math.random().toString(16).slice(2)}`

  onMount(() => {
    toolbar = new Toolbar(
      new DhxToolbar(toolbarConfig.id, toolbarConfig),
      toolbarConfig
    )

    globalToolbarStore.set(toolbar)
  })

  onDestroy(() => {
    toolbar?.dhx.destructor()
  })
</script>

<div class="toolbar" id={toolbarConfig?.id}></div>
