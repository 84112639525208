<!-- FilePreview Component -->
<script lang="ts" context="module">
  import { displayError } from '@components/Global/StatusMessage.svelte'
  import LoadingSpinner from '@components/Utility/LoadingSpinner.svelte'
  import { request } from '@lib/ApiUtil'
  import type { File } from '@models/api/ApiModels'
  import { isString, isUuid } from '@packages/util'
  import {
    getFileDownloadUrl,
    resolvePreview,
  } from './FilePreviewers/_PreviewMap'

  // Max File size in bytes
  const maxPreviewFileSize = 3_145_728 // 3MB

  const __customError__ = Symbol('customError')

  interface CustomError {
    __customError__: typeof __customError__
    text: string
    link?: { url: string; label: string }
    ignore?: boolean
  }
</script>

<script lang="ts">
  export let file: string | Uuid | File

  export let border = false
  export let ignoreNotFound = false

  let _showPreview = false
  let _fileId: Nullable<Uuid> = null
  let loading = true
  let abortController = new AbortController()

  async function getFileInfo(fileId: Nullable<string | File>) {
    if (!isString(fileId)) return fileId
    if (!isUuid(fileId)) return null

    return await request<File>({
      url: ['file', fileId],
      signal: abortController.signal,
      body: {
        expand: 'previewSize,size',
      },
    })
  }

  function customError(
    text: string,
    link?: CustomError['link'],
    ignore = false
  ): CustomError {
    return { __customError__, text, link, ignore }
  }

  function isCustomError(value: any): value is CustomError {
    return value?.__customError__ == __customError__
  }

  async function load(fileId: Nullable<string | File>) {
    _fileId = null
    _showPreview = false
    loading = true
    abortController = new AbortController()

    let fileInfo: File

    try {
      fileInfo = await getFileInfo(fileId)

      if (!fileInfo) throw customError('File not found.')
      if (fileInfo.directory)
        throw customError(
          'Error: Current File is a Directory.',
          {
            label: 'Open it instead',
            url: `#/files/${fileId}`,
          },
          ignoreNotFound
        )
      if (fileInfo.previewSize > maxPreviewFileSize)
        throw customError('Error: File is too big to preview.', {
          label: 'Download it instead',
          url: getFileDownloadUrl(fileInfo.id),
        })

      const previewItem = resolvePreview(fileInfo)
      if (!previewItem)
        throw customError(
          'No preview available.',
          {
            label: 'Download it instead',
            url: getFileDownloadUrl(fileInfo.id),
          },
          ignoreNotFound
        )

      const previewComponent = (await previewItem.component())?.default
      if (!previewComponent)
        throw customError('Error: Failed loading preview component.')

      _fileId = fileInfo.id as Uuid
      _showPreview = true
      return previewComponent
    } catch (error) {
      if (isCustomError(error)) throw error
      const parsedError = displayError(error)
      throw `${parsedError.title}: ${parsedError.description}`
    } finally {
      loading = false
    }
  }
</script>

{#await load(file)}
  <LoadingSpinner />
{:then component}
  <svelte:component this={component} fileId={_fileId} />
{:catch error}
  {#if isCustomError(error)}
    {#if !error.ignore}
      <div class="placeholderText">
        {error.text}
        {#if error.link}
          <a href={error.link.url}>{error.link.label}</a>
        {/if}
      </div>
    {/if}
  {:else}
    <div class="placeholderText">
      {error}
    </div>
  {/if}
{/await}

<style lang="scss">
  @use '../../../theme/variables' as vars;

  .placeholderText {
    margin: 0.5em;
  }
</style>
