<!-- InputFilter Component -->
<script lang="ts">
  export let value: Record<string, string> = {}

  $: _value = value?.like ?? ''

  function handleChange(event: Event) {
    value ??= {}
    // @ts-ignore
    value.like = event.target?.value
    // Clear value if the text is empty
    value = value?.like?.length > 0 ? value : {}
  }
</script>

<div class="dhx_string-cell selectFilter">
  <label class="dhx_grid-filter__label dxi dxi-magnify">
    <input
      class="inputFilter dhx_input"
      value={_value}
      on:change={handleChange}
      autocomplete="off"
    />
  </label>
</div>

<style lang="scss">
  .selectFilter {
    width: 100%;
    height: 100%;
    padding: 0.25em 0;

    .inputFilter {
      background-color: white;
      height: 100%;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
