<script lang="ts" context="module">
  import DOMPurify from 'dompurify'
  export const _name = 'DummyText'
</script>

<script lang="ts">
  export let value = ''
  export let label = 'Text Display'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  $: sanitizedValue = DOMPurify.sanitize(label)
  sanitizedValue = DOMPurify.sanitize(label)

  // Ignored
  value
  state
  helpMessage
  preMessage
  successMessage
  errorMessage
</script>

{@html sanitizedValue ?? ''}
