<script lang="ts">
  export let value: any = 'Example\nText\n\t\twith Tabs'

  $: items = String(value).split('\n')
  $: lines = items?.length
</script>

<span>
  {#each items as item, index}
    {@const innerItems = String(item).split('\t')}
    {@const innerCount = innerItems?.length - 1}
    {#each innerItems as inner, innerindex}
      {inner}
      {#if innerCount != innerindex}
        <span class="tab"></span>
      {/if}
    {/each}
    {#if lines != index}
      <br />
    {/if}
  {/each}
</span>

<style lang="scss">
  .tab {
    display: inline-block;
    margin-left: 1em;
  }
</style>
