<script lang="ts" context="module">
  import { onDestroy, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'
  import { isValidFloat, parseFloatFromAny } from '@packages/util'

  export const _name = 'NumberBetween'
</script>

<script lang="ts">
  export let value = [0, 0]

  export let label = 'Number'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _number_min: string
  let _number_max: string

  let _min_focussed: boolean
  let _max_focussed: boolean

  let _state = state
  let _number_error = errorMessage
  let _lock = false

  let _ready = false

  $: if (
    _ready &&
    validatenumber(_number_min, _number_max) &&
    !(_min_focussed || _max_focussed)
  ) {
    _lock = true
    value = convert(_number_min, _number_max)
    tick().then(() => (_lock = false))
  }

  $: update(value)

  function convert(min: any, max: any): [number, number] {
    return [parseFloatFromAny(min), parseFloatFromAny(max)]
  }

  function validatenumber(min: any, max: any) {
    ;[min, max] = convert(min, max)
    if (isValidFloat(min) && isValidFloat(max) && min <= max) {
      _state = state
      _number_error = errorMessage
      return true
    } else {
      _state = 'error'
      _number_error = 'Range must be valid'
      return false
    }
  }

  function update(value: any) {
    if (_lock) return
    _number_min = value?.[0]
    _number_max = value?.[1]
  }

  onMount(async () => {
    await tick()
    _ready = true
  })
  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  state={_state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={_number_error?.length ? _number_error : errorMessage}
>
  <div class="horizontal">
    <input
      type="number"
      inputmode="numeric"
      placeholder="Min"
      name="_number_min"
      class="dhx_input"
      bind:value={_number_min}
      on:focus={() => (_min_focussed = true)}
      on:blur={() => (_min_focussed = false)}
    />
    <input
      type="number"
      placeholder="Max"
      name="_number_max"
      class="dhx_input"
      bind:value={_number_max}
      min={_number_min}
      on:focus={() => (_max_focussed = true)}
      on:blur={() => (_max_focussed = false)}
    />
  </div>
</FormElementBase>

<style lang="scss">
  .horizontal {
    display: flex;
  }
</style>
