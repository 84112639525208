<script lang="ts" context="module">
  import EditableGrid, {
    EditableGridColumnDataType,
    type EditableGridColumn,
  } from '@components/EditableGrid.svelte'
  import FormElementBase from './FormElementBase.svelte'

  export const _name = 'CustomTable'

  export type CustomDataColumn = Omit<EditableGridColumn, 'dataType' | 'config'>

  export interface CustomTableConfig {
    columns?: CustomDataColumn[]
    maxRows?: number
    minRows?: number
    dataType?: EditableGridColumnDataType
    rowHeaders?: string[]
    singleColumn?: boolean
    disableColumnHeaders?: boolean
  }
</script>

<script lang="ts">
  export let value: Record<any, any>[] = []
  export let label = 'Json Edit'
  export let config: Nullable<CustomTableConfig> = null
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let editableGrid: Nullable<EditableGrid>

  export function validate() {
    const _min = config?.minRows ?? 0
    const _max = config?.minRows ?? Number.MAX_SAFE_INTEGER
    const min = Math.max(_min, 0)
    const max = Math.min(_max, Number.MAX_SAFE_INTEGER)

    return (
      editableGrid?.validate() &&
      Array.isArray(value) &&
      value.length >= min &&
      value.length <= max
    )
  }

  function getColumns(_config: typeof config) {
    return _config?.columns.map<EditableGridColumn>((column) => ({
      ...column,
      dataType: _config?.dataType ?? EditableGridColumnDataType.string,
    }))
  }
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <div class="editableGrid">
    <EditableGrid
      bind:this={editableGrid}
      columns={getColumns(config)}
      rowHeaders={config?.rowHeaders}
      singleColumn={!!config?.singleColumn}
      disableColumnHeaders={!!config?.disableColumnHeaders}
      bind:value
    />
  </div>
</FormElementBase>

<style lang="scss">
  .editableGrid {
    height: 20em;
  }
</style>
