<!-- A modal template, ready to use -->
<script lang="ts">
  import DynamicGrid from '@components/DynamicGrid.svelte'
  import ToolbarItem from '@components/Toolbar/ToolbarItem.svelte'
  import Window from '@components/Window.svelte'
  import type { Grid } from '@dhtmlx/ts-grid'
  import { ApiLazyDataProxy } from '@lib/ApiDataProvider'
  import type ApiBaseModel from '@models/api/ApiBaseModel'
  import type { AnyBaseModel } from '@models/api/BaseModel'
  import type { MappedEvents } from '@packages/util'
  import { camel2title, isSet } from '@packages/util'
  import { createEventDispatcher, tick } from 'svelte'

  interface $$Events {
    apply: CustomEvent<string[]>
    cancel: CustomEvent<never>
    afterClose: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  /** Show the modal */
  export let show = false

  /** The title of the modal */
  export let title: Nullable<string> = null

  /** The api model to use */
  export let apiModel: AnyBaseModel

  /** The current selection (Always an array) */
  export let current: string[] // uuid[]

  /** The name field */
  export let nameField: string = 'name'

  /** Wether to allow more than one selection */
  export let multiSelect = false

  /** Additional columns to show in the modal */
  export let columns: string[] = []

  /** Additional filters to apply */
  export let filter: Record<string, any> = {}

  /** Sorting to apply */
  export let sort: Nullable<string> = null

  /**
   * SPECIAL: Select the field for the lookup, defaults to `id`.
   *
   * Used for the rare cases where a name needs to be used instead of an ID.
   */
  export let field: Nullable<string> = null

  let abortController = new AbortController()
  let entityGrid: Grid
  let loading = true
  let dataProxy: ApiLazyDataProxy<any>

  let singleSelection: ApiBaseModel
  let _applied = false

  function getDataProxy() {
    const _field = field ?? 'id'

    return apiModel.getDataProxy({
      postprocess: async (data) => ({
        ...data,
        results: data.results.map((_item) =>
          multiSelect
            ? {
                ..._item,
                _included:
                  current?.find((_id) => _id == _item[_field]) ?? false,
              }
            : _item
        ),
      }),
    })
  }

  async function apply() {
    const _field = field ?? 'id'

    _applied = false
    let selected = []

    if (!multiSelect) {
      if (singleSelection) selected = [singleSelection[_field]]
    } else {
      //@ts-ignore
      entityGrid.data.forEach((item: ApiBaseModel & { _included: boolean }) => {
        if (item._included) selected.push(item[_field])
      })
    }

    current = selected

    if (isSet(selected)) {
      _applied = true
      dispatch('apply', selected)
    }

    show = false
  }

  async function handleClose() {
    await tick()
    !_applied && dispatch('cancel')
    _applied = false
    dispatch('afterClose')
  }
</script>

<Window
  bind:show
  title={title ??
    `Select ${
      multiSelect
        ? camel2title(apiModel?.endpoint)
        : camel2title(apiModel?.className)
    }`}
  width="40em"
  height="50em"
  on:beforeOpen={() => (dataProxy = getDataProxy())}
  on:beforeClose={() => abortController?.abort()}
  on:afterClose={handleClose}
>
  <DynamicGrid
    bind:loading
    bind:entityGrid
    {dataProxy}
    {apiModel}
    disableExport
    disableImport
    fixedFilters={filter}
    disableUrlSaving
    defaultSort={sort ?? nameField}
    additionalColumns={multiSelect
      ? {
          _included: () => ({
            id: '_included',
            type: 'boolean',
            editable: true,
            width: 100,
            header: [{ text: 'Select' }],
          }),
        }
      : {}}
    overrideDefaultColumns={[
      ...(multiSelect ? ['_included'] : []),
      nameField,
      ...columns,
    ]}
    hideToolbarButtons
    gridConfig={{
      css: 'singleLine',
    }}
    bind:selection={singleSelection}
    on:cellDblClick={() => !multiSelect && apply()}
  />
  <svelte:fragment slot="bottomToolbar">
    <ToolbarItem spacer />
    <ToolbarItem value="Cancel" type="button" on:click={() => (show = false)} />
    <ToolbarItem
      value="Apply"
      type="button"
      on:click={() => apply()}
      disabled={!multiSelect && !singleSelection}
    />
  </svelte:fragment>
</Window>
