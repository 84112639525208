<!-- A special tooltip component to allow showing tooltips from anywhere in the code -->
<script lang="ts" context="module">
  import { tick } from 'svelte'
  import { fade } from 'svelte/transition'
  import type { Vector2 } from '@packages/util'

  type TooltipPlacement = 'bottomRight' | 'bottomLeft' | 'topRight' | 'topLeft'

  // TODO: Use better function calls
  export let showTooltip: (
    _text: string,
    _offset?: Vector2,
    _placement?: TooltipPlacement
  ) => void = () => {}

  export let hideTooltip: () => void = () => {}

  // For usage in use: statements
  export function tooltip(element: HTMLElement, text: string) {
    const show = () => text && showTooltip(text)
    const hide = () => hideTooltip()
    element?.addEventListener('mouseover', show)
    element?.addEventListener('mouseleave', hide)

    return {
      destroy() {
        hideTooltip()
        element?.removeEventListener('mouseover', show)
        element?.removeEventListener('mouseleave', hide)
      },
    }
  }
</script>

<script lang="ts">
  let show = false
  let position: Vector2 = { x: 0, y: 0 }
  let offset: Vector2 = { x: 8, y: 16 }
  let placement: TooltipPlacement = 'bottomRight'
  let text = ''

  let id = `tooltip-${Math.random().toString(16).slice(2)}`
  let isShown = false
  let clientWidth = 0
  let clientHeight = 0

  function setPosition(_position: Vector2) {
    let element = document.getElementById(id)?.style

    _position.x = (_position?.x ?? 0) + (offset?.x ?? 0)
    _position.y = (_position?.y ?? 0) + (offset?.y ?? 0)

    // Apply placement offsets
    switch (placement) {
      case 'bottomLeft':
        _position.x = _position.x - clientWidth
        break
      case 'topLeft':
        _position.x = _position.x - clientWidth
        _position.y = _position.y - clientHeight
        break
      case 'topRight':
        _position.y = _position.y - clientHeight
        break
      // case 'bottomRight': // No Changes needed
    }

    // Apply positioning
    if (element) {
      element.left = `${_position.x}px`
      element.top = `${_position.y}px`
    }
  }

  $: if (show && !isShown) {
    // Showing
    tick().then(() => {
      setPosition(position)
    })

    isShown = true
  } else if (show && isShown) {
    // Shown
    setPosition(position)
  } else if (!show && isShown) {
    // Hiding
    isShown = false
  }

  showTooltip = (
    _text: string,
    _offset: Vector2 = { x: 8, y: 16 },
    _placement: TooltipPlacement = 'bottomRight'
  ) => {
    show = true
    text = _text
    offset = _offset
    placement = _placement
  }

  hideTooltip = () => {
    show = false
    text = null
  }
</script>

<svelte:window
  on:mousemove={(event) => (position = { x: event.clientX, y: event.clientY })}
/>

{#if show}
  <div
    class="tooltip dhx_widget dhx_tooltip dhx_tooltip--bottom dhx_tooltip--animate"
    {id}
    bind:clientHeight
    bind:clientWidth
    transition:fade|global={{ duration: 100 }}
  >
    <div class="dhx_tooltip__text">
      {text}
    </div>
  </div>
{/if}

<style lang="scss">
  @use '../../theme/variables' as vars;
  .tooltip {
    user-select: none;
    pointer-events: none;
    position: fixed;
    z-index: vars.$zId-tooltips;
  }
</style>
