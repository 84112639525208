<!-- ProxySource Component -->
<script lang="ts">
  import type { ProxyComponents } from './ProxyTarget.svelte'
  export let components: ProxyComponents
</script>

<div class="proxySourceUninitialized" use:components.register>
  <slot />
</div>

<style lang="scss">
  .proxySourceUninitialized {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }
</style>
