<!-- Input box that allows you to select the date and time range -->
<script lang="ts">
  import Form from '@components/Form.svelte'
  import type { IBlockConfig, IItemConfig } from '@dhtmlx/ts-form'
  import type { Popup as PopupModel } from '@dhtmlx/ts-popup'
  import DateRange from '@lib/DateRange'
  import type { FormConfig, FormEntity } from '@lib/FormUtil'
  import FormattedDate from '@packages/locale/components/FormattedDate.svelte'
  import type { MappedEvents } from '@packages/util'
  import { awaitRedraw, formatTime } from '@packages/util'
  import { createEventDispatcher } from 'svelte'
  import type { CalendarConfig, CalendarEntity } from './Calendar.svelte'
  import Calendar from './Calendar.svelte'
  import Popup from './Popup.svelte'

  export let value: DateRange = new DateRange()
  export let disabled = false
  export let show = false
  export let compact = false
  export let clearable = false
  export let noTime = false

  type FormItems = (IBlockConfig | IItemConfig)[]

  const timeValidationPattern =
    /^(?<hour>[0-9]{2}):(?<minute>[0-9]{2})(:(?<second>[0-9]{2}))?$/

  interface $$Events {
    change: CustomEvent<DateRange>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()
  const inputId = `date-input-${Math.random().toString(16).slice(2)}`

  let lock = true

  let popupModel: PopupModel

  let form: FormEntity
  let formConfig: FormConfig = {
    config: {
      rows: <FormItems>[
        {
          type: 'input',
          name: 'timeFrom',
          label: 'From',
          labelPosition: 'left',
          labelWidth: '4em',
          validation: (validationValue) =>
            timeValidationPattern.test(String(validationValue)) || noTime,
          css: noTime ? 'hidden' : '',
        },
        {
          type: 'input',
          name: 'timeTo',
          label: 'To',
          labelPosition: 'left',
          labelWidth: '4em',
          validation: (validationValue) =>
            timeValidationPattern.test(String(validationValue)) || noTime,
          css: noTime ? 'hidden' : '',
        },
        {
          cols: <FormItems>[
            ...(clearable
              ? [
                  {
                    type: 'button',
                    name: '_clear',
                    value: 'Clear',
                    view: 'link',
                    // circle: true,
                    padding: '0.25em',
                  },
                ]
              : []),
            { type: 'spacer' },
            {
              type: 'button',
              name: '_cancel',
              value: 'Cancel',
              view: 'link',
              // circle: true,
              padding: '0.25em',
            },
            {
              type: 'button',
              name: '_apply',
              value: 'Apply',
              // circle: true,
              padding: '0.25em',
            },
          ],
        },
      ],
    },
  }

  let calendar: CalendarEntity
  let calendarConfig: CalendarConfig = {
    range: true,
    timePicker: false,
    css: 'noBoxShadow',
  }

  $: setTimeVisibility(!noTime)

  /**
   * Set all the values after showing the popup
   */
  function onShowPopup() {
    lock = true
    setTimeVisibility(!noTime)

    calendar.dhx.setValue(value?.asArray() ?? [])
    form.dhx.setValue({
      timeFrom: setTimeInput('timeFrom', value?.getStart() ?? new Date()),
      timeTo: setTimeInput('timeTo', value?.getEnd() ?? new Date()),
    })

    form.dhx.events.on('click', (_id) => {
      switch (_id) {
        case '_clear':
          clear()
          break
        case '_cancel':
          cancel()
          break
        case '_apply':
          apply()
          break
      }
    })
  }

  /**
   * Take the date object and set its time from an input string
   */
  function setHoursFromString(date: Date, inputValue: string) {
    let time = timeValidationPattern.exec(inputValue)
    if (!time) return date
    date.setHours(
      parseInt(time.groups.hour),
      parseInt(time.groups.minute),
      parseInt(time.groups.second ?? '0')
    )
    return date
  }

  /**
   * Get and return the values of the calendar and the form elements
   */
  function getSelectedTime() {
    // Get the calendar's value
    const calendarValue = calendar.dhx.getValue(true) ?? new Date()

    // Ensure the calendar value is a Date range
    let dateRange: DateRange

    if (calendarValue instanceof Date && !Array.isArray(calendarValue)) {
      dateRange = new DateRange(calendarValue, structuredClone(calendarValue))
    } else if (Array.isArray(calendarValue)) {
      if (calendarValue.length == 2) {
        dateRange = new DateRange(calendarValue[0], calendarValue[1])
      } else {
        dateRange = new DateRange(
          calendarValue[0],
          structuredClone(calendarValue[0])
        )
      }
    }

    // Set the time value to the currently set input
    const timeValue = form.dhx.getValue()
    dateRange.start = setHoursFromString(
      dateRange.start,
      noTime ? '00:00:00' : timeValue['timeFrom']
    )
    dateRange.end = setHoursFromString(
      dateRange.end,
      noTime ? '23:59:59' : timeValue['timeTo']
    )

    return dateRange
  }

  function apply() {
    value = getSelectedTime()
    dispatch('change', value)
    lock = false
    awaitRedraw().then(() => popupModel.hide())
  }

  function cancel() {
    lock = false
    awaitRedraw().then(() => popupModel.hide())
  }

  function clear() {
    value = null
    dispatch('change', value)
    lock = false
    awaitRedraw().then(() => popupModel.hide())
  }

  /**
   * Set the elements of the form to work correctly
   */
  function setTimeInput(which: 'timeFrom' | 'timeTo', value: Date) {
    let time = formatTime(value)

    // dhtmlx doesn't support native `time` inputType, this will force the type
    let element = (<HTMLElement>(
      form.dhx.getItem(which).getRootNode()
    )).querySelector('input')

    element.type = 'time'
    element.step = '1'
    element.pattern = timeValidationPattern.toString()
    element.value = time ?? '00:00:00'

    return time
  }

  /** Show or hide the time selection boxes */
  function setTimeVisibility(visible: boolean) {
    const timeFromNode: Nullable<HTMLElement> = form?.dhx
      ?.getItem('timeFrom')
      ?.getRootNode()?.parentElement
    const timeToNode: Nullable<HTMLElement> = form?.dhx
      ?.getItem('timeTo')
      ?.getRootNode()?.parentElement

    if (!timeFromNode || !timeToNode) return

    if (visible) {
      timeFromNode.classList.remove('hidden')
      timeToNode.classList.remove('hidden')
    } else {
      timeFromNode.classList.add('hidden')
      timeToNode.classList.add('hidden')
    }
  }
</script>

<!-- Handler for the escape keypress -->
<svelte:window
  on:keydown={(event) => {
    if (event.key == 'Escape' && show) cancel()
  }}
/>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="maxHeight dhx_input__wrapper dhx_button"
  class:compact
  id={inputId ?? 'date-input'}
  on:click={() => (show = true)}
  on:change
  class:dhx_form-group--disabled={disabled}
>
  <div class="maxHeight dhx_input__container">
    <div class="maxHeight dhx_input">
      {#if !compact}
        <div class="labelRow">
          <div class="label">From</div>
          <div class="value">
            {#if value?.start}
              <FormattedDate value={value?.start} precision="s" />
            {:else}
              ---
            {/if}
          </div>
        </div>
        <div class="labelRow">
          <div class="label">To</div>
          <div class="value">
            {#if value?.end}
              <FormattedDate value={value?.end} precision="s" />
            {:else}
              ---
            {/if}
          </div>
        </div>
      {:else}
        <div class="labelRowCompact">
          <div class="label">
            {#if value}
              Range Selected
            {/if}
          </div>
          <div class="dxi dxi-calendar-today"></div>
        </div>
      {/if}
    </div>
  </div>
</div>

<!-- NOTE: Add {lock} to disallow outside-click hiding -->
<Popup bind:popupModel bind:show on:afterShow={onShowPopup}>
  <Calendar bind:calendar {calendarConfig} />
  <Form bind:form {formConfig} />
</Popup>

<style lang="scss">
  .dhx_form-group--disabled {
    cursor: default;
  }

  .labelRow {
    display: flex;

    .label {
      width: 3em;
      font-weight: 500; // Bold
      text-align: end;
      padding-right: 0.5em;
    }
  }

  .maxHeight {
    height: 100%;
  }

  .compact .dhx_input {
    background: white !important;
  }

  .labelRowCompact {
    display: flex;
    width: 100%;

    .label {
      flex-grow: 1;
      font-weight: initial;
    }
  }

  :global(.dhx_calendar.noBoxShadow) {
    box-shadow: none;
    // box-shadow: 0 2px 5px rgb(0 0 0 / 30%);
  }
</style>
