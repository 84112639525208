import type {
  IFormConfig,
  ProForm,
  Validation,
  ValidationInputFn,
} from '@dhtmlx/ts-form'
import type { SvelteComponent } from 'svelte'
import { commonStrings } from './CommonStrings'
import { camel2title } from '@packages/util'
import type { MaybeReadable } from '../packages/util/lib/UtilityTypes'

/** List of components using the Glob Import function of ViteJS */
const modules: Record<string, { default: typeof SvelteComponent }> =
  import.meta.glob('../components/FormControls/**/*.svelte', {
    eager: true,
  })

/** Map modules to a usable list */
export const FormControlList: Record<string, typeof SvelteComponent<any>> =
  Object.fromEntries(
    Object.values(modules).map((item) => [
      // Remove the Proxy< ... > text
      // item.default.name.replaceAll(/Proxy\<|\>/g, ''),
      //@ts-ignore
      item._name,
      item.default,
    ])
  )

/**
 * Get the form item that initializes a component.
 */
export function formComponent<TConfig extends Record<string, any>>(
  type: string,
  definition: CustomFormItem<TConfig>
) {
  return {
    type: 'container',
    html: `@${type}`,
    ...(definition.required
      ? {
          errorMessage: commonStrings.valueRequired,
        }
      : {}),
    ...definition,
    label:
      definition.label ??
      camel2title(definition.name.replace(/(id)?(@formattedText)?$/i, '')),
  }
}

export interface CustomFormItem<TConfig extends Record<string, any>> {
  name: string
  config?: MaybeReadable<TConfig>
  label?: string
  required?: boolean
  [other: string]: any
}

export interface FormConfig {
  id?: string
  config?: IFormConfig
}

export interface FormEntity {
  dhx: Nullable<ProForm>
  rebuild: () => void
  setValue: (data: Record<string, any>) => void
  getValue: () => Record<string, any>
  disable: () => void
  enable: () => void
  changeValue: (name: string, value: any) => void
}

export interface ICustomContainerConfig {
  $component?: SvelteComponent
  type: 'container'
  id?: string
  html: `@${string}` // Must start with a @ to make it work
  name: string
  label?: string
  config?: MaybeReadable<Record<string, any>>
  required?: boolean
  value?: any
  validation?: Validation | ValidationInputFn
  state?: 'error' | 'success' | null
  helpMessage?: string
  preMessage?: string
  successMessage?: string
  errorMessage?: string
}
