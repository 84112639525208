<div class="loadingSpinner">
  <img src="/img/loading_icon.svg" alt="Loading indicator" height="100px" />
</div>

<style lang="scss">
  .loadingSpinner {
    margin: 0.5em;
    opacity: 0.75;
  }
</style>
