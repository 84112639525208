<!-- Form element that supports generating a select box based on a TypeScript Enum with labels -->
<script lang="ts" context="module">
  import Combobox from '@components/Combobox.svelte'
  import type { ComboboxItem } from '@components/ComboboxAsync.svelte'
  import { getGeneratedEnumItems } from '@models/api/ApiEnumLabels'
  import {
    awaitRedraw,
    chain,
    isSet,
    single,
    sleep,
    useDebugger,
  } from '@packages/util'
  import { onDestroy, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export interface EnumSelectConfig {
    enum?: Record<any, any> // name: enumValue and enumValue: name
    hide?: any[] // Enum values
    defaultValue?: any // Enum value
    includeEmpty?: boolean
    multiselect?: boolean
  }

  export const _name = 'EnumSelect'
  const valuePrefix = 'value::'
</script>

<script lang="ts">
  export let value: Nullable<string | string[]> = null // enum value
  export let config: EnumSelectConfig = {
    enum: {},
    hide: [],
    defaultValue: null,
    includeEmpty: false,
    multiselect: false,
  }
  export let label = 'Select'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  const debug = useDebugger(_name).disable

  let _ready = false
  let _valueLock = false
  let _value: Nullable<string> = null

  $: updateInternal(value)
  $: debug('UPDATE_ITEMS', getItems(config))

  /** Process the enum items and labels*/
  function getItems(config: EnumSelectConfig) {
    if (!config?.enum)
      throw new Error(
        'EnumSelect: `config.enum` must be set for the form element to work.'
      )

    return new Array<ComboboxItem>()
      .concat(config.includeEmpty ? [{ value: '', id: '-- None --' }] : [])
      .concat(
        getGeneratedEnumItems(config.enum)
          // Map items
          .map((item) => ({
            id: valuePrefix + item.value,
            value: item.label,
          }))
          // Filter out hidden items
          .filter(
            (item) =>
              !(
                config?.hide?.some?.((_i) => valuePrefix + _i == item.id) ??
                false
              )
          )
      )
  }

  /** Update the internal value */
  function updateInternal(newValue: typeof value) {
    if (!_ready || _valueLock) return

    debug('INT_SET_BEFORE', newValue, config?.defaultValue)

    _value = null // Force the reactivity to work
    _value = chain(newValue ?? config?.defaultValue)
      // Make sure the items are an array
      .map((item) => (config.multiselect ? item : [item]) as string[])
      // Prefix each item ID
      .map((items) => items.map?.((item) => valuePrefix + String(item)))
      // Recombine the items
      .map((items) => (config.multiselect ? items.join(',') : single(items)))
      // Collect the result
      .collect()

    // HACK: Wait a little bit before applying the default value if neither current value or new value is set
    if (!isSet(value) && !isSet(newValue) && isSet(config?.defaultValue)) {
      debug('APPLY_DEFAULT_SOON', config?.defaultValue)
      sleep(10).then(() => {
        // Check current value again to really make sure
        if (isSet(value)) return
        // Apply the default value
        debug('APPLY_DEFAULT_NOW', config?.defaultValue)
        value = config.defaultValue
      })
    }

    debug('INT_SET_AFTER', _value)
  }

  function updateExternal(newValue: string) {
    debug('EXTERNAL_SET_BEFORE', newValue)
    _valueLock = true

    value = chain(newValue)
      // Split if multi-select otherwise, make sure it is an array
      .map((item) => (config.multiselect ? newValue.split(',') : [item]))
      // Map to `<valuePrefix><itemID>`
      .map((items) =>
        items.map((item) =>
          Object.values(config?.enum).find(
            (enumItem) => valuePrefix + String(enumItem) == String(item)
          )
        )
      )
      // if multi-select, filter out undefined values, else get a single item
      .map((items) =>
        config.multiselect ? items.filter(isSet) : single(items)
      )
      // Collect the result
      .collect()

    debug('EXTERNAL_SET_AFTER', value)

    awaitRedraw().then(() => (_valueLock = false))
  }

  /** Update the external value on internal value change */

  onMount(async () => {
    await tick()
    _ready = true
    await tick()
    updateInternal(value)
  })

  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  {#if _ready}
    <Combobox
      comboboxConfig={{
        css: 'enumCombobox',
        ...(config?.multiselect ?? false
          ? {
              multiselection: true,
              itemsCount: function (num) {
                return num + (num === 1 ? ' item' : ' items') + ' selected'
              },
            }
          : {}),
      }}
      data={getItems(config)}
      selected={_value}
      on:change={(event) => updateExternal(event?.detail)}
    />
  {/if}
</FormElementBase>

<style lang="scss">
  :global(.enumCombobox) {
    width: 100%;
  }
</style>
