<script lang="ts" context="module">
  import type { Combobox, IComboboxConfig } from '@dhtmlx/ts-combobox'
  import { DhxCombobox } from '@lib/dhtmlx'
  import { awaitRedraw, type MappedEvents } from '@packages/util'
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'

  export { type Combobox as ComboboxModel }
  0
</script>

<script lang="ts">
  interface $$Events {
    change: CustomEvent<string>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()
  const id = `combobox-${Math.random().toString(16).slice(2)}`

  export let comboboxConfig: IComboboxConfig = {}
  export let combobox: Combobox = null

  export let selected: string = null
  export let data: { id: string; value: string }[] = null
  export let disabled: boolean = false

  export let state: null | 'success' | 'error' = null

  let disableEnhancements = false

  let isOpen = false
  let manuallyChanged = false
  let previousValue: any

  $: if (disabled) {
    combobox?.disable()
  } else {
    combobox?.enable()
  }

  $: if (data || comboboxConfig?.data) {
    combobox?.data?.parse(structuredClone(data ?? <any>comboboxConfig?.data))
  }

  $: if (!isOpen) {
    combobox?.setValue(selected?.split?.(',') ?? selected)
    combobox?.paint()
  }

  function enhanceControls() {
    // Save the previous value and clear the input on opening
    combobox?.events.on(
      'beforeOpen',
      () => {
        disableEnhancements = combobox.config.multiselection
        if (disableEnhancements) return

        previousValue = combobox.getValue()
        combobox.clear()
        manuallyChanged = false

        isOpen = true

        awaitRedraw().then(attachEmptyOptionClickHandler)
      },
      id + '_beforeOpen'
    )

    // Restore the value if it hasn't been manually changed
    combobox?.events.on(
      'afterClose',
      () => {
        if (disableEnhancements) return
        isOpen = false
        if (!manuallyChanged) combobox.setValue(previousValue)
        else handleValueChange()
      },
      id + '_afterClose'
    )

    // Mark the element as changed if done so manually
    combobox?.events.on(
      'change',
      () => {
        disableEnhancements = combobox.config.multiselection

        if (disableEnhancements) {
          handleValueChange()
        } else if (isOpen) {
          manuallyChanged = true
        }
      },
      id + '_change'
    )
  }

  function handleValueChange() {
    // The value has changed
    const selectedId = combobox.getValue()
    selected = String(selectedId)
    dispatch('change', selectedId as string)
  }

  /**
   * Manually attach a click handler for the first item.
   * On click, it forces the value to be set to that item.
   */
  function attachEmptyOptionClickHandler() {
    const element = combobox.popup
      .getRootNode()
      .querySelector(
        '.dhx_list .dhx_list-item[data-dhx-id=""]'
      ) as HTMLLIElement

    element?.addEventListener('click', _emptyOptionClickHandler, { once: true })
  }

  async function _emptyOptionClickHandler() {
    manuallyChanged = true
    combobox.popup.hide()
    combobox.setValue('')
    await awaitRedraw()
    setVisualText('')

    // The value has changed
    const selectedId = ''
    selected = String(selectedId)
    dispatch('change', selectedId)
  }

  /**
   * Directly set the (fake) input box's value
   */
  function setVisualText(value: string) {
    const element = combobox.getRootNode()?.querySelector('input')
    if (element) element.value = value
  }

  onMount(async () => {
    combobox = new DhxCombobox(id, { ...comboboxConfig, disabled })
    combobox.data.parse(structuredClone(data ?? <any>comboboxConfig.data))
    enhanceControls()
    await awaitRedraw()
    combobox.setValue(selected?.split(','))
  })

  onDestroy(() => {
    combobox?.destructor()
  })
</script>

<div
  class="combobox"
  class:dhx_form-group--state_error={state == 'error'}
  {id}
></div>

<style lang="scss">
  :global(.dhx_combobox.noMargin) {
    margin: 0 !important;
  }
</style>
