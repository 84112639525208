<script lang="ts" context="module">
  import Button from '@components/Utility/Button.svelte'
  import { commonStrings } from '@lib/CommonStrings'
  import { getOpenItemPageHref } from '@lib/OpenItemPageHandler'
  import { mdiOpenInApp, mdiPlus } from '@mdi/js'
  import { baseApi, type ModelReference } from '@models/api/BaseApi'
  import type { AnyBaseModel } from '@models/api/BaseModel'
  import type { MaybeReadable } from '@packages/util'
  import {
    any,
    camel2title,
    debounce,
    isSet,
    isUuid,
    wrapReadableStore,
  } from '@packages/util'
  import { pages } from '@pages/manageEntity/Pages'
  import { onDestroy, onMount, tick } from 'svelte'
  import ComboboxAsync, { type ComboboxItem } from '../ComboboxAsync.svelte'
  import FormElementBase from './FormElementBase.svelte'

  export interface _EntitySelectConfig {
    type: ModelReference
    filter?: Record<string, any>
    sort?: string
    allowEmpty?: boolean // Setting this to false will not allow an empty value to be set.
    hideExtraButtons?: boolean // Setting this to true will hide the extra buttons (open/new)
    newParams?: Record<any, any>
    openParams?: Record<any, any>
  }

  export type EntitySelectConfig = MaybeReadable<_EntitySelectConfig>

  export const _name = 'EntitySelect'
</script>

<script lang="ts">
  export let value: Nullable<string> = null // ID
  export let config: EntitySelectConfig = {
    type: 'asset',
  }
  export let label = 'ComboBox'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _ready = false
  let _value = value
  let _valueLock = false

  let combobox: Nullable<ComboboxAsync> = undefined
  let apiClassName = ''

  $: update(value)
  $: configStore = getConfigStore(config)
  $: _state = state == 'error' && !isSet(value) ? state : null

  /** Invalidate the combobox on config store change */
  function getConfigStore(_config: typeof config) {
    const _store = wrapReadableStore(_config)
    _store.subscribe(() => {
      combobox?.invalidate()
      getCurrent(value)
    })
    return _store
  }

  function getApiModel() {
    apiClassName = $configStore?.type
    const _apiModel = baseApi[apiClassName ?? '---'] as AnyBaseModel | null

    if (!_apiModel)
      throw new Error(
        'EntitySelect: apiModel must be set in order for this form item to work.'
      )

    return _apiModel
  }

  async function getData() {
    const data = await getApiModel().getPage({
      fields: ['id', getNameField()],
      sort: $configStore?.sort ?? getNameField(),
      limit: 1000,
      ...($configStore?.filter ? { filter: $configStore?.filter } : {}),
    })

    if (!data.results) return null

    return [
      ...($configStore?.allowEmpty
        ? [
            {
              id: 'NULL',
              value: '-- None --',
            } as ComboboxItem,
          ]
        : []),
      ...data.results.map<ComboboxItem>((item) => ({
        id: item.id,
        value: item[getNameField()],
      })),
    ]
  }

  function getNameField() {
    return getApiModel().context.nameField
  }

  const getCurrent = debounce(async (id: string) => {
    if (!isUuid(id)) {
      combobox?.setVisualText(
        $configStore?.allowEmpty ? '-- None --' : '-- Select an Item --'
      )
    } else {
      try {
        combobox?.setVisualText('-- Loading... --')
        combobox?.setVisualText(
          (await getApiModel().getOne(id, { fields: [getNameField()] }))?.[
            getNameField()
          ] ?? '-- Error --'
        )
      } catch (error) {
        console.error(error)
        combobox?.setVisualText('-- Error --')
        throw error
      }
    }
  }, 100)

  /** Update the internal value */
  function update(newValue: typeof value) {
    if (!_ready || _valueLock) return
    _value = newValue
    getCurrent(newValue)
  }

  /** Update the external value on internal value change */
  function handleValueChange(event: CustomEvent<string>) {
    const newValue = event.detail

    if (!_ready || _valueLock) return
    _valueLock = true

    value = newValue == 'NULL' ? null : newValue

    // Clear state
    state = null

    tick().then(() => (_valueLock = false))
  }

  export function invalidate() {
    combobox?.invalidate()
  }

  onMount(async () => {
    await tick()
    _ready = true
    await tick()
    update(value)
    await tick()
    apiClassName = $configStore?.type
  })
  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  state={_state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={errorMessage ?? commonStrings.valueRequired}
>
  <div class="entitySelectWrapper">
    <ComboboxAsync
      bind:this={combobox}
      selected={_value}
      on:change={handleValueChange}
      {getData}
      state={_state}
    />

    <!-- Extra buttons (open/new) -->
    {#if apiClassName && !$configStore?.hideExtraButtons && Object.keys(pages).includes(apiClassName)}
      <Button
        iconOnly
        icon={mdiOpenInApp}
        route={isUuid(value) &&
          getOpenItemPageHref(
            any(apiClassName),
            any(value),
            undefined,
            $configStore?.openParams
          )}
        disabled={!isUuid(value)}
        tooltip="Open Selected {label ?? camel2title(apiClassName)}"
      />
      <Button
        iconOnly
        icon={mdiPlus}
        route={getOpenItemPageHref(
          any(apiClassName),
          'new',
          undefined,
          $configStore?.newParams
        )}
        tooltip="Create New {label ?? camel2title(apiClassName)}"
      />
    {/if}
  </div>
</FormElementBase>

<style lang="scss">
  .entitySelectWrapper {
    display: flex;

    :global(.combobox) {
      flex-grow: 1;
    }
  }
</style>
