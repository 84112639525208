/** List of models using the Glob Import function of ViteJS */
import type { ICol } from '@dhtmlx/ts-grid'
import * as Generated from '@generated'
import { commonColumns } from '@lib/CommonColumns'
import { uncapitalize } from '@packages/util'
import type { WritableKeys } from '@packages/util/lib/UtilityTypes'
import type ApiBaseModel from './ApiBaseModel'
import BaseModel from './BaseModel'

/**
 * Since ViteJS does not import the types,
 * you will need to add each model file here
 * to properly show in the completion
 */
type Models = {
  AppFunction: Generated.AppFunction
  Asset: Generated.Asset
  AssetClass: Generated.AssetClass
  Batch: Generated.Batch
  BatchType: Generated.BatchType
  Channel: Generated.Channel
  Dashboard: Generated.Dashboard
  DashboardWidget: Generated.DashboardWidget
  Enum: Generated.Enum
  Group: Generated.Group
  Process: Generated.Process
  ProcessStep: Generated.ProcessStep
  Program: Generated.Program
  Report: Generated.Report
  Tag: Generated.Tag
  User: Generated.User
  View: Generated.View
}

interface Module<T extends ApiBaseModel> {
  // default: T
  __context__?: ModelContext<T>
}

export type FilterTypeModel = { model: ModelReference }
export type FilterTypeEnum = {
  enum: Record<any, any>
}

export type FilterType =
  | null
  | 'string'
  | 'datetime'
  | 'date'
  | 'time'
  | 'numeric'
  | 'integer'
  | 'boolean'
  | FilterTypeModel
  | FilterTypeEnum

export interface ModelContext<T extends ApiBaseModel> {
  /** Override the endpoint name */
  overrideEndpoint?: string
  /** The name of the model (Defaults to the name of the model file) */
  name?: string
  /** The columns available (Includes base columns by default) */
  columns: BaseApiColumns<T>
  /** The available filters for each column (For use in filtering) */
  filters: Record<WritableKeys<Omit<T, BaseModelColumns>>, FilterType>
  /** The default columns selected for lists */
  defaultColumns: BaseApiColumnNames<T>
  /** The default sort selected for lists */
  defaultSort?: Extract<keyof T, string> | `-${Extract<keyof T, string>}`
  /** The field to extract the name from */
  nameField?: Extract<keyof T, string>
  /** Should the model show up in the list of selectable models (in custom lists) */
  customListSupport?: boolean
}

export type BaseApiColumns<T extends ApiBaseModel> = Partial<
  Record<keyof T, () => ICol>
>

export type BaseApiColumnNames<T extends ApiBaseModel> = Array<keyof T>

/** The base columns on each model */
export type BaseModelColumns =
  | 'id'
  | 'status'
  | 'modifiedBy'
  | 'modifiedOn'
  | 'createdBy'
  | 'createdOn'

/** The columns that comes with each model */
const baseModelColumns: Record<Exclude<BaseModelColumns, 'id'>, () => ICol> = {
  status: () => commonColumns.status,
  modifiedBy: () => commonColumns.modifiedBy,
  modifiedOn: () => commonColumns.modifiedOn,
  createdBy: () => commonColumns.createdBy,
  createdOn: () => commonColumns.createdOn,
}

/** The filters that comes with each model */
const baseModelFilters: Record<Exclude<BaseModelColumns, 'id'>, FilterType> = {
  status: { enum: Generated.BaseModel_STATUSEnum },
  modifiedBy: { model: 'user' },
  modifiedOn: 'datetime',
  createdBy: { model: 'user' },
  createdOn: 'datetime',
}

function initContext(
  context: ModelContext<any>,
  path: string
): ModelContext<any> {
  return {
    ...context,
    columns: { ...baseModelColumns, ...context.columns },
    filters: { ...baseModelFilters, ...context.filters },
    defaultSort: context.defaultSort ?? '-modifiedOn',
    nameField: context.nameField ?? 'id',
    name: context.name ?? getName(path),
  }
}

/** Parse the file name and turn it into a usable interface name */
function getName(path: string) {
  return /([\w_-]+)\.ts$/.exec(path)?.[1] ?? ''
}

/** Get the list of model files */
const modules = import.meta.glob('./apiModels/*.ts', { eager: true }) as {
  [P in keyof Models]: Module<Models[P]>
}

/** Parse the list and turn it into a usable object of BaseModels */
export const baseApi = Object.fromEntries(
  Object.entries(modules).map(([path, module]) => {
    if (!module.__context__) {
      throw new Error(
        `In '${path}': Module must export __context__ with type ModelContext<T>`
      )
    }
    const context = initContext(module.__context__, path)

    return [
      uncapitalize(context.name) as any,
      new BaseModel(context as ModelContext<any>),
    ]
  })
) as { [P in keyof Models as Uncapitalize<P>]: BaseModel<Models[P], P> }

export type ModelReference = Uncapitalize<keyof Models>
export type ModelDereference<T extends ModelReference> = Models[Capitalize<T>]
