<script lang="ts" context="module">
  import GlobalTooltip from '@components/Global/GlobalTooltip.svelte'
  import GlobalWindowTarget from '@components/Global/GlobalWindowTarget.svelte'
  import Header from '@components/LayoutSections/Header.svelte'
  import Sidebar from '@components/Sidebar.svelte'
  import GlobalToolbar from '@components/Toolbar/GlobalToolbar.svelte'
  import Container from '@components/Utility/Container.svelte'
  import { loginData } from '@lib/ApiHandler'
  import { getDefaultSidebar } from '@lib/Sidebars'
  import { config } from '@lib/configStore'
  import { isSet } from '@packages/util'
  import { writable } from 'svelte/store'

  export const fullscreen = writable(false)
  export const sidebarOpen = writable(true)
  export const pageTitle = writable<Nullable<string>>(null)
</script>

<script lang="ts">
  $: sidebar = $loginData && getDefaultSidebar()

  pageTitle.subscribe(
    (newTitle) => (document.title = isSet(newTitle) ? newTitle : $config.title)
  )
</script>

<div class="page-container">
  <div hidden={$fullscreen} style="width:100%">
      <Header on:toggleSidebar={() => ($sidebarOpen = !$sidebarOpen)} />
  </div>
  <GlobalTooltip />
  
  <div class="container">
    <Sidebar items={sidebar} open={sidebarOpen} />
    <!-- <Sidebar items={sidebar} collapsible logo open={sidebarOpen} /> -->
    <div class="content-container" class:fullscreen={$fullscreen}>
      <!-- <HeaderNew on:toggleSidebar={() => ($sidebarOpen = !$sidebarOpen)} /> -->
      <!-- <GlobalToolbar /> -->
      <Container>
        <div class="content-slot">
          <slot />
        </div>
      </Container>
    </div>
  </div>
</div>

<GlobalWindowTarget />

<style lang="scss">
  @use '../theme/variables' as vars;

  .page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    min-width: 768px;
  }

  .container {
    display: flex;
    flex-grow: 1;
  }

  .content-container {
    flex-grow: 1;
    border-left: 1px solid vars.$border;
    display: flex;
    flex-direction: column;

    .content-slot {
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }
</style>
