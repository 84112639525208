<script>
  import LoadingSpinner from '@components/Utility/LoadingSpinner.svelte'
</script>

<!-- LoadingView Component -->
<div class="loadingView">
  <LoadingSpinner />
  <span class="loadingMessage">Loading Page</span>
</div>

<style lang="scss">
  @keyframes appear {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .loadingView {
    opacity: 0;
    animation: appear 250ms 500ms forwards;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading {
      padding: 2em;
      mix-blend-mode: multiply; // Removes the white edges
    }
  }
</style>
