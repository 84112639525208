<!-- FileTree Component -->
<script lang="ts" context="module">
  import { request } from '@lib/ApiUtil'
  import { dhtmlxGridRowHeightMedium } from '@lib/CommonConstants'
  import { getFileIcon, getWbIconMap } from '@lib/FileIcons'
  import { getSvg } from '@lib/Icons'
  import { mdiArrowUpLeft } from '@mdi/js'
  import type { File as __File__ } from '@models/api/ApiModels'
  import { fileApi, type DirectoryRef } from '@models/api/FileApi'
  import type { MappedEvents } from '@packages/util'
  import { awaitRedraw, getId, isSet, isString, isUuid } from '@packages/util'
  import '@theme/_wunderbaum-style.scss'
  import { createEventDispatcher, onMount, tick } from 'svelte'
  import type {
    DndOptionsType,
    DropEventType,
    SourceAjaxType,
    WbActivateEventType,
    WbCancelableEventResultType,
    WbClickEventType,
    WbNodeData,
    WbReceiveEventType,
    WbRenderEventType,
  } from 'types'
  import type { EditExtension } from 'wb_ext_edit'
  import type { WunderbaumNode } from 'wb_node'
  import type { WunderbaumOptions } from 'wb_options'
  import type { Wunderbaum } from 'wunderbaum'

  export type StringFilter = Nullable<(string | RegExp)[] | string | RegExp>

  /** Helper type that points to a file */
  type FileRef = Uuid

  export const fileParentKey = 'PARENT'
  export type FileParentKey = typeof fileParentKey

  /** File with the minimum fields to function */
  type PartialFile = Omit<
    Pick<__File__, (typeof requestFields)[number]>,
    'nodes'
  > & {
    nodes: PartialFile[]
  }

  const requestFields = [
    'id',
    'parent',
    'name',
    'directory',
    'extension',
    'mimeType',
    'nodes',
  ] as const satisfies (keyof __File__)[]

  export type OpenFileEventData = { inFilter: boolean; file: PartialFile }
  _:;
</script>

<script lang="ts">
  /** The available events */
  interface $$Events {
    /** On file focus */
    focus: CustomEvent<PartialFile>
    /** On file open request */
    openFile: CustomEvent<OpenFileEventData>
    /** On directory open request */
    openDirectory: CustomEvent<DirectoryRef>
    /** On parent directory open request (gets the current FileRef) */
    openParent: CustomEvent<DirectoryRef>
    /** On file move request */
    move: CustomEvent<{
      fileId: FileRef
      targetParent: FileRef | FileParentKey
    }>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  const id = getId('fileTree')
  const parentDirectoryRefId = getId('parentDirectory')

  // NOTE: Waiting for Wunderbaum to merge https://github.com/mar10/wunderbaum/pull/85
  const rowHeightPx = dhtmlxGridRowHeightMedium

  /** The parent ID (Defaults to user directory) */
  export let parentId: Nullable<DirectoryRef> = null

  /** @readonly */
  export let selected: Nullable<PartialFile> = null

  /** @readonly Checks if the selected node passes the applied filters */
  export let isSelectedNodeInFilter = false

  /** Show the parent virtual file */
  export let showParent = false

  /** Set a filter on the file type */
  export let fileTypeFilter: StringFilter = null

  export let renameFileHandler: Nullable<
    (fileId: FileRef, newName: string) => Promise<any>
  > = null

  /** The Wunderbaum tree component itself */
  let tree: Nullable<Wunderbaum>
  let _ready = false
  let _currentTempDirectoryEdit: Nullable<WunderbaumNode>
  let _edit_safeToRemove = false

  $: load(parentId)
  $: applyFileTypeFilter(fileTypeFilter)

  $: isSelectedNodeInFilter =
    !isFilterSet(fileTypeFilter) ||
    (selected && getFileTypeMatcher(fileTypeFilter)(selected))

  /** Get the file node */
  function fileToNode(file: PartialFile): WbNodeData {
    return <WbNodeData>{
      title: file.name,
      refKey: file.id,
      lazy: !!file.directory,
      type: file.directory ? 'directory' : 'file',
      ext: file.extension,
      file,
      ...(!file.directory ? { icon: getFileIcon(file) } : {}),
    }
  }

  /** Get the parent link node */
  function parentLink() {
    return <WbNodeData>{
      title: 'Go to Parent Directory',
      refKey: parentDirectoryRefId,
      type: 'parent',
    }
  }

  /** Open the file or parent on double click */
  function handleDoubleClick(
    event: WbClickEventType
  ): WbCancelableEventResultType {
    const fileId = event.node.refKey
    const type = event.node.type
    const file = event.node.data?.file

    if (fileId == parentDirectoryRefId) {
      dispatch('openParent', parentId)
    } else if (type != 'directory') {
      dispatch('openFile', {
        inFilter:
          !isFilterSet(fileTypeFilter) ||
          (file && getFileTypeMatcher(fileTypeFilter)(file)),
        file,
      })
    } else {
      dispatch('openDirectory', fileId as FileRef)
    }

    // Inhibit default action
    return false
  }

  /** Change the focus on activate (dispatches event once) */
  function handleActivate(event: WbActivateEventType) {
    const fileId = event.node.refKey
    const file = event.node.data?.file
    if (fileId == parentDirectoryRefId) return
    if (selected?.id != file?.id) dispatch('focus', file)
    selected = file
  }

  /** Dispatch the move event when a file gets moved */
  function handleDrop(event: Parameters<DndOptionsType['drop']>[0]) {
    const isToParent = event.node.refKey == parentDirectoryRefId
    const toFile = event.node.data?.file as PartialFile

    if (!toFile && !isToParent) return

    // Get source file ID
    const fromId = event.sourceNode?.data?.file?.id

    // Get target file ID (Or 'PARENT' if requested to move to parent)
    const toId = isToParent
      ? fileParentKey
      : toFile.directory
        ? toFile.id
        : toFile.parent

    // Dispatch event when source and target are present
    if (fromId && toId)
      dispatch('move', { fileId: fromId, targetParent: toId as any })
  }

  /** Check if the file can be moved to the dragged location */
  function handleDragEnter(
    event: DropEventType
  ): ReturnType<DndOptionsType['dragEnter']> {
    const sourceFile = event.sourceNode?.data?.file as Nullable<PartialFile>
    const targetFile = event.node?.data?.file as Nullable<PartialFile>
    const isTargetParentDirectory = event.node?.refKey == parentDirectoryRefId

    // Don't allow same parent moves (reorder), unless the target is a directory
    if (
      // Not a parent directory item?
      !isTargetParentDirectory &&
      // Same parent?
      sourceFile?.parent == targetFile?.parent &&
      // Not a directory?
      !targetFile?.directory
    ) {
      return false
    }

    // Allow moving into directory
    if (event.node.type === 'directory' || !!targetFile?.directory) {
      event.event.dataTransfer.dropEffect = 'move'
      return 'over'
    }

    // Allow move to position in directory
    return new Set(['before', 'after'])
  }

  /** Build the endpoint information */
  function getLazyDataEndpoint(
    parentId: string,
    withBaseUrl = false
  ): SourceAjaxType {
    return {
      url: fileApi.getUrl(parentId, null, withBaseUrl),
      params: {
        expand: 'nodes',
        fields: [].concat(
          requestFields,
          requestFields.map((item) => `nodes.${item}`)
        ),
      },
    }
  }

  /** Parse the incoming data from WB's lazy dataloader */
  function parseIncomingData(event: WbReceiveEventType): WbNodeData[] {
    let files: PartialFile | PartialFile[] = event.response
    files = Array.isArray(files) ? files : files.nodes

    return [
      ...(isUuid(parentId) && event.node.isRootNode() && showParent
        ? [parentLink()]
        : []),
      ...files
        .sort(
          // Sort on directory THEN name
          (a, b) =>
            Number(b.directory) - Number(a.directory) ||
            a.name.localeCompare(b.name)
        )
        .map(fileToNode),
    ]
  }

  /** Unpack the data to allow reference inside the header */
  function unpackDataBeforeRender(data: WbRenderEventType) {
    // Unpacks all columns inside e.data
    const node = data.node
    for (const col of Object.values(data.renderColInfosById)) {
      switch (col.id) {
        default:
          // Assumption: we named column.id === node.data.NAME
          col.elem.textContent = node.data[col.id]
          break
      }
    }
  }

  /** Reload the list (loads a directory if set, which sets parentId) */
  export function load(directoryId?: DirectoryRef) {
    if (!_ready) return
    if (directoryId) parentId = directoryId
    // Deselect current
    selected = null
    tree.setActiveNode(null)
    // Reload data
    tree.load(getLazyDataEndpoint(parentId))
  }

  export async function expand(directoryId: Uuid) {
    const node = findNodeByFileId(directoryId)
    if (!node.expanded) {
      // Load the directory content
      await node.load(getLazyDataEndpoint(directoryId, true))
      // Expand the directory
      node.setExpanded(true)
    }
  }

  /** Visually Move the file node by file id */
  export function visualMove(refFrom: FileRef, refTo: FileRef | FileParentKey) {
    if (!tree) return

    const nodeFrom = findNodeByFileId(refFrom)
    const nodeTo = findNodeByFileId(refTo)

    if (refTo == fileParentKey) {
      // Remove the file if it is a parent directory
      nodeFrom.remove()
      return
    } else if (nodeFrom && nodeTo) {
      // Move the file if both from and to nodes are found
      nodeFrom?.moveTo(nodeTo)
    }
  }

  /** Visually Delete the file node by file id */
  export function visualDelete(ref: FileRef) {
    if (!tree) return
    findNodeByFileId(ref)?.remove()
  }

  /** Find the node by file id */
  function findNodeByFileId(fileOrRefId: string) {
    if (!isSet(fileOrRefId)) {
      throw new Error('fileId is not set')
    }
    if (fileOrRefId == parentId) {
      return tree.root
    }
    if (fileOrRefId == parentDirectoryRefId) {
      throw new Error('fileId is a parent directory')
    }
    return tree.findFirst((node) => node.refKey == fileOrRefId)
  }

  /** Prepare a StringFilter matcher */
  function getMatcher(filter: StringFilter): (str: string) => boolean {
    const _filter = Array.isArray(filter) ? filter : [filter]
    if (!isSet(_filter)) return (_) => true
    return (str) =>
      !!_filter.find(
        (filterItem) => isString(str) && str.search(filterItem) >= 0
      )
  }

  /** Prepare a file type matcher using the given filter */
  function getFileTypeMatcher(
    filter: StringFilter
  ): (file: PartialFile) => boolean {
    const _matcher = getMatcher(filter)
    return (file) => {
      if (file.directory) return true
      if (!(file.extension || file.mimeType)) return false
      return _matcher(file.extension) || _matcher(file.mimeType)
    }
  }

  /** Check if the filter is set */
  function isFilterSet(filter: StringFilter) {
    return filter instanceof RegExp || isSet(filter)
  }

  /** Apply the file extension/mimeType filter */
  function applyFileTypeFilter(fileTypeFilter: StringFilter) {
    if (!tree || tree.isLoading()) return

    if (!isFilterSet(fileTypeFilter)) {
      console.log('NOTSET', fileTypeFilter)
      tree.clearFilter()
      return
    }

    const _matcher = getFileTypeMatcher(fileTypeFilter)
    tree.filterNodes((node) => {
      // Always pass through non-files
      if (node.type != 'file') return true
      return _matcher(node?.data?.file as PartialFile)
    }, {})
  }

  /** Lazy-load the wunderbaum class */
  async function init(options: WunderbaumOptions): Promise<Wunderbaum> {
    // NOTE: <#HACK:Wunderbaum#> Currently uses the patched version of Wunderbaum (with support for different row heights)
    return new (
      await import('@public/includes/WunderbaumTemp/wunderbaum.esm')
    ).Wunderbaum(options) as any
  }

  /**
   * Make sure the Wunderbaum Node uses the custom fetch implementation
   */
  function overrideFetch(wbNode: WunderbaumNode) {
    // NOTE: <#HACK:Wunderbaum#> Wunderbaum implements its own error parsing, we don't want that here
    Object.getPrototypeOf(wbNode)._fetchWithOptions = _fetchWithOptions
  }

  /**
   * Custom fetch function for Wunderbaum.
   * Uses custom API.
   *
   * NOTE: <#HACK:Wunderbaum#> Wunderbaum implements its own error parsing, we don't want that here
   * @param source
   */
  async function _fetchWithOptions(source: SourceAjaxType) {
    // @ts-ignore call function on `this`
    this?.setStatus?.('loading')
    return await request({
      url: source.url,
      method: 'GET',
      fetchOptions: source.options,
      body: source.params,
    })
  }

  export function getSelectedDirectory() {
    return !selected
      ? parentId
      : selected.directory
        ? selected.id
        : selected.parent ?? parentId
  }

  /** Rename the selected file or directory */
  export function editSelected() {
    if (!selected?.id) return
    findNodeByFileId(selected.id)?.startEditTitle()
  }

  /** Start creating a new directory on the selected directory (or root) */
  export async function mkdir() {
    // Get the selected directory
    const directoryId = getSelectedDirectory()
    if (!directoryId) return

    const parent = findNodeByFileId(directoryId)

    // If not expanded, expand the directory first
    if (!(parent.isRootNode() || parent.expanded)) {
      await expand(parent.refKey as any)
    }

    // Create a virtual directory
    const node = parent.addNode({
      ...fileToNode({
        directory: true,
        name: '',
        nodes: [],
        parent: directoryId,
      }),
      __creatingDirectory: true,
    })

    // Start editing the directory
    _currentTempDirectoryEdit = node
    node.startEditTitle()
  }

  function _mkdirCancel(node: WunderbaumNode) {
    // Delete all nodes with `__creatingDirectory: true`
    tree
      .findAll((node) => node.data.__creatingDirectory)
      .forEach((node) => node.remove())
    _currentTempDirectoryEdit = null
  }

  async function _mkdirApply(
    node: WunderbaumNode,
    parentDirectoryId: FileRef,
    directoryName: string
  ): Promise<boolean> {
    // Create the new directory, await the returning ID
    const newDirectory = await fileApi
      .mkdir(directoryName, parentDirectoryId)
      .catch(() => null) // Return null instead of throwing an error

    // On fail, return early
    if (!newDirectory) {
      return false
    }

    // Replace the node
    // NOTE: Replacing the RefKey doesn't work yet, so this is the alternative
    node.remove()
    findNodeByFileId(newDirectory.parent)?.addNode(fileToNode(newDirectory))

    // Delete all other nodes with `__creatingDirectory: true`
    tree
      .findAll((node) => node.data.__creatingDirectory)
      .forEach((node) => node.remove())
    _currentTempDirectoryEdit = null

    // Done
    // We have to return false to block wunderbaum from editing again
    // (Otherwise it will throw an error)
    return false
  }

  /**
   * Attach an event handler for when the editing has been stopped
   *
   * @param tree
   * @param handler
   */
  async function attachStopEditEvent(
    tree: Wunderbaum,
    handler: (
      apply: boolean,
      options: { event: Event; forceClose: boolean }
    ) => void
  ) {
    //@ts-ignore Find the edit extension
    const editExtension: EditExtension = (tree.extensionList as any[]).find(
      // NOTE: <#HACK:Wunderbaum#> Can't use instanceOf, so check by name instead for now
      (ext) => ext.constructor.name == 'EditExtension'
    )
    if (!editExtension) return

    // Override the original function
    const originalFunc = editExtension._stopEditTitle
    editExtension._stopEditTitle = function (apply, options) {
      handler(apply, options)
      try {
        originalFunc.call(this, apply, options)
      } catch (_) {
        // Ignore Error
      }
    }
  }

  /**
   * Init the tree
   */
  onMount(async () => {
    tree = await init({
      element: '#' + id,
      debugLevel: 2,
      rowHeightPx,
      // checkbox: true,
      columns: [
        { id: '*', title: 'Name', minWidth: '250px' },
        { id: 'ext', title: 'Extension', width: '100px' },
      ],
      // source: getUrl(parentId),
      iconMap: getWbIconMap(),
      types: {
        directory: { colspan: true },
        parent: { icon: getSvg(mdiArrowUpLeft), colspan: true },
      },
      filter: {
        autoApply: true,
        leavesOnly: true,
      },
      lazyLoad(event) {
        overrideFetch(event.node)
        return getLazyDataEndpoint(event.node.refKey)
      },
      receive: parseIncomingData,
      render: unpackDataBeforeRender,
      load() {
        // Reapply the filter
        awaitRedraw().then(() => applyFileTypeFilter(fileTypeFilter))
      },
      dblclick: handleDoubleClick,
      activate: handleActivate,
      selectMode: 'single',
      dnd: {
        guessDropEffect: true,
        autoExpandMS: 250 as any,

        dragStart: (e) => {
          e.event.dataTransfer.effectAllowed = 'all'
          return true
        },
        dragEnter: handleDragEnter,
        drop: handleDrop,
      },

      edit: {
        trigger: ['F2', 'macEnter'],
        select: true, // Select all text on start
        slowClickDelay: 200,
        trim: true, // Trim input before applying
        validity: true, // Check validation rules while typing
        maxlength: 255,
        beforeEdit(event) {
          _edit_safeToRemove = false
          // Allow editing new directories
          if (event.node.data?.__creatingDirectory) return true
          // Otherwise, only allow non-virtual files to be edited
          return !!renameFileHandler && isUuid(event.node.refKey)
        },
        edit(event) {
          tick().then(() => (_edit_safeToRemove = true))
        },
        apply(event) {
          // Call the renameFileHandler
          const isCreatingDirectory = !!event.node.data?.__creatingDirectory
          const fileId = event.node.refKey
          const parentDirectoryId = event.node.parent?.refKey ?? parentId
          const name = event.inputElem.value

          // If creating a directory, call the mkdirHandler
          if (
            isCreatingDirectory &&
            (!isSet(parentDirectoryId) || isUuid(parentDirectoryId)) &&
            isSet(name)
          ) {
            return _mkdirApply(event.node, parentDirectoryId, name)
          }

          // If renaming an existing and valid file, rename the file
          if (renameFileHandler && isUuid(fileId) && isSet(name))
            return renameFileHandler(fileId, name)

          // Otherwise, block the rename
          return false
        },
      },
    })

    // Override the fetch handler
    overrideFetch(tree.root)

    // Add a cancelation handler when editing a new directory
    attachStopEditEvent(tree, (_, options) => {
      if (
        _currentTempDirectoryEdit &&
        options?.forceClose &&
        _edit_safeToRemove
      )
        _mkdirCancel(null)
    })

    // Wait a bit before loading data
    await tick()
    _ready = true
    load()
  })
</script>

<div class="fileTree" {id} style:--wb-header-height="{rowHeightPx}px"></div>
