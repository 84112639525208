/** Utilities for the svelte use:statement */

import { hasUnsavedChanges, safePush } from '@lib/GlobalPageChangeHandler'
import {
  awaitRedraw,
  collectedDebounce,
  debounce,
  isCtrlHeld,
} from '@packages/util'
import type { Action } from 'svelte/action'
import { get } from 'svelte/store'
import type { ObjectValues } from '../UtilityTypes'

/**
 * Define key map here
 *
 * NOTE: The following keys are not overridable:
 *   - Ctrl + N
 *   - Ctrl + Shift + N
 *   - Ctrl + T
 *   - Ctrl + Shift + T
 *   - Ctrl + W
 */
const keyMap = {
  s: 'save',
  b: 'add', // TODO: Find a better key for this (can't use `N`)
} as const satisfies Record<string, string>

export type HandlerMap = { [P in ObjectValues<typeof keyMap>]?: () => void }

/** Handle certain common key events */
export const handleControlKey: Action<HTMLElement, HandlerMap> = (
  element,
  handlerMap
) => {
  function onKey(event: KeyboardEvent) {
    if (!isCtrlHeld(event)) return
    const key = keyMap?.[event.key]
    if (!key || !handlerMap[key]) return
    handlerMap[key]()
    event.preventDefault()
  }

  element.addEventListener('keydown', onKey)

  return {
    update(map) {
      handlerMap = map
    },
    destroy() {
      element.removeEventListener('keydown', onKey)
    },
  }
}

/** For Anchor elements, check unsaved changes before allowing a page change */
export const checkUnsavedChangesOnClick: Action<HTMLAnchorElement, string> = (
  element,
  route
) => {
  let _route = route

  function handleClick(event: MouseEvent) {
    // Continue normal operation when ctrl/command/shift is pressed or no pending changes or no href
    if (
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey ||
      !get(hasUnsavedChanges) ||
      !_route
    ) {
      return
    }
    safePush(_route)
    event.preventDefault()
  }

  element.addEventListener('click', handleClick)

  return {
    update(route) {
      _route = route
    },
    destroy() {
      element.removeEventListener('click', handleClick)
    },
  }
}

/** Provides an easier way to apply click events what also supports keyboard support */
export interface InteractConfig {
  /** Action to perform on single click */
  single?: () => void
  /** Button to bind for the single click */
  singleKeys?: string[]
  /** Action to perform on double click */
  double?: () => void
  /** Button to bind for the double click */
  doubleKeys?: string[]
  /** Bubble the key events? Defaults to true */
  bubble?: boolean
  /** Double click detection delay in msec. Defaults to 200ms */
  delay?: number
  /** Disallow interaction if true */
  disabled?: boolean
}
export const interact: Action<HTMLElement, InteractConfig> = (
  node,
  parameter
) => {
  let _config = parameter

  function _setup() {
    if (!_config?.double) {
      // Returns a function the single click function instantly
      return (_: number) => _config?.single?.()
    }

    // Returns a function that collects the click count and then runs the correct function
    return collectedDebounce<number>((collection) => {
      const clickCount = collection.reduce((prev, curr) => Math.max(prev, curr))
      if (clickCount <= 1) {
        _config?.single?.()
      } else {
        _config?.double?.()
      }
    }, _config?.delay ?? 200)
  }

  let collectClicks = _setup()

  function onKeyDown(event: KeyboardEvent) {
    if ((_config?.singleKeys ?? ['Enter', 'Space']).includes(event.key))
      _config?.single?.()
    if ((_config?.doubleKeys ?? []).includes(event.key)) _config?.double?.()
  }

  node.tabIndex ??= 0
  node.role ??= 'button'
  node.onclick = (event) => !_config.disabled && collectClicks(event.detail)
  node.onkeydown = onKeyDown

  return {
    update(parameter) {
      _config = parameter
      collectClicks = _setup()
    },
    destroy() {},
  }
}

/**
 * Edits the attached inputElement as soon as it appears.
 * Runs the parameter-supplied function after the user presses enter or the input loses focus.
 */
export const autoEdit: Action<
  HTMLInputElement,
  {
    onCancel?: () => void
    onApply?: (value: string | number) => void
    disallowCancel?: boolean
    disableClickOutsideApply?: boolean
    settleTime?: number
  }
> = (node, parameter) => {
  let _config = parameter
  let _settled = false
  let _cancelled = false

  const _cancel = () => {
    _cancelled = true
    node?.blur()
    _config?.onCancel?.()
  }

  const _realApply = debounce((value: string | number) => {
    _config?.onApply?.(value)
  }, _config.settleTime ?? 100)

  const _apply = debounce((value: string | number) => {
    if (_cancelled) return
    node?.blur()
    _realApply(value)
  }, _config.settleTime ?? 100)

  const _runApply = () => {
    const _value = node.value
    if (
      !(
        node.reportValidity() &&
        _value.length >= node.minLength &&
        _value.length <= node.maxLength
      )
    ) {
      node.focus()
      return
    }
    _apply(_value)
  }

  const onKeyDown = (event: KeyboardEvent) => {
    if (!_config?.disallowCancel && event.key == 'Escape') {
      _cancel()
    } else if (event.key == 'Enter') {
      _runApply()
    }
  }

  const clickOutside = (event: Event) => {
    if (!_settled || (event.target as HTMLElement) == node) return
    if (!_config.disableClickOutsideApply) {
      _runApply()
    } else if (!_config?.disallowCancel) {
      _cancel()
    }
  }

  node.focus()

  node.addEventListener('keydown', onKeyDown)
  window.addEventListener('click', clickOutside)

  // Wait a little bit before activating the window click listener
  // to prevent an instant event dispatch
  awaitRedraw().then(() => (_settled = true))

  return {
    update(parameter) {
      _config = parameter
    },
    destroy() {
      node.removeEventListener('keydown', onKeyDown)
      window.removeEventListener('click', clickOutside)
    },
  }
}
