<script lang="ts" context="module">
  import { commonStrings } from '@lib/CommonStrings'
  import { isSet } from '@packages/util'
  import { onDestroy, onMount, tick } from 'svelte'
  import ComboboxAsync from '../ComboboxAsync.svelte'
  import FormElementBase from './FormElementBase.svelte'

  export interface GradientEditConfig {}

  export type GradientValue = number | 'min' | 'max'
  export interface GradientStep {
    value: GradientValue
    name: string
    color: string
  }

  export const tempGradient = [
    {
      name: 'Unsustainable',
      fill: '#ee1f25',
      from: -100,
      to: -20,
    },
    {
      name: 'Volatile',
      fill: '#f04922',
      from: -20,
      to: 0,
    },
    {
      name: 'Foundational',
      fill: '#fdae19',
      from: 0,
      to: 20,
    },
    {
      name: 'Developing',
      fill: '#f3eb0c',
      from: 20,
      to: 40,
    },
    {
      name: 'Maturing',
      fill: '#b0d136',
      from: 40,
      to: 60,
    },
    {
      name: 'Sustainable',
      fill: '#54b947',
      from: 60,
      to: 80,
    },
    {
      name: 'High Performing',
      fill: '#0f9747',
      from: 80,
      to: 100,
    },
  ]

  export const _name = 'GradientEdit'
</script>

<script lang="ts">
  export let value: Nullable<string> = null // ID
  export let config: GradientEditConfig = {}
  export let label = 'ComboBox'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _ready = false
  let _value = value
  let _valueLock = false

  let combobox: Nullable<ComboboxAsync> = undefined

  $: update(value)
  $: _state = state == 'error' && !isSet(value) ? state : null

  /** Update the internal value */
  function update(newValue: typeof value) {
    if (!_ready || _valueLock) return
    _value = newValue
  }

  /** Update the external value on internal value change */
  function handleValueChange(event: CustomEvent<string>) {
    const newValue = event.detail

    if (!_ready || _valueLock) return
    _valueLock = true

    value = newValue == 'NULL' ? null : newValue

    // Clear state
    state = null

    tick().then(() => (_valueLock = false))
  }

  onMount(async () => {
    await tick()
    _ready = true
  })
  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  state={_state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={errorMessage ?? commonStrings.valueRequired}
>
  <button
    on:click={() => {
      console.log('This would open the gradient editor')
    }}>Click me!</button
  >
</FormElementBase>
