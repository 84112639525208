<!-- FileUploadContainer Component for use in IFrames -->
<script lang="ts">
  import JsonDisplay from '@components/Utility/JsonDisplay.svelte'
  import type { IFileWrapper, IVaultConfig, Vault } from '@dhtmlx/ts-vault'
  import { API } from '@lib/ApiHandler'
  import { parseXMLHttpResponse } from '@lib/ApiUtil'
  import type { ParsedError } from '@lib/ErrorHandler'
  import type { DirectoryRef } from '@models/api/FileApi'
  import type { MappedEvents } from '@packages/util'
  import { awaitRedraw, chain, getId } from '@packages/util'
  import { createEventDispatcher, onMount } from 'svelte'
  import { toast } from 'svelte-sonner'
  import { type Writable } from 'svelte/store'

  interface $$Events {
    /** One or more items failed to upload */
    fail: CustomEvent<never>

    /** All items are successfully uploaded (No Queue or in progress items) */
    success: CustomEvent<never>

    /** All items are successfully uploaded (Has Queue or in progress items) */
    partialSuccess: CustomEvent<never>

    /** File upload started */
    started: CustomEvent<never>

    /** Upload operation complete, may or may not have errors */
    finished: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let thisWindow: Window
  export let disableUploadButton: Writable<boolean>
  export let uploading: Writable<boolean>
  export let targetDirectory: Writable<DirectoryRef>
  export let vault: Writable<Nullable<Vault>>
  // export let toast: Nullable<typeof ToastFn>

  const id = getId('vault')

  let loadingToastId: Nullable<string | number> = null
  let loadingToastError: Record<string, ParsedError> = {}

  function setErrorMessage(uploadFileId: string | number, message: string) {
    chain(
      thisWindow.document.querySelector(
        `#${id} [dhx_id="${uploadFileId}"] div.dhx_list-item--error-info`
      )
    ).map((element) => (element.textContent = message))
  }

  function initVault() {
    // On after window open function here
    $vault = new thisWindow.dhx.Vault(id, <IVaultConfig>{
      uploader: {
        target: `${API.baseURL}/file`,
        singleRequest: false,
        params: {
          parent: $targetDirectory,
        },
        autosend: false,
      },
    })
    // Remove upload button
    $vault.toolbar.data.remove('upload')
    $vault.toolbar.data.remove('upload-separator')

    $vault.events.on('Change', (id, status, file) => {
      $disableUploadButton = false
    })

    $vault.events.on('UploadBegin', (files) => {
      dispatch('started')
      loadingToastId = toast?.loading('Uploading Files...')
      loadingToastError = {}
      $uploading = true
    })

    $vault.events.on('UploadComplete', (files: IFileWrapper[]) => {
      // console.log('OK', files)
      $uploading = false

      // Finished upload
      dispatch('finished')

      if (files.every((item) => item.status == 'uploaded')) {
        // All succeeded, no failure
        // console.log('success')
        dispatch('success')
        uploadFinishToast()
      } else if (files.some((item) => item.status == 'failed')) {
        // Some Failed
        // console.log('fail')
        dispatch('fail')
      } else {
        // Some succeeded, No failure, Still some files in queue
        // console.log('partialSuccess')
        dispatch('partialSuccess')
        uploadFinishToast()
      }
    })

    $vault.events.on('UploadFail', (file: IFileWrapper) => {
      const parsed = parseXMLHttpResponse(file.request)

      // console.log('Upload failed', file, file.request)
      awaitRedraw().then(() => setErrorMessage(file.id, parsed.toString()))

      // Add to the errors
      loadingToastError[file.name] = parsed
      uploadFinishToast()
    })
  }

  function uploadFinishToast() {
    if (Object.keys(loadingToastError).length > 0) {
      // Show/update the toast with the errors (use JsonDisplay to style the text)
      toast?.error('Error Uploading Files:', {
        id: loadingToastId,
        description: JsonDisplay,
        componentProps: {
          value: Object.fromEntries(
            Object.entries(loadingToastError).map(([key, value]) => [
              key,
              value.toString(),
            ])
          ),
        },
      })
    } else {
      // Show/update the toast with the success message
      toast?.success('Upload Successful', {
        id: loadingToastId,
      })
    }
  }

  onMount(() => {
    initVault()
  })
</script>

<div class="fileUploadContainer" {id}></div>

<style lang="scss">
  .fileUploadContainer {
    width: 100%;
    height: 100%;
  }
</style>
