/**
 * Date range utility type.
 */
export default class DateRange {
  start: Date
  end: Date

  constructor(start?: Date | string | number, end?: Date | string | number) {
    if (!start && !end) {
      this.start = new Date()
      this.end = new Date()
    } else {
      this.start = new Date(start)
      this.end = new Date(end)
      this.sort()
    }
  }

  getStart(): Date {
    this.sort()
    return this.start
  }

  getEnd(): Date {
    this.sort()
    return this.end
  }

  asArray() {
    this.sort()
    return [this.start, this.end]
  }

  toString() {
    return `${this.start.toLocaleString()} - ${this.end.toLocaleString()}`
  }

  /**
   * Convert the object to a JSON value
   */
  toJSON(key?: any) {
    this.sort()
    return `DateRange(${this.start.toJSON()},${this.end.toJSON()})`
  }

  /**
   * Parse the object from a JSON value
   */
  static parse(value: string) {
    if (!value.startsWith('DateRange')) return null
    const groups = /^DateRange\((?<start>.+?),(?<end>.+?)\)$/.exec(
      value
    )?.groups
    return groups && groups.start && groups.end
      ? new DateRange(groups.start, groups.end)
      : null
  }

  private sort() {
    if (this.start > this.end) [this.start, this.end] = [this.end, this.start]
  }
}
