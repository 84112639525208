<script lang="ts" context="module">
  import FilterEditModal from '@components/Modals/FilterEditModal.svelte'
  import Button from '@components/Utility/Button.svelte'
  import MultilineText from '@components/Utility/MultilineText.svelte'
  import FilterGroup from '@lib/Filter/FilterGroup'
  import type { ModelReference } from '@models/api/BaseApi'
  import type { ModelFilter } from '@models/api/BaseModel'
  import { onDestroy, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export interface FilterFormEditConfig {
    model?: ModelReference
  }

  export const _name = 'FilterFormEdit'
</script>

<script lang="ts">
  export let value: ModelFilter<any> = {}
  export let config: FilterFormEditConfig = { model: 'asset' }
  export let label = 'Filter'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let show = false
  let _ready = false

  $: preview = FilterGroup.deserialize(config?.model, 'and', value)
    ?.toString()
    ?.trim()
  $: hasPreview = preview.length > 0

  onMount(async () => {
    await tick()
    _ready = true
  })
  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <div class="previewBox">
    <MultilineText value={hasPreview ? preview : 'There are no filters set'} />
  </div>
  <Button
    on:click={() => (show = true)}
    value="Edit Filter"
    width="100%"
    link
  />
</FormElementBase>

<FilterEditModal bind:show model={config?.model} bind:value />

<style lang="scss">
  .previewBox {
    margin-bottom: 0.5em;
  }
</style>
