<!-- DateTimeFilter Component -->
<script lang="ts" context="module">
  import { tick } from 'svelte'
  import DateRange from '@lib/DateRange'
  import DateTimeRangeSelect from '../DateTimeRangeSelect.svelte'
  export interface DateTimeFilterConfig {
    noTime?: boolean
  }
</script>

<script lang="ts">
  export let value: Record<string, string> = {}
  export let config: DateTimeFilterConfig = {}

  let _value: DateRange = new DateRange()
  let valueLock = false

  $: parseValue(value)

  function parseValue(value) {
    if (valueLock) return
    _value = parseFilter(value)
  }

  async function handleChange(event: CustomEvent<DateRange>) {
    valueLock = true
    value = getFilter(event.detail)
    await tick()
    valueLock = false
  }

  function getFilter(range: DateRange) {
    return range && range.start && range.end
      ? {
          '>=': range.start.toISOString(),
          '<=': range.end.toISOString(),
        }
      : null
  }

  function parseFilter(input: Record<string, string>) {
    const fromString = input?.['>=']
    const toString = input?.['<=']
    return fromString && toString ? new DateRange(fromString, toString) : null
  }
</script>

<div class="dhx_string-cell dateTimeFilter">
  <DateTimeRangeSelect
    bind:value={_value}
    on:change={(event) => handleChange(event)}
    clearable
    compact
    noTime={config?.noTime}
  />
</div>

<style lang="scss">
  .dateTimeFilter {
    width: 100%;
    height: 100%;
    padding: 0.25em 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
