<!-- A modal template, ready to use -->
<script lang="ts">
  import ToolbarItem from '@components/Toolbar/ToolbarItem.svelte'
  import Window from '@components/Window.svelte'
  import type { Vault } from '@dhtmlx/ts-vault'
  import { dhxVaultScriptUrl, dhxVaultStyleUrl } from '@lib/dhtmlx'
  import type { DirectoryRef } from '@models/api/FileApi'
  import type { MappedEvents } from '@packages/util'
  import { createEventDispatcher, onDestroy } from 'svelte'
  import { writable, type Writable } from 'svelte/store'
  import FileUploadContainer from './FileUploadContainer.svelte'

  interface $$Events {
    // Define more events here
    reloadRequired: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let show = false
  // export let _param_ = ...

  export let vaultContainer: Nullable<FileUploadContainer> = undefined
  export let targetDirectory: Nullable<DirectoryRef> = undefined

  let vault: Writable<Nullable<Vault>> = writable()
  let _targetDirectory: Writable<Nullable<DirectoryRef>> =
    writable(targetDirectory)

  let disableUploadButton = writable(true)
  let uploading = writable(false)
  let hasSuccessfulUploads = false

  $: _targetDirectory.set(targetDirectory)

  function handleBeforeOpen(event: Event) {
    // On before window open function here
    // event.preventDefault() // Running this function will block the opening of the window
  }

  function handleBeforeClose(event: Event) {
    // On before window close function here
    // event.preventDefault() // Running this function will block the closing of the window
  }

  function handleAfterOpen() {
    initVault()
  }

  function handleAfterClose() {
    // On after window close function here
    $vault?.destructor()
    if (hasSuccessfulUploads) {
      dispatch('reloadRequired')
      hasSuccessfulUploads = false
    }
  }

  async function apply() {
    $vault.uploader.send()
  }

  async function cancel() {
    $vault.uploader.abort()
    show = false
  }

  /**
   * NOTE: DHTMLX Vault loads in scripts that override DHTMLX Suite scripts, causing DHTMLX Suite to break.
   * We need to use an IFrame hack to prevent accidental overrides.
   */
  async function initVault() {
    // Get IFrame references
    const iframeWindow = iframeElement.contentWindow
    const iframeDoc = iframeWindow.document

    // Set script
    const script = iframeDoc.createElement('script')
    script.src = dhxVaultScriptUrl.href

    // Set style
    const link = iframeDoc.createElement('link')
    link.rel = 'stylesheet'
    link.href = dhxVaultStyleUrl.href

    // Render container on script load
    script.onload = () => {
      vaultContainer = new FileUploadContainer({
        target: iframeDoc.body,
        props: {
          thisWindow: iframeWindow,
          targetDirectory: _targetDirectory,
          disableUploadButton,
          uploading,
          vault,
        },
      })

      vaultContainer.$on('success', () => {
        // console.log('ALL OK')
        hasSuccessfulUploads = true
        show = false
        // dispatch('apply')
      })
      vaultContainer.$on('fail', () => {
        // console.log('SOME FAIL')
      })
      vaultContainer.$on('partialSuccess', () => {
        // console.log('PARTIAL')
        hasSuccessfulUploads = true
      })
    }

    iframeDoc.head.appendChild(script)
    iframeDoc.head.appendChild(link)
  }

  let iframeElement: HTMLIFrameElement

  onDestroy(() => {
    vaultContainer?.$destroy()
  })
</script>

<Window
  bind:show
  title="Upload Files"
  width="40em"
  height="50em"
  on:beforeOpen={handleBeforeOpen}
  on:beforeClose={handleBeforeClose}
  on:afterOpen={handleAfterOpen}
  on:afterClose={handleAfterClose}
>
  <!-- Vault container -->
  <iframe
    class="vault"
    bind:this={iframeElement}
    title="file upload"
    height="100%"
    width="100%"
  ></iframe>

  <svelte:fragment slot="bottomToolbar">
    <ToolbarItem spacer />
    <ToolbarItem value="Cancel" type="button" on:click={cancel} />
    <ToolbarItem
      value="Upload"
      type="button"
      on:click={() => apply()}
      disabled={$disableUploadButton || $uploading}
      loading={$uploading}
    />
  </svelte:fragment>
</Window>

<style lang="scss">
  .vault {
    height: 100%;
    width: 100%;
    border: none;
  }
</style>
