import { getId } from '@packages/util'
import { ComponentRenderConfig, Render } from 'svelte-render'

/**
 * Special custom element that adds a dummy element,
 * which tracks whenever it gets loaded into or unloaded from the DOM.
 *
 * Requires onmount and ondestroy to be set as a string reference
 * to the corresponding window function.
 *
 * An `install()` is required to register the custom element.
 *
 * Usage:
 * `<static-render onmount="functionReference_mount" ondestroy="functionReference_destroy" />`
 */
export class StaticRender extends HTMLElement {
  static observedAttributes = ['onmount', 'ondestroy'] as const

  onmount: string
  ondestroy: string

  constructor() {
    super()
  }

  connectedCallback() {
    // Run the given onmount function
    window[this.onmount]?.(this)
  }

  disconnectedCallback() {
    // Run the given ondestroy function
    window[this.ondestroy]?.(this)
  }

  attributeChangedCallback(
    name: (typeof StaticRender.observedAttributes)[number],
    oldValue: any,
    newValue: any
  ) {
    switch (name) {
      case 'ondestroy':
        this.ondestroy = newValue
        break
      case 'onmount':
        this.onmount = newValue
        break
    }
  }

  static install() {
    window.customElements.define('static-render', StaticRender)
  }
}

/**
 * Create a static renderer using the given Component render
 * (made using `createRender` from `svelte-render`)
 *
 * Renders the given component as soon as it is put in the DOM.
 * Useful for rendering components in places that expect an HTML string (eg. DHTMLX)
 *
 * Will destroy the component as soon as the HTML tag is unloaded.
 */
export function staticRender(renderer: ComponentRenderConfig) {
  const renderId = getId('render')

  const funcRender = renderId + '_render'
  const funcDestroy = renderId + '_destroy'

  let refCount = 0
  let _component: InstanceType<typeof Render<any>>

  function onRender(_this: StaticRender) {
    refCount++
    _component = new Render({
      target: _this,
      props: {
        of: renderer,
      },
    })
  }

  function onDestroy(_this: StaticRender) {
    refCount--
    _component?.$destroy()
    if (refCount <= 0) {
      delete window[funcRender]
      delete window[funcDestroy]
    }
  }

  //@ts-ignore set window function
  window[funcRender] = onRender
  //@ts-ignore set window function
  window[funcDestroy] = onDestroy

  return `<static-render onmount="${funcRender}" ondestroy="${funcDestroy}" />`
}
