<!-- A modal template, ready to use -->
<script lang="ts" generics="T extends boolean = false">
  import ToolbarItem from '@components/Toolbar/ToolbarItem.svelte'
  import Window from '@components/Window.svelte'
  import type { Grid, ICol } from '@dhtmlx/ts-grid'
  import { getToolbarIcon } from '@lib/Icons'
  import { DhxGrid } from '@lib/dhtmlx'
  import { mdiDotsGrid } from '@mdi/js'
  import type { BaseApiColumnNames, BaseApiColumns } from '@models/api/BaseApi'
  import type { MappedEvents, Switch } from '@packages/util'
  import { camel2title, filterObject, split, unique } from '@packages/util'
  import { createEventDispatcher } from 'svelte'

  interface $$Events {
    // Define more events here
    apply: CustomEvent<ColumnTypeReturn[]>
  }

  type NormalModeItem = BaseApiColumnNames<any>[number]
  type NormalModeItemsAvailable = BaseApiColumns<any>
  type CompatModeItem = ICol
  type ColumnType = Switch<T, CompatModeItem, NormalModeItem>
  type ColumnTypeAvailable = Switch<
    T,
    CompatModeItem[],
    NormalModeItemsAvailable
  >
  type CompatMappedColumn = ICol & { data: CompatModeItem }
  type ColumnTypeReturn = Switch<T, CompatMappedColumn, NormalModeItem>
  type ColumnTypeIntermediate = Switch<T, CompatMappedColumn, ICol>

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()
  const id = `columnEditor-${Math.random().toString(16).slice(2)}`

  export let show = false
  export let currentColumns = new Array<ColumnType>()
  export let defaultColumns = new Array<ColumnType>()
  export let availableColumns = [] as ColumnTypeAvailable

  export let compatMode = false as T

  let gridSelected: Grid
  let gridAvailable: Grid
  let _enableApply = false

  function toColumnItem(item: ColumnType): ColumnTypeIntermediate {
    if (compatMode) {
      return _toColumnItemCompat(item as any) as any
    } else {
      return _toColumnItem(item as any) as any
    }
  }

  function _toColumnItem(item: NormalModeItem): ICol {
    return {
      id: item as string,
      //@ts-ignore Title exists in ICol
      title: camel2title(String(item).replace(/Id$/, '').trim()),
    }
  }

  function _toColumnItemCompat(item: CompatModeItem): CompatMappedColumn {
    return {
      id: item.id as string,
      //@ts-ignore Title exists in ICol
      title: item.header.find((_header) => !!_header.text).text,
      data: item,
    }
  }

  function toData(item: ColumnType): ColumnTypeReturn {
    if (compatMode) {
      return _toDataCompat(item as any) as any
    } else {
      return _toData(item as any) as any
    }
  }

  function _toData(item: ICol): NormalModeItem {
    return item.id as string
  }

  function _toDataCompat(item: CompatMappedColumn): CompatMappedColumn {
    return filterObject(
      item.data,
      (key) => !key.startsWith('$')
    ) as CompatMappedColumn
  }

  function splitColumns(): [
    ColumnTypeIntermediate[],
    ColumnTypeIntermediate[],
  ] {
    if (compatMode) {
      return _splitColumnsCompat(
        availableColumns as any,
        currentColumns as any
      ) as any
    } else {
      return _splitColumns(
        availableColumns as any,
        currentColumns as any
      ) as any
    }
  }

  function _splitColumns(
    availableColumns: NormalModeItemsAvailable,
    currentColumns: NormalModeItem[]
  ) {
    return split(
      Object.keys(availableColumns).map((item) => toColumnItem(item as any)),
      (item) => !currentColumns.find((_current) => _current == item.id)
    )
  }
  function _splitColumnsCompat(
    availableColumns: CompatModeItem[],
    currentColumns: CompatModeItem[]
  ) {
    return split(
      availableColumns.map((item) => toColumnItem(item as any)),
      (item) => !currentColumns.find((_current) => _current.id == item.id)
    )
  }

  function handleAfterOpen() {
    const columnTemplate: ICol['template'] = (cellValue, a, b) => {
      return getToolbarIcon(mdiDotsGrid, cellValue)
    }

    // Get column data
    const [_, available] = splitColumns()

    const selected = currentColumns.map(toColumnItem)

    // Initialize grids
    gridSelected = new DhxGrid(id + '_selected', {
      columns: [
        {
          id: 'title',
          htmlEnable: true,
          template: columnTemplate,
          tooltipTemplate: (value) => value,
          header: [{ text: 'Selected' }, { content: 'inputFilter' }],
        },
      ],
      data: unique(selected, (item) => item.id),
      dragMode: 'both',
      fitToContainer: true,
    })

    gridAvailable = new DhxGrid(id + '_available', {
      columns: [
        {
          id: 'title',
          htmlEnable: true,
          template: columnTemplate,
          tooltipTemplate: (value) => value,
          header: [{ text: 'Available' }, { content: 'inputFilter' }],
        },
      ],
      data: unique(available, (item) => item.id),
      dragMode: 'both',
      fitToContainer: true,
    })

    // Disable the apply button if the list is empty
    gridSelected.events.on('change', (id, status, updatedItem) => {
      if (id == 'load' && !status) {
        if (gridSelected.data.getLength() > 0) _enableApply = true
        else _enableApply = false
      }
    })

    // Move item to the other side if double clicked
    gridSelected.events.on('cellDblClick', (row, column, event) => {
      gridSelected.data.move(row.id, -1, <any>gridAvailable.data)
    })

    gridAvailable.events.on('cellDblClick', (row, column, event) => {
      gridAvailable.data.move(row.id, -1, <any>gridSelected.data)
    })
  }

  function handleAfterClose() {
    // On after window close function here
    gridSelected?.destructor()
    gridAvailable?.destructor()
  }

  async function apply() {
    show = false
    console.log(gridSelected?.config?.data?.map(toData))
    dispatch('apply', gridSelected?.config?.data?.map(toData))
  }

  function reset() {
    currentColumns = defaultColumns

    const [_, available] = splitColumns()
    const selected = currentColumns.map(toColumnItem)

    gridSelected.data.parse(selected)
    gridAvailable.data.parse(available)
  }
</script>

<Window
  bind:show
  title="Edit Columns"
  maxWidth="40em"
  maxHeight="40em"
  width="90%"
  height="90%"
  on:afterOpen={handleAfterOpen}
  on:afterClose={handleAfterClose}
>
  <div class="columnEditor">
    <div class="grid" id={id + '_selected'}></div>
    <div class="grid" id={id + '_available'}></div>
  </div>
  <svelte:fragment slot="bottomToolbar">
    <ToolbarItem value="Reset" type="button" on:click={reset} />
    <ToolbarItem spacer />
    <ToolbarItem value="Cancel" type="button" on:click={() => (show = false)} />
    <ToolbarItem
      value="Apply"
      type="button"
      on:click={apply}
      disabled={!_enableApply}
    />
  </svelte:fragment>
</Window>

<style lang="scss">
  @use '../../theme/variables' as vars;

  .columnEditor {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 1em;
    overflow: auto;

    .grid {
      // border: 1px solid vars.$border;
      flex: 1 1 0;
      user-select: none;
      width: 20em;

      :global(.dhx_grid-body) {
        overflow: hidden;
        overflow-y: scroll;
      }

      :global(.dhx_grid-filter__label) {
        width: calc(100% - 16px); // Fit the searchbar inside the grid view
      }
    }
  }
</style>
