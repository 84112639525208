import * as Generated from '@generated'
import {
  commonColumns,
  dateTimeColumn,
  downloadLatestReportRenderer,
  dummyColumn,
  entitySelectColumn,
  inputColumn,
  userColumn,
} from '@lib/CommonColumns'
import type { FilterTypeEnum, ModelContext } from '../BaseApi'

function getIteratorIndex<T, TReturn = any, TNext = any>(
  iterator: Iterator<T, TReturn, TNext>,
  index: number = 0
): T | TReturn | null {
  let output: IteratorResult<T, TReturn> = null
  for (let _index = 0; _index < index; _index++) {
    output = iterator.next()
  }
  return output?.value
}

function remarkTooltipTemplate(cellValue: string) {
  // Replace all combinations of carriage returns (\r)
  // and newline characters (\n) to breaks (`<br/>`)
  const out = String(cellValue)
    .replaceAll('\r\n', '\n')
    .replaceAll('\r', '\n')
    .replaceAll('\n', '<br/>')

  const index = getIteratorIndex(out.matchAll(/\<br\/\>/g), 8)?.index
  return index
    ? out.substring(0, index) + '<br/>… <i>(Showing first 8 lines)</i>'
    : out
}

export const __context__: ModelContext<Generated.Batch> = {
  columns: {
    name: () => inputColumn('name'),
    status: () => commonColumns.batchStatus,
    remarks: () => ({
      ...inputColumn('remarks'),
      tooltipTemplate: remarkTooltipTemplate,
      htmlEnable: true,
    }),
    performedOn: () => dateTimeColumn('performedOn'),
    batchTypeId: () =>
      entitySelectColumn('batchType@formattedText', 'batchType', {
        field: 'batchTypeId',
        valueField: 'name',
      }),
    operatorId: () =>
      userColumn('operator@formattedText', {
        field: 'operatorId',
      }),

    assetId: () =>
      entitySelectColumn('asset@formattedText', 'asset', {
        field: 'assetId',
        valueField: 'name',
      }),
    equipmentId: () =>
      entitySelectColumn('equipment@formattedText', 'asset', {
        field: 'assetId',
        valueField: 'name',
        filter: {
          type: Generated.Asset_TYPEEnum.equipment,
        },
        sort: 'name',
      }),
    // requestId: () =>
    //   entitySelectColumn('request@formattedText', 'request', {
    //     field: 'requestId',
    //     valueField: 'name',
    //   }),
    // sampleId: () =>
    //   entitySelectColumn('sample@formattedText', 'sample', {
    //     field: 'sampleId',
    //     valueField: 'name',
    //   }),
    estimatedTime: () => inputColumn('estimatedTime'),
    startTime: () => dateTimeColumn('startTime'),
    endTime: () => dateTimeColumn('endTime'),
    plannedStart: () => dateTimeColumn('plannedStart'),
    plannedEnd: () => dateTimeColumn('plannedEnd'),

    //@ts-expect-error Custom colum, Downloads the latest report on click
    downloadLatestReport: () =>
      dummyColumn('latestReport@databind.id', {
        requiresExpand: true,
        displayComponent: downloadLatestReportRenderer,
      }),
  },
  filters: {
    assetId: { model: 'asset' },
    equipmentId: { model: 'asset' },
    batchTypeId: { model: 'batchType' },
    requestId: null,
    operatorId: { model: 'user' },
    sampleId: null,
    name: 'string',
    remarks: 'string',
    performedOn: 'datetime',
    estimatedTime: 'numeric',
    startTime: 'datetime',
    endTime: 'datetime',
    plannedStart: 'datetime',
    plannedEnd: 'datetime',
    config: null,
    // @ts-expect-error Override status
    status: <FilterTypeEnum>{ enum: Generated.Batch_STATUSEnum },
  },
  defaultColumns: [
    'name',
    'status',
    'remarks',
    'performedOn',
    'batchTypeId',
    'operatorId',
    //@ts-ignore Custom column
    'downloadLatestReport',
  ],
  defaultSort: '-performedOn',
  nameField: 'name',
  customListSupport: true,
}
