import * as Generated from '@generated'
import { inputColumn } from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.User> = {
  columns: {
    username: () => inputColumn('username'),
    fullName: () => inputColumn('fullName'),
    email: () => inputColumn('email'),
  },
  filters: {
    username: 'string',
    firstName: 'string',
    lastName: 'string',
    title: 'string',
    auth_key: null,
    password_hash: null,
    password_reset_token: null,
    email: 'string',
    verification_token: null,
    access_token: null,
    refresh_token: null,
    expire_at: null,
    allowance: null,
    allowance_updated_at: null,
    type: { enum: Generated.User_TYPEEnum },
    // password: null,
    // @ts-ignore fullName is filterable
    fullName: 'string',
  },
  defaultColumns: ['fullName', 'username', 'email', 'status'],
  defaultSort: 'fullName',
  nameField: 'fullName',
  customListSupport: true,
}
