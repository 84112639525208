<!-- FilePreview Component -->
<script lang="ts">
  import JsonDisplay from '@components/Utility/JsonDisplay.svelte'
  import LoadingContainer from '@components/Utility/LoadingContainer.svelte'
  import { request } from '@lib/ApiUtil'
  import { camel2title, formatByteSize, isSet } from '@packages/util'
  import type { File } from '@models/api/ApiModels'
  import { reformatTime } from '@packages/locale/lib/DateFormatter'
  import { tick } from 'svelte'
  import FilePreview from './FilePreview.svelte'

  export let fileId: string
  export let directory = false

  let _showPreview = false
  let loading = true
  let abortController = new AbortController()

  let file: Nullable<File>
  let isDirectory = false

  $: load(fileId, directory)

  async function load(fileId: Nullable<string>, directory: boolean) {
    _showPreview = false
    isDirectory = directory
    loading = true
    abortController = new AbortController()

    try {
      file = await request({
        url: ['file', fileId],
        signal: abortController.signal,
        body: {
          expand: 'previewSize,size',
        },
      })

      isDirectory = file.directory
    } finally {
      loading = false

      // Wait a little bit before showing the preview
      await tick()
      _showPreview = true
    }
  }

  /** The fields to display and their formatting (`true` will pass through) */
  const infoFormatter: Partial<
    Record<keyof File, true | ((value: any) => string)>
  > = {
    'createdBy@formattedText': true,
    createdOn: reformatTime,
    'modifiedBy@formattedText': true,
    modifiedBy: reformatTime,
    size: (value) => formatByteSize(value),
    extension: true,
    mimeType: true,
  }

  /** Resolve the information */
  function getInfo(file: File) {
    return Object.fromEntries(
      Object.entries(infoFormatter)
        .map(([key, value]) =>
          value
            ? [
                camel2title(key.replace(/(\@.*|Id$)/g, '')),
                value === true ? file[key] : value(file[key]),
              ]
            : undefined
        )
        .filter((item) => isSet(item?.[1]))
    )
  }
</script>

<LoadingContainer bind:loading bind:abortController message="Loading Details">
  <div class="previewContent">
    <!-- Title -->
    {#if file}
      <h3>{file.name ?? 'File Information'}</h3>

      <!-- Preview -->
      {#if !file.directory}
        <div class="filePreviewWrapper">
          <FilePreview ignoreNotFound file={file.id} />
        </div>
      {/if}

      <!-- Details -->
      <section class="previewInfo">
        <JsonDisplay value={getInfo(file)} />
      </section>
    {/if}
  </div>
</LoadingContainer>

<style lang="scss">
  @use '../../../theme/variables' as vars;

  .previewContent {
    overflow: auto;
    height: 100%;
    padding: 0.5em;

    & > h3 {
      margin: 0;
      font-size: large;
    }

    .previewInfo {
      margin: 0.5em;
    }
  }

  .filePreviewWrapper {
    height: 100%;
    width: 100%;
    border: vars.$borderStyle;
    max-height: 20em;
    overflow: hidden;
  }
</style>
