import { NumberParser } from '@packages/locale/lib/NumberParser'
import type FormatterInterface from './FormatterInterface'

export default class NumberFormatter implements FormatterInterface {
  private readonly _defaultLocale: string | string[] | undefined = undefined // 'en-US'
  private readonly _defaultConfig: Intl.NumberFormatOptions = {
    useGrouping: false,
  }

  private _formatter: Intl.NumberFormat
  private _config: Intl.NumberFormatOptions
  private _locale: string | string[] | undefined

  constructor(config?: Intl.NumberFormatOptions, locale?: string | string[]) {
    this._config = { ...this._defaultConfig, ...(config ?? {}) }
    this._locale = locale ?? this._defaultLocale

    this._formatter = new Intl.NumberFormat(this._locale, this._config)
  }

  get config() {
    return this._config
  }

  set config(numberFormatOptions: Intl.NumberFormatOptions) {
    this._config = numberFormatOptions
    this._formatter = new Intl.NumberFormat(this._locale, numberFormatOptions)
  }

  get locale() {
    return this._locale
  }

  set locale(locale: string | string[] | undefined) {
    this._locale = locale
    this._formatter = new Intl.NumberFormat(locale, this._config)
  }

  getDerived(numberFormatOptions: Intl.NumberFormatOptions): NumberFormatter {
    return new NumberFormatter(
      {
        ...this._config,
        ...numberFormatOptions,
      },
      this._locale
    )
  }

  format(value: number) {
    return this._formatter.format(value)
  }

  parse(value: number | string) {
    return new NumberParser(this._locale).parse(value)
  }
}

/**
 * Unit mapping
 *
 * https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
 */
const unitMapping = {
  celsius: ['celsius', '°c', 'degc'],
  fahrenheit: ['fahrenheit', '°f', 'degf'],
} as const satisfies Record<string, readonly string[]>

export function unitFormater(unit: string) {
  const _unit = Object.entries(unitMapping).find((item) =>
    (item[1] as readonly string[]).includes(unit?.toLowerCase())
      ? item
      : undefined
  )?.[0] as keyof typeof unitMapping | undefined

  return new NumberFormatter({
    ...(_unit
      ? {
          style: 'unit',
          unit: _unit?.toLowerCase(),
          unitDisplay: 'narrow',
        }
      : {}),
    useGrouping: false,
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })
}
