<!-- Sandbox4 Component -->
<script lang="ts" context="module">
  import EditableGrid, {
    EditableGridColumnDataType,
    type EditableGridColumn,
  } from '@components/EditableGrid.svelte'
  import FormElementBase from '@components/FormControls/FormElementBase.svelte'
  import FormDialog from '@components/Modals/FormDialog.svelte'
  import {
    formItemTypes,
    getFormItems,
    type ApiFormItem,
    type ApiFormItemType,
  } from '@lib/ApiFormGenerator'
  import { mdiCog } from '@mdi/js'
  import { camel2title, chain } from '@packages/util'

  export const _name = 'FormDefinitionEditor'

  interface FormDefinitionEditorItemBase {
    name: string
    type: ApiFormItemType
    required: boolean
    config: Record<string, any>
  }
</script>

<script lang="ts">
  export let value: Nullable<ApiFormItem[]> = []
  // export let config: FormDefinitionEditorConfig = {}
  export let label = 'Form Editor'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  const columns: EditableGridColumn[] = [
    {
      label: 'Name',
      name: 'name',
      dataType: EditableGridColumnDataType.uniqueString,
    },
    {
      label: 'Type',
      name: 'type',
      dataType: EditableGridColumnDataType.select,
      config: {
        allowEmpty: true,
        onChange(rowData) {
          rowData.config = {}
        },
        selectOptions: Object.entries(formItemTypes)
          .filter(([_, config]) => !config?.hidden)
          .map(([type, config]) => ({ id: type, value: camel2title(type) })),
      },
    },
    {
      label: 'Required',
      name: 'required',
      dataType: EditableGridColumnDataType.boolean,
    },
    {
      label: '',
      name: '_button',
      dataType: EditableGridColumnDataType.button,
      config: {
        buttonIcon: mdiCog,
        onClick(row) {
          promptItemConfig(String(row.id), row.type, row.config, row.name)
        },
      },
    },

    // Add dummy columns to avoid data loss
    ...['config', 'default', 'label', 'placeholder', 'options'].map((item) => ({
      name: item,
      dataType: EditableGridColumnDataType.dummy,
    })),
  ]

  let formDialog: Nullable<FormDialog>
  let editableGrid: Nullable<EditableGrid>

  function fromApiFormToInternal(
    item: ApiFormItem
  ): FormDefinitionEditorItemBase {
    return {
      type: item.type as any,
      name: item.name,
      required: item.required,
      config: {
        ...item.config,
        '@default': item.default,
        '@label': item.label,
        '@placeholder': item.placeholder,
        '@options': item.options,
      },
    }
  }

  function fromInternaltoApiForm(
    item: FormDefinitionEditorItemBase
  ): ApiFormItem {
    const {
      '@default': _default,
      '@label': label,
      '@placeholder': placeholder,
      '@options': options,
      ...config
    } = item?.config ?? {}

    return {
      name: item.name,
      type: item.type,
      required: !!item.required,
      config: config,
      default: _default,
      label,
      options,
      placeholder,
    }
  }

  /** Prompt a form for changing the form item configs */
  async function promptItemConfig(
    id: string,
    type: keyof typeof formItemTypes,
    data: any,
    name: string
  ) {
    if (!editableGrid || !editableGrid.grid) return

    const result = await formDialog.promptForm(
      `Edit Form Item (${name ?? 'Unknown'})`,
      data,
      {
        rows: getFormItems(formItemTypes[type]?.metaForm ?? []),
      }
    )

    chain(result)
      .map(() => editableGrid.grid.data.getItem(id))
      .map((item) => {
        item.config = result
        editableGrid.handleValueChange()
      })
  }
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <div class="gridRenderer">
    <EditableGrid
      bind:this={editableGrid}
      {columns}
      bind:value
      adaptOutput={fromInternaltoApiForm}
      adaptInput={fromApiFormToInternal}
    />
  </div>
</FormElementBase>

<FormDialog bind:this={formDialog} />

<style lang="scss">
  @use '../../theme/variables' as vars;

  .gridRenderer {
    width: 100%;
    height: 30em;
  }
</style>
