<script lang="ts">
  import type { Combobox } from '@dhtmlx/ts-combobox'
  import type { IAnyObj } from '@dhtmlx/ts-common/types'
  import type { ICheckbox, ICombo, IFormConfig, IInput } from '@dhtmlx/ts-form'
  import type { IDatePicker, IItem } from '@dhtmlx/ts-navbar'
  import {
    FormControlList,
    type FormConfig,
    type FormEntity,
    type ICustomContainerConfig,
  } from '@lib/FormUtil'
  import { DhxForm } from '@lib/dhtmlx'
  import {
    awaitRedraw,
    bindComponentProp,
    chain,
    isDHTMLXDateTime,
    isSet,
    isString,
    parseBoolean,
    type MappedEvents,
  } from '@packages/util'
  import {
    SvelteComponent,
    createEventDispatcher,
    getAllContexts,
    onDestroy,
    onMount,
  } from 'svelte'

  interface $$Events {
    change: CustomEvent<{
      name: string
      value: any
    }>
    click: CustomEvent<{
      name: string
      value: any
    }>
    focus: CustomEvent<{
      name: string
      value: any
      id?: string | number
    }>
    blur: CustomEvent<{
      name: string
      value: any
      id?: string | number
    }>
    customContainersRender: CustomEvent<void>
  }

  export let formConfig: FormConfig = {}
  export const form: FormEntity = {
    dhx: null,
    rebuild: initForm,
    setValue,
    getValue,
    enable,
    disable,
    changeValue,
  }

  export let disabled = false

  let componentMap = new Map<string, SvelteComponent>()

  let value: Record<string, any> = {}
  let convertValues: { field: string; type: string }[] = []

  let _context = getAllContexts()
  let _ready = false

  $: if (disabled) {
    form?.disable()
  } else {
    form?.enable()
  }

  const dispatchEvent = createEventDispatcher<MappedEvents<$$Events>>()

  let defaultConfig: FormConfig = {
    id: `form-${Math.random().toString(16).slice(2)}`,
    config: { rows: [] },
  }

  formConfig = { ...defaultConfig, ...formConfig }

  /**
   * Function to allow for adding custom svelte components
   *
   * Usage:
   * <IItemConfig>
   * {
   *    type: 'component'
   *    html: 'containerName' // This will get replaced with the proper component
   * }
   *
   * Slot syntax:
   * <div data-container="containerName">
   *    <ComponentHere />
   * </div>
   */
  async function assignCustomContainers() {
    _ready = false
    await awaitRedraw()

    form.dhx?.forEach((item) => {
      // @ts-ignore the config property is set
      const config = item.config
      if (config.type == 'container') {
        const identifier = config.name ?? config.id
        const formItem = form.dhx?.getItem(identifier)
        const formNode = formItem?.getRootNode() as HTMLElement

        if (isCustomContainer(config)) {
          // Render the custom component
          _renderCustomComponent(item, config)
          // Show the component content
          formNode?.classList.add('rendered')
        } else {
          // Show the generic container content
          formNode
            ?.querySelector(
              '.dhx_layout-cell-content .dhx_layout-cell-inner_html'
            )
            ?.classList.add('rendered')
        }
      }
    })

    await awaitRedraw()

    dispatchEvent('customContainersRender')

    await awaitRedraw()

    // FIX: Add tooltips to labels in non-container type elements
    form.dhx.forEach((item: IItem) => {
      if (item.config.type != 'container')
        chain(item)
          .map((item) => item.getRootNode() as HTMLElement)
          .map((element) => element.querySelector('label'))
          .map((label) => (label.title = label.textContent))
    })

    _ready = true
  }

  function isCustomContainer(config: any): config is ICustomContainerConfig {
    const htmlValue: string = config.html
    return (
      config.type == 'container' &&
      isString(htmlValue) &&
      htmlValue.length < 64 &&
      htmlValue?.startsWith('@')
    )
  }

  function _renderCustomComponent(
    item: IFormConfig,
    config: ICustomContainerConfig
  ) {
    const requiredClass = 'dhx_form-group--required'
    const identifier = config.name ?? config.id

    const htmlValue: string = config.html
    // Set component directly via component initialization
    const formItem = form.dhx?.getItem(identifier)
    const formNode = formItem?.getRootNode() as HTMLElement

    // Return if form node has not been found
    if (!formNode) return

    // Sanitize the container name input
    const containerName = htmlValue.replaceAll(/[^\w\-]/g, '')

    // Return if component has not been found
    if (!Object.keys(FormControlList).find((value) => value == containerName))
      return

    // @ts-ignore Remove the initial `@Component` html input
    formNode.innerHTML = null

    // Set a reference to the source form
    // (Using is not recommended, but necessary in some situations)
    const context = new Map(_context)
    context.set('getForm', getForm)

    // Actually initialize the component
    const component = new FormControlList[containerName]({
      target: formNode,
      context,
    })

    config.$component = component

    // Set props safely
    if (component.value !== undefined) component.value = config.value //      `any`
    if (component.label !== undefined) component.label = config.label //      `string`
    if (component.config !== undefined) component.config = config.config //     `any`
    if (component.validation !== undefined)
      component.validation = config.validation // `(value: any) => boolean`
    if (component.state !== undefined) component.state = config.state //      `null | 'success' | 'error'`
    if (component.helpMessage !== undefined)
      component.helpMessage = config.helpMessage //    `string`
    if (component.preMessage !== undefined)
      component.preMessage = config.preMessage //     `string`
    if (component.successMessage !== undefined)
      component.successMessage = config.successMessage // `string`
    if (component.errorMessage !== undefined)
      component.errorMessage = config.errorMessage //   `string`

    // Apply required value
    if (config.required) {
      formNode.classList.add(requiredClass)
    }

    // Attach events to component
    // component.$on('update', (event) => {})

    // Temporary flag to prevent a change event to
    // be immediately dispatched after `setValue()` has been called
    let hasBeenSet = false

    // Apply value binding
    bindComponentProp(component, 'value', (event: unknown) => {
      value[identifier] = event

      // Emit the change event
      if (event !== undefined && _ready) {
        // Fire a svelte event (Only when manually changed)
        !hasBeenSet &&
          dispatchEvent('change', {
            name: config.name ?? config.id,
            value: event,
          })
        // Also fire a DHTMLX event
        formItem.events.fire('change', [event])
      }
    })

    // @ts-ignore Set DHTMLX function
    item.setValue = (value: unknown) => {
      hasBeenSet = true
      component.value = value
      awaitRedraw().then(() => (hasBeenSet = false))
    }

    // @ts-ignore Set DHTMLX function
    item.getValue = () => component.value

    // @ts-ignore Override hide function
    item.hide = () => formNode.parentElement.classList.add('hidden')

    // @ts-ignore Override show function
    item.show = () => formNode.parentElement.classList.remove('hidden')

    // @ts-ignore Override validate function
    item.validate = (silent?: boolean, validateValue?: any) => {
      const result =
        // Check Required constraint
        (component?.required ?? formNode?.classList.contains(requiredClass)
          ? isSet(component?.value)
          : true) &&
        // Apply component validation function
        (component?.validate?.() ?? true) &&
        // Apply validation from config
        (component.validation?.(validateValue ?? component?.value) ?? true)

      if (!silent && Object.hasOwn(component, 'state')) {
        component.state = result ? 'success' : 'error'
      }

      return result
    }

    // @ts-ignore Override clearValidate function
    item.clearValidate = () => {
      if (Object.hasOwn(component, 'state')) {
        component.state = null
      }
    }

    // @ts-ignore Override setProperties function
    item.setProperties = (obj: Record<string, any>) => {
      Object.entries(obj).forEach(([key, value]) => {
        component[key] = value
      })
    }

    // Insert it into the loaded component map
    componentMap.set(config.id, component)
  }

  function enhanceControls() {
    // Clear the conversion list
    convertValues = []

    // Go through each element
    form.dhx?.forEach((item: IItem) => {
      /**
       * Add functionality to every single-selection combobox
       *  to clear the input when opening the selection view.
       * This allows the user to more easily select a different item,
       *  without clearing the input.
       * When the combobox is closed without any changes,
       *  it will revert to its previous value.
       */
      const combobox = <ICombo>item
      if (
        combobox?.config?.type == 'combo' &&
        !combobox?.config?.multiselection
      ) {
        const actualItem = <Combobox>combobox.getWidget()

        let isOpen = false
        let manuallyChanged = false
        let previousValue: any

        // Save the previous value and clear the input on opening
        actualItem.events.on('beforeOpen', () => {
          previousValue = combobox.getValue()

          actualItem.clear()

          manuallyChanged = false
          isOpen = true
        })

        // Restore the value if it hasn't been manually changed
        actualItem.events.on('afterClose', () => {
          isOpen = false

          if (!manuallyChanged) {
            combobox.setValue(previousValue)
          }
        })

        // Mark the element as changed if done so manually
        actualItem.events.on('change', () => {
          if (isOpen) {
            manuallyChanged = true
          }
        })
      }

      const datePicker = <IDatePicker>item
      if (datePicker?.config?.type == 'datepicker') {
        const field = datePicker.config.name ?? datePicker.config.id
        convertValues.push({ field, type: 'datetime' })
      }

      /** Fire a change event on first keypress (once, waits until the field gets unfocused )*/
      const input = <IInput>item
      if (input?.config?.type == 'input') {
        let startedEdit = false
        input.events.on('keydown', (event) => {
          if (startedEdit) return
          startedEdit = true
          input.events.fire('change', [input.getValue()])
        })
        input.events.on('blur', (event) => {
          startedEdit = false
        })
      }
      
      /** Ensure the checkbox follows the current value on creation */
      const checkbox = <ICheckbox>item
      if (checkbox?.config?.type == 'checkbox') {
        checkbox.setValue(parseBoolean(checkbox.config?.value))
      }

      /** Disable the form item when read-only */
      if (item?.config?.readOnly) {
        item.disable()
      }
    })
  }

  function convertFormResult(result: IAnyObj): IAnyObj {
    if (!result) return result

    convertValues.forEach((element) => {
      switch (element.type) {
        case 'datetime':
          if (
            result[element.field] &&
            isDHTMLXDateTime(result[element.field])
          ) {
            result[element.field] = new Date(
              result[element.field]
            ).toISOString()
          }
          break
      }
    })

    return result
  }

  function initForm() {
    if (!formConfig?.config) return

    form?.dhx?.destructor()

    form.dhx = new DhxForm(String(formConfig.id), formConfig.config)
    if (disabled) form.dhx.disable()

    assignCustomContainers()
    enhanceControls()

    form.dhx.events.on('change', function (name: string, value: any) {
      _ready && dispatchEvent('change', { name: name, value: value })
    })

    form.dhx.events.on('click', function (name: string, value: any) {
      dispatchEvent('click', { name: name, value: value })
    })

    form.dhx.events.on('focus', function (name: string, value: any, id: any) {
      dispatchEvent('focus', { name, value, id })
    })

    form.dhx.events.on('blur', function (name: string, value: any, id: any) {
      dispatchEvent('blur', { name, value, id })
    })
  }

  function setValue(data: Record<string, any>) {
    value = data
    form?.dhx?.setValue(value)
  }

  function getValue(): Record<string, any> {
    let returnValue = {
      ...value,
      ...convertFormResult(form?.dhx?.getValue() ?? {}),
    }

    return returnValue
  }

  function changeValue(_name: string, _value: any) {
    value[_name] = _value
  }

  /**
   * Enable the form
   */
  function enable() {
    form?.dhx?.forEach((item: IItem) => {
      if (item?.config && !item?.config?.readOnly) item?.enable?.()
    })
  }

  /**
   * Disable the form
   */
  function disable() {
    form?.dhx?.forEach((item: IItem) => {
      if (item?.config) item?.disable?.()
    })
  }

  function getForm() {
    return form.dhx
  }

  onMount(() => {
    initForm()
  })

  onDestroy(() => {
    if (form?.dhx?.destructor) {
      form.dhx.destructor()
      form.dhx = undefined
    } else {
      awaitRedraw().then(function () {
        form?.dhx?.destructor()
        form.dhx = undefined
      })
    }

    // Destroy each component in the map
    componentMap.forEach((component, key) => {
      component.$destroy()
      componentMap.delete(key)
    })
  })
</script>

<div class="form" id={formConfig?.id ?? ''}></div>

<style lang="scss">
  .form {
    height: fit-content;
    :global(label) {
      text-align: left;
      color: rgba(0, 0, 0, 0.7);
    }

    :global(.dhx_form-group) {
      margin-bottom: 0px;
    }
    :global(.dhx_form-element) {
      padding-bottom: 5px;
    }
    :global(input) {
      background-color: transparent;
      // box-shadow: none;
      transition: none;

      &:placeholder-shown {
        box-shadow: inset 0 0 0 1px #dfdfdf;
      }
      // &:hover {
      //   box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.7);
      // }
    }

    :global(.dhx_form.grayoutOnDisable .dhx_form-group--disabled) {
      filter: grayscale(1);
    }

    :global(.dhx_form.noSelect .dhx_text) {
      user-select: none;
    }

    // By default, hide the HTML type container content before it is properly rendered
    :global(.dhx_layout-cell-inner_html:not(.rendered)) {
      user-select: none;

      width: 20px;
      height: 20px;
      margin: 0.25em;
      overflow: hidden;

      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      &::before {
        // mdiLoading
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJNMTIsNFYyQTEwLDEwIDAgMCwwIDIsMTJINEE4LDggMCAwLDEgMTIsNFoiIC8+PC9zdmc+');
      }
    }
  }
</style>
