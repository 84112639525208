<!-- Special text element that automatically applies the global formatting options -->
<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import { dateFormatter } from '../lib/dateFormatterStore'

  export let value: Date | string | number
  export let precision: 's' | 'ms' = 'ms'

  let unsubscribe = () => {}
  let displayValue = ''

  $: format(value)

  function format(value: Date | string | number) {
    value = new Date(value)
    if (!isDate(value)) return `####`
    displayValue = $dateFormatter.format(value)
    if (precision == 'ms') displayValue += `.${value.getMilliseconds()}`
  }

  function isDate(value: any): boolean {
    return value instanceof Date && !!value.getTime
  }

  onMount(() => {
    unsubscribe = dateFormatter.subscribe(() => {
      format(value)
    })

    format(value)
  })

  onDestroy(() => {
    unsubscribe()
  })
</script>

<span>{displayValue}</span>
