<!-- ToolbarHook Component, Place multiple ToolbarItems to add and remove buttons to the global toolbar -->
<script lang="ts">
  import { DhxToolbar } from '@lib/dhtmlx'
  import type { ToolbarConfig } from '@models/Toolbar'
  import { Toolbar } from '@models/Toolbar'
  import {
    createEventDispatcher,
    onDestroy,
    onMount,
    setContext,
    tick,
  } from 'svelte'
  import { readable, writable } from 'svelte/store'

  const dispatch = createEventDispatcher()

  const id = `toolbarGroup-${Math.random().toString(16).slice(2)}`

  const toolbarStore = writable<Toolbar>()

  export let disabled = false
  export let hidden = false

  let toolbarReady = false

  let toolbar: Toolbar = undefined
  let toolbarConfig: ToolbarConfig = {
    css: 'headerToolbar',
  }

  let updateDisabledState: (state: boolean) => void

  $: updateDisabledState?.(disabled)
  $: if (toolbar)
    if (hidden) toolbar?.dhx.hide(undefined)
    else toolbar?.dhx.show(undefined)

  toolbarConfig.id = toolbarConfig.id
    ? toolbarConfig.id
    : `toolbar-${Math.random().toString(16).slice(2)}`

  setContext('contextId', id)
  setContext('toolbarStore', toolbarStore)

  setContext(
    'disabled',
    readable(disabled, (set) => {
      updateDisabledState = (state) => set(state)
    })
  )

  onMount(async () => {
    toolbar = new Toolbar(
      new DhxToolbar(toolbarConfig.id, toolbarConfig),
      toolbarConfig
    )

    toolbarStore.set(toolbar)
    await tick()
    toolbarReady = true
    dispatch('ready')
  })

  onDestroy(() => {
    toolbarReady = false

    toolbar?.dhx.destructor()
  })
</script>

<div class="toolbar" id={toolbarConfig?.id}></div>

{#if toolbarReady}
  <div class="toolbarHook" style="display: none;">
    <slot />
  </div>
{/if}
