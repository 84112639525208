/**
 * Alias to `console.log`, but only prints when in Development mode.
 * This is preffered to avoid clogging up the console in the production build.
 */
export function debug(...message: Parameters<typeof console.log>) {
  if (import.meta.env.DEV) {
    // @ts-ignore
    console.log(...message)
  }

  return message[0]
}
