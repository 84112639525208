/* Browser Dependent Utilities */

/**
 * Write text to clipboard
 */
export function setClipboard(value: string) {
  if (!navigator?.clipboard?.writeText) {
    throw new Error('navigator.clipboard.writeText not supported')
  }

  navigator.clipboard.writeText(value)
}

/** Download a file */
export async function download(
  data: string | Blob | MediaSource | Response,
  name = 'download.txt'
) {
  let _data: Nullable<Blob | MediaSource>

  if (typeof data == 'string') {
    _data = new Blob([data])
  } else if (data instanceof Response) {
    _data = await data.blob()
  } else {
    _data = data
  }

  if (!_data) return

  let a = document.createElement('a')
  try {
    a.href = window.URL.createObjectURL(_data)
    a.download = name
    a.click()
  } finally {
    a.remove()
  }
}

/** Get the decimal seperator in the current locale */
export function getDecimalSeparator(locales?: Intl.LocalesArgument) {
  return (1.1).toLocaleString(locales).substring(1, 2)
}
