<script lang="ts" context="module">
  import { onDestroy, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export const _name = 'PasswordVerify'
</script>

<script lang="ts">
  export let value = ''

  export let label = 'Password'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _password: string
  let _password_repeat: string

  let _password_focussed: boolean
  let _password_repeat_focussed: boolean

  let _state = state
  let _password_error = errorMessage

  let _ready = false

  function validatePassword(password: string, password_repeat: string) {
    if (!password?.length) {
      _state = state
      _password_error = errorMessage
      return false
    }

    if (password != password_repeat) {
      _state = 'error'
      _password_error = 'The given repeated password does not match.'
      return false
    }

    const meetsRequirements =
      // Minimum length: 8
      password.length >= 8 &&
      // Maximum length: 255
      password.length <= 255 &&
      // Contains: Alphabet
      /[a-zA-Z]/.test(password) &&
      // Contains: Numbers
      /[0-9]/.test(password) &&
      // Contains: Special Characters
      /[\x20-\x7E]/.test(password.replaceAll(/[\w]/g, ''))

    // No invalid characters allowed
    if (/[^\x20-\x7E]/.test(password)) {
      _state = 'error'
      _password_error = 'The given password contains invalid characters'
      return false
    }

    if (!meetsRequirements) {
      _state = 'error'
      _password_error =
        'The given password must be at least 8 characters long and must' +
        ' contain at least one letter, digit and a special character.'
      return false
    }

    _state = 'success'
    return true
  }

  $: if (
    _ready &&
    validatePassword(_password, _password_repeat) &&
    !(_password_focussed || _password_repeat_focussed)
  ) {
    value = _password
  }

  onMount(async () => {
    await tick()
    _ready = true
  })
  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  state={_state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={_password_error?.length ? _password_error : errorMessage}
>
  <input
    type="password"
    placeholder="Enter a password (Leave empty to keep unmodified)"
    name="_password"
    required={true}
    class="dhx_input"
    autocomplete="new-password"
    bind:value={_password}
    on:focus={() => (_password_focussed = true)}
    on:blur={() => (_password_focussed = false)}
  />
  <input
    type="password"
    placeholder="Repeat the password"
    name="_password_repeat"
    required={true}
    class="dhx_input"
    autocomplete="new-password"
    bind:value={_password_repeat}
    on:focus={() => (_password_repeat_focussed = true)}
    on:blur={() => (_password_repeat_focussed = false)}
  />
</FormElementBase>
