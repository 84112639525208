import * as Generated from '@generated'
import {
  downloadLatestReportRenderer,
  dummyColumn,
  entitySelectColumn,
  generatedEnumColumn,
  inputColumn,
} from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.Asset> = {
  columns: {
    name: () => inputColumn('name'),
    alias: () => inputColumn('alias'),
    assetClassId: () =>
      entitySelectColumn('assetClass@formattedText', 'assetClass', {
        field: 'assetClassId',
        valueField: 'name',
      }),
    parentId: () =>
      entitySelectColumn('parent@formattedText', 'asset', {
        field: 'parentId',
        valueField: 'name',
      }),
    type: () => generatedEnumColumn('type', Generated.Asset_TYPEEnum),

    //@ts-expect-error Custom colum, Downloads the latest report on click
    downloadLatestReport: () =>
      dummyColumn('latestReport@databind.id', {
        requiresExpand: true,
        displayComponent: downloadLatestReportRenderer,
      }),
  },
  filters: {
    accountId: null,
    assetClassId: { model: 'assetClass' },
    alias: 'string',
    config: null,
    name: 'string',
    parentId: { model: 'asset' },
    type: { enum: Generated.Asset_TYPEEnum },
    access_token: null,
  },
  defaultColumns: [
    'name',
    'alias',
    'modifiedBy',
    'status',
    //@ts-ignore Custom column
    'downloadLatestReport',
  ],
  defaultSort: 'name',
  nameField: 'name',
  customListSupport: true,
}
