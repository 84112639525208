import type { ModelFilter } from '@models/api/BaseModel'
import {
  FilterBase,
  FilterBetween,
  FilterEndsWith,
  FilterFalse,
  FilterGreaterThan,
  FilterGreaterThanOrEqual,
  FilterIn,
  FilterIncludes,
  FilterLessThan,
  FilterLessThanOrEqual,
  FilterLike,
  FilterNone,
  FilterNotIn,
  FilterSome,
  FilterStartsWith,
  FilterTrue,
} from './FilterTypes'
import type { FilterTypeName } from './FilterUtil'

/**
 * The available filters.
 * When deserializing, the first matching filter will be found in order of the list
 */
export function getAvailableFilters() {
  return [
    // Custom types comes first
    FilterBetween,
    FilterTrue,
    FilterFalse,
    FilterStartsWith,
    FilterEndsWith,
    FilterIncludes,

    // Then native types
    FilterSome,
    FilterNone,
    FilterLessThan,
    FilterLessThanOrEqual,
    FilterGreaterThan,
    FilterGreaterThanOrEqual,
    // FilterEqual,
    // FilterNotEqual,
    FilterIn,
    FilterNotIn,
    FilterLike,
  ] as const satisfies (typeof FilterBase)[]
}

/**
 * For each filter type, define which types can be implicitely converted to
 * (without returning to the default filter)
 */
export const convertableMatrix = {
  string: ['string'],
  boolean: ['boolean', 'string', 'numeric', 'integer'],
  datetime: ['datetime', 'string', 'date', 'time'],
  date: ['date', 'string', 'datetime', 'time'],
  time: ['time', 'string', 'datetime', 'date'],
  numeric: ['numeric', 'string', 'integer'],
  integer: ['integer', 'string', 'numeric'],
  model: [],
  enum: [],
} as const satisfies Record<FilterTypeName, FilterTypeName[]>

/** The default filter for each FilterType */
export function getDefaultFilter(type: FilterTypeName): typeof FilterBase {
  switch (type) {
    case 'string':
      return FilterLike
    case 'boolean':
      return FilterTrue
    case 'datetime':
      return FilterBetween
    case 'date':
      return FilterBetween
    case 'time':
      return FilterBetween
    case 'numeric':
      return FilterBetween
    case 'integer':
      return FilterBetween
    case 'model':
      return FilterIn
    case 'enum':
      return FilterIn
  }
}

/** Used for deserializing `and` and `or` groups */
function _deserializeAndOr(value: ModelFilter<any>[] | ModelFilter<any>) {
  return (Array.isArray(value) ? value : [value]).reduce(
    (prev, curr) => ({ ...prev, ...curr }),
    {}
  )
}

/** Apply a function when deserializing a group */
export const deserializeGroup = {
  and: _deserializeAndOr,
  or: _deserializeAndOr,
  not: (value) => value,
} as const satisfies Record<
  (typeof groupTypes)[number],
  (value: ModelFilter<any>[] | ModelFilter<any>) => ModelFilter<any>
>

/** Apply a function when serializing a group */
export const serializeGroup = {
  and: (value) => [value],
  or: (value) =>
    Object.entries(value).map(([_key, _value]) => ({
      [_key]: _value,
    })) as ModelFilter<any>[],
  not: (value) => value,
} as const satisfies Record<
  (typeof groupTypes)[number],
  (value: ModelFilter<any>) => ModelFilter<any> | ModelFilter<any>[]
>

/** Group types */
export const groupTypes = ['and', 'or', 'not'] as const
export const groupArrayTypes = ['and', 'or'] as const
