<!-- ProxyTarget Component -->
<script lang="ts" context="module">
  import { getId, useDebugger } from '@packages/util'
  import type { Action } from 'svelte/action'

  type _Element = HTMLDivElement

  const proxyAttr = 'x-proxy-id'
  const proxySourceUninitializedClass = 'proxySourceUninitialized'

  export function useProxy() {
    const debug = useDebugger('ProxyTarget').disable

    const queue = new Map<string, _Element>()
    let _registeredTarget: Nullable<Element> = null

    const _register = (id: string, element: _Element) => {
      element.setAttribute(proxyAttr, id)

      if (_registeredTarget) {
        debug('REGISTER_ELEMENT')
        element.classList.remove(proxySourceUninitializedClass)
        _registeredTarget.appendChild(element)
      } else {
        debug('REGISTER_ELEMENT_QUED')
        queue.set(id, element)
      }
    }

    const _deregister = (id: string) => {
      if (_registeredTarget) {
        debug('DEREGISTER_ELEMENT')
        for (const item of _registeredTarget.children) {
          if (item.getAttribute(proxyAttr) == id) {
            _registeredTarget.removeChild(item)
          }
        }
      } else {
        debug('DEREGISTER_ELEMENT_QUED')
        queue.delete(id)
      }
    }

    const register: Action<_Element> = (element) => {
      const id = getId()
      _register(id, element)

      return {
        // update() {},
        destroy() {
          _deregister(id)
        },
      }
    }

    const registerTarget = (id: string) => {
      debug('REGISTER_TARGET')
      const _element = document.getElementById(id)
      if (!_element) return
      _registeredTarget = _element

      // Add all items in queue
      queue.forEach((item) => {
        item.classList.remove(proxySourceUninitializedClass)
        _registeredTarget.appendChild(item)
      })
      queue.clear()
      debug('REGISTER_TARGET_OK')
    }

    const deregisterTarget = (id: string) => {
      debug('DEREGISTER_TARGET')
      for (const item of _registeredTarget.children) {
        queue.set(item.getAttribute(proxyAttr), item as _Element)
      }

      // Put all elements back in queue
      _registeredTarget = null
      debug('DEREGISTER_TARGET_OK')
    }

    return {
      register,
      registerTarget,
      deregisterTarget,
    }
  }

  //prettier-ignore
  export type ProxyComponents = ReturnType<typeof useProxy>;
</script>

<script lang="ts">
  export let components: ProxyComponents

  const registerTarget: Action<_Element, Nullable<ProxyComponents>> = (
    node,
    components
  ) => {
    const id = getId('target')
    node.id = id

    components.registerTarget(node.id)

    return {
      destroy() {
        components?.deregisterTarget(node.id)
      },
    }
  }
</script>

<div class="proxyTarget" use:registerTarget={components}></div>

<style lang="scss">
  .proxyTarget {
    display: contents;
  }
</style>
