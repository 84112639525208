import { camel2title } from '@packages/util'

export const generatedEnumTranslationMap = {
  desc: 'Descending',
  asc: 'Ascending',

  noTiming: 'No Timing',
  time1Msec: '1 Millisecond',
  time2Msec: '2 Milliseconds',
  time5Msec: '5 Milliseconds',
  time10Msec: '10 Milliseconds',
  time20Msec: '20 Milliseconds',
  time50Msec: '50 Milliseconds',
  time100Msec: '100 Milliseconds',
  time200Msec: '200 Milliseconds',
  time500Msec: '500 Milliseconds',
  time1Sec: '1 Second',
  time2Sec: '2 Seconds',
  time5Sec: '5 Seconds',
  time10Sec: '10 Seconds',
  time30Sec: '30 Seconds',
  time1Min: '1 Minute',
  time2Min: '2 Minutes',
  time3Min: '3 Minutes',
  time5Min: '5 Minutes',
  time10Min: '10 Minutes',
  time15Min: '15 Minutes',
  time30Min: '30 Minutes',
  time1Hour: '1 Hour',
  time2Hour: '2 Hours',
  time6Hour: '6 Hours',
  time12Hour: '12 Hours',
  time24Hour: '24 Hours',

  int: 'Integer',

  ok: 'Successful',
  notOk: 'Failed',

  gui: 'GUI',
}

export function getEnumLabels<T extends Record<string, any>>(
  _enum: T
): Record<keyof T, string> {
  return Object.fromEntries(
    Object.keys(_enum).map((key) => [
      key,
      generatedEnumTranslationMap[key] || camel2title(key),
    ])
  ) as Record<keyof T, string>
}

export function getGeneratedEnumItems<T extends Record<string, any>>(
  _enum: T
): { key: string; label: string; value: number }[] {
  const labels = getEnumLabels(_enum)
  return Object.entries(_enum).map(([key, value]) => ({
    key,
    value,
    label: labels[key],
  }))
}
