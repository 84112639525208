import { isSet, parseFloatFromAny } from '@packages/util'

/**
 * Used for making sure the given value is a valid Integer.
 *
 * Returns true if it successfully parses to an Integer.
 */
export function isValidFloat(value: any): value is number {
  return isSet(parseFloatFromAny(value))
}

/**
 * Used for making sure the given value is a valid Integer.
 *
 * Returns true if it successfully parses to an Integer.
 */
export function isValidInt(value: any): value is number {
  const integer = parseFloatFromAny(value)
  return Number.isSafeInteger(integer)
}

/**
 * Is the given value a Date class?
 */
export function isDate(value: any): value is Date {
  return typeof value == 'object' && value instanceof Date && !!value.getTime
}

/**
 * Turn a variety of values to a boolean
 */
export function parseBoolean(value: any): value is boolean {
  return value === undefined || value === null
    ? false
    : value === true ||
        value >= 1 ||
        value === '1' ||
        value.toLowerCase?.() === 'on' ||
        value.toLowerCase?.() === 'true'
}

/**
 * Parse the given mimeType into individual elements
 */
export function parseMimeType(mimeType: string): Nullable<{
  /** Main Type: [application]/json;param=1 */
  type: string
  /** Sub Type: application/[json];param=1 */
  subType: string
  /** Parameters (No parsing): application/json;[param=1] */
  params?: string
}> {
  //#region Big Regex statement
  if (!isSet(mimeType) || typeof mimeType != 'string') return
  return /(?<type>\*|(?:[0-9A-Za-z!#$%&'*+.^_`|~-]+))\/(?<subType>[0-9A-Za-z!#$%&'*+.^_`|~-]+)(?<params>(?:[ \t]*;[ \t]*[0-9A-Za-z!#$%&'*+.^_`|~-]+=(?:[0-9A-Za-z!#$%&'*+.^_`|~-]+|\"(?:[^\"\\\\]|\\.)*\"))*)/.exec(
    mimeType
  )?.groups as any
  //#endregion Big Regex statement
}

/**
 * Try to parse the incoming JSON 
 * 
 * @return the fallback when it's an invalid JSON string
 */
export function tryParseJson<T>(json: any, fallback: T): T {
  if (typeof json != 'string') return fallback
  try {
    return JSON.parse(json)
  } catch (_) {
    return fallback
  }
}