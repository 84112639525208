import type { EnumSelectDynamicConfig } from '@components/FormControls/EnumSelectDynamic.svelte'
import { metaFormBase, type FormItemTypeConfig } from '@lib/ApiFormGenerator'

export function generateFormEnum(): FormItemTypeConfig {
  return {
    metaForm: [
      ...metaFormBase,
      {
        name: 'options',
        type: '$enumEdit',
      },
    ],
    toItem(data) {
      return {
        type: 'container',
        html: '@EnumSelectDynamic',
        config: {
          options: data.config?.options ?? {},
        } satisfies EnumSelectDynamicConfig,
      }
    },
  }
}
