import type { ComboboxItem } from '@components/ComboboxAsync.svelte'
import { camel2title } from '@packages/util'
import {
  baseApi,
  type FilterType,
  type ModelReference,
} from '@models/api/BaseApi'
import type { ModelFilterCmp } from '@models/api/BaseModel'
import {
  convertableMatrix,
  getAvailableFilters,
  getDefaultFilter,
  groupTypes,
} from './FilterDefinitions'
import FilterNode from './FilterNode'
import { FilterBase } from './FilterTypes'
import { findFilterByName, getFilterTypeName, resolve } from './FilterUtil'

/** The Filter Group (column -> filter) */
export default class FilterItem extends FilterNode {
  type: FilterBase
  column: string
  columnType: FilterType

  isItem(): this is FilterItem {
    return true
  }

  getKey(): string {
    return this.column
  }

  serialize(): [key: string, value: ModelFilterCmp<any>] {
    return [this.column, this.type?.serialize()]
  }

  static deserialize(
    model: ModelReference,
    key: string,
    value: ModelFilterCmp<any>
  ): FilterItem | null {
    if (groupTypes.includes(key as any)) return null

    const filters = baseApi[model].context.filters
    const filterType = filters[key] as FilterType

    if (!filterType) return null

    let instance = new FilterItem()
    instance.model = model
    instance.column = key
    instance.columnType = filterType
    instance.type = FilterBase.findAndInstantiate(filterType, value)

    if (!instance.type) return null

    return instance
  }

  /** Get the list of available operations in a Combobox items format */
  getOperations(): ComboboxItem[] {
    return getAvailableFilters()
      .filter(
        (filter) =>
          filter.supportedFilterTypes == '*' ||
          (filter.supportedFilterTypes as any as string[]).includes(
            getFilterTypeName(this.columnType)
          )
      )
      .map<ComboboxItem>((filter) => ({
        id: filter.name,
        value: resolve(filter.label, this.columnType),
      }))
  }

  /** Swap the column with a new one by name */
  swapColumn(columnName: string): boolean {
    // Check if the column exists
    const newColumnType = baseApi[this.model].context.filters[
      columnName
    ] as FilterType
    if (!newColumnType) return false

    const newColumnTypeName = getFilterTypeName(newColumnType)

    // Check if the new column is compatible with the current type,
    const currentColumnType = this.columnType
    let filterClass = this.type?.constructor as typeof FilterBase
    let hasFilter = !!filterClass

    if (
      // Not...
      !(
        hasFilter && // Filter is set
        // New column type is convertable
        (
          convertableMatrix[getFilterTypeName(currentColumnType)] as string[]
        ).includes(newColumnType as string) &&
        // Filter is compatible in new column
        filterClass.matchFilterType(newColumnType)
      )
    ) {
      // Default to FilterIn
      filterClass = getDefaultFilter(newColumnTypeName)
      if (hasFilter) this.type.value = null
    }

    // Swap column and filter
    this.columnType = newColumnType
    this.column = columnName
    this.swapFilter(filterClass.name)

    return true
  }

  /** Swap the filter with a new one by name */
  swapFilter(filterName: string): boolean {
    const found = findFilterByName(filterName)
    if (!found) return false

    const newType = new found(this.columnType)

    const value = this.type?.implicitValue ? null : this.type?.serialize()
    newType.deserialize(value)

    this.type = newType

    return true
  }

  /** Convert the item to a string */
  toString(): string {
    if (!this.type?.constructor) return '<NULL>'

    const name = camel2title(this.column)
    const label = resolve(
      (this.type.constructor as typeof FilterBase).label,
      this.columnType
    )

    return `"${name}" ${label} ${this.type.valueToString()}`
  }

  /** Check if this node has a valid type */
  isSet() {
    return !!this.getKey() && !!this.type
  }
}
