<!-- Special text element that automatically applies the global formatting options -->
<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import { numberFormatter } from '../lib/numberFormatterStore'

  export let value: any
  export let hideNaN = true

  let unsubscribe = () => {}
  let displayValue = ''

  $: format(value)

  function format(value: any) {
    let _value: number =
      typeof value != 'number' ? Number.parseFloat(`${value}`) : value
    displayValue = Number.isNaN(_value)
      ? hideNaN
        ? ''
        : 'NaN'
      : $numberFormatter.format(value)
  }

  onMount(() => {
    unsubscribe = numberFormatter.subscribe(() => format(value))

    format(value)
  })

  onDestroy(() => {
    unsubscribe()
  })
</script>

<span>{displayValue}</span>
