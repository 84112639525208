<!-- A modal template, ready to use -->
<script lang="ts">
  import Form from '@components/Form.svelte'
  import ToolbarItem from '@components/Toolbar/ToolbarItem.svelte'
  import Window from '@components/Window.svelte'
  import type {
    IButton,
    IItemConfig,
    ISimpleVault,
    ITextArea,
  } from '@dhtmlx/ts-form'
  import { request } from '@lib/ApiUtil'
  import { parseError, type TraceBack } from '@lib/ErrorHandler'
  import type { FormConfig, FormEntity } from '@lib/FormUtil'
  import type { ApiImportResultList } from '@models/api/ApiModels'
  import { baseApi, type ModelReference } from '@models/api/BaseApi'
  import type BaseModel from '@models/api/BaseModel'
  import type { AnyBaseModel } from '@models/api/BaseModel'
  import type { MappedEvents } from '@packages/util'
  import { formatBackendTime } from '@packages/util'
  import { createEventDispatcher } from 'svelte'

  interface $$Events {
    // Define more events here
    apply: CustomEvent<string[]>
  }

  const acceptFileTypes = [
    // Spreadsheets
    '.xlsx',
    '.xls',
    '.ods',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    // CSV
    '.csv',
    'text/csv',
    'text/x-csv',
    'text/plain',
  ].join(',')

  const id = `importDialog-${Math.random().toString(16).slice(2)}`

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let show = false
  export let apiModel: BaseModel<any> | ModelReference | null = null

  let abortController = new AbortController()

  let _applying = false
  let form: FormEntity = null
  let formConfig: FormConfig = {
    config: {
      rows: <IItemConfig[]>[
        {
          type: 'text',
          label:
            'Note: Importing data can take longer than a minute if the input is large',
          css: 'dummy',
        },
        {
          type: 'simplevault',
          name: 'file',
          required: true,
          singleRequest: true,
          label: 'File selection',
          errorMessage: 'There must be one file selected.',
          accept: acceptFileTypes,
        },
        {
          type: 'checkbox',
          checked: true,
          value: true,
          name: 'dryRun',
          text: 'Dry Run',
          label: 'Extra settings',
        },
        {
          type: 'container',
          name: 'summary',
          html: '@TextDisplay',
          label: 'Result',
          // Can not use `hidden: true`, since it won't render the component
          css: 'hidden',
          state: 'error',
        },
        {
          type: 'button',
          text: 'Download summary',
          id: 'downloadSummary',
          hidden: true,
        },
      ],
    },
  }

  function handleBeforeOpen(event: Event) {
    // On before window open function here
    // event.preventDefault() // Running this function will block the opening of the window
  }

  function handleBeforeClose(event: Event) {
    // On before window close function here
    // event.preventDefault() // Running this function will block the closing of the window
  }

  function handleAfterOpen() {
    // On after window open function here
  }

  function handleAfterClose() {
    // On after window close function here
  }

  function fileImportGeneric(file: File, dryRun = false, signal?: AbortSignal) {
    let data = new FormData()

    data.append('file', file)
    data.append('dryRun', dryRun ? '1' : '0')

    return request<ApiImportResultList>({
      url: ['setup', 'import'],
      method: 'POST',
      signal,
      body: data,
      allowResponseCodes: [200, 204, 422],
      displayError: false,
    })
  }

  async function apply() {
    if (!form.dhx.validate()) return

    if (form.getValue()?.file?.length != 1) {
      // Mark as invalid if more than one file is selected
      form.dhx.getItem('file').config.$validationStatus = 1
      return
    }

    form.dhx.disable()
    _applying = true

    hideSummary()

    // Get the form value
    const data = form.getValue()

    let importResult: ApiImportResultList = null
    let trace: TraceBack = ['ImportDialog', 'apply']

    try {
      const apiModel = getApiModel()
      // Upload the file
      if (apiModel) {
        trace.push('fileImport')
        importResult = await apiModel.fileImport(
          data.file[0].file,
          data.dryRun,
          (abortController = new AbortController()).signal
        )
      } else {
        trace.push('fileImportGeneric')
        importResult = await fileImportGeneric(
          data.file[0].file,
          data.dryRun,
          (abortController = new AbortController()).signal
        )
      }

      trace.push('isSuccesful')
      if (importResult.success) {
        // Show import success
        if (!data.dryRun) {
          showSummary('Import Successful! Closing soon...', false, false)
          setTimeout(() => {
            show = false
          }, 2000)
        } else {
          showSummary(
            'Dry run: No errors have been found. ' +
              "Disable 'Dry Run' and run the action again to save the data.",
            false,
            false
          )
        }
      } else {
        showSummary(importResult.summary)
      }
    } catch (e) {
      const error = parseError(e, trace)
      showSummary(error.title + ': ' + error.description)
    } finally {
      form.dhx.enable()
      _applying = false
    }
  }

  function hideSummary() {
    const summary = <ITextArea>form.dhx.getItem('summary')
    const downloadSummary = <IButton>form.dhx.getItem('downloadSummary')

    summary.hide()
    downloadSummary.hide()
    // @ts-ignore 'click' event exists
    downloadSummary.events.detach('click')
  }

  function showSummary(message: string, asError = true, showDownload = true) {
    const file = <ISimpleVault>form.dhx.getItem('file')
    const summary = <ITextArea>form.dhx.getItem('summary')
    const downloadSummary = <IButton>form.dhx.getItem('downloadSummary')

    // Clear file
    file.clear()
    file.clearValidate()

    // Show the summary with a download button, clear file first
    summary.show()
    summary.setValue(message)

    // Add or remove error state
    if (asError) {
      ;(
        (summary as any)?.getRootNode() as HTMLElement
      )?.parentElement?.classList.add('state_error')
    } else {
      ;(
        (summary as any)?.getRootNode() as HTMLElement
      )?.parentElement?.classList.remove('state_error')
    }

    if (showDownload) {
      downloadSummary.show()
      downloadSummary.events.on('click', () => {
        // Download string
        const element = document.createElement('a')
        element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' +
            encodeURIComponent(
              message
                .replaceAll(/\<br\/?\>/g, '\n')
                .replaceAll(/(\&nbsp;){4}/g, '\t')
                .replaceAll(/\<\/?\w+\>/g, '')
            )
        )
        element.setAttribute(
          'download',
          `${formatBackendTime(new Date())} ${
            getApiModel()?.endpoint ?? 'data'
          }_importErrors.txt`
        )
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      })
    }

    form.dhx.paint()
  }

  function getApiModel(): AnyBaseModel {
    return typeof apiModel == 'string' ? baseApi[apiModel] : apiModel
  }
</script>

<Window
  bind:show
  title={'Import ' + (getApiModel()?.endpoint?.replaceAll('-', ' ') ?? 'Data')}
  width="40em"
  height="40em"
  on:beforeOpen={handleBeforeOpen}
  on:beforeClose={handleBeforeClose}
  on:afterOpen={handleAfterOpen}
  on:afterClose={handleAfterClose}
>
  <Form bind:form {formConfig} />
  <svelte:fragment slot="bottomToolbar">
    <ToolbarItem spacer />
    <ToolbarItem value="Cancel" type="button" on:click={() => (show = false)} />
    <ToolbarItem
      value="Import"
      type="button"
      on:click={() => apply()}
      loading={_applying}
    />
  </svelte:fragment>
</Window>
