<!-- A modal template, ready to use -->
<script lang="ts">
  import FilterEditGroup from '@components/FilterEdit/FilterEditGroup.svelte'
  import Window from '@components/Window.svelte'
  import ToolbarItem from '@components/Toolbar/ToolbarItem.svelte'
  import FilterGroup from '@lib/Filter/FilterGroup'
  import type { ModelReference } from '@models/api/BaseApi'
  import type { ModelFilter } from '@models/api/BaseModel'
  import type { MappedEvents } from '@packages/util'
  import { createEventDispatcher, tick } from 'svelte'

  interface $$Events {
    // Define more events here
    apply: CustomEvent<string[]>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let show = false
  export let model: ModelReference
  export let value: ModelFilter<any>
  // export let _param_ = ...

  let data: FilterGroup
  let display = true

  function handleBeforeOpen(event: Event) {
    data = FilterGroup.deserialize(model, 'and', value)
  }

  async function apply() {
    data.clean()
    await tick()
    value = data.serialize()?.[1]?.[0]
    show = false
    dispatch('apply')
  }

  async function clean() {
    display = false
    data.clean()
    await tick()
    display = true
  }
</script>

<Window
  bind:show
  title="Edit Filter"
  maxWidth="80em"
  width="95vw"
  height="95vh"
  maxHeight="95vh"
  on:beforeOpen={handleBeforeOpen}
>
  <div class="editor">
    {#if display}
      <FilterEditGroup bind:value={data} />
    {/if}
  </div>
  <svelte:fragment slot="bottomToolbar">
    <ToolbarItem
      value="Clean Filters"
      type="button"
      on:click={clean}
      css="marginLeft"
    />
    <ToolbarItem spacer />
    <ToolbarItem value="Cancel" type="button" on:click={() => (show = false)} />
    <ToolbarItem value="Apply" type="button" on:click={apply} />
  </svelte:fragment>
</Window>

<style lang="scss">
  .editor {
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
</style>
