import { ApiLazyDataProxy, type IApiLazyConfig } from '@lib/ApiDataProvider'
import { API } from '@lib/ApiHandler'
import {
  camel2kebab,
  capitalize,
  encodePHPQueryParams,
  kebab2camel,
} from '@packages/util'
import type {
  Imply,
  ReadonlyKeys,
  StringKeyOf,
} from '@packages/util/lib/UtilityTypes'
import { toast } from 'svelte-sonner'
import type ApiBaseModel from './ApiBaseModel'
import type { BaseModelResponse } from './ApiBaseModel'
import type { ApiImportResultList, HistoryStepsResponse } from './ApiModels'
import { type ModelContext } from './BaseApi'
import {
  request,
  type ApiRequestOptions as _ApiRequestOptions,
  type HttpMethod,
} from '@lib/ApiUtil'

// A list of supported query parameters supported by Yii ActiveRecord
export interface QueryParams<T extends ApiBaseModel> {
  /** Comma seperated list of columns to retrieve. Leave empty for all columns. */
  fields?: Array<keyof T>

  /** @see https://www.yiiframework.com/doc/guide/2.0/en/rest-resources */
  expand?: Array<ReadonlyKeys<T> | string>
}

// A list of supported query parameters supported by Yii ActiveRecord
export interface PageQueryParams<T extends ApiBaseModel>
  extends QueryParams<T> {
  /* Max item count to display */
  limit?: number

  /* Page number */
  page?: number

  /** @see https://localhost/api/v1/docs/filtering-guide */
  filter?: ModelFilter<T>

  /** @ts-ignore `keyof T` is usually of type string */
  sort?: ModelSort<T>
}

/** Comparable types in filter */
export type ModelFilterCmp<T> = {
  in?: T[]
  nin?: T[]
  like?: T
  lt?: T
  gt?: T
  lte?: T
  gte?: T
  eq?: T
  neq?: T
  set?: boolean
  null?: boolean
}

/** Filter type in request filter */
export type ModelFilter<T extends ApiBaseModel> = {
  and?: ModelFilter<Imply<T>>[]
  or?: ModelFilter<Imply<T>>[]
  not?: ModelFilter<Imply<T>>
} & {
  [P in keyof Imply<T>]?:
    | Imply<T>[P]
    | Imply<T>[P][]
    | ModelFilterCmp<Imply<T>[P]>
}

export type ModelSort<T extends ApiBaseModel> =
  | StringKeyOf<T>
  | `-${StringKeyOf<T>}`
  | Array<StringKeyOf<T> | `-${StringKeyOf<T>}`>

export const supportedExportFileTypes = [
  'xlsx',
  'xls',
  'ods',
  'csv',
  'html',
] as const

export const supportedImportFileTypes = [
  'xlsx',
  'xls',
  'xml',
  'ods',
  'slk',
  'slk',
  'gnumeric',
  'html',
  'csv',
] as const

export type ExtractBaseModelType<T extends BaseModel<any, any>> =
  T extends BaseModel<infer X, any> ? X : never

export type AnyBaseModel = BaseModel<any, any>

export type UpdateManyEntitiesEntry<T extends ApiBaseModel = ApiBaseModel> =
  Partial<T> & { $action?: 'delete' | null }

type ApiRequestOptions = Partial<_ApiRequestOptions>

// This will handle basic api related data calls
// TODO: Needs a better name, since its job is to send and receive update through the API
export default class BaseModel<
  T extends ApiBaseModel = ApiBaseModel,
  ClassName extends string = '',
> {
  #_context: ModelContext<T>
  #_endpoint: string = null // 'model-names'
  #_className: string = null // 'ModelName'

  constructor(context: ModelContext<T>) {
    this.#_context = context
    this.#_className = context.name
    this.#_endpoint = context.overrideEndpoint ?? camel2kebab(this.className)
  }

  getDataProxy(params?: IApiLazyConfig<T>) {
    return new ApiLazyDataProxy<T>(this.endpoint, params)
  }

  /**
   * Perform a getAll request
   *
   * @param queryParams
   * @param signal
   * @returns
   */
  getPage(
    queryParams?: PageQueryParams<T>,
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<BaseModelResponse<T>> {
    return request({
      ...requestOptions,
      url: this.buildPath(),
      signal,
      body: buildQueryParams<T>(queryParams),
    })
  }

  /**
   * Perform a getOne request
   *
   * @param queryParams
   * @param signal
   * @returns
   */
  getOne(
    entityOrId: string | T,
    queryParams?: QueryParams<T>,
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<T> {
    return request({
      ...requestOptions,
      url: this.buildPath(this.getId(entityOrId)),
      signal,
      body: buildQueryParams(queryParams),
    })
  }

  /**
   * Perform a deleteOne request
   *
   * @param queryParams
   * @param signal
   * @returns
   */
  deleteOne(
    entityOrId: string | T,
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<void> {
    return request({
      ...requestOptions,
      url: this.buildPath(this.getId(entityOrId)),
      signal,
      method: 'DELETE',
    })
  }

  /**
   * Perform a updateOne request
   *
   * @param entity
   * @param id
   * @param signal
   * @returns
   */
  updateOne(
    entity: Partial<T>,
    id?: string,
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<T> {
    return request({
      ...requestOptions,
      url: this.buildPath(id ?? this.getId(entity.id)),
      signal,
      body: entity,
      method: 'PUT',
    })
  }

  /**
   * Perform a batch update request
   *
   * @param entities
   * @param signal
   * @returns
   */
  updateMany(
    entities: UpdateManyEntitiesEntry<T>[],
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<T> {
    return request({
      ...requestOptions,
      url: this.buildPath('batch-update'),
      signal,
      body: entities,
      method: 'POST',
    })
  }

  /**
   * Perform a createOne request
   *
   * @param queryParams
   * @param signal
   * @returns
   */
  createOne(
    entity: T,
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<T> {
    return request({
      ...requestOptions,
      url: this.buildPath(),
      signal,
      body: entity,
      method: 'POST',
    })
  }

  /**
   * Perform a cloneOne request
   *
   * @param entityOrId
   * @param signal
   * @returns
   */
  cloneOne(
    entityOrId: string | T,
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<T> {
    return request({
      ...requestOptions,
      url: this.buildPath(this.getId(entityOrId), 'clone'),
      signal,
      method: 'POST',
    })
  }

  /**
   * Perform a historyGet request
   *
   * @param entityOrId
   * @param signal
   * @param dateFrom
   * @param dateTo
   * @returns
   */
  historyGet(
    entityOrId: string | T,
    dateFrom?: Date,
    dateTo?: Date,
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<HistoryStepsResponse<T>> {
    return request({
      ...requestOptions,
      url: this.buildPath(this.getId(entityOrId), 'get-history'),
      signal,
      body: {
        startDate: dateFrom?.toISOString() ?? 'P5Y',
        endDate: (dateTo ?? new Date())?.toISOString(),
      },
    })
  }

  /**
   * Perform a historyRevert request
   *
   * @param entityOrId
   * @param historyId
   * @param signal
   * @returns
   */
  historyRevert(
    entityOrId: string | T,
    historyId: string,
    signal?: AbortSignal,
    requestOptions?: ApiRequestOptions
  ): Promise<T> {
    return request({
      ...requestOptions,
      url: this.buildPath(this.getId(entityOrId), 'revert'),
      signal,
      body: {
        to: historyId,
      },
      method: 'POST',
    })
  }

  /**
   * Perform a generic request
   *
   * The same as request, but with the endpoint prefixed
   */
  fetch<TData = T>(
    url: string | string[],
    options?: RequestInit,
    body?: BodyInit | Record<string, any>,
    allowResponseCodes: number[] = [],
    displayError = true
  ): Promise<TData> {
    return request({
      url: this.buildPath(...(Array.isArray(url) ? url : [url])),
      signal: options?.signal,
      body,
      method: options?.method as HttpMethod,
      fetchOptions: options,
      allowResponseCodes,
      displayError,
    })
  }

  /**
   * Perform a generic page request
   *
   * The same as request, but with the endpoint prefixed
   * and the expected endpoint returning a paginated response
   */
  fetchPage<TData extends ApiBaseModel = T>(
    url: string | string[],
    queryParams?: PageQueryParams<TData>,
    signal?: AbortSignal,
    allowResponseCodes: number[] = [],
    displayError = true
  ): Promise<BaseModelResponse<TData>> {
    return request({
      url: this.buildPath(...(Array.isArray(url) ? url : [url])),
      signal: signal,
      body: buildQueryParams<TData>(queryParams),
      allowResponseCodes,
      displayError,
    })
  }

  /**
   * Get the concrete id of an entity
   * @param entityOrId The entity object or id (passes through)
   * @returns The entity id
   */
  getId(entityOrId: string | T) {
    return typeof entityOrId == 'string' ? entityOrId : entityOrId?.id
  }

  fileExport(
    queryParams: PageQueryParams<T>,
    format: (typeof supportedExportFileTypes)[number] = 'xlsx',
    asTemplate = false
  ) {
    performExportList(this.endpoint, queryParams, format, asTemplate)
  }

  fileImport(file: File, dryRun = false, signal?: AbortSignal) {
    let data = new FormData()

    data.append('file', file)
    data.append('dryRun', dryRun ? '1' : '0')

    return this.fetch<ApiImportResultList>(
      'import',
      { method: 'POST', signal },
      data,
      [422]
    )
  }

  /**
   * Turn a list of strings into a usable path with the endpoint prefixed
   * @param pathItems Zero or more path items
   * @returns The resulting path string
   */
  buildPath(...pathItems: string[]) {
    return [this.endpoint, ...pathItems]
  }

  /** Get an object with all the basic permissions of this model */
  get permissions() {
    const modelName = capitalize(kebab2camel(this.endpoint))

    if (modelName.includes('_')) {
      throw new Error('Not Implemented: Underscored permissions')
    }

    const _create = `create${modelName}` as `create${ClassName}`
    const _view = `view${modelName}` as `view${ClassName}`
    const _update = `update${modelName}` as `update${ClassName}`
    const _delete = `delete${modelName}` as `delete${ClassName}`

    return {
      create: _create,
      view: _view,
      update: _update,
      delete: _delete,
      all: [_create, _view, _update, _delete] as const,
    }
  }

  /** Property getter for `context` */
  get context() {
    return this.#_context
  }

  /** Property getter for `className` */
  get className() {
    return this.#_className
  }

  /** Property getter for `endpoint` */
  get endpoint() {
    return this.#_endpoint
  }
}

/**
 * Helper function to build query params for getting a page of a model
 */
export function buildQueryParams<T extends ApiBaseModel>(
  queryParams: PageQueryParams<T> | QueryParams<T>
) {
  if (queryParams?.fields && Array.isArray(queryParams.fields)) {
    // @ts-ignore String conversion
    queryParams.fields = queryParams.fields.join(',')
  }

  if (queryParams?.expand && Array.isArray(queryParams.expand)) {
    // @ts-ignore String conversion
    queryParams.expand = queryParams.expand.join(',')
  }

  // @ts-ignore Sort is only available in the page params
  if (queryParams?.sort && Array.isArray(queryParams.sort)) {
    // @ts-ignore String conversion
    queryParams.sort = queryParams.sort.join(',')
  }

  // @ts-ignore filter is only available in the page params
  if (queryParams?.filter && typeof queryParams.filter == 'object') {
    // @ts-ignore String conversion
    queryParams.filter = encodeFilter(queryParams.filter)
  }

  return queryParams
}

export function encodeFilter<T extends ApiBaseModel>(filter: ModelFilter<T>) {
  return window.btoa(JSON.stringify(filter))
}

/**
 * Export the list of the given modelName (camelCase)
 * This will open a new tab to perform the download
 */
export function performExportList<T extends ApiBaseModel = any>(
  endpointModelName: string,
  queryParams: PageQueryParams<T>,
  format: (typeof supportedExportFileTypes)[number] = 'xlsx',
  asTemplate = false,
  overrideEndpoint = false
) {
  // NOTE: Setting asTemplate to true will remove the search params
  const _query = encodePHPQueryParams({
    ...(!asTemplate ? buildQueryParams(queryParams) : []),
    type: format ?? 'xlsx',
    asTemplate,
  })

  const url = `/${endpointModelName}${!overrideEndpoint ? '/export' : ''}`

  const toastId = toast.loading('Preparing Export...')

  // First check if the request returns any data
  request({
    url: `${url}?${_query}`,
    method: 'HEAD',
    returnResponse: true,
  })
    .then((result) => {
      // Check if not NoContent (code 204)
      if (result.status != 204) {
        toast.dismiss(toastId)
        // Perform the action
        API.openDirectly(url, _query)
      } else {
        toast.error('Error', {
          id: toastId,
          description: 'No data to Export.',
        })
      }
    })
    .catch((error) => {
      // Dismiss the error before passing the error on
      toast.dismiss(toastId)
      throw error
    })
}
