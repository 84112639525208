<script context="module" lang="ts">
  import { parseError, type TraceBack } from '@lib/ErrorHandler'
  import { Toaster, toast, type ExternalToast } from 'svelte-sonner'

  const limit = 500

  /** Parse and wrap the error, display if not ignored */
  export function displayError(
    error: any,
    trace?: TraceBack,
    extraToastOptions?: ExternalToast
  ) {
    const parsedError = parseError(error, trace)
    if (parsedError.ignore) return parsedError

    // Limit full message body
    const description = parsedError.description
    const tooBig = description.length > limit

    console.log(extraToastOptions)

    toast.error(parsedError.title, {
      description: `${description.substring(0, limit)}${
        tooBig ? '... (Full message body hidden)' : ''
      }`,
      // duration: 1000000,
      action: {
        label: 'Copy Error',
        onClick(event) {
          navigator.clipboard.writeText(JSON.stringify(parsedError))
        },
      },
      ...(extraToastOptions ?? {}),
    })
    return parsedError
  }
</script>

<Toaster richColors class="svelte-sonner" />

<style lang="scss">
  @use '../../theme/variables' as vars;

  :global(.svelte-sonner [data-sonner-toast] [data-button]) {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 1);
  }
</style>
