import * as Generated from '@generated'
import {
  entitySelectColumn,
  generatedEnumColumn,
  inputColumn,
} from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.Channel> = {
  columns: {
    name: () => inputColumn('name'),
    alias: () => inputColumn('alias'),
    position: () => inputColumn('position'),
    dataType: () =>
      generatedEnumColumn('dataType', Generated.Channel_DATATYPEEnum),
    assetClassId: () =>
      entitySelectColumn('assetClass@formattedText', 'assetClass', {
        field: 'assetClassId',
        valueField: 'name',
        filter: {
          type: Generated.AssetClass_TYPEEnum.channel,
        },
      }),
  },
  filters: {
    assetId: { model: 'asset' },
    assetClassId: { model: 'assetClass' },
    name: 'string',
    alias: 'string',
    config: null,
    dataType: { enum: Generated.Channel_DATATYPEEnum },
    position: 'integer',
    unit: null,
  },
  defaultColumns: ['name', 'alias', 'status'],
  defaultSort: 'name',
  nameField: 'name',
  customListSupport: true,
}
