<!-- Form element that supports Selecting an enum from the Enum endpoint -->
<script lang="ts" context="module">
  import ComboboxAsync, {
    type ComboboxItem,
  } from '@components/ComboboxAsync.svelte'
  import FormElementBase from '@components/FormControls/FormElementBase.svelte'
  import { debounce } from '@packages/util'
  import type { EnumEditorItemBase } from './EnumEditor.svelte'
  import {
    getOptions,
    type EnumEditorDerivedValue,
  } from './EnumEditorDerived.svelte'

  export interface EnumSelectDynamicConfig {
    options?: EnumEditorDerivedValue
  }

  export const _name = 'EnumSelectDynamic'
</script>

<script lang="ts">
  export let value: Nullable<string> = null // Selected item value
  export let config: EnumSelectDynamicConfig = {}
  export let label = 'Form Editor'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  const reloadText = debounce((_: unknown) => {
    if (_changed) return
    comboboxAsync?.invalidate()
  }, 100)

  let _changed = false
  let dataPromise: Nullable<Promise<EnumEditorItemBase[]>> = null
  let comboboxAsync: Nullable<ComboboxAsync>

  $: reloadText(value)

  async function getData(): Promise<ComboboxItem[]> {
    return (await getDataPromise())?.map<ComboboxItem>((item) => ({
      id: item.value as any,
      value: item.name,
    }))
  }

  function getDataPromise() {
    if (dataPromise) return dataPromise
    dataPromise = getOptions(config?.options)
    return dataPromise
  }

  async function getCurrent(id: string) {
    return (
      (await getDataPromise()).find((item) => item.value == id)?.name ??
      '-- Select --'
    )
  }
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <ComboboxAsync
    bind:this={comboboxAsync}
    bind:selected={value}
    {getCurrent}
    {getData}
    on:change={() => (_changed = true)}
    autoConvert
  />
</FormElementBase>
