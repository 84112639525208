/**
 * Global number formatter for consistent formatting accross the page
 */
import { writable } from 'svelte/store'
import NumberFormatter from './NumberFormatter'

export const numberFormatterFixedFractions = writable(
  new NumberFormatter({
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  })
)

export const numberFormatter = writable(
  new NumberFormatter({
    useGrouping: false,
  })
)
