import type { ComboboxItem } from '@components/ComboboxAsync.svelte'
import { alphaSort, camel2title, isSet } from '@packages/util'
import { baseApi, type ModelReference } from '@models/api/BaseApi'
import type { ModelFilter } from '@models/api/BaseModel'
import {
  deserializeGroup,
  groupTypes,
  serializeGroup,
} from './FilterDefinitions'
import FilterItem from './FilterItem'
import FilterNode from './FilterNode'

/** The Filter Group (and, or, etc.) */
export default class FilterGroup extends FilterNode {
  children: FilterNode[]
  type: (typeof groupTypes)[number]
  _depth = 0

  isGroup() {
    return true
  }

  getKey(): string {
    return this.type
  }

  serialize(): [key: string, value: ModelFilter<any>] {
    return [
      this.type,
      serializeGroup[this.type](
        Object.fromEntries(
          this.children.map((child) => child?.serialize()).filter(isSet)
        )
      ),
    ]
  }

  static deserialize(
    model: ModelReference,
    key: string,
    value: ModelFilter<any>,
    _depth = 0
  ): FilterGroup | null {
    const _groupType = key as (typeof groupTypes)[number]

    if (!groupTypes.includes(_groupType)) return null

    let instance = new FilterGroup()
    instance.model = model
    instance.type = _groupType
    instance._depth = _depth

    instance.children = Object.entries(
      deserializeGroup[_groupType](value ?? {})
    )
      .map(
        ([_key, _value]) =>
          FilterGroup.deserialize(model, _key, _value, _depth + 1) ??
          FilterItem.deserialize(model, _key, _value)
      )
      .filter(isSet)

    return instance
  }

  /** Get the available column/group options as a list of DHTMLX Combobox items */
  getColumnOptions(): ComboboxItem[] {
    return (groupTypes as any as string[])
      .map<ComboboxItem>((item) => ({
        id: item,
        value: 'Expression: ' + camel2title(item),
      }))
      .concat(
        Object.entries(baseApi[this.model].context.filters ?? {})
          .filter(([_, value]) => isSet(value))
          .map(([key, _]) => key)
          .sort(alphaSort)
          .map((name) => ({
            id: name,
            value: camel2title(String(name)?.replace(/Id$/, '')),
          }))
      )
  }

  /** Convert the given child index to another filter item/group */
  swapItem(index: number, type: string): boolean {
    const _groupType = type as (typeof groupTypes)[number]
    const currentType = this.children[index]
    const toGroup = groupTypes.includes(_groupType)

    // From Group -> To Group
    if (toGroup && currentType.isGroup()) {
      currentType.type = _groupType
      return true
    }

    // From Item -> To Group
    if (toGroup && currentType.isItem()) {
      let instance = new FilterGroup()
      instance.model = this.model
      instance.type = _groupType
      instance.children = []
      this.children[index] = instance
      return true
    }

    // From Group -> To Item
    if (currentType.isGroup()) {
      return this.swapGroupToItem(index, type)
    }

    // From Item -> To Item
    if (currentType.isItem()) {
      // Swap the column
      return currentType.swapColumn(type)
    }
  }

  /** Swap the given group (by index) to a column */
  swapGroupToItem(index: number, column: string) {
    if (!this.children[index]?.isGroup()) return false

    // Check if column exists
    const columnType = baseApi[this.model]?.context.filters?.[column]
    if (!columnType) return false

    // Swap group to item
    let instance = new FilterItem()
    instance.model = this.model
    instance.column = column
    instance.columnType = columnType
    this.children[index] = instance
    return true
  }

  /** Add a new child to the group of the given type */
  addChild(type?: string) {
    let instance = new FilterItem()
    instance.model = this.model
    this.children.push(instance)

    // let instance = new FilterGroup()
    // instance.model = this.model
    // instance.type = <any>type
    // instance.children = []
    // this.children.push(instance)
  }

  /** Remove the child from the group */
  removeChild(index: number) {
    this.children = this.children.filter((_, _index) => index != _index)
  }

  /** Convert the group to a string */
  toString(): string {
    const prefix = this._depth > 0 ? `${camel2title(this.type)}:\n` : ''
    const tabs = '\t'.repeat(this._depth)
    const children = this.children
      .map((child) => child.toString())
      .join(';\n' + tabs)
      .trim()

    return prefix + tabs + children
  }

  /** Sort children in group */
  clean(): void {
    function sortFn<T extends FilterNode>(a: T, b: T): number {
      return alphaSort(a.getKey(), b.getKey())
    }

    // Filter and sort children
    const children = this.children.filter((item) => item.isSet()).sort(sortFn)

    // Get groups and items
    const groups = children.filter((item) => item.isGroup()) as FilterGroup[]
    const items = children.filter((item) => item.isItem()) as FilterItem[]

    // Clean inner items in group
    groups.forEach((group) => group.clean())

    // Set children
    this.children = [...groups, ...items]
  }

  /** Check if this node has any items */
  isSet() {
    return !!this.getKey() && this.children.some((item) => item.isSet())
  }
}
