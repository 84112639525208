// Enable or Disable features here
import _revision from './REVISION?raw'

// @ts-expect-error BUILD_DATE is defined in vite.config.ts
const buildDate = String(BUILD_DATE ?? 'Unknown')
const revision = String(_revision ?? 'Unknown').substring(0, 99)
const _isDev = import.meta.env.DEV ?? false

export const buildConfig = {
  revision,
  buildDate,
  features: {
    dev: _isDev,
    reports: true,
    equipment: true,
    installations: true,
    dashboard: true,
    batches: true,
    dataviewer: true,
    customLists: false, // Incomplete
    processEditor: true,
    mediaManager: true,
  },
  homepage: 'dashboard',
  processEditorType: 'blockly' as 'blockly' | 'casim',
  instanceConfig: {
    instanceTitle: 'Wizzo',
    instanceDescription: 'Instatune',
    title: 'Wizzo Instatune',
    defaultUserIcon: 'img/avatar-01.png',
    footer: {
      url: 'https://wizzo.nl',
      location: 'The Netherlands',
      telephone: '+31 (0)251-501675',
      email: 'support@wizzo.nl',
    },
    backendUrl: '%ORIGIN%/api/v1',
    batchName: {
      singular: 'Batch',
      plural: 'Batches',
    },
  },
} as const
