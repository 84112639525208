import type * as _DHTMLX from '@dhtmlx/ts-all/sources/entry_pro'
import type { SchedulerEnterprise, SchedulerStatic } from '@dhtmlx/ts-scheduler'
import type * as _DHTMLX_Vault from '@dhtmlx/ts-vault'

export type DHTMLX = typeof _DHTMLX & typeof _DHTMLX_Vault

const _dhtmlx: DHTMLX = window.dhx

export let dhxSchedulerStatic: SchedulerStatic = window?.scheduler
export let DhxSchedulerEnterprise: SchedulerEnterprise = window?.Scheduler
export type DhxSchedulerStatic = SchedulerStatic

export const dhxAlert = _dhtmlx.alert
export const dhxConfirm = _dhtmlx.confirm

export const DhxCalendar = _dhtmlx.Calendar
export const DhxCombobox = _dhtmlx.Combobox
export const DhxForm = _dhtmlx.Form
export const DhxPagination = _dhtmlx.Pagination
export const DhxTree = _dhtmlx.Tree
export const DhxTreeGrid = _dhtmlx.TreeGrid
export const DhxLayout = _dhtmlx.Layout
export const DhxGrid = _dhtmlx.Grid
export const DhxToolbar = _dhtmlx.Toolbar
export const DhxColorpicker = _dhtmlx.Colorpicker
export const DhxPopup = _dhtmlx.Popup

export const dhxVaultScriptUrl = new URL(
  '@public/includes/DHTMLX_Vault/vault.min.js',
  import.meta.url
)

export const dhxVaultStyleUrl = new URL(
  '@public/includes/DHTMLX_Vault/vault.min.css',
  import.meta.url
)
