import type { EntityMultiSelectConfig } from '@components/FormControls/EntityMultiSelect.svelte'
import type { EntitySelectConfig } from '@components/FormControls/EntitySelect.svelte'
import type { NumberFieldConfig } from '@components/FormControls/NumberField.svelte'
import type { IItemConfig } from '@dhtmlx/ts-form'
import * as Generated from '@generated'
import {
  camel2title,
  conditionalObj,
  debug,
  dhtmlxDateTimeFormat,
  isSet,
  isString,
} from '@packages/util'
import { generateFormEnum } from './ApiFormGenerator/FormGenerators/GenerateFormEnum'
import { generateFormTable } from './ApiFormGenerator/FormGenerators/GenerateFormTable'
import { commonStrings } from './CommonStrings'
import { validNumber } from './Validation'

export type ApiFormItemType = keyof typeof formItemTypes | `@${string}`

/** Properly typed version of FormItem */
export type ApiFormItem = Generated.FormItem

export interface FormItemTypeConfig {
  name?: string
  hidden?: boolean
  metaForm?: ApiFormItem[]
  toItem: (data: ApiFormItem) => IItemConfig
}

const formItemTypeNames = [
  ...(Object.keys(
    Generated.FormItem_FORMTYPEEnum
  ) as Generated.FormItem__FORM_TYPE[]),
  // Internal types
  '$enumEdit',
  // TODO: Group types? (rows, columns)
] as const

export const metaFormBase: ApiFormItem[] = [
  /**
   * NOTE: Anything with an @ prefixed in the name
   * will point to a value on the root of ApiFormItem
   * instead of the config property
   */
  {
    name: '@label',
    label: 'Label',
    type: 'string',
    placeholder: 'Custom label text',
  },
]

const metaFormNumeric: ApiFormItem[] = [
  {
    name: 'min',
    type: 'number',
    label: 'Minimum Number',
    placeholder: 'Lowest number possible',
  },
  {
    name: 'max',
    type: 'number',
    label: 'Maximum Number',
    placeholder: 'Highest number possible',
  },
]

const metaFormItemPlaceholder: ApiFormItem = {
  name: '@placeholder',
  label: 'Placeholder',
  type: 'string',
  placeholder: 'Custom placeholder text',
}

function getFormItemConfigNumeric(isInt = false) {
  return (data: ApiFormItem) => {
    const min = isSet(data.config?.min) ? data.config.min : null
    const max = isSet(data.config?.max) ? data.config.max : null

    return <IItemConfig>{
      type: 'container',
      html: '@NumberField',
      config: <NumberFieldConfig>{
        min,
        max,
      },
      validation: validNumber(min, max, data.required, isInt),
      ...(isSet(min) ? { min } : {}),
      ...(isSet(max) ? { max } : {}),
    }
  }
}

// TODO: Find a way to get this list (backend generated?)
const entities = [
  'appFunction',
  'asset',
  'assetClass',
  'batch',
  'batchType',
  'channel',
  'dashboard',
  'dashboardWidget',
  'enum',
  'group',
  'process',
  'processStep',
  'program',
  'report',
  'tag',
  'user',
  'view',
]

/** Define available form item types */
export const formItemTypes: Record<
  (typeof formItemTypeNames)[number],
  FormItemTypeConfig
> = {
  string: {
    metaForm: [
      ...metaFormBase,
      metaFormItemPlaceholder,
      {
        name: 'min',
        type: 'number',
        label: 'Minimum length',
        placeholder: 'Minimum length of the string',
      },
      {
        name: 'max',
        type: 'number',
        label: 'Maximum length',
        placeholder: 'Maximum length of the string',
      },
    ],
    toItem(data) {
      return {
        type: 'input',
        ...(isSet(data.config?.min) ? { minlength: data.config.min } : {}),
        ...(isSet(data.config?.max) ? { maxlength: data.config.max } : {}),
        validation: (value) =>
          (isSet(value) && isString(value)) ||
          (!isSet(value) && !data.required),
      }
    },
  },
  int: {
    metaForm: [...metaFormBase, metaFormItemPlaceholder, ...metaFormNumeric],
    toItem: getFormItemConfigNumeric(true),
  },
  float: {
    hidden: true, // Use 'number' instead
    toItem: getFormItemConfigNumeric(),
  },
  number: {
    metaForm: [...metaFormBase, metaFormItemPlaceholder, ...metaFormNumeric],
    toItem: getFormItemConfigNumeric(),
  },
  slider: {
    metaForm: [
      ...metaFormBase,
      ...metaFormNumeric,
      {
        name: 'step',
        type: 'number',
        label: 'Step Size',
      },
    ],
    toItem(data) {
      return {
        type: 'slider',
        min: data.config?.min ?? 0,
        max: data.config?.max ?? 1000,
        step: data.config?.step ?? 0.01,
      }
    },
  },
  datetime: {
    metaForm: [...metaFormBase],
    toItem(data) {
      return {
        type: 'datepicker',
        timePicker: true,
        dateFormat: dhtmlxDateTimeFormat,
      }
    },
  },
  boolean: {
    metaForm: [...metaFormBase],
    toItem(data) {
      return {
        type: 'checkbox',
      }
    },
  },
  select: {
    hidden: true,
    metaForm: [...metaFormBase, metaFormItemPlaceholder],
    toItem(data) {
      return {
        type: 'combo',
        data: data.options,
      }
    },
  },
  richEdit: {
    metaForm: [...metaFormBase, metaFormItemPlaceholder],
    toItem(data) {
      return {
        type: 'container',
        html: '@RichTextEditor',
      }
    },
  },
  entitySelect: {
    // hidden: true,
    metaForm: [
      ...metaFormBase,
      {
        name: 'type',
        default: 'asset',
        type: Generated.FormItem_FORMTYPEEnum.select,
        options: entities.map((key) => ({
          value: camel2title(key),
          id: key,
        })),
      },
      {
        name: 'allowEmpty',
        type: Generated.FormItem_FORMTYPEEnum.boolean,
        label: 'Allow Empty Selection',
        default: true as any,
      },
      {
        name: 'hideExtraButtons',
        type: Generated.FormItem_FORMTYPEEnum.boolean,
        label: 'Hide the extra buttons',
        default: true as any,
      },
      {
        name: 'newItemParams',
        type: Generated.FormItem_FORMTYPEEnum.string,
        label: 'New Item Params (JSON)',
        placeholder: '{}',
      },
      {
        name: 'openItemParams',
        type: Generated.FormItem_FORMTYPEEnum.string,
        label: 'Open Item Params (JSON)',
        placeholder: '{}',
      },
      {
        name: 'filter',
        type: Generated.FormItem_FORMTYPEEnum.string,
        label: 'Filter (JSON)',
        placeholder: '{}',
      },
      {
        name: 'sort',
        type: Generated.FormItem_FORMTYPEEnum.string,
        label: 'Sort',
        placeholder: '-modifiedOn',
      },
    ],
    toItem(data) {
      function tryParse(json: string): any {
        try {
          return JSON.parse(json ?? '{}')
        } catch (_) {
          return {}
        }
      }

      return {
        type: 'container',
        html: '@EntitySelect',
        config: <EntitySelectConfig>{
          type: data.config?.type ?? 'asset',
          allowEmpty: data.config?.allowEmpty ?? true,
          hideExtraButtons: data.config?.hideExtraButtons ?? true,
          newParams: tryParse(data.config?.newItemParams),
          openParams: tryParse(data.config?.openItemParams),
          filter: tryParse(data.config?.filter),
          sort: data.config?.sort ?? '-modifiedOn',
        },
      }
    },
  },
  entityMultiSelect: {
    hidden: true,
    metaForm: [
      {
        name: 'type',
        default: 'asset',
        type: 'selection',
        options: entities.map((key) => ({
          value: camel2title(key),
          id: key,
        })),
      },
    ],
    toItem(data) {
      return {
        type: 'container',
        html: '@EntityMultiSelect',
        config: <EntityMultiSelectConfig>{
          type: data.config.type,
        },
      }
    },
  },
  enum: generateFormEnum(),
  table: generateFormTable(),

  //#region Internal Form Types
  $enumEdit: {
    hidden: true,
    toItem(data) {
      return {
        type: 'container',
        html: '@EnumEditorDerived',
      }
    },
  },

  //#endregion Internal Form Types

  // To add more possible types,
  // first add the name of the form item in `formItemTypeNames
}

/**
 * Converts a list of given form items to dhtmlx form items.
 *
 * @param items The array of form items
 * @returns The array of items converted to dhtmlx form items
 */
export function getFormItems(items: ApiFormItem[]) {
  return items.map(getFormItem)
}

/**
 * Converts the given form item to dhtmlx form item.
 *
 * @param items The form items
 * @returns The item converted to dhtmlx form item
 */
export function getFormItem(item: ApiFormItem) {
  return {
    ...item,
    name: item.name,
    label: isSet(item.label) ? item.label : camel2title(item.name),
    required: item.required,
    config: item.config,
    value: item.default,
    ...conditionalObj(item.required, {
      errorMessage: commonStrings.valueRequired,
    }),
    ...mapType(item),
  } as IItemConfig
}

/**
 * Convert the ApiFormItem to an IItemConfig item
 */
function mapType(item: ApiFormItem): IItemConfig {
  if (item.type.startsWith('@')) {
    return {
      type: 'container',
      html: item.type as `@${string}`,
      css: `itemType__${item.type.replace(/^@/, '')}`,
    }
  }

  function fallback(data: ApiFormItem): IItemConfig {
    debug(`WARN: Form item type '${data.type ?? '---'}' not defined`, data)
    return {
      type: 'input',
      css: `itemType__fallback`,
    }
  }

  const itemData = (formItemTypes[item.type]?.toItem ?? fallback)(item)

  return {
    ...itemData,
    css: `itemType__${item.type} ${itemData?.css ?? ''}`,
  }
}

/**
 * Filter the value based on the formItems.
 * It will return the same input but with all inner values
 *  removed that aren't included in the formItems.
 */
export function filterFormValues(
  value: Record<any, any>,
  formItems: ApiFormItem[]
) {
  return Object.fromEntries(
    Object.entries(typeof value == 'object' ? value : {})
      .map(([key, value]) => {
        const item = formItems.find((item) => item.name == key)
        if (!isSet(item)) return undefined
        // TODO: For when the item has child values
        // if (item.type == '<group>') {
        //   return [key, filterFormValues(value, item.children)]
        // }
        return [key, value]
      })
      .filter((item) => {
        const value = item?.[1]
        if (value === undefined) return false
        else if (value === null) return true
        else if (Array.isArray(value)) return true
        else return isSet(value)
      })
  )
}
