// @ts-nocheck <- Hide the errors related to window binding
// Fix various issues in DHTMLX and extend some functionality by overriding some prototype functions

import { debug } from '@packages/util'

let applied = false

export function improveDhtmlx() {
  if (applied) {
    console.error('Can only apply changes once')
    return
  }
  applied = true

  /**
   * Replace the assignment of calendar values
   * to let the native JS parse the date
   * instead of a specific formatting string
   */
  const origProtSetValue: Function = window.dhx.Calendar.prototype.setValue
  window.dhx.Calendar.prototype.setValue = function (value) {
    if (typeof value == 'string')
      return origProtSetValue.call(this, new Date(value))
    else return origProtSetValue.call(this, value)
  }

  /**
   * This override will mute the output of the grid filter
   * ("the method doesn't work with lazyLoad")
   */
  const origProtFilter: Function = window.dhx.DataCollection.prototype.filter
  window.dhx.DataCollection.prototype.filter = function (rule, config) {
    if (
      !this.isDataLoaded() ||
      this.dataProxy?.abortAll /** Only ApiLazyDataProxy has abortAll */
    )
      return
    return origProtFilter.call(this, rule, config)
  }

  /**
   * This override will mute the output of the grid sort
   * ("the method doesn't work with lazyLoad")
   */
  const origProtSort: Function = window.dhx.DataCollection.prototype.sort
  window.dhx.DataCollection.prototype.sort = function (rule, config) {
    if (
      !this.isDataLoaded() ||
      this.dataProxy?.abortAll /** Only ApiLazyDataProxy has abortAll */
    ) {
      // Also fire an event for overriding the sorting system
      this.events.fire('customSort', [rule, config])
      return
    }
    return origProtSort.call(this, rule, config)
  }

  /** @from ./public/suite.js:1463 */
  function _isVerify(config) {
    if (config.type == 'container') {
      return true
    }

    var validationProp = [
      'required',
      'validation',
      'minlength',
      'maxlength',
      'min',
      'max',
    ]
    return validationProp.some(function (prop) {
      switch (prop) {
        case 'required':
          return !!config[prop]
        case 'validation':
          return (
            typeof config[prop] === 'function' ||
            config[prop] === 'email' ||
            config[prop] === 'integer' ||
            config[prop] === 'numeric' ||
            config[prop] === 'alphanumeric' ||
            config[prop] === 'IPv4'
          )
        case 'minlength':
        case 'maxlength':
          return (
            typeof config[prop] === 'number' || typeof config[prop] === 'string'
          )
        case 'min':
        case 'max':
          return (
            (typeof config[prop] === 'number' ||
              typeof config[prop] === 'string') &&
            config.inputType === 'number'
          )
      }
    })
  }

  /** @from ./public/suite.js:14510 */
  window.dhx.Form.prototype.validate = function (silent: boolean) {
    if (silent === void 0) {
      silent = false
    }
    this._isValid = true
    for (var key in this._attachments) {
      const _formItem = this._attachments[key]
      if (
        _isVerify(_formItem.config) &&
        typeof _formItem.validate === 'function'
      ) {
        if (!this._attachments[key].validate(silent)) {
          debug(`Form value ${key} is not valid`)
          this._isValid = false
          !silent && this.events.fire('validationfail', [key, _formItem])
        }
      }
    }
    return this._isValid
  }

  // function changeTag(node, tag) {
  //   const clone = document.createElement(tag)
  //   for (const attr of node.attributes) {
  //     clone.setAttributeNS(null, attr.name, attr.value)
  //   }
  //   while (node.firstChild) {
  //     clone.appendChild(node.firstChild)
  //   }
  //   node.replaceWith(clone)
  //   return clone
  // }

  // const origProtPopup: Function = window.dhx.Popup.prototype.show
  // window.dhx.Popup.prototype.show = function (
  //   node: HTMLElement,
  //   config,
  //   attached
  // ) {
  //   // node = changeTag(node, 'dialog')
  //   this._popup = changeTag(this._popup, 'dialog')
  //   console.log(this)
  //   this._popup.classList.add('dhx_popup--dialog_override')
  //   a(this, this._popup as HTMLDialogElement)
  //   // window.dhx.awaitRedraw().then(dhx.awaitRedraw).then(dhx.awaitRedraw).then(() => this._popup.showModal())
  //   return origProtPopup.call(this, node, config, attached)
  // }

  // function a(p: Popup, popup: HTMLDialogElement) {
  //   popup.addEventListener('close', (event) => {
  //     p.hide()
  //     event.stopPropagation()
  //   })
  //   p.events.on('afterShow', async () => {
  //     console.log('!!')
  //     await dhx.awaitRedraw()
  //     popup.showModal()
  //   })
  //   p.events.on('afterHide', async () => {
  //     console.log('!!')
  //     popup.close()
  //     await dhx.awaitRedraw()
  //   })
  // }

  //#region POPUP FIXES
  const origProtPopupShow: Function = window.dhx.Popup.prototype.show
  window.dhx.Popup.prototype.show = function (node, config, attached) {
    popupMap.set(this._uid, () => {
      if (this._isActive) {
        this._setPopupSize(node, config)
      }
    })

    return origProtPopupShow.call(this, node, config, attached)
  }

  const origProtPopupHide: Function = window.dhx.Popup.prototype.hide
  window.dhx.Popup.prototype.hide = function () {
    popupMap.delete(this._uid)
    return origProtPopupHide.call(this)
  }

  const origProtPopupDestructor: Function =
    window.dhx.Popup.prototype.destructor
  window.dhx.Popup.prototype.destructor = function () {
    popupMap.delete(this._uid)
    return origProtPopupDestructor.call(this)
  }
  //#endregion POPUP FIXES

  /**
   * Make non-range sliders return a single value
   */
  const origProtSliderGetValue: Function = window.dhx.Slider.prototype.getValue
  window.dhx.Slider.prototype.getValue = function () {
    const value = origProtSliderGetValue.call(this)
    if (!this.config.range && Array.isArray(value)) return value.at(0)
    return value
  }

  /**
   * SimpleVault -> Upload file
   * Add File select `accept` (filetype filtering) support
   */
  const origProtUploaderSelectFile: Function =
    window.dhx.Uploader.prototype.selectFile
  window.dhx.Uploader.prototype.selectFile = function () {
    // Added line
    this._fileInput.accept = this.config.accept
    return origProtUploaderSelectFile.call(this)
  }
}

const popupMap = new Map<string, () => void>()

/** Reposition ALL active popups */
export function repositionPopups() {
  popupMap.forEach((item) => item())
}
