/**
 * The main entrypoint of the frontend
 */

// Polyfills
import '@formatjs/intl-datetimeformat/add-all-tz' // Add ALL tz data
import '@formatjs/intl-datetimeformat/locale-data/en' // locale-data for en
import '@formatjs/intl-datetimeformat/polyfill'
import '@lib/CustomPolyfills'
import { improveDhtmlx } from '@lib/dhtmlxImprovements'

// Other things
import { features } from '@lib/Features'
import App from './App.svelte'
import './global.scss'
import { StaticRender } from '@packages/util'

// Ensure the features are loaded first
features

// Improve some DHTMLX Features
improveDhtmlx()

/**
 * (Special) When in DEV mode, allow the frontend to be pause from anywhere.
 * This can be helpful for debugging specific CSS issues that requires interactivity.
 */
if (import.meta.env.DEV) {
  window.addEventListener('keydown', (event) => {
    if (event.key == 'Pause') {
      if (event.shiftKey) {
        console.log('WAITING 2 SECONDS')
        setTimeout(() => {
          debugger
        }, 2000)
      } else {
        debugger
      }
    }
  })
}

// Install the static renderer custom component
StaticRender.install()

/**
 * The actual app
 */
var app = new App({
  target: document.body,
})

export default app
