import type { Grid } from '@dhtmlx/ts-grid'
import { capitalize, isSet, isString } from '@packages/util'
import { validNumber } from './Validation'

const validationErrorClass = 'validationError'
const emptyRowCellClass = 'emptyRowCell'
const cellTypeClassPrefix = 'cellType'

/** Check if the grid has any validation errors */
export function gridHasValidationErrors(grid: Grid) {
  return (
    (grid?.getRootNode().querySelectorAll(`.${validationErrorClass}`).length ??
      0) > 0
  )
}

/**
 * Helper function for creating a validation marker
 */
export function markUsingValidation(
  type: string,
  valid: (value: any, column: any, row: any) => boolean,
  allowEmpty?: boolean,
  extraClasses?: string
) {
  allowEmpty ??= true
  return (cell: any, column: any, row: any) =>
    // Add prefixClassType
    `${cellTypeClassPrefix}${capitalize(type)} ` +
    // Add emptyRowCellClass if it is an empty row
    (row.$emptyRow ? `${emptyRowCellClass} ` : '') +
    // Ignore validation if empty row (uninitialized row)
    (row.$emptyRow ||
    // Ignore validation if empty
    (allowEmpty && !isSet(cell)) ||
    // Validate value
    valid(cell, column, row)
      ? // Valid (no class applied)
        ''
      : // Invalid (validationErrorClass applied)
        `${validationErrorClass} `) +
    // Apply any other classes
    (extraClasses ?? '')
}

/**
 * Used for making sure the given value is a valid boolean.
 *
 * Marks the cell's css as 'validationError' if invalid.
 */
export function gridMarkBoolean(allowEmpty = true) {
  return markUsingValidation('boolean', () => true, allowEmpty)
}

/**
 * Used for making sure the given value is a valid Float.
 *
 * Marks the cell's css as 'validationError' if invalid.
 */
export function gridMarkFloat(
  allowEmpty?: boolean,
  min?: number,
  max?: number
) {
  return markUsingValidation(
    'float',
    validNumber(min, max, !allowEmpty, false),
    allowEmpty
  )
}

/**
 * Used for making sure the given value is a valid Integer.
 *
 * Marks the cell's css as 'validationError' if invalid.
 */
export function gridMarkInt(allowEmpty?: boolean, min?: number, max?: number) {
  return markUsingValidation(
    'int',
    validNumber(min, max, !allowEmpty, true),
    allowEmpty
  )
}

/**
 * Used for making sure the given value is a valid String.
 *
 * Marks the cell's css as 'validationError' if invalid.
 */
export function gridMarkString(
  allowEmpty?: boolean,
  min?: number,
  max?: number
) {
  allowEmpty ??= true
  min ??= 0
  max ??= Number.MAX_VALUE

  return markUsingValidation(
    'string',
    (cell) => isString(cell) && cell.length >= min && cell.length <= max,
    allowEmpty
  )
}

/**
 * Used for making sure the given value is a valid String and is unique across the column.
 *
 * Marks the cell's css as 'validationError' if invalid.
 */
export function gridMarkUniqueString(
  allowEmpty?: boolean,
  min?: number,
  max?: number
) {
  allowEmpty ??= true
  min ??= 0
  max ??= Number.MAX_VALUE

  return markUsingValidation(
    'string',
    (cell, column) =>
      isString(cell) &&
      cell.length >= min &&
      cell.length <= max &&
      column.filter((item: string) => item == cell).length <= 1,
    allowEmpty
  )
}
