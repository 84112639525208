import { isSet } from '@packages/util'

/** Get the CSS variable value on the element */
export function cssVar(name: string, element?: HTMLElement) {
  return getComputedStyle(
    element ?? document.documentElement
  )?.getPropertyValue(`--${name}`)
}

/**
 * Normalizes the input to a valid CSS unit.
 * When the given parameter is a:
 * - `string`: it will return the input.
 * - `number`: it will return the input as a pixel size.
 * - anything else: it will return null
 */
export function cssUnit(value: Nullable<number | string>) {
  return isSet(value)
    ? typeof value == 'string'
      ? value
      : typeof value == 'number'
        ? `${value}px`
        : null
    : null
}

/**
 * Convert absolute CSS numerical values to pixels.
 *
 * @link https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units#numbers_lengths_and_percentages
 * @link From https://stackoverflow.com/a/66569574
 *
 * @param cssValue Any string value
 * @param target Used for relative units.
 * @return The calculated pixel unit
 */
export function convertCssUnit(
  cssValue: Nullable<string | number>,
  target?: HTMLElement,
  percentReference?: number
): Nullable<number> {
  if (!isSet(cssValue)) return null
  if (typeof cssValue != 'string') return null
  if (typeof cssValue == 'number') return cssValue

  target = target || document.body

  const supportedUnits = {
    // Absolute sizes
    px: (value) => value,
    cm: (value) => value * 38,
    mm: (value) => value * 3.8,
    q: (value) => value * 0.95,
    in: (value) => value * 96,
    pc: (value) => value * 16,
    pt: (value) => value * 1.333333,

    // Relative sizes
    rem: (value) =>
      value * parseFloat(getComputedStyle(document.documentElement).fontSize),
    em: (value) => value * parseFloat(getComputedStyle(target).fontSize),
    vw: (value) => (value / 100) * window.innerWidth,
    vh: (value) => (value / 100) * window.innerHeight,

    // Times
    ms: (value) => value,
    s: (value) => value * 1000,

    // Angles
    deg: (value) => value,
    rad: (value) => value * (180 / Math.PI),
    grad: (value) => value * (180 / 200),
    turn: (value) => value * 360,

    // Percent
    '%': (value) => percentReference * (value / 100),
  } satisfies Record<string, (value: number) => number>

  // Match positive and negative numbers including decimals with following unit
  const pattern = new RegExp(
    `^([\-\+]?(?:\\d+(?:\\.\\d+)?))(${Object.keys(supportedUnits).join('|')})$`,
    'i'
  )

  // If is a match, return example: [ "-2.75rem", "-2.75", "rem" ]
  const matches = String.prototype.toString
    .apply(cssValue)
    .trim()
    .match(pattern)

  if (matches) {
    const value = Number(matches[1])
    const unit = matches[2].toLocaleLowerCase()

    // Sanity check, make sure unit conversion function exists
    if (unit in supportedUnits) {
      return supportedUnits[unit](value)
    }
  }

  return parseFloat(cssValue)
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels
 * @param text The text to be rendered.
 * @param fromElement use the font of a given element as the reference.
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text: string, fromElement?: HTMLElement): number {
  // re-use canvas object for better performance
  const canvas =
    (getTextWidth as any).canvas ||
    ((getTextWidth as any).canvas = document.createElement('canvas'))

  const context = canvas.getContext('2d')
  const computedStyle = window.getComputedStyle(
    fromElement ?? document.body,
    null
  )
  const fontWeight = computedStyle.getPropertyValue('font-weight') || 'normal'
  const fontSize = computedStyle.getPropertyValue('font-size') || '16px'
  const fontFamily =
    computedStyle.getPropertyValue('font-family') || 'sans-serif'

  context.font = `${fontWeight} ${fontSize} ${fontFamily}`

  return context.measureText(text).width
}
