// export const dhtmlxDateTimeFormat = '%Y-%m-%d %H:%i:%s.%u'
export const dhtmlxDateTimeFormat = '%Y-%m-%d %H:%i:%s'

/**
 * Check if the string is a DHTMLX Datetime string
 */
export function isDHTMLXDateTime(value: string) {
  if (typeof value != 'string') return false
  return /^\d+-\d+-\d+ \d+:\d+:\d+(.\d+)?$/.test(value)
}
