<!-- FormSingle Component -->
<script lang="ts">
  import type { IFormConfig, IItemConfig } from '@dhtmlx/ts-form'
  import type { FormEntity } from '@lib/FormUtil'
  import type { MappedEvents } from '@packages/util'
  import { awaitRedraw, getId } from '@packages/util'
  import { createEventDispatcher } from 'svelte'
  import Form from './Form.svelte'

  type Value = any

  interface $$Events {
    change: CustomEvent<Value>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()
  const id = getId('formItem')

  export let item: IItemConfig
  export let value: Value = null
  export let config: IFormConfig = {}
  export let form: Nullable<FormEntity> = undefined
  export let disabled = false

  let _lock = false

  $: update(value)
  $: if (disabled) {
    form?.disable()
  } else {
    form?.enable()
  }

  function update(value: Value) {
    if (_lock || !form) return
    form.setValue({ value })
  }

  function updateExternal(_value: Value) {
    _lock = true
    value = _value
    dispatch('change', _value)
    awaitRedraw().then(() => (_lock = false))
  }
</script>

<Form
  bind:form
  formConfig={{
    config: {
      ...config,
      rows: [{ ...item, name: 'value', id, value, disabled }],
      css: 'formSingle ' + (config.css ?? ''),
      padding: 0,
    },
  }}
  on:change={(event) => updateExternal(event.detail.value)}
/>

<style lang="scss">
  :global(
      .formSingle,
      .formSingle .dhx_layout-cell.dhx_form-element,
      .formSingle .dhx_form-group
    ) {
    margin: 0;
    padding: 0;
  }
</style>
