import type { File } from '@models/api/ApiModels'
import {
  extensions,
  folder as directory,
  mimeTypes,
  wbMap,
} from '@public/img/fileIcons/map.json'
import { isSet, parseMimeType } from '@packages/util'

/** Only used when all matches fails (or when the map isn't properly loaded) */
const fallbackIcon = 'file-question-outline'

/** The path to the icon (`%NAME%` will get replaced) */
const iconPathTemplate = '/img/fileIcons/%NAME%.svg'

/** For some reason, wunderbaum loads certain icon maps as classes, define those here */
const overrideMap = {
  checkChecked: 'dxi dxi-checkbox-marked',
  checkUnchecked: 'dxi dxi-checkbox-blank-outline',
  checkUnknown: 'dxi dxi-help-circle-outline', // Undefined
  radioChecked: 'dxi dxi-help-circle-outline', // Undefined
  radioUnchecked: 'dxi dxi-help-circle-outline', // Undefined
  radioUnknown: 'dxi dxi-help-circle-outline', // Undefined
}

export function getFileIcon(
  file: File | Pick<File, 'directory' | 'mimeType' | 'extension'>
) {
  // Is it a directory? If so, return a directory icon
  if (file.directory) {
    return getDirectoryIcon(file)
  }

  // Get the mimeType information
  const mimeTypeInfo = parseMimeType(file.mimeType)

  const mimeTypeGroup = mimeTypes?.[mimeTypeInfo.type ?? '---']
  const mimeTypeFullMatch = mimeTypeGroup?.[mimeTypeInfo.subType ?? '---']
  const mimeTypeGroupMatch = mimeTypeGroup?.['*']
  const mimeTypeAnyMatch = mimeTypes?.['*']
  const extensionsMatch = extensions?.[file.extension]

  // Get file icon in the given order
  const icon =
    // 1. Check mimeType <abc>/<abc>
    mimeTypeFullMatch ||
    // 2. Check extension
    extensionsMatch ||
    // 3. Check mimeType <abc>/*
    mimeTypeGroupMatch ||
    // 4. Check mimeType *
    mimeTypeAnyMatch ||
    // 5. (Should never happen) Return a fallback icon
    fallbackIcon

  return getSvgUrlFromName(icon)
}

export function getDirectoryIcon(_directory: File | Pick<File, 'directory'>) {
  // Is it a directory? Else, throw an error
  if (!_directory.directory) {
    throw new Error('Given node is not a directory')
  }

  // TODO: Return the default for now
  return getSvgUrlFromName(directory.closed)
}

/** Get the Wunderbaum Icon map */
export function getWbIconMap() {
  return {
    ...Object.fromEntries(
      Object.entries(wbMap).map(([key, value]) => [
        key,
        getSvgUrlFromName(value),
      ])
    ),
    ...overrideMap,
  }
}

function getSvgUrlFromName(name: string) {
  return iconPathTemplate.replace('%NAME%', name)
}
