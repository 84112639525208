<!-- Form element that supports Editing an enum -->
<script lang="ts" context="module">
  import Grid from '@components/Grid.svelte'
  import type { Grid as GridModel, ICol, IGridConfig } from '@dhtmlx/ts-grid'
  import {
    gridHasValidationErrors,
    gridMarkBoolean,
    gridMarkFloat,
    gridMarkInt,
    gridMarkString,
    gridMarkUniqueString,
  } from '@lib/GridComponentUtils'
  import { getSvg, type IconPath } from '@lib/Icons'
  import {
    mdiCog,
    mdiDelete,
    mdiLockOpenVariantOutline,
    mdiLockOutline,
  } from '@mdi/js'
  import { numberFormatter } from '@packages/locale/lib/numberFormatterStore'
  import type { GridCellClickEvent } from '@packages/util'
  import {
    awaitRedraw,
    camel2title,
    chain,
    filterObject,
    getId,
    getTextWidth,
    isSet,
    padArray,
    parseFloatFromAny,
    single,
  } from '@packages/util'
  import { get } from 'svelte/store'
  import type { ComboboxItem } from './ComboboxAsync.svelte'

  // Extend ICol
  declare module '@dhtmlx/ts-grid' {
    interface ICol {
      x_onClick?: (rowData?: Record<any, any>) => void
      x_default?: () => any
    }
  }

  export type EditableGridColumnValue = TODO
  export type EditableGridColumnValueInternal = TODO

  export enum EditableGridColumnDataType {
    numeric = 'numeric',
    integer = 'integer',
    string = 'string',
    uniqueString = 'uniqueString',
    color = 'color',
    boolean = 'boolean',
    button = 'button',
    select = 'select',
    dummy = 'dummy',
  }

  export interface EditableGridColumn {
    name: string
    label?: string
    dataType?: EditableGridColumnDataType // Defaults to 'string'
    config?: EditableGridColumnDataTypeConfig
  }

  export interface EditableGridColumnDataTypeConfig {
    allowEmpty?: boolean
    /** Types with ranges only (string, number, etc.) */
    min?: number
    /** Types with ranges only (string, number, etc.) */
    max?: number
    /** Any input box */
    onChange?: (rowData: Record<any, any>) => void
    /** Button only */
    onClick?: (rowData: Record<any, any>) => void
    /** Button only */
    buttonIcon?: IconPath
    /** Select only */
    selectOptions?: ComboboxItem[]
  }

  /** Template for the number display */
  function templateNumber(cellValue: any, row: any, col: ICol) {
    const formatter = get(numberFormatter)
    if (row?.$emptyRow) return ''
    else if (isSet(cellValue))
      return formatter.format(parseFloatFromAny(row[col.id]))
    else return formatter.format(0)
  }

  const editorMap: Record<
    EditableGridColumnDataType,
    (config: EditableGridColumnDataTypeConfig) => Partial<ICol>
  > = {
    integer: (config) => ({
      x_default: () => 0,
      editorType: 'input',
      type: 'number',
      align: 'right',
      mark: gridMarkInt(config?.allowEmpty, config?.min, config?.max),
    }),
    numeric: (config) => ({
      x_default: () => 0,
      editorType: 'input',
      type: 'number',
      align: 'right',
      mark: gridMarkFloat(config?.allowEmpty, config?.min, config?.max),
      template: templateNumber,
    }),
    string: (config) => ({
      x_default: () => '',
      editorType: 'input',
      mark: gridMarkString(config?.allowEmpty, config?.min, config?.max),
    }),
    uniqueString: (config) => ({
      editorType: 'input',
      mark: gridMarkUniqueString(config?.allowEmpty, config?.min, config?.max),
    }),
    color: (config) => ({
      x_default: () => '#000000',
      // TODO: Color picker
      editorType: 'input',
      mark: gridMarkString(config?.allowEmpty, 6, 6),
    }),
    boolean: (config) => ({
      x_default: () => false,
      editorType: 'input',
      type: 'boolean',
      mark: gridMarkBoolean(config?.allowEmpty),
    }),
    select: (config) => ({
      editorType: 'combobox',
      options: config?.selectOptions ?? [],
    }),
    button: (config) => ({
      x_onClick: config?.onClick ?? (() => {}),
      template: (_, row) =>
        !row.$emptyRow &&
        getSvg(config?.buttonIcon ?? mdiCog, null, null, 'cursor:pointer;'),
      htmlEnable: true,
      maxWidth: 40,
      minWidth: 40,
    }),
    dummy: () => ({
      hidden: true,
    }),
  }

  const deleteButtonKey = '_delete'
  const rowHeaderKey = '_rowHeader'
  const singleColumnKey = 'value'

  const rowHeaderClass = 'rowHeader'
  const rowHeaderExtraWidth = 16 * 3 // add x pixels to calculated row header width
  const rowHeaderMaxWidth = 200

  const reservedKeys = {
    id: getId('__column_id'),
    _delete: getId('__column_delete'),
    _rowHeader: getId('__column_rowHeader'),
    $emptyRow: getId('__column_emptyRow'),
    $height: getId('__column_height'),
  } satisfies Record<string, string>
</script>

<script lang="ts">
  export let value: EditableGridColumnValue[] = []
  export let readOnly = false
  export let disableReorder = false
  export let disableAddItems = false
  export let disableDelete = false
  export let disableColumnHeaders = false
  export let columns: EditableGridColumn[] = []
  export let singleColumn = false
  export let rowHeaders: Nullable<string[]> = null
  export let grid: Nullable<GridModel> = null

  export let adaptInput: (
    rowData: EditableGridColumnValue
  ) => EditableGridColumnValueInternal = (data) => data
  export let adaptOutput: (
    rowData: EditableGridColumnValueInternal
  ) => EditableGridColumnValue = (data) => data

  export function validate() {
    // Make sure the value is properly initialized
    if ((value?.length ?? 0) <= 0) {
      value = processOutputArray(value)
    }

    // Check if the value is an array and doesn't have any errors inside
    return Array.isArray(value) && !gridHasValidationErrors(grid)
  }

  let _valueLock = false
  let _canDelete = false

  $: _disableReorder = disableReorder || rowHeaders?.length > 0
  $: _disableAddItems = disableAddItems || rowHeaders?.length > 0
  $: _disableDelete = disableDelete || rowHeaders?.length > 0

  $: update(value)
  $: updateColumns(columns, rowHeaders)
  $: updateEditableState(
    readOnly,
    _disableReorder,
    _disableAddItems,
    _disableDelete
  )

  // Add or remove a class when _canDelete is toggled
  $: if (_canDelete) {
    grid?.getRootNode()?.classList.add('editableGridConditionalState--true')
  } else {
    grid?.getRootNode()?.classList.remove('editableGridConditionalState--true')
  }

  /** Hide delete button based on readOnly state */
  function updateEditableState(
    readOnly: boolean,
    disableReorder: boolean,
    disableAddItems: boolean,
    disableDelete: boolean
  ) {
    chain(grid).map((grid) => {
      if (readOnly || disableDelete) {
        grid.hideColumn(deleteButtonKey)
        grid.getRootNode().classList.add('readOnlyEmptyItem')
      } else {
        grid.showColumn(deleteButtonKey)
        grid.getRootNode().classList.remove('readOnlyEmptyItem')
      }
      grid.config = {
        ...grid.config,
        ...getGridConfigEdit(
          !readOnly,
          disableReorder,
          disableAddItems,
          disableDelete
        ),
      }
    })
  }

  /** Update the internal value */
  function update(newValue: typeof value) {
    if (_valueLock) return
    // Make sure the value is an array
    if (!Array.isArray(newValue)) newValue = []
    // Set data
    grid?.data.parse(
      // Pad the array if rowheaders are set
      padArray(newValue, rowHeaders?.length ?? 0, {}).map((item, index) =>
        fromExternalToInternal(item, rowHeaders?.[index])
      ) ?? []
    )
  }

  function updateColumns(columns: EditableGridColumn[], rowHeaders?: string[]) {
    grid?.setColumns(getGridConfigColumns(columns, rowHeaders))
  }

  /** Update the external value on internal value change */
  export function handleValueChange() {
    const data = (
      grid.data?.getRawData(0, 9999) as EditableGridColumnValue[]
    )?.filter((item) => isSet(item) && !item.$emptyRow)

    if (_valueLock) return
    _valueLock = true

    // Wait a bit for the error classes to apply
    awaitRedraw()
      .then(() => {
        if (!gridHasValidationErrors(grid)) {
          value = processOutputArray(data.map(fromInternaltoExternal))
        }
      })
      .then(awaitRedraw)
      .then(() => (_valueLock = false))
  }

  /**
   * Map the external value to an internal one,
   * remapping reserved keys to a temporary key
   */
  function fromExternalToInternal(
    item: EditableGridColumnValue,
    rowHeader?: string
  ): EditableGridColumnValue {
    // In singleColumn mode, convert the item to {value: item}
    if (singleColumn) {
      return { [singleColumnKey]: item }
    }

    const {
      // Get user values of reserved keys
      id: __id,
      $emptyRow: __$emptyRow,
      $height: __$height,
      [deleteButtonKey]: __delete,
      [rowHeaderKey]: __rowHeader,
      ...rest
    } = adaptInput(item)

    return {
      // Remap user values of reserved keys to an internal reference
      [reservedKeys.id]: __id,
      [reservedKeys.$emptyRow]: __$emptyRow,
      [reservedKeys.$height]: __$height,
      [reservedKeys._delete]: __delete,
      [reservedKeys._rowHeader]: __rowHeader,
      [rowHeaderKey]: rowHeader, // Add row header
      ...rest,
    }
  }

  /**
   * Map the internal value to an external one,
   * remapping reserved keys back to the original key
   */
  function fromInternaltoExternal(
    item: EditableGridColumnValue
  ): EditableGridColumnValue {
    // In singleColumn mode, convert the {value: item} to item
    if (singleColumn) {
      return item[singleColumnKey]
    }

    const {
      // Get remapped keys (user supplied values of reserved keys)
      [reservedKeys.id]: id,
      [reservedKeys.$emptyRow]: $emptyRow,
      [reservedKeys.$height]: $height,
      [reservedKeys._delete]: _delete,
      [reservedKeys._rowHeader]: _rowHeader,
      // Ignore internal keys
      id: __unset_0,
      $emptyRow: __unset_1,
      $height: __unset_2,
      [deleteButtonKey]: __unset_3,
      [rowHeaderKey]: __unset_4,
      // The rest
      ...rest
    } = item

    // Filter out any values that are empty or not in the current column set
    return adaptOutput(
      filterObject<Record<string, any>>(
        {
          ...rest,
          id,
          $emptyRow,
          $height,
          [deleteButtonKey]: _delete,
          [rowHeaderKey]: _rowHeader,
        },
        (key, value) =>
          !!columns.find((column) => column?.name == key) && !!isSet(value)
      )
    )
  }

  /**
   * Make sure all default values are set
   * (when row headers are enabled,
   *   pad and limit to the amount of row headers being set)
   */
  function processOutputArray(items: any[]) {
    items ??= []

    if (isSet(rowHeaders)) {
      // pad and limit the items with the amount of row headers set
      items = padArray(items, rowHeaders.length, {}).slice(0, rowHeaders.length)
    }

    function _getDefaultCellValue(
      column: EditableGridColumn,
      currentRowData: any
    ) {
      return (
        currentRowData?.[column.name] ??
        editorMap[column?.dataType ?? 'string']?.(column.config)?.x_default?.()
      )
    }

    return items.map((row) => {
      if (singleColumn) {
        // In single column mode...
        // Get the default value of the first column
        return _getDefaultCellValue(single(columns), row)
      } else {
        // In multi-column mode...
        // Map the default value for each column to the output object
        return Object.fromEntries(
          columns.map((item) => [item.name, _getDefaultCellValue(item, row)])
        )
      }
    })
  }

  /** Get the proper column ID (remapped keys if it is a reserved key) */
  function getProperColumnId(id: string) {
    return reservedKeys[id ?? '--'] ? reservedKeys[id] : id
  }

  /** Get the grid editor data */
  function getGridEditor(
    dataType?: Nullable<EditableGridColumnDataType>,
    dataTypeConfig?: Nullable<EditableGridColumnDataTypeConfig>
  ): Partial<ICol> {
    dataType ??= EditableGridColumnDataType.string
    dataTypeConfig ??= {}

    if (editorMap[dataType]) return editorMap[dataType](dataTypeConfig)
    else throw new Error('Unsupported DataType: ' + dataType)
  }

  /** Handle grid cell clicks (for buttons) */
  function handleCellClick(event: CustomEvent<GridCellClickEvent>) {
    const { row, col } = event.detail

    if (row.$emptyRow) return

    switch (col.id) {
      case deleteButtonKey: // On delete clicked
        if (!_canDelete) break // Only when _canDelete is true
        grid.data.remove(row.id)
        handleValueChange()
        break
      default:
        if (typeof col.x_onClick == 'function') {
          col.x_onClick(row)
        }
        break
    }
  }

  /** Handle grid header cell clicks (for buttons) */
  function handleHeaderCellClick(event: CustomEvent<GridCellClickEvent>) {
    const { col } = event.detail
    switch (col.id) {
      case deleteButtonKey: // On delete clicked
        // Toggle _canDelete
        _canDelete = !_canDelete
        break
    }
  }

  function getGridConfig(
    editable: boolean,
    disableReorder: boolean,
    disableAddItems: boolean,
    disableDelete: boolean,
    disableColumnHeaders: boolean
  ): IGridConfig {
    return {
      columns: getGridConfigColumns(columns, rowHeaders),
      ...getGridConfigEdit(
        editable,
        disableReorder,
        disableAddItems,
        disableDelete
      ),
      sortable: false,
      autoHeight: false,
      ...(disableColumnHeaders
        ? {
            // Use a really small value (DHTMLX regards 0 as default row height)
            headerRowHeight: 0.0001,
            css: 'disableColumnHeaders',
          }
        : {}),
    }
  }

  function getGridConfigEdit(
    editable: boolean,
    disableReorder: boolean,
    disableAddItems: boolean,
    disableDelete: boolean
  ): Partial<IGridConfig> {
    return {
      editable: editable,
      autoEmptyRow: editable && !disableAddItems,
      selection: editable ? 'cell' : null,
      dragItem: editable && !disableReorder ? 'row' : null,
    }
  }

  function getGridConfigColumns(
    columns: EditableGridColumn[],
    rowHeaders?: string[]
  ): ICol[] {
    if (!Array.isArray(columns)) columns = []

    return [
      // Delete button (if allowed)
      ...(!_disableDelete
        ? [
            {
              id: deleteButtonKey,
              header: [
                {
                  // Draw a lock icon
                  text: conditionalButtonHtml(
                    mdiLockOpenVariantOutline,
                    mdiLockOutline
                  ),
                },
              ],
              width: 40,
              template(cellValue: any, row: any) {
                // Draw a trash can icon
                return row.$emptyRow ? '' : conditionalButtonHtml(mdiDelete)
              },
              editable: false,
              htmlEnable: true,
            },
          ]
        : []),

      // Row headers (if enabled)
      ...(Array.isArray(rowHeaders) && rowHeaders.length > 0
        ? [
            <ICol>{
              id: rowHeaderKey,
              editable: false,
              header: [],
              align: 'right',
              width: getColumnWidthByContent(rowHeaders),
              mark: () => rowHeaderClass,
              template: (value) =>
                [
                  '<span class="ellipsis-right">',
                  /**/ '<div class="ellipsis-content">',
                  /*    */ String(value).replaceAll(/[<>&;]/g, ''),
                  /**/ '</div>',
                  '</span>',
                ].join(''),
              htmlEnable: true,
            },
          ]
        : []),

      // Get all custom columns
      ...columns
        // Limit by one in single column mode
        .slice(0, singleColumn ? 1 : undefined)
        // Then map to proper columns
        .map<ICol>((column) => ({
          id: singleColumn ? singleColumnKey : getProperColumnId(column.name),
          header: [{ text: String(column.label ?? camel2title(column.name)) }],
          ...(singleColumn
            ? {}
            : {
                minWidth: 100,
                maxWidth: 300,
              }),
          autoWidth: true,
          x_onChange: column.config?.onChange ?? (() => {}),
          ...getGridEditor(column.dataType, column.config),
        })),
    ]
  }

  /** Get a conditionally styled button with two icon states */
  function conditionalButtonHtml(onTrue: IconPath, onFalse: IconPath = null) {
    return (
      `<div class="editableGridConditional">` +
      getSvg(onTrue, null, null, 'cursor:pointer;') +
      `</div>` +
      `<div class="editableGridConditional editableGridConditional--invert">` +
      getSvg(onFalse, null, null, !onFalse ? '' : 'cursor:pointer;') +
      `</div>`
    )
  }

  /**
   * Calculate width based on the given content
   * (with a limit of rowHeaderMaxWidth)
   */
  function getColumnWidthByContent(content: string[]) {
    return Math.min(
      content
        .map((header) => getTextWidth(header))
        .reduce((curr, header) => Math.max(curr, header), -1) +
        rowHeaderExtraWidth,
      rowHeaderMaxWidth
    )
  }

  async function initGrid() {
    update(value)
    updateColumns(columns, rowHeaders)

    // Handle grid content changes
    grid.events.on('beforeEditStart', () => !readOnly)

    // HACK: Automatically set the number inputs to an actual number formatted field
    grid.events.on('afterEditStart', (_, col) => {
      if (col.type == 'number') {
        awaitRedraw().then(() =>
          chain(grid.getRootNode())
            .map<HTMLInputElement>((node) =>
              node.querySelector('input[data-dhx-id="cell_editor"]')
            )
            .map((node) => (node.type = 'number'))
        )
      }
    })

    grid.events.on('afterEditEnd', (_, row, col) => {
      // @ts-ignore Custom action
      if (typeof col.x_onChange == 'function') {
        // @ts-ignore Custom action
        col.x_onChange(row)
      }
      handleValueChange()
    })

    grid.events.on('afterRowDrop', () => {
      handleValueChange()
    })
  }
</script>

<Grid
  bind:grid
  gridConfig={getGridConfig(
    !readOnly,
    _disableReorder,
    _disableAddItems,
    _disableDelete,
    disableColumnHeaders
  )}
  on:cellClick={handleCellClick}
  on:headerCellClick={handleHeaderCellClick}
  on:gridRender={initGrid}
/>

<style lang="scss">
  @use '../theme/variables' as vars;

  :global(.validationError) {
    background-color: rgb(255, 222, 222);
    color: vars.$error;
  }
  :global(.emptyRowCell) {
    background-color: unset;
    color: unset;
  }

  :global(.emptyRowCell.cellTypeBoolean) {
    pointer-events: none;
  }
  :global(.emptyRowCell.cellTypeBoolean .dhx_checkbox__visual-input) {
    opacity: 0;
  }

  // Set empty row height to 0 if readOnly
  :global(.readOnlyEmptyItem .dhx_grid-row:has(.emptyRowCell)) {
    height: 0 !important;
  }

  // Make the readonly grid un-editable
  :global(.readOnlyEmptyItem) {
    opacity: 0.85;
    cursor: pointer;
    user-select: none;
    pointer-events: none;
  }

  // Style applied when editableGridConditionalState is false
  :global(.editableGridConditional) {
    display: none;
  }
  :global(.editableGridConditional--invert) {
    display: contents;
  }

  // Style applied when editableGridConditionalState is true
  :global(.editableGridConditionalState--true .editableGridConditional) {
    display: contents;
  }

  :global(
      .editableGridConditionalState--true .editableGridConditional--invert
    ) {
    display: none;
  }

  // Style applied to rowHeaders
  :global(.rowHeader) {
    background: vars.$gridHeader;
  }

  :global(.ellipsis-right) {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
  }

  :global(.ellipsis-right .ellipsis-content) {
    direction: ltr;
    display: contents;
  }

  // Style applied when disableColumnHeaders
  :global(.disableColumnHeaders .dhx_grid-header-cell) {
    display: none;
  }
</style>
