<script lang="ts">
  import { getSvg, type IconPath } from '@lib/Icons'

  export let name: Nullable<IconPath> = null
  export let width: number | string = null
  export let height: number | string = null
  export let style: string = null
</script>

{@html getSvg(name, parseInt(`${width}`), parseInt(`${height}`), style)}
