/**
 * Capitalizes the first letter in a string.
 * Simulates the intrinsic string manipulation
 */
export function capitalize<T extends string>(value: T): Capitalize<T> {
  if (value === null || value === undefined) return '' as Capitalize<T>
  return (value.charAt(0).toUpperCase() + value.slice(1)) as Capitalize<T>
}

/**
 * Uncapitalizes the first letter in a string.
 * Simulates the intrinsic string manipulation
 */
export function uncapitalize<T extends string>(value: T): Uncapitalize<T> {
  if (value === null || value === undefined) return '' as Uncapitalize<T>
  return (value.charAt(0).toLowerCase() + value.slice(1)) as Uncapitalize<T>
}

/**
 * Convert camelCase (and PascalCase) to Title Case
 * Returns input if not a string
 */
export function camel2title(camelCase: string) {
  if (typeof camelCase != 'string') return camelCase
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/_/g, ' ')
    .replace(/^./, (match) => match.toUpperCase())
    .replace(/ +/g, ' ')
    .trim()
}

/**
 * Convert Title Case to camelCase
 * Returns input if not a string
 */
export function title2camel(titleCase: string) {
  if (typeof titleCase != 'string') return titleCase
  return titleCase
    .replace(/ (\w)/g, (match) => match.toUpperCase())
    .replace(' ', '')
    .replace(/^./, (match) => match.toLowerCase())
    .trim()
}

/**
 * Convert kebab-case to camelCase
 * Returns input if not a string
 */
export function kebab2camel(kebabCase: string) {
  if (typeof kebabCase != 'string') return kebabCase
  return kebabCase
    .replace(/-/g, ' ')
    .replace(/ (\w)/g, (match) => match.toUpperCase())
    .replace(/ /g, '')
    .replace(/^./, (match) => match.toLowerCase())
    .trim()
}

/**
 * Convert camelCase (and PascalCase) to kebab-case
 * Returns input if not a string
 */
export function camel2kebab(camelCase: string) {
  if (typeof camelCase != 'string') return camelCase
  return camelCase
    .replace(/ /g, '-')
    .replace(/([A-Z])/g, (match) => '-' + match.toLowerCase())
    .replace(/^-+/g, '')
    .replace(/-{2,}/g, '-')
    .trim()
}
