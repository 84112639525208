<script lang="ts" context="module">
  import Form from '@components/Form.svelte'
  import JsonDisplay from '@components/Utility/JsonDisplay.svelte'
  import type { ProForm } from '@dhtmlx/ts-form'
  import type { Container } from '@dhtmlx/ts-form/sources/elements/container'
  import {
    filterFormValues,
    getFormItems,
    type ApiFormItem,
  } from '@lib/ApiFormGenerator'
  import { type FormEntity } from '@lib/FormUtil'
  import { collectedDebounce, debounce, isSet, sleep } from '@packages/util'
  import { baseApi } from '@models/api/BaseApi'
  import { getContext, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export const _name = 'AssetConfigEdit'

  const fieldAssetClassId = 'assetClassId'
  const debounceTime = 10

  // export interface AssetConfigEditConfig {
  //   height?: number
  // }
</script>

<script lang="ts">
  export let value: Record<any, any> = {}
  export let label = 'Asset Config Edit'
  // export let config: AssetConfigEditConfig = {}
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  const getForm = getContext<() => ProForm>('getForm')

  let _ready = false
  let _interacted = false
  let _lock = true
  let _state: typeof state = null
  let _errorMessage: typeof errorMessage = null
  let _assetClassId: Nullable<string>

  let staticInfo: Nullable<any>
  let formDefinition: Nullable<ApiFormItem[]>
  let form: Nullable<FormEntity>

  let loading = false
  let abortController = new AbortController()

  $: deboucedUpdateInternal(value)
  $: fetchAssetClassForm(_assetClassId)

  const debouncedHandleChange = debounce(updateExternal, 100)

  const deboucedUpdateInternal = collectedDebounce<typeof value>((items) => {
    updateInternal(Object.assign(items[0], ...items.slice(1)))
  }, debounceTime)

  /** Set the form when updated via the `value` property */
  function updateInternal(newValue: typeof value) {
    if (_lock) return
    form?.setValue(newValue)
  }

  /** Set the `value` property when the form updates */
  function updateExternal() {
    if (!_interacted || !validate()) return null

    const newValue = filterFormValues(form?.getValue(), formDefinition)
    _lock = true
    value = newValue
    // Wait a bit longer than the debounce time for the updateExternal to settle
    sleep(debounceTime + 10).then(() => (_lock = false))
  }

  /** Validate the form and update the state accordingly */
  function validate() {
    if (!loading && !form?.dhx?.validate()) {
      _state = 'error'
      _errorMessage = 'The configuration must be valid'
      return false
    }
    _state = null
    _errorMessage = null
    return true
  }

  /** Get the dependency container (assetClassId) */
  function getAssetClassIdContainer() {
    const formItem = getForm()?.getItem(fieldAssetClassId) as Container

    if (!formItem) {
      throw new Error(
        `${_name} depends on another form item (${fieldAssetClassId}) to be available`
      )
    }

    return formItem
  }

  /** Attach the events on the depending container */
  function attachAssetClassFormItemEvents() {
    getAssetClassIdContainer().events.on('change', async (newId) => {
      _assetClassId = null
      await tick()
      _assetClassId = newId
    })
  }

  /** Update the internal assetClassId from the depending container */
  function retrieveAssetId() {
    // @ts-ignore getValue is defined
    _assetClassId = getAssetClassIdContainer().getValue()
  }

  /** Fetch the asset class' form definition */
  async function fetchAssetClassForm(assetClassId: typeof _assetClassId) {
    if (!assetClassId) {
      staticInfo = null
      return
    }
    try {
      loading = true
      abortController = new AbortController()

      const result = await baseApi.assetClass.getOne(
        assetClassId,
        {
          fields: ['formDefinition@databind', 'staticInfo@databind'],
        },
        abortController.signal
      )

      // Undo interaction flag on change
      if (formDefinition != result['formDefinition@databind']) {
        _interacted = false
      }

      formDefinition = result['formDefinition@databind']
      staticInfo = result['staticInfo@databind']

      // Reload the form data
      await tick()
      deboucedUpdateInternal(value)

      // Mark the component as ready to receive changes
      _ready = true
    } finally {
      loading = false
    }
  }

  onMount(async () => {
    attachAssetClassFormItemEvents()
    _lock = false
    deboucedUpdateInternal(value)
    retrieveAssetId()
  })
</script>

<FormElementBase
  {label}
  state={_state ?? state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={_errorMessage ?? errorMessage}
>
  {#if _assetClassId}
    <div class="placeholderText" class:hidden={!loading}>
      Loading Configuration...
    </div>
    {#if isSet(formDefinition)}
      {#key formDefinition}
        <div class="formRenderer" class:hidden={loading}>
          <Form
            bind:form
            formConfig={{
              config: {
                rows: getFormItems(formDefinition),
                padding: 0,
              },
            }}
            on:change={() => _ready && debouncedHandleChange()}
            on:focus={() => (_interacted = true)}
          />
        </div>
      {/key}
    {/if}
  {:else if !loading}
    <div class="placeholderText">No extra configuration required.</div>
  {/if}
</FormElementBase>

{#if !loading && staticInfo}
  <div class="staticInformationDisplay">
    <legend
      class="dhx_label"
      aria-label="Static Information"
      style="max-width: 100%"
    >
      Static Information
    </legend>
    <div class="information">
      <JsonDisplay value={staticInfo} />
    </div>
  </div>
{/if}

<style lang="scss">
  .staticInformationDisplay {
    margin-top: 7px;

    .information {
      margin: 0.5em;
    }
  }

  .formRenderer {
    :global(.dhx_form > :first-child > .dhx_form-group) {
      margin-top: 0 !important;
    }
  }
</style>
