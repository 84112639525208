<!-- InputFilter Component -->
<script lang="ts" context="module">
  import Combobox from '../Combobox.svelte'

  export interface SelectFilterConfig {
    /** Combobox Style data list (Takes precedence over Select Style) */
    items?: { id: string; value: string }[]

    /** Select Style data list */
    options?: { value: string; content: string }[]
  }
</script>

<script lang="ts">
  export let value: string = ''
  export let config: SelectFilterConfig = {}

  function getData(config: SelectFilterConfig): SelectFilterConfig['items'] {
    return [
      { id: '', value: '' },
      ...(config?.items ?? []),
      ...(config?.options?.map((item) => ({
        id: item.value,
        value: item.content,
      })) ?? []),
    ]
  }
</script>

<div class="entityFilter">
  <Combobox data={getData(config)} bind:selected={value} />
</div>

<style lang="scss">
  .entityFilter {
    width: 100%;
    height: 100%;
    padding: 0.25em 0;

    display: flex;
    justify-content: center;
    align-items: center;

    :global(.combobox) {
      height: 100%;
      width: 100%;
    }
  }
</style>
