<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import ToolbarItem from '../ToolbarItem.svelte'
  import type { MappedEvents } from '@packages/util'
  import { mdiMicrosoftExcel } from '@mdi/js'

  interface $$Events {
    export: CustomEvent<never>
    import: CustomEvent<never>
    templateExport: CustomEvent<never>
    fullExport: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let hidden = false
  export let disableImport = false
  export let disableExport = false
</script>

<ToolbarItem
  after="editColumnsTarget"
  name="importExport"
  icon={mdiMicrosoftExcel}
  tooltip="Import/Export Data to/from Excel"
  hidden={hidden || (disableImport && disableExport)}
/>
<ToolbarItem
  parent="importExport"
  value="Export"
  on:click={() => dispatch('export')}
  hidden={hidden || disableExport}
  tooltip={'Export currently visible items to Excel'}
/>
<ToolbarItem
  parent="importExport"
  value="Export All Pages and Fields"
  on:click={() => dispatch('fullExport')}
  hidden={hidden || disableExport}
  tooltip={'Export all pages and all fields to Excel'}
/>
<ToolbarItem
  parent="importExport"
  value="Import"
  on:click={() => dispatch('import')}
  hidden={hidden || disableImport}
  tooltip="Import Excel file"
/>
<ToolbarItem
  parent="importExport"
  value="Download Template"
  on:click={() => dispatch('templateExport')}
  hidden={hidden || (disableImport && disableExport)}
  tooltip={'Export a template with all necessary fields to Excel'}
/>
