<script lang="ts" context="module">
  import type { StringFilter } from '@packages/fileManager/components/FileTree.svelte'
  import FormElementBase from './FormElementBase.svelte'
  import FilePicker from '@packages/fileManager/components/FilePicker.svelte'

  export interface FilePickerConfig {
    title?: string
    filter?: StringFilter
  }

  export const _name = 'FilePicker'
</script>

<script lang="ts">
  export let value: Nullable<Uuid> = null
  export let config: FilePickerConfig = {}
  export let label = 'File Picker'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null
  export let readOnly = false
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <FilePicker
    bind:value
    fileTypeFilter={config?.filter}
    title={config?.title}
    {readOnly}
  />
</FormElementBase>
