import { EditableGridColumnDataType } from '@components/EditableGrid.svelte'
import type {
  CustomDataColumn,
  CustomTableConfig,
} from '@components/FormControls/CustomTable.svelte'
import type {
  EnumEditorConfig,
  EnumEditorItemBase,
} from '@components/FormControls/EnumEditor.svelte'
import { metaFormBase, type FormItemTypeConfig } from '@lib/ApiFormGenerator'

export function generateFormTable(): FormItemTypeConfig {
  return {
    metaForm: [
      ...metaFormBase,
      {
        type: 'select',
        name: 'dataType',
        label: 'Data Type',
        default: 'numeric',
        options: [
          { value: 'Numeric', id: EditableGridColumnDataType.numeric },
          { value: 'Integer', id: EditableGridColumnDataType.integer },
          { value: 'String', id: EditableGridColumnDataType.string },
        ] satisfies { value: string; id: EditableGridColumnDataType }[],
      },
      {
        type: 'boolean',
        name: 'showColumnHeaders',
        default: true as any,
      },
      {
        type: '@EnumEditor',
        name: 'columns',
        label: 'Columns',
        config: {
          labelColumnTitle: 'Label',
          nameColumnTitle: 'Key',
        } satisfies EnumEditorConfig,
      },
      {
        type: 'boolean',
        name: 'showRowHeaders',
        default: false as any,
      },
      {
        type: '@CustomTable',
        name: 'rowHeaders',
        label: 'Row Headers',
        config: {
          columns: [{ name: 'rowHeader' }],
          dataType: EditableGridColumnDataType.string,
          singleColumn: true,
        } satisfies CustomTableConfig,
      },
    ],
    toItem(data) {
      return {
        type: 'container',
        html: '@CustomTable',
        config: {
          columns: (
            (data?.config?.columns ?? []) as EnumEditorItemBase[]
          ).map<CustomDataColumn>((item) => ({
            label: item.name,
            name: String(item.value),
          })),
          rowHeaders: data?.config?.showRowHeaders
            ? data?.config?.rowHeaders
            : null,
          // minRows: data?.config?.minRows,
          // maxRows: data?.config?.maxRows,
          disableColumnHeaders: !(data?.config?.showColumnHeaders ?? true),
          dataType: data?.config?.dataType,
        } satisfies CustomTableConfig,
      }
    },
  }
}
