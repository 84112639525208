import { isString } from '@packages/util'

/**
 * Intl Number Parser.
 * Parses the number in the user's curent locale
 * @see https://observablehq.com/@mbostock/localized-number-parsing
 */
export class NumberParser {
  _group: RegExp
  _decimal: RegExp
  _numeral: RegExp
  _index: (d: string) => any

  constructor(locales: string | string[]) {
    const parts = new Intl.NumberFormat(locales).formatToParts(12345.6)
    const numerals = [
      ...new Intl.NumberFormat(locales, { useGrouping: false }).format(
        9876543210
      ),
    ].reverse()
    const index = new Map(numerals.map((d, i) => [d, i]))

    this._group = new RegExp(
      `[${parts.find((d) => d.type === 'group').value}]`,
      'g'
    )
    this._decimal = new RegExp(
      `[${parts.find((d) => d.type === 'decimal').value}]`
    )
    this._numeral = new RegExp(`[${numerals.join('')}]`, 'g')
    this._index = (d: string) => index.get(d)
  }

  parse(value: string | number) {
    if (typeof value == 'number') return value
    if (!isString(value)) throw Error('Not a valid string nor number')
    return (value = value
      .trim()
      .replace(this._group, '')
      .replace(this._decimal, '.')
      .replace(this._numeral, this._index))
      ? +value
      : NaN
  }
}
