import { isSet, isString } from '@packages/util'
import {
  get,
  readable,
  readonly,
  writable,
  type Readable,
  type Writable,
} from 'svelte/store'
import type { OmitFirst } from './UtilityTypes'

// Re-export some Utilities
export * from './Utility'

/**
 * Encodes an object into a query string the PHP backend can parse
 */
export function encodePHPQueryParams(
  value: Record<string, any>,
  prefix?: string
) {
  let output: string[] = []
  let property: string

  for (property in value) {
    if (value.hasOwnProperty(property)) {
      let _key = prefix ? prefix + '[' + property + ']' : property
      let _value = value[property]

      output.push(
        _value !== null && typeof _value === 'object'
          ? encodePHPQueryParams(_value, _key)
          : encodeURIComponent(_key) + '=' + encodeURIComponent(_value)
      )
    }
  }

  return output.join('&')
}

/**
 * Decode the query parameters and map it to an object
 */
export function decodeQueryParams<T extends Record<string, any>>(
  value: string
): T {
  const result = {}
  for (const [_key, _value] of new URLSearchParams(value)) {
    //@ts-ignore
    result[_key] = _value
  }
  return <T>result
}

/**
 * Simple UUID validator
 *
 * @param value String value
 * @returns A boolean stating the value is a UUID
 */
export function isUuid(value: any): value is Uuid {
  if (!isString(value)) return false
  return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
    value
  )
}

/**
 * Get a random UUID.
 *
 * If a string is given, it will be checked if it has a valid UUID.
 */
export function uuid(fromString?: string): Uuid {
  if (fromString !== undefined) {
    if (isUuid(fromString)) return fromString as Uuid
    else throw new Error('Given string is not a valid UUID')
  }

  return crypto.randomUUID()
}

/**
 * Get a random ID with an optional prefix
 */
export function getId(prefix?: string) {
  return `${prefix ? prefix + '-' : ''}${Math.random().toString(16).slice(2)}`
}

/**
 * Compares two strings, ignoring whitespace and in-between characters
 * Example inputs that matches source "Hello World":
 *  helloworld
 *  hllOwRlD
 *  h world
 *
 * @param source Source String
 * @param input Input String
 */
export function fuzzyCompare(source: string, input: string) {
  if (!input) return true
  if (typeof input != 'string' || typeof source != 'string') return false

  // Search in strings
  return (
    input
      .toLowerCase() // Lowercase
      .replace(/\s/g, '') // Remove whitespace
      .split('') // Split by each character
      .reduce<string | null>((currentString, inputCharacter) => {
        if (currentString === null) return null
        const characterPosition = currentString.indexOf(inputCharacter)
        if (characterPosition === -1) return null
        return currentString.substring(characterPosition + 1)
      }, source.toLowerCase().trim()) !== null
  )
}

/**
 * Calls a defined callback function on each element of an array,
 * and returns an array that contains the results filtered by uniqueness.
 * @param array The input array to perform the operation on
 * @param callbackfn A function that accepts up to three arguments.
 * The map method calls the callbackfn function one time for each element in the array.
 * By default it will use the item itself
 * @param thisArg An object to which the this keyword can refer in the callbackfn function.
 * If thisArg is omitted, undefined is used as the this value.
 */
export function unique<T, U>(
  array: T[],
  callbackfn?: (value: T, index: number, array: T[]) => U,
  thisArg?: any
): T[] {
  callbackfn ??= (value, index, array) => String(value) as U
  return [
    ...new Map(
      array.map(
        (value, index, array) => [callbackfn?.(value, index, array), value],
        thisArg
      )
    ).values(),
  ]
}

/**
 * Sets a value in the query string of the current page
 * This will replace the current history state
 */
export function setQueryStringParam(key: string, value: string) {
  const queryParams = window.location.href.match(/\?.*$/)?.[0]
  const baseUrl = window.location.href.match(/^[^?]*/)?.[0]
  const searchParams = new URLSearchParams(queryParams)

  searchParams.set(key, value)
  const paramString = searchParams.toString()
  const newRelativePathQuery =
    baseUrl + (!isSet(paramString) ? '' : '?' + paramString)
  history.replaceState(null, '', newRelativePathQuery)
}

/**
 * Sets a value in the query string of the current page
 * This will replace the current history state
 */
export function unsetQueryStringParam(key: string) {
  const queryParams = window.location.href.match(/\?.*$/)?.[0]
  const baseUrl = window.location.href.match(/^[^?]*/)?.[0]
  const searchParams = new URLSearchParams(queryParams)

  searchParams.delete(key)
  const paramString = searchParams.toString()
  const newRelativePathQuery =
    baseUrl + (!isSet(paramString) ? '' : '?' + paramString)
  history.replaceState(null, '', newRelativePathQuery)
}

/**
 * Get the value inside the query string of the current page
 */
export function getQueryStringParam(key: string): Nullable<string> {
  return new URLSearchParams(
    window.location.href.match(/\?.*$/)?.[0] ?? ''
  ).get(key)
}

/**
 * Get all values inside the query string of the current page
 */
export function getQueryStringParams() {
  return Object.fromEntries(
    new URLSearchParams(window.location.href.match(/\?.*$/)?.[0] ?? '') ?? []
  )
}

/**
 * Given a string template formatted like a template literal,
 * and an object of values, return the modified string.
 *
 * Format: "Hello ${name}" (note the use of quoting, don't use a backtick <`>!)
 *
 * @see https://longviewcoder.com/2023/05/17/javascript-plain-string-as-template-literal-without-eval/
 */
export function interpolateTemplate(
  template: string,
  args: Record<string, string>
) {
  return Object.entries(args).reduce(
    (result, [arg, val]) => result.replace(`$\{${arg}}`, `${val}`),
    template
  )
}

/**
 * Shortcut function for the sort function to sort by alphabetical order
 */
export function alphaSort(stringA: string, stringB: string) {
  return stringA.toLocaleLowerCase().localeCompare(stringB.toLocaleLowerCase())
}

/**
 * Opens a route in a new tab and focusses on it
 */
export function pushNew(path: string, asFullUrl = false) {
  if (asFullUrl) {
    //@ts-ignore
    window.open(path).focus()
  } else {
    const baseUrl = window.location.href.match(/^[^?#]*/)?.[0]
    //@ts-ignore
    window.open(baseUrl + '#' + path, '_blank').focus()
  }
}

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  timeout = 300
) {
  let timer: NodeJS.Timeout

  return (...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      //@ts-ignore
      func.apply(this, args)
    }, timeout)
  }
}

/** Helper function for displaying the loading state of the given promise */
export function useLoader<
  T extends (signal: AbortSignal, ...args: any[]) => Promise<any>,
>(loader: T, defaultLoadState = true) {
  const loading = writable(defaultLoadState)
  const abortController = writable(new AbortController())

  return {
    loading,
    abortController,
    async load(
      ...args: OmitFirst<Parameters<T>> extends never
        ? []
        : OmitFirst<Parameters<T>>
    ): Promise<Awaited<ReturnType<T>>> {
      const _abortController = new AbortController()
      abortController.set(_abortController)
      loading.set(true)
      try {
        return await loader(_abortController.signal, ...args)
      } finally {
        loading.set(false)
      }
    },
    abort() {
      get(abortController).abort()
    },
  }
}

/**
 * Create a helpful debug function that increases readability when debugging often
 * Provides a `.disable` property that acts as a passthrough (disables debugging)
 */
export function useDebugger(type?: string, label?: string) {
  type Func = <T>(action: string, passThroughData?: T, ...data: any[]) => T
  type DisableObj = {
    /** Disable the debugger */
    disable: Func
  }

  // Set base function
  let _function: Func = (_, passThroughData, __) => passThroughData

  // Only enable debugger on dev
  if (import.meta.env.DEV) {
    const debugId = getId()
    const _type = type ?? 'Debug'
    const _label = label ?? debugId

    _function = (action, passThroughData, ...data) => {
      console.log(
        `%c${_type} ${_label}`,
        `color: #${debugId.substring(0, 6)}`,
        (action ?? 'UNKNOWN').padEnd(20, ' '),
        passThroughData,
        ...data
      )
      return passThroughData
    }
  }

  // Combine Function and Disabled Function
  return Object.assign<Func, DisableObj>(_function, {
    disable: (_, passThroughData, __) => passThroughData,
  })
}

/**
 * Check if the Ctrl (or in case of macOS, Meta) key
 * is being held while this event was called
 */
export function isCtrlHeld(
  event: Event | KeyboardEvent | MouseEvent | TouchEvent
): event is KeyboardEvent | MouseEvent | TouchEvent {
  return (
    typeof event == 'object' &&
    ((event?.['ctrlKey'] ?? false) || (event?.['metaKey'] ?? false))
  )
}

/** Simple Logical XOR, can function as a conditional NOT */
export function xor(state: boolean, invert: boolean): boolean {
  return invert ? !state : state
}

/**
 * Waits for the next browser animation frame.
 *
 * NOTE: Same as dhtmlx's await redraw
 */
export function awaitRedraw() {
  return new Promise<void>((res) => requestAnimationFrame(() => res()))
}
