import { dhxAlert, dhxConfirm } from '@lib/dhtmlx'

export interface ConfirmConfig {
  title?: string
  content?: string
  confirmText?: string
  cancelText?: string
}

/**
 * Simple alert box
 */
export async function alert(config: ConfirmConfig, onConfirm?: () => void) {
  await dhxAlert({
    header: config.title ?? 'Alert',
    text: config.content ?? 'Something Happened...',
    buttons: [config.confirmText ?? 'Continue'],
    buttonsAlignment: 'right',
  })
  onConfirm?.()
}

/**
 * Simple confirmation box
 */
export async function confirm(config: ConfirmConfig, onConfirm?: () => void) {
  const confirmed = await dhxConfirm({
    header: config.title ?? 'Confirm',
    text: config.content ?? 'Continue?',
    buttons: [config.cancelText ?? 'Cancel', config.confirmText ?? 'Confirm'],
  })
  if (confirmed) onConfirm?.()
  return confirmed
}

/**
 * Simple confirmation box for deleting items
 */
export function confirmDelete(
  config: ConfirmConfig & {
    entityType?: string
    entityName?: string
  },
  onConfirm?: () => void
) {
  return confirm(
    {
      ...config,
      title:
        config.title ??
        `Delete ${config.entityType ?? 'Entity'}${
          config.entityName ? ' "' + config.entityName + '"' : ''
        }?`,
      content:
        config.content ??
        'Are you sure you want to continue? This action will not be reversible!',
    },
    onConfirm
  )
}

/**
 * Simple confirmation box for cloning items
 */
export function confirmClone(
  config: ConfirmConfig & {
    entityType?: string
    entityName?: string
  },
  onConfirm?: () => void
) {
  return confirm(
    {
      ...config,
      title:
        config.title ??
        `Clone ${config.entityType ?? 'Entity'} ${
          config.entityName ? '"' + config.entityName + '"' : ''
        }?`,
      content: config.content ?? 'Are you sure you want to clone this item?',
    },
    onConfirm
  )
}
