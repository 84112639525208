<!-- A modal template, ready to use -->
<script lang="ts">
  import ToolbarItem from '@components/Toolbar/ToolbarItem.svelte'
  import Container from '@components/Utility/Container.svelte'
  import Window from '@components/Window.svelte'
  import { mdiReload, mdiUpload } from '@mdi/js'
  import type { File } from '@models/api/ApiModels'
  import { isUuid, type MappedEvents } from '@packages/util'
  import { createEventDispatcher } from 'svelte'
  import FileManager from './FileManager.svelte'
  import type { OpenFileEventData, StringFilter } from './FileTree.svelte'

  interface $$Events {
    apply: CustomEvent<Uuid>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let show = false
  export let title: Nullable<string> = null
  export let fileTypeFilter: StringFilter = null

  let fileManager: Nullable<FileManager>
  let selectedItem: Nullable<File>

  let isSelectedNodeInFilter = false

  $: allowSelection =
    isUuid(selectedItem?.id) &&
    !selectedItem?.directory &&
    isSelectedNodeInFilter

  function apply(fileId: Uuid) {
    show = false
    dispatch('apply', fileId)
  }

  function handleOpenFile(event: CustomEvent<OpenFileEventData>) {
    const _id = event.detail?.file?.id
    if (!isUuid(_id) || !event.detail?.inFilter) return
    apply(_id)
  }

  function handleApplyClick() {
    if (!allowSelection) return
    apply(selectedItem?.id as Uuid)
  }
</script>

<Window
  bind:show
  title={title ?? 'Select a File'}
  width="80em"
  height="70em"
  maxHeight="90vh"
  maxWidth="90vw"
>
  <svelte:fragment slot="extraTopButtons">
    <ToolbarItem
      icon={mdiReload}
      css="circular"
      on:click={() => fileManager?.reload()}
    />
    <ToolbarItem
      icon={mdiUpload}
      css="circular"
      on:click={() => fileManager?.openUploadWindow()}
    />
  </svelte:fragment>
  <Container border>
    <FileManager
      bind:this={fileManager}
      bind:selectedItem
      on:openFile={handleOpenFile}
      bind:isSelectedNodeInFilter
      {fileTypeFilter}
    />
  </Container>
  <svelte:fragment slot="bottomToolbar">
    <ToolbarItem spacer />
    <ToolbarItem value="Cancel" type="button" on:click={() => (show = false)} />
    <ToolbarItem
      value="Open"
      type="button"
      on:click={handleApplyClick}
      disabled={!allowSelection}
    />
  </svelte:fragment>
</Window>
