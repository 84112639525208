<script lang="ts">
  import { capitalize } from '@packages/util'

  export let data: {
    url: string
    location: string
    telephone: string
    email: string
  }

  const _default = {
    url: '---',
    location: '---',
    telephone: '---',
    email: '---',
  }

  let _data = { ..._default, ...data }
  $: _data = { ..._default, ...data }
</script>

<footer>
  <div class="footer-content">
    <p class="powered-by">Powered by</p>
    <a href={_data.url}>
      <img src="./img/logo.webp" alt="logo" width="200px" />
    </a>

    <p>
      <a href={_data.url}
        >{capitalize(_data.url.replace(/https?:\/\/(www\.)?/g, ''))}</a
      ><br />
      {_data.location}<br />
      <a href={'tel:' + _data.telephone}>{_data.telephone}</a><br />
      <a href={'mailto:' + _data.email}>{_data.email}</a><br />
    </p>
  </div>
</footer>

<style lang="scss">
  footer {
    width: 100%;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    margin-top: 4em;

    .powered-by {
      margin: 0;
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
</style>
