<span class="spinner" title="Loading Data..."></span>

<style lang="scss">
  .spinner {
    user-select: none;

    width: 20px;
    height: 20px;
    margin: 0.25em;
    overflow: hidden;

    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    // mdiLoading
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJNMTIsNFYyQTEwLDEwIDAgMCwwIDIsMTJINEE4LDggMCAwLDEgMTIsNFoiIC8+PC9zdmc+');
  }
</style>
