import type {
  Toolbar as DHXToolbar,
  IToolbarElement,
  IToolbarConfig,
} from '@dhtmlx/ts-toolbar'

export interface ToolbarConfig extends IToolbarConfig {
  id?: string
  data?: IToolbarElement[]

  /**
   * Prebuild classes:
   * - `compact`: Minimizes vertical height
   */
  css?: string
}

export class Toolbar {
  readonly dhx: DHXToolbar
  readonly id: string
  data: IToolbarElement[];
  [key: string]: any

  constructor(DHTMLXToolbar: DHXToolbar, toolbarConfig?: ToolbarConfig) {
    this.dhx = DHTMLXToolbar
    this.data = []
    Object.entries(toolbarConfig).forEach(([key, value]) => {
      this[key] = value
    })

    this.data.forEach((toolbarItem) => {
      if (!Object.prototype.hasOwnProperty(toolbarItem.html)) {
        toolbarItem.html = String(
          toolbarItem.svg ? toolbarItem.svg + (toolbarItem?.value ?? '') : ''
        )
      }
    })
  }

  add(
    toolbarElement: IToolbarElement | IToolbarElement[],
    after?: string | number,
    parent?: string
  ) {
    let index =
      typeof after == 'string' ? this.dhx.data.getIndex(after) + 1 : after

    if (Array.isArray(toolbarElement))
      toolbarElement.forEach((_element, _index) =>
        this.dhx.data.add(_element, index + _index, parent)
      )
    else this.dhx.data.add(toolbarElement, index, parent)
  }

  remove(id: string | string[]) {
    this.dhx.data.remove(id)
  }

  /**
   * Add a collection of toolbar elements
   * All elements given will get the id of `<collection name>:<toolbar element id>`
   * @param collection The collection name
   * @param toolbarElement One or more toolbar items
   */
  addCollection(
    collection: string,
    toolbarElement: IToolbarElement | IToolbarElement[]
  ) {
    if (Array.isArray(toolbarElement))
      toolbarElement.forEach((_element, _index) =>
        this.dhx.data.add({ ..._element, id: `${collection}:${_element.id}` })
      )
    else
      this.dhx.data.add({
        ...toolbarElement,
        id: `${collection}:${toolbarElement.id}`,
      })
  }

  /**
   * Remove a collection of toolbar elements
   * @param collection The collection name
   */
  removeCollection(collection: string) {
    this.remove(
      this.dhx.data
        .findAll((item: any) => item.id.startsWith(collection + ':'))
        .map((item) => item.id)
    )
  }

  /**
   * Enable a collection of toolbar elements
   * @param collection The collection name
   * @param state Enable (`true`) or disable (`false`) the collection
   */
  setCollectionState(collection: string, state: boolean) {
    this.dhx.data
      .findAll((item: any) => item.id.startsWith(collection + ':'))
      .forEach((item) => {
        if (state) this.dhx.enable(item.id)
        else this.dhx.disable(item.id)
      })
  }
}

export type ToolbarModel = Toolbar
