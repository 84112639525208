<script lang="ts" context="module">
  import FormDialog from '@components/Modals/FormDialog.svelte'
  import LocalToolbar from '@components/Toolbar/LocalToolbar.svelte'
  import ToolbarItem from '@components/Toolbar/ToolbarItem.svelte'
  import { isSet } from '@packages/util'
  import { type Editor } from '@tiptap/core'

  import {
    mdiEraser,
    mdiFormatAlignCenter,
    mdiFormatAlignJustify,
    mdiFormatAlignLeft,
    mdiFormatAlignRight,
    mdiFormatBold,
    mdiFormatItalic,
    mdiFormatStrikethrough,
    mdiFormatUnderline,
    mdiLinkVariant,
    mdiRedo,
    mdiUndo,
  } from '@mdi/js'

  export type ParagraphSize = keyof typeof ParagraphSize
  export const ParagraphSize = {
    normal: 'Normal',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    h4: 'Heading 4',
    h5: 'Heading 5',
    h6: 'Heading 6',
  } as const

  export type Alignment = keyof typeof Alignment
  export const Alignment = {
    left: 'Left',
    center: 'Center',
    right: 'Right',
    justify: 'Justify',
    unset: 'Unset',
  } as const
  const alignmentIcon = {
    left: mdiFormatAlignLeft,
    center: mdiFormatAlignCenter,
    right: mdiFormatAlignRight,
    justify: mdiFormatAlignJustify,
    unset: mdiEraser,
  } satisfies Record<Alignment, string>

  type Level = 1 | 2 | 3 | 4 | 5 | 6

  const toolbarMainId = 'richTextEditor' as const
  const toolbarIds = {
    undo: `${toolbarMainId}:undo`,
    redo: `${toolbarMainId}:redo`,
    separator1: `${toolbarMainId}:separator1`,
    sizeMenu: `${toolbarMainId}:sizeMenu`,
    separator2: `${toolbarMainId}:separator2`,
    bold: `${toolbarMainId}:bold`,
    italic: `${toolbarMainId}:italic`,
    strike: `${toolbarMainId}:strike`,
    under: `${toolbarMainId}:under`,
    separator3: `${toolbarMainId}:separator3`,
    align: `${toolbarMainId}:align`,
    separator4: `${toolbarMainId}:separator4`,
    link: `${toolbarMainId}:link`,
    unformat: `${toolbarMainId}:unformat`,
  } as const

  const formDialogHeight = '17.5em'
</script>

<script lang="ts">
  export let editor: Editor

  let formDialog: Nullable<FormDialog>

  function setTextAlign(alignment: Alignment) {
    if (alignment == 'unset') editor.chain().focus().unsetTextAlign().run()
    else editor.chain().focus().setTextAlign(alignment).run()
  }

  function getTextAlign() {
    return Object.keys(Alignment).find((textAlign) =>
      editor.isActive({ textAlign })
    ) as Alignment
  }

  function setSize(size: ParagraphSize) {
    const currentAlignment = getTextAlign()
    let level = getLevel(size)

    if (!level) {
      editor.chain().focus().setParagraph().run()
    } else {
      editor.chain().focus().toggleHeading({ level }).run()
    }

    // Restore alignment
    setTextAlign(currentAlignment)
  }

  function getLevel(size: ParagraphSize): Level | false {
    switch (size) {
      case 'h1':
        return 1
      case 'h2':
        return 2
      case 'h3':
        return 3
      case 'h4':
        return 4
      case 'h5':
        return 5
      case 'h6':
        return 6
      default:
        return false
    }
  }

  function setLink() {
    // Prompt a form asking for the URL
    formDialog.promptForm(
      'Set URL for Current Selection',
      { href: editor.getAttributes('link')?.href ?? '' },
      {
        rows: [
          {
            type: 'input',
            label: 'URL (Leave Empty to Unset)',
            name: 'href',
            placeholder: 'https://example.org/',
          },
        ],
      },
      async ({ href }) => {
        // On success...
        if (isSet(href)) {
          // Set Link (if set)
          editor.chain().focus().extendMarkRange('link').setLink({ href }).run()
        } else {
          // Unset Link
          editor.chain().focus().extendMarkRange('link').unsetLink().run()
        }
        return true
      }
    )
  }

  /** (Util) Wrapper for object entries with proper typing */
  function constObjectEntries<T extends Record<string, any>>(
    obj: T
  ): [key: keyof T, value: T[keyof T]][] {
    return Object.entries(obj)
  }
</script>

<LocalToolbar>
  <!-- HISTORY ACTIONS -->
  <!-- Undo -->
  <ToolbarItem
    name={toolbarIds.undo}
    tooltip="Undo Action"
    icon={mdiUndo}
    on:click={() => editor.chain().undo().run()}
    disabled={!editor.can().undo()}
  />

  <!-- Redo -->
  <ToolbarItem
    name={toolbarIds.redo}
    after={toolbarIds.undo}
    tooltip="Redo Action"
    icon={mdiRedo}
    on:click={() => editor.chain().redo().run()}
    disabled={!editor.can().redo()}
  />

  <!-- Separator -->
  <ToolbarItem name={toolbarIds.separator1} after={toolbarIds.redo} separator />

  <!-- SIZE ACTIONS -->
  <!-- Paragraph Size menu -->
  <ToolbarItem
    name={toolbarIds.sizeMenu}
    after={toolbarIds.separator1}
    value="Size"
    tooltip="Set Line Size"
  />
  {#each constObjectEntries(ParagraphSize) as [value, label]}
    <ToolbarItem
      parent={toolbarIds.sizeMenu}
      name="{toolbarIds.sizeMenu}:{value}"
      value={label}
      on:click={() => setSize(value)}
      active={value == 'normal'
        ? editor.isActive('paragraph')
        : editor.isActive('heading', { level: getLevel(value) })}
    />
  {/each}

  <!-- Separator -->
  <ToolbarItem
    name={toolbarIds.separator2}
    after={toolbarIds.sizeMenu}
    separator
  />

  <!-- BASIC STYLING ACTIONS -->
  <!-- Bold -->
  <ToolbarItem
    name={toolbarIds.bold}
    after={toolbarIds.separator2}
    icon={mdiFormatBold}
    on:click={() => editor.chain().focus().toggleBold().run()}
    disabled={!editor.can().toggleBold()}
    active={editor.isActive('bold')}
    tooltip="Toggle style: Bold"
  />

  <!-- Italic -->
  <ToolbarItem
    name={toolbarIds.italic}
    after={toolbarIds.bold}
    icon={mdiFormatItalic}
    on:click={() => editor.chain().focus().toggleItalic().run()}
    disabled={!editor.can().toggleItalic()}
    active={editor.isActive('italic')}
    tooltip="Toggle style: Italic"
  />

  <!-- Strikethrough -->
  <ToolbarItem
    name={toolbarIds.strike}
    after={toolbarIds.italic}
    icon={mdiFormatStrikethrough}
    on:click={() => editor.chain().focus().toggleStrike().run()}
    disabled={!editor.can().toggleStrike()}
    active={editor.isActive('strike')}
    tooltip="Toggle style: Strikethrough"
  />

  <!-- Underline -->
  <ToolbarItem
    name={toolbarIds.under}
    after={toolbarIds.strike}
    icon={mdiFormatUnderline}
    on:click={() => editor.chain().focus().toggleUnderline().run()}
    disabled={!editor.can().toggleUnderline()}
    active={editor.isActive('underline')}
    tooltip="Toggle style: Underline"
  />

  <!-- Separator -->
  <ToolbarItem
    name={toolbarIds.separator3}
    after={toolbarIds.under}
    separator
  />

  <!-- ALIGNMENT ACTIONS -->
  <ToolbarItem
    name={toolbarIds.align}
    after={toolbarIds.separator3}
    value="Alignment"
    tooltip="Set Horizontal Alignment for Current Line"
  />
  {#each constObjectEntries(Alignment) as [value, label]}
    <ToolbarItem
      parent={toolbarIds.align}
      name="{toolbarIds.align}:{value}"
      icon={alignmentIcon[value]}
      value={label}
      on:click={() => setTextAlign(value)}
      active={value == 'unset' ? false : editor.isActive({ textAlign: value })}
    />
  {/each}

  <!-- Separator -->
  <ToolbarItem
    name={toolbarIds.separator4}
    after={toolbarIds.align}
    separator
  />

  <!-- SPECIAL ACTIONS -->
  <!-- Create Link -->
  <ToolbarItem
    name={toolbarIds.link}
    after={toolbarIds.separator4}
    icon={mdiLinkVariant}
    on:click={setLink}
    active={editor.isActive('link')}
    tooltip="Set URL for Current Selection"
  />

  <!-- Remove Styling -->
  <ToolbarItem
    name={toolbarIds.unformat}
    after={toolbarIds.link}
    icon={mdiEraser}
    on:click={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}
    tooltip="Clear All Applied Styling"
  />
</LocalToolbar>

<!-- Used for link set action -->
<FormDialog bind:this={formDialog} height={formDialogHeight} />
