import {
  canSeeItem,
  type SidebarItem,
  type SidebarLink,
} from '@components/Sidebar.svelte'
import {
  mdiCalendarBlankMultiple,
  mdiChartTimelineVariant,
  mdiCog,
  mdiDeveloperBoard,
  mdiFileMultipleOutline,
  mdiFileTree,
  mdiFormatListBulleted,
  mdiPackageVariantClosed,
  mdiViewDashboardOutline,
} from '@mdi/js'
import { nameOfBatches } from './CommonStrings'
import { buildConfig, features } from './Features'
import { permission } from './Permissions'

const showIf = (state: boolean, obj: SidebarLink): SidebarLink[] =>
  state ? [obj] : []

export function getDefaultSidebar(): SidebarItem[] {
  return [
    ...showIf(features.dashboard, {
      route: '/dashboard',
      name: 'Dashboard',
      icon: mdiViewDashboardOutline,
      permissions: [permission.viewDashboard],
    }),
    ...showIf(features.dataviewer, {
      route: '/dataviewer',
      name: 'Data Viewer',
      icon: mdiChartTimelineVariant,
      permissions: [permission.viewDatapoint],
    }),
    ...showIf(features.batches, {
      route: '/batches',
      name: nameOfBatches,
      icon: mdiCalendarBlankMultiple,
      permissions: [permission.viewBatch],
    }),
    ...showIf(features.installations, {
      route: '/installations',
      name: 'Installations',
      icon: mdiFileTree,
      permissions: [permission.viewAsset],
    }),
    ...showIf(features.equipment, {
      route: '/equipment',
      name: 'Equipment',
      icon: mdiPackageVariantClosed,
      permissions: [
        permission.viewAsset,
        permission.equipmentManagement,
        permission.equipmentView,
      ],
      hasSome: true,
    }),
    ...showIf(features.mediaManager, {
      route: '/files',
      name: 'Files',
      icon: mdiFileMultipleOutline,
      permissions: [permission.fileAccess],
    }),
    ...showIf(features.customLists, {
      route: '/list',
      name: 'Custom Lists',
      icon: mdiFormatListBulleted,
      permissions: [permission.viewView],
    }),
    { type: 'spacer' },
    ...showIf(features.dev, {
      route: '/dev',
      name: 'Developer',
      icon: mdiDeveloperBoard,
    }),
    {
      route: '/settings',
      name: 'Settings',
      icon: mdiCog,
      permissions: [permission.Administrators],
    },
    // { type: 'separator' },
    // {
    //   type: 'custom',
    //   render: createRender(UserSidebarItem, {}),
    // },
  ]
}

/**
 * Return the real home page if the user has access to it,
 * otherwise try to find a link that the user does have access to
 */
export function getHomePage() {
  const isLink = (item: SidebarItem): item is SidebarLink =>
    !(item as any).spacer
  const links = Object.values(getDefaultSidebar()).filter(isLink)
  const realHome = links.find(
    (item) => canSeeItem(item) && item.route == buildConfig.homepage
  )
  const fallback = links.find(canSeeItem)
  return (realHome ?? fallback)?.route
}
