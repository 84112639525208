// https://www.npmjs.com/package/svelte-spa-router
import DefaultLayout from '@layouts/DefaultLayout.svelte'
import MinimalLayout from '@layouts/MinimalLayout.svelte'
import { camel2kebab, capitalize, isSet } from '@packages/util'
import { devRoutes } from '@pages/developerPages/DevIndex.svelte'
import { pages, type PageDetails } from '@pages/manageEntity/Pages'
import { features } from './Features'
import { permission } from './Permissions'
import {
  flattenRoutes,
  loginComponent,
  requirePermissions,
  showRouteIf,
  wrap,
  type Routes,
} from './Router'
import { getHomePage } from './Sidebars'

const baseRoutes: Routes = {
  // Login Page
  login: wrap({
    //@ts-ignore
    asyncComponent: loginComponent,
    userData: null,
  }),
  logout: wrap({
    //@ts-ignore
    asyncComponent: () => import('../pages/Logout.svelte'),
    userData: null,
  }),

  // Temporary
  admin: wrap({
    asyncComponent: requirePermissions(
      () => import('../pages/Admin.svelte'),
      permission.Administrators
    ),
    userData: {
      layout: DefaultLayout,
    },
  }),

  // Fallback (404)
  '*': wrap({
    asyncComponent: () =>
      //@ts-ignore
      import('../pages/errorViews/NotFound.svelte'),
  }),
}

const settingsRoutes = {
  'settings/:view?': wrap({
    asyncComponent: requirePermissions(
      () => import('../pages/Settings.svelte'),
      permission.Administrators
    ),
  }),
}

const homepageRedirect = wrap({
  asyncComponent: requirePermissions(
    () => import('../pages/errorViews/Redirect.svelte')
  ),
  props: {
    url: getHomePage(),
  },
})

const homepages: Routes = {
  index: homepageRedirect,
  home: {
    index: homepageRedirect,
    '*': homepageRedirect,
  },
}

const dashboardRoutes: Routes = {
  // Dashboard
  dashboard: {
    index: wrap({
      asyncComponent: requirePermissions(
        () => import('../pages/DashboardPage.svelte'),
        permission.viewDashboard
      ),
    }),
    ':id': wrap({
      asyncComponent: requirePermissions(
        () => import('../pages/DashboardPage.svelte'),
        permission.viewDashboard
      ),
    }),
  },
  'edit-sub-layout/:id': wrap({
    // Special route meant for using inside iframes
    asyncComponent: requirePermissions(
      () => import('../pages/DashboardPage.svelte'),
      permission.viewDashboard
    ),
    userData: {
      layout: MinimalLayout,
    },
  }),
}

const entityLists: Routes = {
  // Batches
  'batches/:view?': wrap({
    asyncComponent: requirePermissions(
      () => import('../pages/entityList/Batches.svelte'),
      permission.viewBatch
    ),
  }),
  // Installations
  'installations/:view?': wrap({
    asyncComponent: requirePermissions(
      () => import('../pages/entityList/Installations.svelte'),
      permission.viewAsset
    ),
  }),
  // Equipment
  'equipment/:view?': wrap({
    asyncComponent: requirePermissions(
      () => import('../pages/entityList/Equipment.svelte'),
      [
        permission.viewAsset,
        permission.equipmentManagement,
        permission.equipmentView,
      ]
    ),
  }),
  // Files
  'files/:id?': wrap({
    asyncComponent: requirePermissions(
      () => import('../pages/entityList/Files.svelte'),
      permission.fileManagement
    ),
  }),
  // File View
  'file/:id?': wrap({
    asyncComponent: requirePermissions(
      () => import('../pages/entityList/FileView.svelte'),
      permission.fileManagement
    ),
  }),
}

export function getRoutes() {
  return flattenRoutes({
    // Based on if dashboards are enabled or not
    // ...(features.dashboard ? dashboardEnabledRoutes : dashboardDisabledRoutes),

    // Entity Views
    ...Object.entries<PageDetails>(pages)
      .filter(([name, details]) => !isSet(details.enable) || details.enable)
      .reduce(
        (prev, [name, details]) => ({
          ...prev,
          [`${details.overrideName ?? camel2kebab(name)}/:id/:view?`]: wrap({
            asyncComponent: requirePermissions(
              () => import('../pages/manageEntity/ManageEntity.svelte'),
              details?.permissions ?? (`view${capitalize(name)}` as any)
            ),
            userData: {
              layout: DefaultLayout,
            },
            props: {
              modelName: name,
            },
          }),
        }),
        {}
      ),

    // Dataviewer
    dataviewer: wrap({
      asyncComponent: requirePermissions(
        () => import('../pages/DataViewer.svelte'),
        permission.viewDatapoint
      ),
      userData: {
        layout: DefaultLayout,
      },
    }),

    // Dashboard
    ...showRouteIf(features.dashboard, dashboardRoutes),

    // Customizable lists
    ...showRouteIf(features.customLists, {
      'list/:id?': wrap({
        asyncComponent: requirePermissions(
          () => import('../pages/entityList/CustomizableLists.svelte')
        ),
      }),
    }),

    // Entity Lists
    ...entityLists,

    // Settings
    ...settingsRoutes,

    // Dev Routes
    ...showRouteIf(features.dev, devRoutes),

    // Homepage
    ...homepages,

    // Base Routes
    ...baseRoutes,
  })
}
