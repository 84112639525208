<!-- JsonDisplay Component -->
<script lang="ts">
  import { camel2title, getDisplayValue, isSet } from '@packages/util'

  export let value: any
  export let _depth = 0

  $: entries = Object.entries(
    value !== null && typeof value == 'object' ? value : {}
  )

  function getDisplayKey(key: any) {
    if (Array.isArray(value)) return '-'
    else return `${camel2title(key)}:`
  }
</script>

<!-- NOTE: Keep the formatting like this to reduce unnecessary spaces at the start -->
<ul class="jsonDisplay">
  {#each entries as [key, value] (key)}<li>
      {@html '&nbsp;'.repeat(_depth * 4)}{#if typeof value != 'object'}<span
          class="key">{getDisplayKey(key)}</span
        >
        <span class="value">{getDisplayValue(value)}</span>{:else}<span
          class="key">{getDisplayKey(key)}</span
        ><svelte:self {value} _depth={_depth + 1} />{/if}
    </li>{/each}
</ul>

<style lang="scss">
  .jsonDisplay {
    display: contents;

    .key {
      font-weight: 500;
    }
  }

  // Remove ul styling
  ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
  }
</style>
