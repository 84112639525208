import * as Generated from '@generated'
import {
  camel2title,
  capitalize,
  getBuild,
  isSet,
  single,
} from '@packages/util'
import type { ObjectValues } from '../packages/util/lib/UtilityTypes'
import { API } from './ApiHandler'

export const symbolParsedError = Symbol('ParsedError')
export const symbolIntermediateFetchError = Symbol('IntermediateFetchError')
export const symbolIntermediateXMLHttpError = Symbol('IntermediateXMLHttpError')

export type ErrorType =
  | 'message'
  | 'database'
  | 'syntax'
  | 'http'
  | 'undefined'
  | 'string'
  | 'validation'
  | 'import'
  | 'authentication'
  | 'unknown'
  | 'unspecified'

export interface ParsedError {
  $__type: typeof symbolParsedError
  /** Should the error be ignored? */
  ignore?: boolean
  /** Title of the error */
  title: string
  /** Description of the error */
  description: string
  /** Error type */
  type: ErrorType
  /** Error type description */
  typeDescription: string
  /** Error code */
  code: HttpErrorCode
  /** Current page URL */
  url: string
  /** Original Error */
  data: any
  /** Custom trace */
  trace: TraceBack
  /** Error stack trace */
  stackTrace: string[]
  /** Build string */
  buildVersion: string

  toString: typeof Object.prototype.toString
}

export interface IntermediateFetchError {
  $__type: typeof symbolIntermediateFetchError
  type: 'fetch' | 'parse' | 'code'
  /** Should the error be ignored? */
  ignore?: boolean
  /** Fetch Response */
  response?: Response
  /** Fetch Data */
  data?: any
  /** Fetch Error */
  error: any
}

export interface IntermediateXMLHttpError {
  $__type: typeof symbolIntermediateXMLHttpError
  type: 'xmlHttp'
  /** Should the error be ignored? */
  ignore?: boolean
  /** XMLHttpRequest Response */
  response?: XMLHttpRequest
  /** XMLHttpRequest Data */
  data?: any
  /** XMLHttpRequest Error */
  error: any
}

export type TraceBack = (string | Record<any, any>)[]

// prettier-ignore
export const httpErrorCode = {
  unknown                       : 0,
  ok                            : 200,
  created                       : 201,
  accepted                      : 202,
  nonAuthoritativeInformation   : 203,
  noContent                     : 204,
  resetContent                  : 205,
  partialContent                : 206,
  multiStatus                   : 207,
  alreadyReportedWebDav         : 208,
  imUsed                        : 226,
  multipleChoices               : 300,
  movedPermanently              : 301,
  found                         : 302,
  seeOther                      : 303,
  notModified                   : 304,
  useProxy                      : 305,
  temporaryRedirect             : 307,
  permanentRedirect             : 308,
  badRequest                    : 400,
  unauthorized                  : 401,
  paymentRequired               : 402,
  forbidden                     : 403,
  notFound                      : 404,
  methodNotAllowed              : 405,
  notAcceptable                 : 406,
  proxyAuthenticationRequired   : 407,
  requestTimeout                : 408,
  conflict                      : 409,
  gone                          : 410,
  lengthRequired                : 411,
  preconditionFailed            : 412,
  requestEntityTooLarge         : 413,
  requestUriTooLong             : 414,
  unsupportedMediaType          : 415,
  requestedRangeNotSatisfiable  : 416,
  expectationFailed             : 417,
  iMATeapot                     : 418,
  enhanceYourCalm               : 420,
  misdirectedRequest            : 421,
  unprocessableEntity           : 422,
  locked                        : 423,
  failedDependency              : 424,
  tooEarly                      : 425,
  upgradeRequired               : 426,
  preconditionRequired          : 428,
  tooManyRequests               : 429,
  requestHeaderFieldsTooLarge   : 431,
  noResponse                    : 444,
  retryWith                     : 449,
  blockedByWindowsParentalCont  : 450,
  unavailableForLegalReasons    : 451,
  httpRequestSentToHttpsPort    : 497,
  tokenExpiredInvalid           : 498,
  clientClosedRequest           : 499,
  internalServerError           : 500,
  notImplemented                : 501,
  badGateway                    : 502,
  serviceUnavailable            : 503,
  gatewayTimeout                : 504,
  httpVersionNotSupported       : 505,
  variantAlsoNegotiates         : 506,
  insufficientStorage           : 507,
  loopDetected                  : 508,
  bandwidthLimitExceeded        : 509,
  notExtended                   : 510,
  networkAuthenticationRequire  : 511,
  webServerIsDown               : 521,
  originIsUnreachable           : 523,
  sslHandshakeFailed            : 525,
  networkReadTimeoutError       : 598,
  networkConnectTimeoutError    : 599,
} as const

export type HttpErrorCode = ObjectValues<typeof httpErrorCode>

type ErrorHandlerFuncBase<T> = (
  error: T,
  trace?: TraceBack
) => Nullable<Partial<ParsedError>>

interface ErrorHandlerItem<T extends ErrorHandlerFuncBase<any>> {
  description: string
  errorHandlerFunc: T
}

type ErrorHandlerMap<T extends ErrorHandlerFuncBase<any>> = Map<
  number,
  ErrorHandlerItem<T>
>

/**
 * Helps turning errors into a human readable format by wrapping it in a ParsedError.
 * Returning nothing will pass the error to the next handler
 */
type ErrorHandlerFunc = ErrorHandlerFuncBase<any>

/** Same as `ErrorHandlerFunc`, but specifically for Fetch errors */
type FetchErrorHandlerFunc = ErrorHandlerFuncBase<IntermediateFetchError>

/** Same as `ErrorHandlerFunc`, but specifically for XMLHttp errors */
type XMLHttpErrorHandlerFunc = ErrorHandlerFuncBase<IntermediateXMLHttpError>

/** Same as `ErrorHandlerFunc`, but specifically for Fetch AND XMLHttp errors */
type RequestErrorHandlerFunc = ErrorHandlerFuncBase<
  IntermediateXMLHttpError | IntermediateFetchError
>

const errorHandlerMap: ErrorHandlerMap<ErrorHandlerFunc> = new Map()
const fetchErrorHandlerMap: ErrorHandlerMap<FetchErrorHandlerFunc> = new Map()
const xmlHttpErrorHandlerMap: ErrorHandlerMap<XMLHttpErrorHandlerFunc> =
  new Map()
const requestHandlerMap: ErrorHandlerMap<RequestErrorHandlerFunc> = new Map()

// Internal counter to keep the handler map in the correct order
let _counter = 0

// Internal flag to only initialize the request handlers once
let _initialized = false

function register<T extends ErrorHandlerFuncBase<any>>(
  map: ErrorHandlerMap<T>,
  description: string,
  errorHandlerFunc: T
) {
  map.set(++_counter, { description, errorHandlerFunc })
}

export function wrapFetchError(
  data: Omit<IntermediateFetchError, '$__type'>
): IntermediateFetchError {
  return {
    $__type: symbolIntermediateFetchError,
    ...data,
  }
}

export function wrapXMLHttpError(
  data: Omit<IntermediateXMLHttpError, '$__type'>
): IntermediateXMLHttpError {
  return {
    $__type: symbolIntermediateXMLHttpError,
    ...data,
  }
}

function isParsedError(error: any): error is ParsedError {
  return isSet(error.$__type) && error.$__type == symbolParsedError
}

function isFetchError(error: any): error is IntermediateFetchError {
  return isSet(error.$__type) && error.$__type == symbolIntermediateFetchError
}

function isXmlHttpError(error: any): error is IntermediateXMLHttpError {
  return isSet(error.$__type) && error.$__type == symbolIntermediateXMLHttpError
}

function _isXMLHttpRequest(value: any): value is XMLHttpRequest {
  return (
    value instanceof XMLHttpRequest ||
    value.constructor.name == 'XMLHttpRequest'
  )
}

/**
 * This function will parse the given error
 * (Against a list of `ErrorHandlerFunc`tions registered into the `errorHandlerMap`)
 * and returns a Parsed Error object.
 */
export function parseError(error: any, trace?: TraceBack): ParsedError {
  if (!_initialized) {
    initErrorHandler()
    initRequestErrorHandler()
  }

  // Found result
  let result: Nullable<Partial<ParsedError>> = null
  let resultDescription: Nullable<string> = null

  const _isFetchError = isFetchError(error)
  const _isXMLHttpError = isXmlHttpError(error)

  let _handlerMapArray: [number, ErrorHandlerItem<any>][]

  // Select the correct map
  switch (true) {
    case _isFetchError:
      _handlerMapArray = [
        ...Array.from(requestHandlerMap),
        ...Array.from(fetchErrorHandlerMap),
      ]
      break
    case _isXMLHttpError:
      _handlerMapArray = [
        ...Array.from(requestHandlerMap),
        ...Array.from(xmlHttpErrorHandlerMap),
      ]
      break
    default:
      _handlerMapArray = Array.from(errorHandlerMap)
      break
  }

  // Find and execute the handler function
  _handlerMapArray
    // Sort the error map by index
    .sort(([indexA, _A], [indexB, _B]) => indexA - indexB)
    // Get the first result
    .find(([_, { description, errorHandlerFunc }]) => {
      const _result = errorHandlerFunc(error, trace ?? [])
      if (isSet(_result)) {
        // Set the found result
        result = _result
        resultDescription = description
        return true
      } else {
        return false
      }
    })

  // Construct and return the parsed error
  return {
    ...errorHandlerBASE(error, trace ?? []),
    ...(isSet(result) ? result : {}),
    ...(_isFetchError || _isXMLHttpError
      ? {
          // Pass original ignore state
          ignore: error?.ignore || result?.ignore,
        }
      : {}),
    typeDescription: resultDescription,
    toString() {
      return `${result?.title ?? 'Error'}: ${
        result?.description ?? 'Undefined Error'
      }`
    },
  }
}

//#region Error handlers
const errorHandlerBASE = (error: any, trace?: TraceBack): ParsedError => {
  return {
    $__type: symbolParsedError,
    title: 'Error',
    code: 0,
    url: window?.location?.href ?? undefined,
    type: 'unspecified',
    typeDescription: 'Unspecified',
    description: 'An unknown error has occurred',
    data: error,
    trace,
    stackTrace:
      isSet(error.stack) && typeof error.stack == 'string'
        ? error.stack.split('\n')
        : error.stack,
    buildVersion: getBuild(),
  }
}

function _validationErrorResponse(error: any): Partial<ParsedError> {
  const firstItem = Array.isArray(error) ? single(error) : null
  const hasMoreThanOne = Array.isArray(error) ? error.length > 1 : false
  let message = 'There are validation errors'

  if (firstItem) {
    message = `On field "${firstItem.field}": ${firstItem.message}${
      hasMoreThanOne ? ` (and ${error.length - 1} more)` : ''
    }`
  }

  return {
    description: message,
    type: 'validation',
    code: 0,
    url: window?.location?.href ?? undefined,
    data: error,
  }
}
//#endregion Error handlers

/**
 * Initialize the list of error handlers.
 * NOTE: The order of registering error handlers is important!
 */
function initErrorHandler() {
  // Parsed Error
  register(errorHandlerMap, 'Pre-Parsed', (error) => {
    if (isSet(error.$__type) && error.$__type == symbolParsedError) return error
  })

  // Null Error
  register(errorHandlerMap, 'Generic Null', (error) => {
    if (!isSet(error))
      return {
        description: 'An undefined error has occurred',
        type: 'undefined',
      }
  })

  // String Error
  register(errorHandlerMap, 'Generic String', (error) => {
    if (typeof error == 'string')
      return {
        description: error,
        type: 'string',
      }
  })

  // Non-object Error
  register(errorHandlerMap, 'Generic Unknown Type', (error) => {
    if (typeof error != 'object')
      return {
        description: 'An unknown error has occurred',
        type: 'unknown',
      }
  })

  // Syntax error
  register(errorHandlerMap, 'Syntax', (error) => {
    if (error instanceof SyntaxError) {
      let message = error.message
      return {
        title: capitalize(error.name ?? 'Error'),
        description: message || 'An unknown syntax error has occurred',
        type: 'syntax',
      }
    }
  })

  // Database error
  register(errorHandlerMap, 'Database', (error) => {
    if ((<string>error.name)?.toLowerCase().includes('database')) {
      console.error(error)
      return {
        title: capitalize(error.name ?? 'Error'),
        description:
          'There was a problem while loading/saving data in the backend',
        type: 'database',
        code: parseInt(error.status ?? error.code ?? 0) as HttpErrorCode,
      }
    }
  })

  // Error object with a message (and possibly a title)
  register(errorHandlerMap, 'Generic Object', (error) => {
    if (error.message || error.description) {
      return {
        title: capitalize(error.title ?? error.name ?? 'Error'),
        description: error.message ?? error.description,
        type: 'message',
        code: parseInt(error.status ?? error.code ?? 0) as HttpErrorCode,
      }
    }
  })
}

/**
 * Initialize the list of `fetch` error handlers.
 * NOTE: The order of registering error handlers is important!
 */
function initRequestErrorHandler() {
  // Abort Error
  register(fetchErrorHandlerMap, 'Aborted', (_error) => {
    const error = _error.error
    if (error?.name == 'AbortError') {
      const isGenericError =
        error?.message == 'signal is aborted without reason'
      return {
        ignore: isGenericError,
        title: 'Action Aborted',
        description: isGenericError ? 'Action Aborted' : error?.message,
        type: 'http',
      }
    }
  })

  // Authentication error (Not logged in)
  register(requestHandlerMap, 'Not Logged In', (_error) => {
    const error = _error.error
    if (
      !!['Login Required', 'Not currently logged in'].find((text) =>
        String(error?.message).includes(text)
      )
    ) {
      // Redirect to the login screen
      API.logout()
      return {
        ignore: true,
        description: 'Please log in to continue',
        type: 'authentication',
      }
    }
  })

  // Syntax error
  register(requestHandlerMap, 'Syntax', (_error) => {
    const error = _error.error

    if (error instanceof SyntaxError) {
      let message = error.message

      if (message.toLowerCase().includes('xdebug')) {
        message = 'Backend debugger is enabled but failed to load'
      }

      return {
        title: capitalize(error.name ?? 'Error'),
        description: message || 'An unknown syntax error has occurred',
        type: 'syntax',
      }
    }
  })

  // New style Validation Error(s)
  register(requestHandlerMap, 'Validation (v2)', (_error) => {
    const errorData = _error?.data

    // Skip if it isn't a proper `ValidationException`
    if (
      _error.response?.status != httpErrorCode.unprocessableEntity ||
      !isSet(errorData) ||
      _error.data?.name != 'ValidationException'
    ) {
      return
    }

    const validationErrors = errorData.data as Record<string, string[]>

    // Skip if it isn't a proper object
    if (typeof validationErrors != 'object') return

    // Get first field and message
    const firstField = single(Object.keys(validationErrors))
    const firstError = single(validationErrors[firstField ?? '--'])

    // It's safe to assume that no field or no error means that it can be skipped
    if (!isSet(firstError) || !isSet(firstField)) return

    // Count the number of errors
    const count = Object.values(validationErrors).reduce(
      (currentCount, items) => currentCount + items.length,
      0
    )

    // Convert to the old format (for now)
    // TODO: Use new format in the future! (with the converter removed)
    const oldFormat = Object.entries(validationErrors).reduce<
      Generated.ValidationError[]
    >(
      (curr, [field, errors]) => [
        ...curr,
        ...errors.map<Generated.ValidationError>((message) => ({
          field,
          message,
        })),
      ],
      []
    )

    // Return the error data
    return {
      description: `On field "${firstField}": ${firstError}${
        count > 1 ? ` (and ${count - 1} more)` : ''
      }`,
      type: 'validation',
      code: _error.response?.status,
      url: window?.location?.href ?? undefined,
      data: oldFormat,
    }
  })

  // Old style Validation Error(s)
  register(requestHandlerMap, 'Validation (v1)', (_error) => {
    if (_error.response?.status != httpErrorCode.unprocessableEntity) return

    const error = _error?.data
    // Validation Error (Single)
    if (error?.message && error?.field) {
      return _validationErrorResponse([error])
    }

    // Validation Errors (Array)
    if (
      Array.isArray(error) &&
      error.length &&
      single(error).message &&
      single(error).field
    ) {
      return _validationErrorResponse(error)
    }
  })

  // Integrity constraint violation
  register(requestHandlerMap, 'Integrity constraint violation', (_error) => {
    const errorName = _error?.data?.name

    if (errorName == 'Integrity constraint violation') {
      return {
        type: 'database',
        title: errorName,
        description: `Tried to update or delete an item that other entities depend on`,
        code: (_error?.response?.status ?? 0) as HttpErrorCode,
      }
    }
  })

  // Final fetch response error (for when everything else fails, must be last)
  register(requestHandlerMap, 'Catastrophic', (_error) => {
    const response = _error.response

    // For extra safety, check if the response is really a `Response` or `XMLHttpRequest`
    if (!(response instanceof Response || _isXMLHttpRequest(response))) {
      // This point shouldn't be reachable
      return {
        type: 'unknown',
        title: 'Catastrophic failure',
        description: 'Unable to parse the error.',
        code: 0,
      }
    }

    // Construct a description
    const description =
      `Status code ${response?.status ?? '---'}: ` +
      String(
        response?.statusText ||
          _error.data?.message ||
          camel2title(
            Object.entries(httpErrorCode).find(
              ([_, code]) => code == response?.status
            )?.[0] ?? ''
          ) ||
          'Unknown'
      )

    // Return the parsed error
    return {
      type: 'http',
      title: response?.statusText || _error.data?.name || 'Error',
      description,
      code: (response?.status ?? 0) as HttpErrorCode,
    }
  })
}
