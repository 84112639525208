import { displayError } from '@components/Global/StatusMessage.svelte'
import * as Generated from '@generated'
import type { WhoAmIResponse, WhoAmIResponseUser } from '@models/api/ApiModels'
import { baseApi } from '@models/api/BaseApi'
import { encodePHPQueryParams, useSessionStorage } from '@packages/util'
import { get, writable, type Writable } from 'svelte/store'
import { request } from './ApiUtil'
import { permission, type Permission } from './Permissions'

export const sessionStorageUser = 'user'
export const sessionStorageAccessToken = 'accessToken'

export const apiAdminUsername = 'admin'
export const apiAdminRolename = 'Administrators'
export const apiDefaultRolename = 'Default'
export const apiInstallationRolename = 'Installation'

export const apiRoleSet = {
  historyManagement: [apiAdminRolename],
  createEndpoint: [permission.createEndpoint],
  getEndpoint: [permission.getEndpoint],
  renewEndpointToken: [permission.renewEndpointToken],
  revokeEndpoint: [permission.renewEndpointToken],
  dashboardViewing: [
    baseApi.dashboard.permissions.view,
    baseApi.dashboardWidget.permissions.view,
  ],
  dashboardEditing: [
    ...baseApi.dashboard.permissions.all,
    ...baseApi.dashboardWidget.permissions.all,
  ],
  dashboardDeleting: [
    ...baseApi.dashboard.permissions.all,
    ...baseApi.dashboardWidget.permissions.all,
  ],
} satisfies Record<string, Permission[]>

export default class ApiHandler {
  baseURL: string
  accessToken: string

  /**
   * Used to prevent error spam. Set the timout and clear it in its own function
   */
  errorTimout: NodeJS.Timeout

  /**
   * Used for login polling
   */
  loginTimer: NodeJS.Timer

  /**
   * Polling rate in milliseconds
   */
  loginPollingRate = 2 * 60 * 1000 // 2 minutes

  constructor(baseURL?: string) {
    this.baseURL = baseURL
    this.accessToken = sessionStorage.getItem(sessionStorageAccessToken)
  }

  /**
   * Open a API endpoint directly, used for downloading files.
   * Always includes `baseUrl`
   */
  openDirectly(
    url: string | string[],
    queryParams?: string | Record<any, any>
  ) {
    if (Array.isArray(url)) url = url.join('/')
    if (typeof queryParams == 'object')
      queryParams = encodePHPQueryParams(queryParams)

    window
      .open(
        `${this.baseURL}/${url.replace(/^\/|\/$/, '')}?${queryParams ?? ''}`
      )
      .focus()
  }

  /**
   * Clear access tokens and return to the login page
   * @param quick setting this to true will skip the backend logout function
   */
  async logout(quick = false) {
    this.detachLoginPoller()

    // Perform the logout
    try {
      await request({
        url: 'logout',
        returnResponse: true,
        displayError: false,
      })
    } catch {}

    // Unset the access token
    sessionStorage.removeItem(sessionStorageAccessToken)
    this.accessToken = null

    // Unset the login data
    sessionStorage.removeItem(sessionStorageUser)
    loginData.set(null)
  }

  /**
   * Log in with the required username and password
   */
  async login(username: string, password: string) {
    // Build Request Options
    const requestOptions: RequestInit = {
      cache: 'no-cache',
      /*redirect: 'manual',*/
      headers: {
        Authorization: `Basic ${window.btoa(username + ':' + password)}`,
      },
    }

    // Perform the login
    const response = await fetch(`${this.baseURL}/login`, requestOptions)

    // Try to parse the response
    let json: WhoAmIResponse

    try {
      json = await response.json()
    } catch {
      throw response
    }

    if (!response.ok) {
      throw json ?? response
    }

    if (json.type != Generated.User_TYPEEnum.operator) {
      throw Error('Current user is not an operator!')
    }

    // Retrieve the needed information
    this.accessToken = String(json.token)

    // Set the session storage
    sessionStorage.setItem(sessionStorageAccessToken, this.accessToken)

    // Save the login data
    sessionStorage.setItem(sessionStorageUser, JSON.stringify(json))
    const { data, message, ...restData } = json as any // TODO: Not needed when the old login data content has been removed
    loginData.set(restData)

    this.attachLoginPoller()

    // Return the parsed response
    return json
  }

  /**
   * Attach a login poller to make sure the user gets automatically logged out
   */
  async attachLoginPoller() {
    const _this = this
    async function check() {
      if (
        !(await (
          await fetch(`${_this.baseURL}/login-status`, {
            cache: 'no-cache',
            /*redirect: 'manual',*/
          })
        ).json())
      ) {
        if (document.location.pathname.includes('login')) {
          // Don't log out if on the login screen
          return
        } else {
          displayError('Please log in to continue.', [
            'ApiHandler',
            'attachLoginPoller',
            'fetch',
          ])
          _this.logout()
          _this.detachLoginPoller()
        }
      }
    }

    check.call(this)
    this.loginTimer = setInterval(() => check.call(this), this.loginPollingRate)
  }

  /**
   * Remove the poller
   */
  detachLoginPoller() {
    this.loginTimer && clearInterval(this.loginTimer as any)
  }

  /**
   * Getter wrapper for the svelte store
   */
  get loginData() {
    return get(loginData)
  }

  /**
   * Checks if the currently logged in user has all given roles and permissions
   * @param roleOrPermissions One or more permissions to check the user for
   * @param hasSome Set to true to check if the user has
   *  at least one of the given list of permissions.
   * (defaults to requiring all given permissions)
   */
  userHas(roleOrPermissions: string | string[], hasSome = false) {
    // Return false if the data is missing
    if (!(this?.loginData?.roles && this?.loginData?.permissions)) return false

    // Check if the user is in the system admin group
    if (this.loginData.roles.includes(apiAdminRolename)) {
      return true
    }

    // Check every given roles and permissions
    roleOrPermissions = Array.from(
      typeof roleOrPermissions == 'string'
        ? [roleOrPermissions]
        : roleOrPermissions
    )

    if (hasSome) {
      return roleOrPermissions.some(
        (item) =>
          this.loginData.permissions.includes(item) ||
          this.loginData.roles.includes(item)
      )
    } else {
      return roleOrPermissions.every(
        (item) =>
          this.loginData.permissions.includes(item) ||
          this.loginData.roles.includes(item)
      )
    }
  }

  /**
   * Perform a check if the user is logged in
   */
  async isUserLoggedIn() {
    try {
      return await (
        await fetch(`${this.baseURL}/login-status`, {
          cache: 'no-cache',
          /*redirect: 'manual',*/
        })
      ).json()
    } catch (error) {
      return false
    }
  }

  async updateLoginData() {
    // '/' is the route to fetch all current user's roles and permissions
    request<WhoAmIResponse>({ url: 'whoami', displayError: false })
      .then((responseData) => {
        if (responseData.type != Generated.User_TYPEEnum.operator)
          throw Error('Current user is not an operator!')

        loginData.update((data) => {
          // Patch in the new rbac
          const newData = {
            ...data,
            ...responseData,
          }

          // Update the session storage
          sessionStorage.setItem(sessionStorageUser, JSON.stringify(newData))

          return newData
        })
      })
      .catch(() => {
        /** Ignore Error */
      })
  }
}

export const API = new ApiHandler() // Backend url will be retrieved from the config file within ./public

export let loginData: Writable<WhoAmIResponseUser | null> = writable(
  JSON.parse(sessionStorage.getItem(sessionStorageUser))
)

// TODO: Temporary solution
export const enableHardDelete = useSessionStorage('enableHardDelete')
export const enableShowSoftDeletedItems = useSessionStorage(
  'enableShowSoftDeletedItems'
)
