import type { ILazyConfig, ILazyDataProxy } from '@dhtmlx/ts-data'
import type ApiBaseModel from '@models/api/ApiBaseModel'
import type { BaseModelResponse } from '@models/api/ApiBaseModel'
import { buildQueryParams, type PageQueryParams } from '@models/api/BaseModel'
import { request } from './ApiUtil'
import { debug, flatten } from '@packages/util'

export interface ILazyDataProxyResponse<T extends Record<string, any>> {
  data: T[]
  total_count: number
  from: number
}

// Map ILazyDataProxy with the correct load type
export interface IApiLazyDataProxy<T> extends Omit<ILazyDataProxy, 'load'> {
  load: () => Promise<ILazyDataProxyResponse<T>>
  abortAll(): void
}

export type IApiExtraParams<T extends ApiBaseModel> = Omit<
  PageQueryParams<T>,
  'limit' | 'page'
>

export type ApiPostProcessor<T extends ApiBaseModel> = (
  data: BaseModelResponse<T>
) => Promise<BaseModelResponse<T>>

export interface IApiLazyConfig<T extends ApiBaseModel> extends ILazyConfig {
  extraParams?: IApiExtraParams<T>
  postprocess?: ApiPostProcessor<T>
}

export class ApiLazyDataProxy<T extends ApiBaseModel>
  implements IApiLazyDataProxy<T>
{
  // The stored Url (Don't set it manually as it will be overwritten)
  url: string

  // The pagination/query param config(?)
  config: IApiLazyConfig<T>

  private _abortControllers: Map<string, AbortController>
  private _timeout: NodeJS.Timeout
  private _cooling: boolean
  private _endpoint: string

  constructor(endpoint: string, params?: IApiLazyConfig<T>) {
    this.config = {
      delay: 150,
      from: 0,
      limit: 20,
      prepare: 0,
      extraParams: {},
    }
    this.config = Object.assign(this.config, params ?? {})
    this._abortControllers = new Map()
    this._endpoint = endpoint
  }

  // This gets called before the data gets loaded. it sets the pagination parameters
  updateUrl(url: string, params: IApiLazyConfig<T>) {
    // Given url gets ignored, it will be built from the params
    this.config = Object.assign(this.config, params)
  }

  // The load function with a cooldown, see `_fetch` for the actual data acquisition logic
  load(): Promise<ILazyDataProxyResponse<T>> {
    return new Promise((resolve, reject) => {
      if (!this._timeout) {
        this._fetch().then(resolve).catch(reject)
        this._cooling = true
        this._timeout = setTimeout(() => {
          return
        })
      } else {
        clearTimeout(this._timeout)

        this._timeout = setTimeout(() => {
          this._fetch().then(resolve).catch(reject)
          this._cooling = true
        }, this.config.delay)

        if (this._cooling) {
          resolve(null)
          this._cooling = false
        }
      }
    })
  }

  // This (might be needed to) save the data
  async save(data: any, mode: any) {
    debug('Saving', data, mode)
    throw new Error('Saving not implemented')
  }

  // This actually loads the data. It should look the current url
  private _fetch() {
    const abortController = new AbortController()
    const id = `${Math.random().toString(16).slice(2)}`
    this._abortControllers.set(id, abortController)

    return new Promise<ILazyDataProxyResponse<T>>(async (res, rej) => {
      request<BaseModelResponse<T>>({
        url: [this._endpoint],
        signal: abortController.signal,
        body: buildQueryParams(this.getQueryParams()),
      })
        .then(async (data) => {
          const out = {
            // Automatically flatten the result
            data: (this.config?.postprocess
              ? await this.config.postprocess(data)
              : data
            ).results.map((item) => flatten(item, '.')),
            from: (data._meta.currentPage - 1) * data._meta.perPage,
            total_count: data._meta.totalCount,
          }

          res(out as any)
        })
        .catch(rej)
        .finally(() => {
          this._abortControllers.delete(id)
        })
    })
  }

  updateExtraParams(extraParams: IApiLazyConfig<T>['extraParams']) {
    this.config.extraParams = Object.assign(
      this.config.extraParams,
      extraParams
    )
  }

  /**
   * Get the full object of query params that will get sent to the API
   * (Includes extra params, requires compiling to a compatible format using `buildQueryParams()`)
   */
  getQueryParams(): PageQueryParams<T> {
    return {
      limit: this.config.limit,
      page: Math.round(this.config.from / this.config.limit) + 1,
      ...this.config.extraParams,
    }
  }

  abortAll() {
    this._abortControllers.forEach((abortController) =>
      abortController.abort('The user aborted a request')
    )
    this._abortControllers.clear()
  }

  clear() {
    this._timeout = undefined
    this._cooling = undefined
  }
}
