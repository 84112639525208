/* Utilities for Objects and Arrays */

import { isSet, unique } from '@packages/util'

/**
 * Filter out keys in an object based on the filter result
 */
export function filterObject<T extends Record<any, any>>(
  obj: T,
  filter: <Key extends keyof T>(key: Key, value: T[Key]) => boolean
): Partial<T> {
  let output = {} as T

  for (const key in obj) {
    if (filter(key, obj[key])) {
      output[key] = obj[key]
    }
  }

  return output
}

/**
 * Flatten an object from
 * ```ts
 *  {
 *    a: "value",
 *    b: 2,
 *    c: {
 *      d: 0,
 *      e: {
 *        f: "value"
 *      }
 *    }
 *  }
 * ```
 * To
 * ```ts
 *  {
 *    "a": "value",
 *    "b": 2,
 *    "c/d": 0,
 *    "c/e/f": "value"
 *  }
 * ```
 *
 * @param obj The object to flatten
 * @param parent Parent property name, Only used for Recursive operation
 * @returns
 */
export function flatten(
  obj: Record<string, any>,
  separator = '/',
  parent?: string
) {
  let output: Record<string, any> = {}

  for (const prop in obj) {
    const propName = `${parent ?? ''}${parent ? separator : ''}${prop}`

    // TODO: Flatten arrays correctly
    if (typeof obj[prop] == 'object')
      output = { ...output, ...flatten(obj[prop], separator, propName) }
    else output[propName] = obj[prop]
  }

  return output
}

/**
 * Set a value deep inside the object
 * @param obj The object to modify
 * @param path The path, seperated by `/`
 * @param value The given value
 */
export function deepSet(
  obj: Record<string, any>,
  path: string,
  value: any,
  separator = '/'
) {
  let _path = path.split(separator)
  let _obj = obj
  let _loopLimit = 32

  // TODO: Don't use while loop
  while (_path.length - 1 || !_loopLimit--) {
    let _slicedPath = _path.shift()
    if (!_slicedPath) continue
    if (!(_slicedPath in _obj)) _obj[_slicedPath] = {}
    _obj = _obj[_slicedPath]
  }

  // Protection against infinite loops
  if (_loopLimit <= 0) throw new Error('Depth limit reached!')

  _obj[_path[0]] = value
}

/**
 * Unset a value deep inside the object
 * @param obj The object to modify
 * @param path The path, seperated by `/`
 */
export function deepUnset(
  obj: Record<string, any>,
  path: string,
  separator = '/'
) {
  let _path = path.split(separator)
  let _obj = obj
  let _loopLimit = 32

  // TODO: Don't use while loop
  while (_path.length - 1 || !_loopLimit--) {
    let _slicedPath = _path.shift()
    if (!_slicedPath) continue
    if (!(_slicedPath in _obj)) _obj[_slicedPath] = {}
    _obj = _obj[_slicedPath]
  }

  // Protection against infinite loops
  if (_loopLimit <= 0) throw new Error('Depth limit reached!')

  delete _obj[_path[0]]
}

/**
 * Unflatten an object from
 * ```ts
 *  {
 *    "a": "value",
 *    "b": 2,
 *    "c/d": 0,
 *    "c/e/f": "value"
 *  }
 * ```
 * To
 * ```ts
 *  {
 *    a: "value",
 *    b: 2,
 *    c: {
 *      d: 0,
 *      e: {
 *        f: "value"
 *      }
 *    }
 *  }
 * ```
 *
 * @param obj The object to unflatten
 * @returns
 */
export function unflatten(obj: Record<string, any>, separator = '/') {
  let output: Record<string, any> = {}

  for (const prop in obj) {
    if (!prop.includes(separator)) {
      output[prop] = obj[prop]
      continue
    }

    deepSet(output, prop, obj[prop], separator)
  }

  return output
}

/**
 * Returns a copy of the object.
 *
 * @param object  Source object
 * @returns Copy of the object
 */
export function copy<Object>(object: Object): Object {
  return Object.assign({}, object)
}

/**
 * Merges two objects and returns a new object that contains properties from
 * both source objects.
 *
 * @param object1  Source object #1
 * @param object2  Source object #2
 * @returns Combined object
 */
export function merge<Object1, Object2>(
  object1: Object1,
  object2: Object2
): Object1 & Object2 {
  return Object.assign({}, object1, object2)
}

/**
 * Returns object clone.
 *
 * @param object  Source object
 * @returns       Clone
 */
export function clone<Object>(object: Object): Object {
  return object ? JSON.parse(JSON.stringify(object)) : null
}

/** Ensure the input value will be returned as an array */
export function ensureArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value]
}

/** Get the first Key Value pair from an object */
export function getFirstKeyValue<T>(
  obj: Record<string, T>
): [key: Nullable<string>, value: Nullable<T>] {
  return single(Object.entries(obj ?? {})) ?? [null, null]
}

/**
 * Always ensure the return value is a single item in an arrayable value
 */
export function single<T>(value: T | T[] | Record<any, T>): Nullable<T> {
  return typeof value == 'object' && value !== null && value !== undefined
    ? Object.values(value).find(
        (value) => value !== null || value !== undefined
      )
    : value
}

/**
 * Split the array into two based on the result of the filter function
 *
 * @param array The source array
 * @param filter The filter function.
 *  Return `null` to ignore
 *  Return `false` to put it in the first array
 *  Return `true` to put it in the second array
 * @returns A tuple of two arrays
 */
export function split<T>(
  array: T[],
  filter: (item: T) => boolean | null
): [T[], T[]] {
  let returnArrayFirst: T[] = []
  let returnArraySecond: T[] = []

  array.forEach((item) => {
    const result = filter(item)

    if (result === null) return
    if (result === false) returnArrayFirst.push(item)
    if (result === true) returnArraySecond.push(item)
  })

  return [returnArrayFirst, returnArraySecond]
}

/**
 * Compares two objects and check if there are any changes
 */
export function hasObjectChanged<T extends Record<any, any>>(
  objA: T,
  objB: T,
  ignoreKeys?: (keyof T)[]
): boolean {
  return !!unique(Object.keys(objA).concat(Object.keys(objB)))
    .filter((key) => !ignoreKeys?.find((_key) => _key == key) ?? true)
    .find((key) => objA[key] != objB[key])
}

/**
 * Compares two objects and return a list of changes
 */
export function diffObject<
  TA extends Record<any, any>,
  TB extends Record<any, any>,
>(
  valuesA: TA,
  valuesB: TB,
  /**
   * Return must be comparable!
   * - if `["one", null ]`: Removed
   * - if `[null , "one"]`: Added
   * - if `["one", "two"]`: Changed
   * - if `["one", "one"]`: Unchanged
   * - if `[null , null ]`: (Ignored)
   */
  cmpFunc: (a: TA[keyof TA], b: TB[keyof TB]) => [any, any] = (a, b) => [a, b]
): {
  added: (keyof (TA & TB))[]
  removed: (keyof (TA & TB))[]
  changed: (keyof (TA & TB))[]
  unchanged: (keyof (TA & TB))[]
} {
  let output = {
    added: new Array<keyof (TA & TB)>(),
    removed: new Array<keyof (TA & TB)>(),
    changed: new Array<keyof (TA & TB)>(),
    unchanged: new Array<keyof (TA & TB)>(),
  }

  const zippedKeys = new Set([...Object.keys(valuesA), ...Object.keys(valuesB)])

  const isSet = (value: any) => value !== null && value !== undefined

  zippedKeys.forEach((key) => {
    const result = cmpFunc(valuesA[key], valuesB[key])

    // null to null
    if (!isSet(result[0]) && !isSet(result[1])) {
      // Ignore
      return
    }

    // null to value
    if (!isSet(result[0]) && isSet(result[1])) {
      output.added.push(key)
      return
    }

    // value to null
    if (isSet(result[0]) && !isSet(result[1])) {
      output.removed.push(key)
      return
    }

    // "one" to "one"
    if (result[0] == result[1]) {
      output.unchanged.push(key)
      return
    }

    // "one" to "two"
    if (result[0] != result[1]) {
      output.changed.push(key)
      return
    }
  })

  return output
}

/**
 * Check the similarity of two arrays using simple equality operations on each items.
 * Returns null if neither inputs are arrays.
 */
export function isArraySimilar<T>(
  arrayA: T[],
  arrayB: T[],
  checkFn?: (a: T, b: T) => boolean
): boolean | null {
  // Return null if neither values are arrays
  if (!(Array.isArray(arrayA) && Array.isArray(arrayB))) return null
  // Return true if both arrays are empty
  if (!isSet(arrayB) && !isSet(arrayA)) return true
  // Return false if the sizes don't match
  if (arrayA.length != arrayB.length) return false
  // Return true if all the items are the exactly the same
  return arrayA.every((item, index) =>
    (checkFn ?? ((a, b) => a === b))(item, arrayB[index])
  )
}

/**
 * Check the similarity of two objects using (shallow!) equation
 */
export function isObjectSimilar<T>(objectA: T, objectB: T): boolean {
  const keys1 = Object.keys(objectA)
  const keys2 = Object.keys(objectB)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (objectA[key] !== objectB[key]) {
      return false
    }
  }

  return true
}

/**
 * Only return all `values` when `state` is `true`
 */
export function conditional<T = any>(state: boolean, ...values: T[]): T[] {
  return state ? values : []
}

/**
 * Only return all `values` when `state` is `true`
 */
export function conditionalObj<T extends Record<any, any> = any>(
  state: boolean,
  value: T
): T | {} {
  return state ? value : {}
}

/**
 * Pad the array with extra items
 */
export function padArray<T>(
  array: T[],
  length: number,
  withValue?: T | ((index: number) => T)
) {
  array = Array.isArray(array) ? array : []
  return [
    ...array,
    ...Array.from({ length: Math.max(length - array.length, 0) }).map(
      (_, index) =>
        withValue instanceof Function
          ? withValue(index + array.length)
          : withValue
    ),
  ]
}
