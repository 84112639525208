<!-- Container Component -->
<script lang="ts">
  import { cssUnit } from '@packages/util'
  import { createEventDispatcher, onMount } from 'svelte'

  const dispatch = createEventDispatcher<{
    resize: { width: number; height: number; top: number; left: number }
  }>()

  let contentRect: Nullable<DOMRectReadOnly>
  let resizeWatcherElement: Nullable<HTMLElement>

  export let width = -1 // Read-Only
  export let height = -1 // Read-Only
  export let top = -1 // Read-Only
  export let left = -1 // Read-Only
  export let noResize = false
  export let border = false

  export let minHeight: Nullable<string | number> = undefined
  export let minWidth: Nullable<string | number> = undefined
  export let maxHeight: Nullable<string | number> = undefined
  export let maxWidth: Nullable<string | number> = undefined

  $: {
    width = contentRect?.width ?? -1
    height = contentRect?.height ?? -1
    top = resizeWatcherElement?.offsetTop ?? -1
    left = resizeWatcherElement?.offsetLeft ?? -1
    dispatch('resize', { width, height, top, left })
  }

  $: _watcherStyle =
    '' +
    (minHeight ? `min-height:${cssUnit(minHeight) ?? 'inherit'};` : '') +
    (maxHeight ? `max-height:${cssUnit(maxHeight) ?? 'inherit'};` : '') +
    (minWidth ? `min-width:${cssUnit(minWidth) ?? 'inherit'};` : '') +
    (maxWidth ? `max-width:${cssUnit(maxWidth) ?? 'inherit'};` : '')

  onMount(() => {
    width = contentRect?.width ?? -1
    height = contentRect?.height ?? -1
    top = resizeWatcherElement?.offsetTop ?? -1
    left = resizeWatcherElement?.offsetLeft ?? -1
    dispatch('resize', { width, height, top, left })
  })
</script>

<div
  class="resize-watcher"
  bind:this={resizeWatcherElement}
  bind:contentRect
  style={_watcherStyle}
></div>
<div
  class="container-content"
  class:border
  style={!noResize
    ? `height: ${contentRect?.height}px; width: ${contentRect?.width}px; top: ${top}px; left: ${left}px;`
    : ''}
>
  <slot {height} {width} />
</div>

<style lang="scss">
  @use '../../theme/variables' as vars;

  .resize-watcher {
    height: 100%;
    width: 100%;
    pointer-events: none;
  }

  .container-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &.border {
      border: vars.$borderStyle;
    }
  }
</style>
