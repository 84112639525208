<script lang="ts" context="module">
  import type { Calendar, ICalendarConfig } from '@dhtmlx/ts-calendar'
  import { DhxCalendar } from '@lib/dhtmlx'
  import { onDestroy, onMount } from 'svelte'

  export type DateTimeLike =
    | Date
    | Date[]
    | number
    | number[]
    | string
    | string[]

  export interface CalendarEntity {
    dhx: Calendar
  }
  export interface CalendarConfig extends ICalendarConfig {
    id?: string
    value?: DateTimeLike | any
  }
</script>

<script lang="ts">
  export let calendarConfig: CalendarConfig = {}
  export const calendar: CalendarEntity = {
    dhx: undefined,
  }
  export let value: DateTimeLike = new Date()

  /**
   * Validate the value
   * Value has to be parseable to a date object to work with the dhtmlx calendar
   */
  try {
    if (Array.isArray(value)) {
      ;(value as Date[] | Number[]).forEach((_value, i) => {
        if (isNaN(new Date(_value).getTime())) {
          throw Error('value has to be parseable to a valid date')
        }
        value[i] = new Date(_value)
      })
    } else {
      if (isNaN(new Date(value).getTime())) {
        throw Error('value has to be parseable to a valid date')
      }
      value = new Date(value)
    }
  } catch (e) {
    console.warn('Calendar value is not parseable to a date object')
  }

  let defaultConfig: CalendarConfig = {
    id: `calendar-${Math.random().toString(16).slice(2)}`,
    timePicker: true,
    range: false,
    timeFormat: 24,
  }

  calendarConfig = { ...defaultConfig, ...calendarConfig, ...{ value: value } }

  onMount(() => {
    calendar.dhx = new DhxCalendar(calendarConfig.id, calendarConfig)
    setEvents()
  })

  function setEvents(): void {
    calendar.dhx.events.on('change', function (date, oldDate, byClick) {
      // Use get value as the given date is just the date that changed and not the range
      value = calendar.dhx.getValue(true)
    })
  }

  onDestroy(() => {
    calendar.dhx.destructor()
  })
</script>

<div class="calendar" id={calendarConfig?.id}></div>
