<script lang="ts" context="module">
  import ColumnEditor from '@components/Modals/ColumnEditor.svelte'
  import { commonStrings } from '@lib/CommonStrings'
  import {
    baseApi,
    type BaseApiColumnNames,
    type ModelReference,
  } from '@models/api/BaseApi'
  import type { AnyBaseModel } from '@models/api/BaseModel'
  import { camel2title, isSet } from '@packages/util'
  import { onDestroy, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export interface ListColumnsSelectConfig {
    type: ModelReference
  }

  export const _name = 'ListColumnsSelect'
</script>

<script lang="ts">
  export let value: Nullable<BaseApiColumnNames<any>> = null // ID
  export let config: ListColumnsSelectConfig = {
    type: 'asset',
  }
  export let label = 'ComboBox'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _ready = false
  let _entityModel: Nullable<AnyBaseModel>
  let _showColumnEditor = false

  $: _state = state == 'error' && !isSet(value) ? state : null
  $: _displayText = getNames(value)

  function getNames(ids: BaseApiColumnNames<any>) {
    if (!_entityModel) return '---'
    if (!ids || ids?.length <= 0) return '---'
    return ids.map((value) => camel2title(String(value))).join(' | ')
  }

  function getApiModel() {
    const _apiModel = baseApi[config?.type] as AnyBaseModel | null

    if (!_apiModel)
      throw new Error(
        'ListColumnsSelect: apiModel must be set in order for this form item to work.'
      )

    _entityModel = _apiModel
    value ??= _apiModel.context.defaultColumns
    _displayText = getNames(value)
    return _apiModel
  }

  function handleColumnEditApply(event: CustomEvent<BaseApiColumnNames<any>>) {
    value = event.detail
  }

  export async function reload() {
    _ready = false
    await tick()
    _ready = true
    await tick()
    getApiModel()
  }

  onMount(reload)
  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  state={_state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={errorMessage ?? commonStrings.valueRequired}
>
  <div
    class="ListColumnsSelectWrapper dhx_input"
    on:click={() => (_showColumnEditor = true)}
    on:keypress={() => (_showColumnEditor = true)}
    role="button"
    tabindex="0"
  >
    <div class="select_text">Selected: {_displayText}</div>
    <div
      class="select_button dhx_button dhx_button--view_flat dhx_button--color_primary"
    >
      Select Columns
    </div>
  </div>
</FormElementBase>

{#if _entityModel}
  <ColumnEditor
    bind:show={_showColumnEditor}
    availableColumns={_entityModel.context.columns}
    defaultColumns={_entityModel.context.defaultColumns}
    currentColumns={value}
    on:apply={handleColumnEditApply}
    compatMode={false}
  />
{/if}

<style lang="scss">
  .ListColumnsSelectWrapper {
    display: flex;
    padding: 0;

    cursor: pointer;

    .select_text {
      flex-grow: 1;
      padding: 0.5em;
    }

    .select_button {
      padding: 0 1em;
    }
  }
</style>
