import * as Generated from '@generated'
import {
  entitySelectColumn,
  generatedEnumColumn,
  inputColumn,
} from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.Tag> = {
  columns: {
    name: () => inputColumn('name'),
    alias: () => inputColumn('alias'),
    dataType: () => generatedEnumColumn('dataType', Generated.Tag_DATATYPEEnum),
    sampleMode: () =>
      generatedEnumColumn('sampleMode', Generated.Tag_SAMPLEMODEEnum, {
        fallback: Generated.Tag_SAMPLEMODEEnum.original,
      }),
    sampleInterval: () =>
      generatedEnumColumn('sampleInterval', Generated.Tag_INTERVALEnum, {
        fallback: Generated.Tag_INTERVALEnum.noTiming,
      }),
    shortId: () => inputColumn('shortId'),
    assetClassId: () =>
      entitySelectColumn('assetClass@formattedText', 'assetClass', {
        field: 'assetClassId',
        valueField: 'name',
        filter: {
          type: Generated.AssetClass_TYPEEnum.tag,
        },
      }),
  },
  filters: {
    shortId: null,
    channelId: { model: 'channel' },
    assetId: { model: 'asset' },
    assetClassId: { model: 'assetClass' },
    scalingId: null,
    name: 'string',
    alias: 'string',
    description: 'string',
    unit: 'string',
    dataType: { enum: Generated.Tag_DATATYPEEnum },
    precision: 'integer',
    sampleVar: 'integer',
    sampleMode: { enum: Generated.Tag_SAMPLEMODEEnum },
    sampleInterval: { enum: Generated.Tag_INTERVALEnum },
    dataLog: 'boolean',
    readOnly: 'boolean',
    config: null,
  },
  defaultColumns: [
    'name',
    'alias',
    'dataType',
    'sampleMode',
    'sampleInterval',
    'status',
  ],
  defaultSort: 'name',
  nameField: 'name',
  customListSupport: true,
}
