<script lang="ts" context="module">
  import { isSet } from '@packages/util'
  import { onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export const _name = 'SimpleJsonEdit'

  // export interface SimpleJsonEditConfig {
  //   height?: number
  // }
</script>

<script lang="ts">
  export let value: Record<any, any> = {}
  export let label = 'Json Edit'
  // export let config: SimpleJsonEditConfig = {}
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _value = prettyJsonString(value)
  let _lock = true

  let _state: typeof state = null
  let _errorMessage: typeof errorMessage = null

  $: updateInternal(value)

  function prettyJsonString(value: any): string {
    return (
      typeof value != 'string' ? JSON.stringify(value, undefined, 1) : value
    )?.replace(/^\[\]$/, '{\n  \n}')
  }

  function updateInternal(newValue: typeof value) {
    if (_lock) return
    if (!validateAndUpdate(newValue)) return
    _value = prettyJsonString(newValue)
  }

  function updateExternal(event: Event) {
    const newValue = (event.target as HTMLTextAreaElement).value
    if (!validateAndUpdate(newValue)) return
    _lock = true
    value = JSON.parse(newValue)
    tick().then(() => (_lock = false))
  }

  function validateAndUpdate(str: any) {
    if (!validate(typeof str != 'string' ? JSON.stringify(str) : str)) {
      _state = 'error'
      _errorMessage = 'The configuration must be a valid JSON object'
      return false
    }
    _state = null
    _errorMessage = null
    return true
  }

  function validate(str: string) {
    if (!isSet(str)) return true
    try {
      return typeof JSON.parse(str) == 'object'
    } catch (error) {
      return false
    }
  }

  onMount(async () => {
    await tick()
    _lock = false
    updateInternal(value)
  })
</script>

<FormElementBase
  {label}
  state={_state ?? state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={_errorMessage ?? errorMessage}
>
  <textarea
    aria-label="JSON Value Editor"
    class="dhx_input dhx_input--textarea jsonValueEditor"
    autocapitalize="off"
    autocomplete="off"
    autocorrect="off"
    spellcheck="false"
    value={_value}
    on:change={updateExternal}
  ></textarea>
</FormElementBase>

<style lang="scss">
  .jsonValueEditor {
    font-family: 'JetBrains Mono', monospace;
    min-height: 20em;
  }
</style>
