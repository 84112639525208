<script lang="ts" context="module">
  import StatusMessage from '@components/Global/StatusMessage.svelte'
  import Footer from '@components/LayoutSections/Footer.svelte'
  import LoadingSpinner from '@components/Utility/LoadingSpinner.svelte'
  import { API } from '@lib/ApiHandler'
  import { attachPageUnloadListener } from '@lib/GlobalPageChangeHandler'
  import type { Routes } from '@lib/Router'
  import { getRoutes } from '@lib/Routes'
  import { config as configStore } from '@lib/configStore'
  import { route } from '@lib/locationStore'
  import { buildConfig } from 'BUILDCONFIG'
  import { onMount, tick } from 'svelte'
  import type { RouteLoadingEvent } from 'svelte-spa-router'
  import Router, { params } from 'svelte-spa-router'

  let _repaint = async () => {}

  /**
   * Force a repaint of the current route.
   * Only run this when absolutely necessary.
   */
  export function repaintView() {
    return _repaint?.()
  }
</script>

<script lang="ts">
  let layout: any

  let lastRoute: string = ''
  let showRoute = true
  let routes: Routes

  let loadingMessage = ''

  function routeLoading(_event: RouteLoadingEvent) {
    const event = _event as RouteLoadingEvent & {
      detail: { userData: Record<string, any> }
    }

    // Set the current route (`/path/inner/:param/:optionalParam?`)
    $route = event.detail.route

    // Properly apply the parameters _before_ the route has a chance to load
    $params = event.detail.params

    layout = event.detail.userData?.layout
      ? event.detail.userData?.layout
      : undefined
    lastRoute = String(event.detail.route)
  }

  async function init() {
    loadingMessage = 'Loading Configuration...'

    const _config = buildConfig.instanceConfig //await config.load()

    configStore.set(_config)
    document.title = _config.title
    API.baseURL = _config.backendUrl.replace('%ORIGIN%', window.location.origin)

    loadingMessage = 'Checking Login Status...'

    const isLoggedIn = await API.isUserLoggedIn()

    if (isLoggedIn) {
      API.attachLoginPoller()
    } else {
      await API.logout(true)
    }

    await tick()

    loadingMessage = 'Getting permissions...'
    await API.updateLoginData()

    loadingMessage = 'Loading Routes...'
    routes = getRoutes()
  }

  onMount(() => {
    _repaint = async () => {
      showRoute = false
      await tick()
      showRoute = true
    }
    attachPageUnloadListener()
  })
</script>

<StatusMessage />

{#await init()}
  <div class="loadingContainer">
    <LoadingSpinner />
    <span>{loadingMessage}</span>
  </div>
{:then}
  {#if showRoute}
    {#if layout}
      <svelte:component this={layout}>
        <Router {routes} on:routeLoading={routeLoading} />
      </svelte:component>
    {:else}
      <Router {routes} on:routeLoading={routeLoading} />
    {/if}
  {/if}
{:catch error}
  <div class="errorPage">
    <div class="spacer"></div>
    <div class="errorMessage">
      <h5>An error has occured while loading the configuration file.</h5>
      <p>
        Contact the provider if this problem remains. <br />
        ({`${error}`})
      </p>
    </div>
    <Footer
      data={{
        url: 'https://wizzo.nl',
        location: 'The Netherlands',
        telephone: '+31 (0)251-501675',
        email: 'support@wizzo.nl',
      }}
    />
  </div>
{/await}

<style lang="scss">
  .errorPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .errorMessage {
    padding-bottom: 1em;

    & > * {
      margin: 0;
    }
  }

  .loadingContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
</style>
