import {
  get,
  readable,
  readonly,
  writable,
  type Readable,
  type Writable,
} from 'svelte/store'
import { getId, tryParseJson } from '../Util'

export type MaybeWritable<T> = T | Writable<T>
export type MaybeReadable<T> = T | Readable<T>
export type MaybeStore<T> = T | Readable<T> | Writable<T>

export type ConcreteWritable<T> =
  T extends MaybeWritable<infer U>
    ? Writable<U>
    : T extends Writable<any>
      ? T
      : Writable<T>
export type ConcreteReadable<T> =
  T extends MaybeReadable<infer U>
    ? Readable<U>
    : T extends Readable<any>
      ? T
      : Readable<T>

export function isReadable<T>(check: T | Readable<T>): check is Readable<T> {
  return (
    check !== null &&
    typeof check == 'object' &&
    Object.hasOwn(check, 'subscribe')
  )
}

export function isWritable<T>(
  check: T | Writable<T> | Readable<T>
): check is Writable<T> {
  return (
    isReadable(check) &&
    Object.hasOwn(check, 'set') &&
    Object.hasOwn(check, 'update')
  )
}

/** Wrap a value that isn't a store into as store */
export function wrapWritableStore<T>(valueOrStore: MaybeStore<T>): Writable<T> {
  if (isWritable(valueOrStore)) return valueOrStore
  if (isReadable(valueOrStore))
    return {
      subscribe: valueOrStore.subscribe,
      set: () => {},
      update: () => {},
    }
  else return writable<T>(valueOrStore)
}

/** Wrap a value that isn't a store into as store */
export function wrapReadableStore<T>(valueOrStore: MaybeStore<T>): Readable<T> {
  if (isReadable(valueOrStore)) return valueOrStore
  if (isWritable(valueOrStore)) return readonly(valueOrStore)
  else return readable<T>(valueOrStore)
}

export function unwrapStore<T>(valueOrStore: MaybeStore<T>): T {
  if (isReadable(valueOrStore)) return get(valueOrStore)
  else return valueOrStore
}

export type SerializedStoreObj<T extends Record<string, Readable<any>>> = {
  [P in keyof T]: T[P] extends Readable<infer U> ? U : never
}

export type MaybeReadableObj<T extends Record<any, any>> = {
  [P in keyof T]?: T[P] extends Readable<infer U> ? MaybeReadable<U> : never
}

export type ConcreteReadableObj<T extends Record<any, any>> = {
  [P in keyof T]-?: ConcreteReadable<T[P]>
}

export type ConcreteWritableObj<T extends Record<any, any>> = {
  [P in keyof T]-?: ConcreteWritable<T[P]>
}

export function unwrapAllStoreValues<
  T extends Record<string, MaybeReadable<any>>,
>(storeObj: T): SerializedStoreObj<T> {
  return Object.fromEntries(
    Object.entries(storeObj).map(([key, store]) => [key, unwrapStore(store)])
  ) as any
}

export function wrapAllValuesInStore<
  T extends Record<string, MaybeReadable<any>>,
>(storeObj: T): ConcreteReadableObj<T> {
  return Object.fromEntries(
    Object.entries(storeObj).map(([key, store]) => [
      key,
      wrapReadableStore(store),
    ])
  ) as any
}

export function wrapAllValuesInWritableStore<
  T extends Record<string, MaybeReadable<any>>,
>(storeObj: T): ConcreteWritableObj<T> {
  return Object.fromEntries(
    Object.entries(storeObj).map(([key, store]) => [
      key,
      wrapReadableStore(store),
    ])
  ) as any
}

/**
 * Simple store that interfaces with two external functions,
 * one that gets the current value (on subscribe and update),
 * and one that sets the value somewhere else
 */
export function useGetSetStore<T>(
  getFn: () => T,
  setFn: (value: T) => void
): Writable<T> {
  let _current: T
  let _run = new Map<string, (value: T) => void>()
  return {
    subscribe(run, _) {
      const id = getId()
      _current = getFn()
      _run.set(id, run)
      run(_current)
      return () => {
        _run.delete(id)
      }
    },
    set(value) {
      setFn(value)
      _current = value
      _run.forEach((fn) => fn(_current))
    },
    update(updater) {
      _current = updater(_current)
      setFn(_current)
      _run.forEach((fn) => fn(_current))
    },
  }
}

export function useLocalStorage<T = string>(
  key: string,
  fallback?: T
): Writable<T> {
  return useGetSetStore(
    () => tryParseJson(localStorage.getItem(key), fallback),
    (value) => localStorage.setItem(key, JSON.stringify(value))
  )
}

export function useSessionStorage<T = string>(
  key: string,
  fallback?: T
): Writable<T> {
  return useGetSetStore(
    () => tryParseJson(sessionStorage.getItem(key), fallback),
    (value) => sessionStorage.setItem(key, JSON.stringify(value))
  )
}
