<script lang="ts" context="module">
  import DateTimeRangeSelect from '@components/DateTimeRangeSelect.svelte'
  import DateRange from '@lib/DateRange'
  import { onDestroy, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export interface DateBetweenConfig {
    timeSelect?: boolean
  }

  export const _name = 'DateBetween'
</script>

<script lang="ts">
  export let value = null
  export let config: DateBetweenConfig = {
    timeSelect: false,
  }
  export let label = 'Date'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  type ValidValue = typeof value | DateRange

  let _state = state
  let _date_error = errorMessage
  let _lock = false
  let _ready = false
  let _value = convertTo(value)

  $: if (_ready && validateDate(_value)) {
    _lock = true
    value = convertBack(_value)
    tick().then(() => (_lock = false))
  }

  $: update(value)

  function convertTo(_value: ValidValue): DateRange {
    if (_value instanceof DateRange) return _value
    if (!Array.isArray(_value)) return new DateRange()
    return new DateRange(_value[0], _value[1])
  }

  function convertBack(_value: ValidValue): typeof value {
    if (_value instanceof DateRange) return _value.asArray()
    return _value
  }

  function validateDate(_value: ValidValue) {
    const result = true

    if (result) {
      _state = state
      _date_error = errorMessage
    } else {
      _state = 'error'
      _date_error = 'Value must be valid'
    }

    return result
  }

  function update(newValue: ValidValue) {
    if (_lock) return
    _value = convertTo(newValue)
  }

  onMount(async () => {
    await tick()
    _ready = true
  })
  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  state={_state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={_date_error?.length ? _date_error : errorMessage}
>
  <DateTimeRangeSelect
    compact
    bind:value={_value}
    noTime={!(config?.timeSelect ?? false)}
  />
</FormElementBase>
