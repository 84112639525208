/** Is the current user using 12h time? */
export function using12hTime(locales?: string | string[]) {
  locales ??= navigator.language
  return Intl.DateTimeFormat(locales, { hour: 'numeric' }).resolvedOptions()
    .hour12
}

/** Check if the value is a valid Date object */
export function isValidDate(date: any): date is Date {
  return Object.prototype.toString.call(date) === '[object Date]'
}

/** Attempt to parse a date from a string or number */
export function tryParseDate(date: string | number | Date) {
  if (isValidDate(date)) return date
  const stringified = `${date}`
  date = new Date(parseInt(stringified))
  if (isValidDate(date)) return date
  date = new Date(Date.parse(stringified))
  if (isValidDate(date)) return date
  throw new Error('Invalid date supplied')
}

/**
 * Convert the date to a time string the backend can correctly parse
 *
 * @param time The input time
 * @param convertTimezone Set to true to align the given time to the server timezone (UTC +0000)
 * @returns The formatted time string
 */
export function formatBackendTime(time: Date | string | number) {
  return new Date(time).toISOString()
}

/**
 * Parse the time string given by the backend and apply the correct timezone to it
 *
 * @param time The input time string
 * @returns The timezone aligned date object
 */
export function parseBackendTime(time: Date | string | number) {
  return new Date(new Date(time).toISOString().replace('Z', '+0000'))
}

/** @deprecated Use `dateFormatter.format()` instead */
export function formatTime(
  time: Date | string | number,
  convertTimezone = false
) {
  const _time = new Date(time)
  const pad = (value: number) => {
    return String(value).padStart(2, '0')
  }

  return convertTimezone
    ? `${pad(_time.getUTCHours())}:${pad(_time.getUTCMinutes())}:${pad(
        _time.getUTCSeconds()
      )}`
    : `${pad(_time.getHours())}:${pad(_time.getMinutes())}:${pad(
        _time.getSeconds()
      )}`
}

/**
 * Get the amount of days in a month using bitwise operators.
 * Assumes gregorian calendar notation.
 *
 * @param month 1 (January) to 12 (December)
 * @param year The actual full year
 *
 * @see https://stackoverflow.com/a/27947860
 */
export function getDaysInMonth(month: number, year: number) {
  return month === 2
    ? year & 3 || (!(year % 25) && year & 15)
      ? 28
      : 29
    : 30 + ((month + (month >> 3)) & 1)
}
