<script lang="ts" context="module">
  import { Tag_DATATYPEEnum } from '@packages/api/generated'
  import { isUuid } from '@packages/util'
  import { tick } from 'svelte'
  import EntitySelect from './EntitySelect.svelte'
  import EnumSelect from './EnumSelect.svelte'
  import FilePicker from './FilePicker.svelte'
  import TextField from './TextField.svelte'

  export interface ImageCardSourceConfig {}

  export const _name = 'ImageCardSource'

  export interface ImageCardSourceData {
    type: ImageCardSourceType
    data: string | Uuid
  }

  export type ImageCardSourceType = keyof typeof ImageCardSourceType
  export const ImageCardSourceType = {
    url: 'url',
    blob: 'blob',
    file: 'file',
  }
</script>

<script lang="ts">
  export let value: Nullable<ImageCardSourceData> = null
  // export let config: ImageCardSourceConfig = {}
  // export let label = 'File Picker'
  // export let state: null | 'success' | 'error' = null
  // export let helpMessage: string | null = null
  // export let preMessage: string | null = null
  // export let successMessage: string | null = null
  // export let errorMessage: string | null = null
  export let readOnly = false

  let _tagId: Nullable<Uuid> = null
  let _fileId: Nullable<Uuid> = null
  let _url: Nullable<string> = null
  let _type: Nullable<ImageCardSourceType> = null

  let _internalUpdateLock = false
  let _externalUpdateLock = false

  $: internalToExternal(_tagId, _fileId, _url, _type)
  $: externalToInternal(value)

  function externalToInternal(data: Nullable<ImageCardSourceData>) {
    if (_externalUpdateLock) return
    _internalUpdateLock = true

    _type = data?.type

    _tagId = null
    _fileId = null
    _url = null

    switch (_type) {
      case 'url':
        _url = data?.data
        break
      case 'blob':
        _tagId = isUuid(data?.data) ? data.data : null
        break
      case 'file':
        _fileId = isUuid(data?.data) ? data.data : null
        break
    }

    // console.log('UPDATE_INT')

    tick().then(() => (_internalUpdateLock = false))
  }

  function internalToExternal(
    tagId: typeof _tagId,
    fileId: typeof _fileId,
    url: typeof _url,
    type: typeof _type
  ) {
    if (_internalUpdateLock) return
    _externalUpdateLock = true

    switch (type) {
      case 'url':
        value = { type, data: url }
        break
      case 'blob':
        value = { type, data: tagId }
        break
      case 'file':
        value = { type, data: fileId }
        break
    }

    // console.log('UPDATE_EXT')

    tick().then(() => (_externalUpdateLock = false))
  }
</script>

<!-- <FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
> -->
<div class="imageCardSource">
  <div class="formItem">
    <EnumSelect
      bind:value={_type}
      label="Source Type"
      config={{
        enum: ImageCardSourceType,
        defaultValue: ImageCardSourceType.file,
      }}
    />
  </div>

  <div class="formItem">
    {#if _type == ImageCardSourceType.url}
      <TextField bind:value={_url} label="Source Url" config={{}} />
    {:else if _type == ImageCardSourceType.blob}
      <EntitySelect
        bind:value={_tagId}
        label="Source Tag"
        config={{
          type: 'tag',
          allowEmpty: true,
          filter: {
            dataType: Tag_DATATYPEEnum.blob,
            'config.mimeType': [
              // Known supported image mime types by TCPDF
              'image/jpeg',
              'image/png',
              'image/gif',
              'image/bmp',
            ],
          },
        }}
      />
    {:else if _type == ImageCardSourceType.file}
      <FilePicker
        bind:value={_fileId}
        label="Source Image"
        config={{
          filter: /image\/.*/,
          title: 'Select Image',
        }}
        {readOnly}
      />
    {:else}
      <div class="placeholderText">Select a Source Type</div>
    {/if}
  </div>
</div>

<!-- </FormElementBase> -->

<style lang="scss">
  .imageCardSource {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
</style>
