<script lang="ts">
  import Popup from '@components/Popup.svelte'
  import GlobalToolbar from '@components/Toolbar/GlobalToolbar.svelte'
  import Icon from '@components/Utility/Icon.svelte'
  import { loginData } from '@lib/ApiHandler'
  import { push } from '@lib/GlobalPageChangeHandler'
  import { config } from '@lib/configStore'
  import { mdiAccount, mdiMenu } from '@mdi/js'
  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  let showPopup = false
  let userMenu = [
    {
      name: 'Logout',
      action() {
        push('/logout')
      },
    },
  ]
</script>

<div class="header">
  <!-- Sidebar toggler -->
  <div class="sidebarButton dhx_toolbar__item circular">
    <button
      type="button"
      class="dhx_button dhx_button--view_link dhx_button--color_default"
      on:click={() => dispatch('toggleSidebar')}
    >
      <Icon name={mdiMenu} />
    </button>
  </div>

  <!-- Logo -->
  <div class="logo iconButton">
    <a href="#/home">
      <img class="logoImage" src="./img/logo.webp" alt="logo" />
    </a>
  </div>

  <!-- Dynamic toolbar -->
  <GlobalToolbar />

  <!-- User details button -->
  <button
    type="button"
    class="userButton dhx_button dhx_button--size_medium dhx_button--view_link dhx_button--color_default"
    on:click={() => (showPopup = true)}
  >
    <div class="userButtonContent">
      <div class="userButtonText">
        <div class="instanceTitle">{$config?.instanceTitle ?? '---'}</div>
        <div class="instanceDescription">
          {$config?.instanceDescription ?? '---'}
        </div>
        <div class="fullNameHolder">
          <Icon name={mdiAccount} width="12" />
          <span class="fullName">{$loginData?.fullName ?? '---'}</span>
        </div>
      </div>
      <img
        class="userImage"
        src={`/${$config.defaultUserIcon}`}
        alt={`Profile icon for ${$loginData?.fullName ?? 'unknown user'}`}
      />
    </div>

    <!-- User action menu -->
    <Popup bind:show={showPopup}>
      <ul class="dhx_widget dhx_menu">
        {#each userMenu as item}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
          <li class="dhx_menu-item" on:click={() => item.action()}>
            <button class="dhx_button dhx_menu-button" type="button">
              <span class="dhx_menu-button__block dhx_menu-button__block--left">
                <span class="dhx_menu-button__text">{item.name}</span>
              </span>
            </button>
          </li>
        {/each}
      </ul>
    </Popup>
  </button>
</div>

<style lang="scss">
  @use '../../theme/variables' as vars;

  $iconSize: 24px;

  .header {
    @include vars.thinScrollbar;

    background-color: vars.$primary;
    color: #000;
    height: vars.$headerHeight;
    position: sticky;
    top: 0;
    border-bottom: vars.$borderStyle;
    z-index: vars.$zId-nudgeMore;
    // width: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;

    // Scrollable header when the view is small
    overflow-x: auto;
    overflow-y: hidden;
  }

  .logoImage {
    height: $iconSize;
  }

  .userImage {
    height: $iconSize * 1.5;
    width: $iconSize * 1.5;
    // border-radius: 999px;
  }

  .logo {
    transition: opacity 0.2s ease-out;

    &:hover {
      opacity: 0.5;
    }
  }

  .iconButton {
    height: $iconSize;
    cursor: pointer;
    padding: 0.5em;
    box-sizing: content-box;
    user-select: none;
  }

  .userButton {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    display: flex;
    flex-direction: column;
    text-align: start;

    .userButtonText {
      margin-right: 0.5em;

      & > * {
        margin: -0.3em 0;
      }
    }

    .instanceDescription {
      font-size: small;
    }

    .userButtonContent {
      display: flex;
      align-items: center;
    }

    .fullNameHolder {
      display: flex;
      align-items: center;
      font-size: smaller;
    }
  }

  :global(.toolbar) {
    width: 100%;
  }

  :global(.dhx_toolbar-button-image__image) {
    background-repeat: no-repeat;
    background-position: center;
    background-size: $iconSize;
  }

  :global(.toolbarIcon) {
    height: $iconSize;
    width: $iconSize;
    vertical-align: middle;
  }
</style>
