<script lang="ts">
  /**
   * To properly use this base element, copy the following set of lines:
   * (We cannot use spread operators (`{...$$props}`), sadly)
   */
  export let label: Nullable<string> = 'Text Display'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null
  export let required = false

  /**
   * And import it like so:
   * <FormElementBase {label} {state} {helpMessage} {preMessage} {successMessage} {errorMessage} >
   *  Content
   * </FormElementBase>
   */

  /** Set to true to show a border around the input element (Follows State) */
  export let border = false
  export let forField: Nullable<string> = null

  // TODO: Help message, required * sign

  // Unused for now:
  helpMessage
</script>

{#if label}
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label
    class="dhx_label"
    class:required
    aria-label={label ?? 'Label'}
    title={label ?? 'Label'}
    style="max-width: 100%"
    for={forField}
  >
    {label ?? 'Label'}
  </label>
{/if}
<div class="dhx_input__wrapper">
  <div class="dhx_input__container border--{state ?? 'neutral'}" class:border>
    <slot />
  </div>
  {#if !state && preMessage}
    <span class="dhx_input__caption">
      {preMessage}
    </span>
  {:else if state == 'success' && successMessage}
    <span class="dhx_input__caption">
      {successMessage}
    </span>
  {:else if state == 'error' && errorMessage}
    <span class="dhx_input__caption state_error">
      {errorMessage}
    </span>
  {/if}
</div>

<style lang="scss">
  @use '../../theme/variables' as vars;

  :global(.state_error .test-display-value) {
    color: vars.$error;
  }

  .state_error {
    color: vars.$error;
  }

  .required:after {
    content: '*';
    display: inline-block;
    padding-left: 4px;
    color: rgba(0, 0, 0, 0.3);
  }

  .border {
    border: vars.$borderStyle;

    &.border--error {
      border-color: vars.$errorBorder;
    }
  }
</style>
