<script lang="ts">
  import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
  import Icon from './Icon.svelte'

  let scrollTimer: NodeJS.Timeout[] = []
  let contentRect: Nullable<DOMRectReadOnly>
  let tabbarElement: Nullable<HTMLElement>

  $: showScrollButtons =
    contentRect && tabbarElement.clientWidth < tabbarElement.scrollWidth

  function setScroll(level: number) {
    scrollTimer.forEach((item) => clearInterval(item))
    scrollTimer = []

    if (level != 0) {
      scrollTimer.push(
        setInterval(() => tabbarElement.scrollBy(level * 3, 0), 5)
      )
    }
  }
</script>

<div class="scroll-container">
  <div
    class="scroll-button"
    class:hidden={!showScrollButtons}
    on:pointerdown={() => setScroll(-1)}
    on:pointerup={() => setScroll(0)}
  >
    <Icon name={mdiChevronLeft} />
  </div>
  <div
    class="scroll-container-content"
    bind:contentRect
    bind:this={tabbarElement}
  >
    <slot />
  </div>
  <div
    class="scroll-button"
    class:hidden={!showScrollButtons}
    on:pointerdown={() => setScroll(1)}
    on:pointerup={() => setScroll(0)}
  >
    <Icon name={mdiChevronRight} />
  </div>
</div>

<style lang="scss">
  @use '../../theme/variables' as vars;

  .scroll-container {
    display: flex;
    width: 100%;
  }

  .scroll-container-content {
    @include vars.noScrollbar;

    display: flex;

    overflow-y: none;
    overflow-x: auto;
  }

  .scroll-button {
    padding: 0.5em;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    font-weight: 500;
    color: vars.$dhxPrimaryHover;
    user-select: none;

    transition: color 250ms;
    transition: background-color 250ms;

    &:hover {
      background-color: vars.$border;
    }

    &.hidden {
      display: none;
    }
  }
</style>
