import * as Generated from '@generated'
import { generatedEnumColumn, inputColumn } from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.AssetClass> = {
  columns: {
    name: () => inputColumn('name'),
    type: () => generatedEnumColumn('type', Generated.AssetClass_TYPEEnum),
  },
  filters: {
    parentId: { model: 'assetClass' },
    // config: null,
    formDefinition: null,
    staticInfo: null,
    name: 'string',
    type: { enum: Generated.AssetClass_TYPEEnum },
    config: null,
  },
  defaultColumns: ['name', 'type', 'modifiedBy', 'status'],
  defaultSort: 'name',
  nameField: 'name',
  customListSupport: true,
}
