<script lang="ts" context="module">
  import type { EnumEditorItemBase } from '@components/FormControls/EnumEditor.svelte'
  import {
    getOptions,
    type EnumEditorDerivedValue,
  } from '@components/FormControls/EnumEditorDerived.svelte'
  import LoadingSpinnerSmall from '@components/Utility/LoadingSpinnerSmall.svelte'
  import { isString } from '@packages/util'

  /** Cache with all promises for each ref string */
  const enumCache = new Map<string, Promise<EnumEditorItemBase[]>>()

  /** Helper function that will get all options and cache it for quick reuse */
  function getValues(ref: EnumEditorDerivedValue) {
    // If the ref is a raw array of items, immediately resolve it
    if (!isString(ref)) return Promise.resolve(ref)

    // Get the promise
    let promise = enumCache.get(ref)

    // Initialize the promise if not done so yet
    if (!promise) {
      promise = getOptions(ref)
      enumCache.set(ref, promise)
    }

    // Return the promise
    return promise
  }
</script>

<script lang="ts">
  export let value: string
  export let ref: EnumEditorDerivedValue
</script>

{#await getValues(ref)}
  <LoadingSpinnerSmall />
{:then values}
  {values.find((item) => item.value == value)?.name}
{:catch}
  -- Error Fetching Data --
{/await}
