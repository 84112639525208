import { type ModelReference } from '@models/api/BaseApi'
import type { ModelFilter, ModelFilterCmp } from '@models/api/BaseModel'
import type FilterGroup from './FilterGroup'
import type FilterItem from './FilterItem'

/** The Base Filter Node */
export default abstract class FilterNode {
  /** The model reference */
  model: ModelReference

  /** Is the current node a Group? */
  isGroup(): this is FilterGroup {
    return false
  }

  /** Is the current node an Item? */
  isItem(): this is FilterItem {
    return false
  }

  /** Convert this class to a filter object */
  abstract serialize(): [
    key: string,
    value: ModelFilter<any> | ModelFilterCmp<any>,
  ]

  /** Get the key (group type OR column) */
  abstract getKey(): string

  /** Convert the filter object to this class */
  static deserialize(
    model: ModelReference,
    key: string,
    value: ModelFilter<any> | ModelFilterCmp<any>
  ): unknown | null {
    throw new Error('Method not implemented.')
  }

  /** Get the string representation of this class */
  toString(): string {
    throw new Error('Method not implemented.')
  }

  /** Check if this node is set */
  abstract isSet(): boolean
}
