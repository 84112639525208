import * as Generated from '@generated'
import type { ApiFormItem } from '@lib/ApiFormGenerator'
import {
  booleanColumn,
  dummyColumn,
  inputColumn,
  userColumn,
} from '@lib/CommonColumns'
import type { IconPath } from '@lib/Icons'
import type { ModelContext } from '../BaseApi'
import type { pageFormats } from '../PageFormats'

export interface ReportCardMetadata {
  name: string
  title: string
  icon: IconPath
  options: ApiFormItem[]
}

/* A page format can either be a named format (A4, LETTER, etc.) or a two-float array (`pt` size) */
export type PdfFormat = (keyof typeof pageFormats)[number] | number[]

/* The supported units in TCPDF */
export type PdfUnit = 'pt' | 'px' | 'mm' | 'cm' | 'in'

// Mirror of common\lib\helpers\PdfSize
export interface PdfSize extends Omit<Generated.PdfSize, 'pageFormat'> {
  pageFormat: PdfFormat
}

// Mirror of common\lib\helpers\PdfData
export interface PdfData extends Omit<Generated.PdfData, 'pageFormat'> {
  /* Basic style information */
  /** NOTE: "CUSTOM" is only to be used internally */
  pageFormat?: PdfFormat | 'CUSTOM'
}

export const defaultPdfData: PdfData = {
  pageFormat: 'A4',
  pageOrientation: 'P',
  fontSize: 10,
  gridSize: 10,
  marginLeft: 10, // Real default: 15
  marginTop: 10, // Real default: 27
  marginBottom: 10, // Real default: 25
  marginRight: 10, // Real default: 15
  cardPadding: 1,
  unit: 'mm',
}
//#endregion Extra models

export const __context__: ModelContext<Generated.Report> = {
  columns: {
    name: () => inputColumn('name'),
    isTemplate: () =>
      booleanColumn('isTemplate', {
        trueText: 'Yes',
        falseText: 'No',
        config: {
          width: 100,
        },
      }),
    owner: () =>
      userColumn('owner@formattedText', {
        field: 'owner',
      }),
    'batch@formattedText': () =>
      dummyColumn('batch@formattedText', { requiresExpand: true }),
  },
  defaultColumns: [
    'name',
    // 'isTemplate',
    'batch@formattedText',
    'owner',
    'createdOn',
  ],
  filters: {
    config: null,
    data: null,
    isTemplate: 'boolean',
    name: 'string',
    owner: { model: 'user' },
    fileId: null,
  },
  defaultSort: '-createdOn',
  nameField: 'name',
  customListSupport: true,
}
