<!-- Grid with support for modifying the shown columns 
 To properly place the toolbar item generated by this component... 
 ...Set the name of the toolbar item to `editColumnsTarget` -->
<script lang="ts" context="module">
  import type { IDataItem } from '@dhtmlx/ts-data'
  import type { Grid as GridModel, ICol, IGridConfig } from '@dhtmlx/ts-grid'
  import type { Pagination } from '@dhtmlx/ts-pagination'
  import type {
    ApiLazyDataProxy,
    ApiPostProcessor,
    IApiExtraParams,
  } from '@lib/ApiDataProvider'
  import {
    confirmUnsavedChanges,
    hasUnsavedChanges,
    push,
  } from '@lib/GlobalPageChangeHandler'
  import { openItemPage } from '@lib/OpenItemPageHandler'
  import {
    mdiCalendar,
    mdiContentSave,
    mdiPlus,
    mdiReload,
    mdiTableEdit,
  } from '@mdi/js'
  import type ApiBaseModel from '@models/api/ApiBaseModel'
  import {
    baseApi,
    type BaseApiColumnNames,
    type BaseApiColumns,
    type ModelReference,
  } from '@models/api/BaseApi'
  import type BaseModel from '@models/api/BaseModel'
  import type { PageQueryParams } from '@models/api/BaseModel'
  import type {
    GridCellClickEvent,
    GridCellDblClickEvent,
    MappedEvents,
  } from '@packages/util'
  import {
    getQueryStringParam,
    handleControlKey,
    isArraySimilar,
    isCtrlHeld,
    isSet,
    merge,
    pushNew,
    setQueryStringParam,
    single,
    uncapitalize,
    unique,
    unsetQueryStringParam,
  } from '@packages/util'
  import { inject } from 'regexparam'
  import { createEventDispatcher, onMount, tick } from 'svelte'
  import ColumnEditor from './Modals/ColumnEditor.svelte'
  import ImportDialog from './Modals/ImportDialog.svelte'
  import PaginatedGrid, {
    type IHeaderComponent,
    type SortingState,
  } from './PaginatedGrid.svelte'
  import ToolbarHook from './Toolbar/ToolbarHook.svelte'
  import ToolbarItem from './Toolbar/ToolbarItem.svelte'
  import ImportExportToolbarItems from './Toolbar/ToolbarUtilities/ImportExportToolbarItems.svelte'

  type FilterState = Record<string, any>
  type IColExt = ICol & { $name: string; $requiresExpand?: boolean }

  export interface DynamicGridSaveState {
    model: string
    title?: string
    columns?: string[]
    filter?: FilterState // Filter object (Must be (de)serializable)
    sort?: string
  }
</script>

<script lang="ts">
  type T = $$Generic<ApiBaseModel>

  interface $$Events {
    afterReload: CustomEvent<void>
    afterMount: CustomEvent<void>
    saveClick: CustomEvent<void>
    addClick: CustomEvent<void>
    cellDblClick: CustomEvent<GridCellDblClickEvent>
    cellClick: CustomEvent<GridCellClickEvent>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  // These fields should always be in the request
  const fixedFields: (keyof T)[] = ['id', 'status']

  // Column separator in the url
  const columnSeperator = ';'

  /**
   * The base state object to load
   */
  export let baseState: Nullable<DynamicGridSaveState> = null

  /**
   * Override the default column visibility and order
   */
  export let overrideDefaultColumns: Nullable<BaseApiColumnNames<any>> = null

  /**
   * Add extra columns
   */
  export let additionalColumns: Nullable<BaseApiColumns<any>> = null

  /**
   * Set additional DHTMLX grid config entries
   */
  export let gridConfig: IGridConfig = {}

  /**
   * Manually set the apiModel
   */
  export let apiModel: BaseModel<T, any> = getApiModel()

  /**
   * The page to open on double click or ctrl click (format: '/entity/:id').
   * Set to true to open the right page automatically
   */
  export let openPage: Nullable<string | boolean> = null

  /**
   * Force the row height
   */
  export let forceRowHeight = true

  /**
   * The current loading state (Avoid setting directly)
   */
  export let loading = true

  /**
   * Hide the toolbar buttons
   */
  export let hideToolbarButtons = false

  /**
   * Add a toolbar button to allow the grid to be reloaded
   */
  export let canReload = false

  /**
   * Add a toolbar button to allow the data to be saved (Does nothing by itself)
   */
  export let canSave = false

  /**
   * Add a toolbar button to allow an item to be added.
   * When `openPage` is true, it will open the entity page with the ID set to `new`,
   * along with the parameters set via the `addParams` prop
   */
  export let canAdd = false

  /**
   * Allow exports of this list
   */
  export let disableExport = false

  /**
   * Allow imports of this list
   */
  export let disableImport = false

  /**
   * The parameters to add in the URL when opening the `new` entity page
   */
  export let addParams: Nullable<Partial<T>> = null

  /**
   * Set to true to not update the URL with saved states (filters, sort, columns, etc.)
   */
  export let disableUrlSaving = false

  /**
   * Override to set a custom ApiLazyDataProxy (Limited support!)
   */
  export let dataProxy: Nullable<ApiLazyDataProxy<T>> = null

  /**
   * A set of filters to include in each request
   */
  export let fixedFilters: Nullable<FilterState> = null

  /**
   * The default sort string (fieldName or -fieldName)
   */
  export let defaultSort: Nullable<IApiExtraParams<T>['sort']> = null

  /**
   * Add these fields to each request (Model::fields() in Yii)
   */
  export let includeFields: (keyof T)[] = []

  /**
   *  Add these extra fields to each request (Model::extraFields() in Yii)
   */
  // export let extraFields: (keyof T)[] = []

  /**
   * The currently selected item
   * @readonly
   */
  export let selection: Nullable<T> = null

  /** The function to run when the user wants to export the list */
  export let overrideExport: Nullable<
    (asTemplate: boolean, queryParams: PageQueryParams<T>) => void
  > = null

  dataProxy ??= apiModel?.getDataProxy()

  let showImportDialog = false
  let showColumnEditor = false
  let currentColumns: IColExt[] = getColumns()
  let showGrid = false

  // The stored filter and sort state
  let boundFilterState: Nullable<FilterState> = null
  let visualGridSortingState: Nullable<SortingState> = null // The visual sorting state of the grid (DHTMLX)

  // These variables are used for reapplying the extra BaseModel params
  let __storedFilter: Nullable<FilterState> = null
  let __storedSortParam: Nullable<string> = null
  let __storedFields: (keyof T)[] = []

  // Set grid config
  let entityGridConfig: IGridConfig = {
    ...gridConfig,
    columns: currentColumns,
    selection: 'row',
    autoWidth: true,
    css: gridConfig.css ?? 'noBorder singleLine',
  }

  export let entityGrid: Nullable<GridModel> = null
  export let entityGridPagination: Nullable<Pagination> = null

  $: hasFiltersApplied = !!__storedFilter

  export function getStoredEntities(): T[] {
    // @ts-ignore
    return entityGrid.data.getRawData() as T[]
  }

  /**
   * Helper function to turn `{field}/{property}` columns
   * to fetch the {field} data inside the objects
   *
   * Example:
   * Column `id` of `"config/options/image"` would look up the "image" property set deep inside the config property
   *
   * ```jsonc
   * {
   *  "config": {
   *   "options": {
   *     "image": "https://image.com/abcd.png", // <- Gets this value
   *     "...": "..."
   *   },
   *   "...": "..."
   *  }
   * }
   * ```
   */
  function convertData(value: T): IDataItem {
    // Go through each selected column
    currentColumns.forEach((column) => {
      // Perform a regex function to get the field and its path
      const match = /^(?<field>.*?)\/(?<jsonPath>.*)$/.exec(String(column.id))

      // Return if there is no match
      if (!match) return

      // Extract the named groups from the result
      const jsonPath = match?.groups?.jsonPath
      const field = match?.groups?.field

      // @ts-ignore Return if either aren't set
      if (!jsonPath || !field || !value[field]) return

      // Recursive lookup function to find a value according to a given path set by the column id
      const lookup: (_value: {} | any, ...path: string[]) => any = (
        _value,
        ...path
      ) =>
        path.length > 1
          ? lookup(_value[path[0]], ...path.slice(1))
          : _value[path[0]]

      // @ts-ignore Actually perform the value lookup
      value[`${field}/${jsonPath}`] = lookup(
        value,
        field,
        ...jsonPath.split('/')
      )
    })

    // DHTMLX seem to apply an automatic height, regardless of the config value
    // This forces the set height
    if (forceRowHeight) {
      // @ts-ignore
      value.height = entityGridConfig.rowHeight
    }

    // Return the resulting value
    return value
  }

  // Show the column editor
  export function editColumns() {
    showColumnEditor = true
  }

  // The function that will run when the column edits gets applied
  function handleColumnEditApply(event: CustomEvent<BaseApiColumnNames<any>>) {
    const data = event.detail as BaseApiColumnNames<T>
    // Store the currently selected columns
    currentColumns = getColumns(data)

    // Set the currently selected columns
    entityGrid?.setColumns(currentColumns)

    urlSaveColumns(data)
    loadFields()
    loadFilters()

    return reload()
  }

  /**
   * The function to run when the add button has been clicked
   */
  function handleAddClick() {
    if (openPage === true) openItemPage(getApiName(), 'new', null, addParams)
    dispatch('addClick')
  }

  async function load() {
    if (!entityGrid) return

    // Only overwrite the postprocessor when it hasn't been set
    if (!entityGrid.data.dataProxy.config.postprocess) {
      // @ts-ignore
      ;(entityGrid.data.dataProxy.config.postprocess as ApiPostProcessor<T>) = (
        data
      ) => {
        data.results = <any>data.results.map(convertData)
        return data
      }
    }

    // Only load data on the given page at first
    entityGrid.data.dataProxy.config.from =
      entityGrid.data.dataProxy.config.limit * urlLoadPage()
  }

  export async function reload() {
    urlSavePage() // Unset current page
    showGrid = false
    await tick()
    dataProxy?.clear()
    entityGrid = null
    await tick()
    showGrid = true
    await tick()
    // @ts-ignore
    entityGrid.setColumns(currentColumns)
    dispatch('afterReload')
  }

  function exportList(asTemplate = false, fullExport = false) {
    // Properly apply the current page
    const queryParams = {
      ...dataProxy.getQueryParams(),
      page: entityGridPagination.getPage() + 1,
      ...(fullExport
        ? {
            page: 0,
            limit: 1000,
            fields: ['*'] as any,
          }
        : {}),
    }

    if (overrideExport) {
      overrideExport(asTemplate, queryParams)
    } else {
      apiModel.fileExport(queryParams, undefined, asTemplate)
    }
  }

  function importList() {
    // dispatch('import')
    showImportDialog = true
  }

  /**
   * Clear the stored state (filters, sorting and page)
   * and reload the list.
   */
  export async function clearStoredState() {
    await tick()

    urlSaveFilters()
    urlSaveSorting()

    // Process the filters and fields
    loadFilters(true)
    loadSorting()

    // Allow the grid to reload
    await reload()
  }

  /**
   * Clear all URL params (Without reload)
   */
  export function clearUrlParams() {
    urlSaveFilters()
    urlSaveSorting()
    urlSaveColumns()
    urlSavePage()
  }

  function handleFilterChange() {
    loadFilters()
    return reload()
  }

  function handleSortChange(event: CustomEvent<SortingState>) {
    loadSorting(event.detail)
    return reload()
  }

  /**
   * Encode the sorting state if it isn't already encoded
   */
  function ensureSortingString(
    input: SortingState | string | string[] | null
  ): string | null | undefined {
    return isSortingState(input)
      ? `${input.direction == 'desc' ? '-' : ''}${input.column}`
      : single(input)
  }

  /**
   * Decode the sorting string if it isn't already decoded
   */
  function ensureSortingState(
    input: SortingState | string | string[]
  ): SortingState | null {
    const _input = single(input) as string
    return isSortingState(input)
      ? input
      : !isSet(_input)
        ? null
        : {
            column: _input.replace(/^-?/, ''),
            direction: _input.startsWith('-') ? 'desc' : 'asc',
          }
  }

  /**
   * Find the filter cell attached to the given columnId
   */
  function getColumnFilterCell(columnId: string | number): IHeaderComponent {
    return currentColumns
      ?.find((item) => item.id == columnId)
      ?.header?.find((item) =>
        item.css?.includes('x-component')
      ) as IHeaderComponent
  }

  function handleClick(event: CustomEvent<GridCellClickEvent>) {
    if (!openPage) return

    // Only respond to ctrl/command keys
    if (!isCtrlHeld(event.detail.e)) return

    const columnName = String(event.detail.col.id)
    const data = event.detail.row as any

    if (openPage === true) {
      openItemPage(getApiName(), data, 'newTab', null, columnName)
    } else {
      pushNew(inject(openPage, { id: data.id }))
    }
  }

  function handleDblClick(event: CustomEvent<GridCellDblClickEvent>) {
    if (!openPage) return

    const columnName = String(event.detail.col.id)
    const data = event.detail.row as any

    if (openPage === true) {
      openItemPage(getApiName(), data, null, null, columnName)
    } else {
      push(inject(openPage, { id: data.id }))
    }
  }

  /**
   * Get columns from the list of available columns by its name.
   * Leave empty to return to the default layout.
   */
  function getColumns(columns?: BaseApiColumnNames<T>): IColExt[] {
    if (!apiModel) return []

    const defaultColumns =
      urlLoadColumns() ??
      overrideDefaultColumns ??
      baseState?.columns ??
      // @ts-ignore
      baseApi[getApiName()]?.context?.defaultColumns

    const availableColumns = {
      // @ts-ignore
      ...baseApi[getApiName()]?.context?.columns,
      ...additionalColumns,
    }

    return ((columns ?? defaultColumns) as string[])
      .map((name: string) => {
        const column = availableColumns[name]?.()
        if (!column) {
          console.error('Column not defined: ' + name)
          return null
        }
        return { ...column, $name: name }
      })
      .filter(isSet)
  }

  function getColumnNames(columns: IColExt[]): string[] {
    return columns.map((column: IColExt) => column.$name)
  }

  //#region URL actions
  /* URL Functions for Page */
  function urlSavePage(value?: number) {
    if (!disableUrlSaving)
      if (!isSet(value)) unsetQueryStringParam('page')
      else setQueryStringParam('page', String((value ?? 0) + 1))
  }

  function urlLoadPage(): number {
    const page = disableUrlSaving
      ? 1
      : parseInt(getQueryStringParam('page') ?? '1')
    return (isNaN(page) ? 1 : page) - 1
  }

  /* URL Functions for Filters */
  /**
   * Update the filter query parameter to a Base64 encoded JSON object
   */
  function urlSaveFilters(value?: FilterState) {
    if (!disableUrlSaving)
      if (!isSet(value)) unsetQueryStringParam('filter')
      else setQueryStringParam('filter', window.btoa(JSON.stringify(value)))
  }

  /**
   * Get the filter from a Base64 encoded JSON object from the query parameter
   */
  function urlLoadFilters(): FilterState {
    if (disableUrlSaving) return {}
    const data = getQueryStringParam('filter') ?? '{}'
    try {
      return !isSet(data) ? {} : JSON.parse(window.atob(data))
    } catch (_) {
      return {}
    }
  }

  /* URL Functions for Sorting */
  function urlSaveSorting(value?: SortingState | string | string[]) {
    const isEmptyOrDefault =
      !isSet(value) || value == (baseState?.sort ?? defaultSort)

    if (!disableUrlSaving)
      if (isEmptyOrDefault) unsetQueryStringParam('sort')
      else
        setQueryStringParam(
          'sort',
          isSortingState(value) ? ensureSortingString(value) : single(value)
        )
  }

  function urlLoadSorting(): string | null {
    return disableUrlSaving ? null : getQueryStringParam('sort')
  }

  /* URL Functions for Columns */
  function urlSaveColumns(value?: BaseApiColumnNames<T>) {
    const isEmptyOrDefault =
      !isSet(value) || isArraySimilar(value, getDefaultColumns())

    if (!disableUrlSaving)
      if (isEmptyOrDefault) unsetQueryStringParam('columns')
      else setQueryStringParam('columns', value?.join(columnSeperator))
  }

  function urlLoadColumns(): BaseApiColumnNames<T> | null {
    return disableUrlSaving
      ? null
      : (getQueryStringParam('columns')?.split(columnSeperator) as any)
  }
  //#endregion URL actions

  /**
   * Get the sorting string from the URL or baseState or defaultSort
   */
  function getSortingString() {
    return ensureSortingString(
      (urlLoadSorting() ?? baseState?.sort ?? defaultSort) as any
    )
  }

  /**
   * Apply the sorting state in the following ways:
   * - The visual sorting state in the DHTMLX grid
   * - The URL query params
   * - The save state
   * - The extra params in the base model
   *
   * Leaving the input empty will load the state reported by `getSortingString`
   */
  function loadSorting(input?: SortingState | string | string[]) {
    // Get the string from the url or default sort if input is not set
    input ??= getSortingString() as string

    // Get the actual sorting state and string
    const _sortingString = ensureSortingString(input)
    const _sortingState = ensureSortingState(input)

    // Set visual DHTML State
    visualGridSortingState = _sortingState

    // Set URL queryParam
    urlSaveSorting(_sortingString)

    // Set Save State
    // TODO:

    // Set extra params
    __storedSortParam = _sortingString
    let params = dataProxy.config.extraParams
    params.sort = _sortingString as any
    dataProxy.updateExtraParams(params)
  }

  /**
   * Get the fixed filter state from the baseState after manual fixed filters
   */
  function getFixedFilters() {
    // TODO: First fixedFilters THEN baseState.filters or the other way around?
    return merge(fixedFilters, baseState?.filter)
  }

  /**
   * Get the current filter state OR load from the url
   */
  function getFilterState() {
    return boundFilterState ?? urlLoadFilters() ?? {}
  }

  /**
   * Apply the filtering from the current filter state.
   * @param clear Set to true to clear the previous filters.
   */
  function loadFilters(clear = false) {
    boundFilterState = clear ? {} : getFilterState()

    let _storedFilter = __storedFilter

    // Update the filter
    Object.entries(boundFilterState)?.forEach(([fieldName, value]) => {
      // Set the filter object if not created before
      if (!_storedFilter) _storedFilter = {}

      // Add or remove filter conditions
      if (
        !isSet(value) ||
        !currentColumns.find(
          (column) =>
            (getColumnFilterCell(column.id)?.field ?? column.id) == fieldName
        )
      ) {
        delete _storedFilter[fieldName]
        // Also remove the filter that use an invalid/invisible column
        delete boundFilterState[fieldName]
      } else {
        _storedFilter[fieldName] = value
      }
    })

    // Set URL queryParam
    urlSaveFilters(_storedFilter)

    // Set Save State
    // TODO:

    // Set extra params
    __storedFilter = _storedFilter
    dataProxy.updateExtraParams({
      filter: getRealFilters(),
      sort: __storedSortParam as any,
    })
  }

  /**
   * Convert the visible columns to fields to add to each request
   */
  function loadFields() {
    // Process the filters and fields
    let _storedFields: (keyof T)[] = []

    _storedFields.push(...fixedFields)
    _storedFields.push(...includeFields)

    currentColumns.forEach((column) => {
      if (/[\/\\]/g.test(String(column.id))) return false
      _storedFields.push(column.id as any)
    })

    __storedFields = unique(_storedFields)

    // Get params
    let params = dataProxy.config.extraParams
    params.fields = __storedFields

    // Add expanded fields of each column if the $requiresExpand flag is set
    params.expand = __storedFields.filter(
      (field) =>
        !!currentColumns.find(
          (column) => column.$requiresExpand && column.id == field
        )
    ) as any

    dataProxy.updateExtraParams(params)
  }

  /**
   * Is the input a SortingState object?
   */
  function isSortingState(
    input: SortingState | string | string[] | null
  ): input is SortingState {
    return (
      !!isSet(input) &&
      typeof input == 'object' &&
      !!(input as SortingState).column &&
      !!(input as SortingState).direction
    )
  }

  function getApiModel() {
    if (baseState?.model) {
      const model = baseApi[baseState.model]
      if (!model) console.error('Model type not found:', baseState.model)
      return model
    } else {
      return null
    }
  }

  export function getSaveState() {
    const state: DynamicGridSaveState = {
      title: baseState?.title,
      model: baseState?.model ?? uncapitalize(apiModel.className),
      columns: getColumnNames(currentColumns),
      filter: getFilterState(),
      sort: getSortingString() ?? undefined,
    }
    return state
  }

  function getDefaultColumns() {
    return (
      overrideDefaultColumns ??
      baseState?.columns ??
      baseApi[getApiName()]?.context?.defaultColumns
    )
  }

  function getApiName() {
    return uncapitalize(apiModel?.className) as ModelReference
  }

  /** Get the actual filter used in the dataproxy */
  export function getRealFilters() {
    return merge(__storedFilter, getFixedFilters())
  }

  onMount(async () => {
    if (!apiModel) return

    await tick()

    // Get and apply the stored sorting state
    loadSorting()
    loadFilters()
    loadFields()

    // Allow the grid to load
    showGrid = true
    await load()

    dispatch('afterMount')
  })
</script>

<svelte:window
  use:handleControlKey={{
    add() {
      canAdd && handleAddClick()
    },
  }}
/>

{#if apiModel}
  <!-- This adds a button for editing the shown columns -->
  {#if !hideToolbarButtons}
    <ToolbarHook>
      {#if canReload}
        <ToolbarItem
          icon={mdiReload}
          value="Reload"
          name="gridReload"
          on:click={() => confirmUnsavedChanges(reload)}
        />
      {/if}
      {#if canReload && (canSave || canAdd)}
        <ToolbarItem separator name="gridReloadSeparator" after="gridReload" />
      {/if}
      {#if canSave}
        <ToolbarItem
          icon={mdiContentSave}
          value="Save"
          name="gridSave"
          after="gridReloadSeparator"
          on:click={() => dispatch('saveClick')}
          disabled={!$hasUnsavedChanges}
        />
      {/if}
      {#if canAdd}
        <ToolbarItem
          icon={mdiPlus}
          value="New"
          name="gridAdd"
          after="gridSave"
          on:click={handleAddClick}
        />
      {/if}
      {#if canReload || canSave || canAdd}
        <ToolbarItem spacer name="editColumnsTarget" after="gridAdd" />
      {/if}

      <ToolbarItem
        after="editColumnsTarget"
        icon={mdiCalendar}
        value="Clear Filters"
        name="gridClearFilters"
        disabled={!hasFiltersApplied}
        on:click={() => confirmUnsavedChanges(clearStoredState)}
      />

      <ToolbarItem
        icon={mdiTableEdit}
        value="Edit Columns"
        after="gridClearFilters"
        on:click={() => editColumns()}
      />

      <ImportExportToolbarItems
        hidden={!apiModel}
        {disableExport}
        disableImport={disableImport || !canAdd}
        on:export={() => exportList()}
        on:fullExport={() => exportList(false, true)}
        on:import={() => importList()}
        on:templateExport={() => exportList(true)}
      />

      <slot name="toolbar" />
    </ToolbarHook>
  {/if}

  <!-- This shows a loading container with a grid inside -->
  {#if showGrid}
    <PaginatedGrid
      on:cellClick
      on:cellDblClick
      on:cellClick={handleClick}
      on:cellDblClick={handleDblClick}
      on:pageChange={(event) => urlSavePage(event.detail)}
      on:filterChange={handleFilterChange}
      on:sortChange={handleSortChange}
      initialPage={urlLoadPage()}
      bind:filterState={boundFilterState}
      bind:sortingState={visualGridSortingState}
      bind:grid={entityGrid}
      bind:pagination={entityGridPagination}
      bind:loading
      bind:selection
      gridConfig={entityGridConfig}
      {dataProxy}
    />
  {/if}

  <!-- This is a window with a way to edit the shown columns -->
  <ColumnEditor
    bind:show={showColumnEditor}
    availableColumns={{
      ...baseApi[getApiName()]?.context?.columns,
      ...additionalColumns,
    }}
    defaultColumns={getDefaultColumns()}
    currentColumns={getColumnNames(currentColumns)}
    on:apply={handleColumnEditApply}
    compatMode={false}
  />

  <!-- This is the import dialog -->
  <ImportDialog bind:show={showImportDialog} {apiModel} />
{:else if baseState?.model}
  <div class="modelNotFound">
    <i>Error: Model type "{baseState.model}" not found</i>
  </div>
{/if}

<style lang="scss">
  .modelNotFound {
    padding: 0.5em;
    opacity: 0.75;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
</style>
