<!-- Files Component -->
<script lang="ts" context="module">
  import { displayError } from '@components/Global/StatusMessage.svelte'
  import DynamicSplitView from '@components/Utility/DynamicSplitView.svelte'
  import { API } from '@lib/ApiHandler'
  import type { File, FileDirectories } from '@models/api/ApiModels'
  import { fileApi, type DirectoryRef } from '@models/api/FileApi'
  import type { MappedEvents } from '@packages/util'
  import { any, capitalize, isSet, isString, isUuid } from '@packages/util'
  import { confirmDelete } from '@packages/util/lib/CommonAlerts'
  import { createEventDispatcher, onMount, tick } from 'svelte'
  import { toast } from 'svelte-sonner'
  import FileInfo from './FileInfo.svelte'
  import FileTree, {
    fileParentKey,
    type FileParentKey,
    type OpenFileEventData,
    type StringFilter,
  } from './FileTree.svelte'
  import FileUploadWindow from './FileUploadWindow.svelte'

  export type OpenDirectoryData = {
    id: Nullable<DirectoryRef>
    isRoot: boolean
  } & {
    [P in keyof FileDirectories as `is${Capitalize<P>}`]: boolean
  }
  _:;
</script>

<script lang="ts">
  interface $$Events {
    openFile: CustomEvent<OpenFileEventData>
    openDirectory: CustomEvent<OpenDirectoryData>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  // TODO: Use path instead of directory ID or alias for better directory traversal

  /**
   * The current directory
   */
  export let directoryId: Nullable<DirectoryRef> = null

  /**
   * Is the current directory the user's home directory?
   * @readonly
   */
  export let isHomeDirectory = true

  /**
   * The currently selected file/directory
   * @readonly
   */
  export let selectedItem: Nullable<File> = null

  /** @readonly Checks if the selected node passes the applied filters */
  export let isSelectedNodeInFilter = false

  /** Set a filter on the file type */
  export let fileTypeFilter: StringFilter = null

  let fileTree: Nullable<FileTree>
  let showUploader = false
  let uploaderParent: Nullable<Uuid>
  let _ready = false

  let commonDirectories: Nullable<FileDirectories>

  $: parentId = (directoryId ?? commonDirectories?.home) as Uuid
  $: isHomeDirectory = parentId == commonDirectories?.home

  export function reload() {
    fileTree?.load()
  }

  export function mkdir() {
    fileTree?.mkdir()
  }

  export function openUploadWindow() {
    // Get the proper parent
    const _directoryId = fileTree.getSelectedDirectory()
    uploaderParent = isUuid(_directoryId) ? _directoryId : null
    if (!uploaderParent) console.warn('Invalid Parent ID')
    showUploader = true
  }

  export function downloadSelected() {
    if (!selectedItem?.id || selectedItem?.directory) return
    API.openDirectly(['file', selectedItem.id, 'download'])
  }

  async function moveFile(fileId: Uuid, targetParent: Uuid | FileParentKey) {
    const action = fileApi
      .updateFile(fileId, {
        parent:
          targetParent == fileParentKey
            ? await getParentDirectory(parentId)
            : targetParent,
      })
      .then(() => {
        fileTree.visualMove(fileId, targetParent)
      })
      .catch(displayError)

    toast.promise(action, {
      success: 'File successfully moved.',
      error: 'There was a problem moving the file.',
      loading: 'Moving file...',
    })
  }

  export function renameSelected() {
    fileTree.editSelected()
  }

  export async function deleteSelected(): Promise<void> {
    const isDirectory = !!selectedItem.directory
    const fileTypeName = isDirectory ? 'Directory' : 'File'
    const fileName = selectedItem.name
    const fileId = selectedItem.id

    // Wait for confirmation
    if (
      !(await confirmDelete({
        entityName: fileName,
        entityType: fileTypeName,
        content: `WARNING: 'The content of this ${fileTypeName.toLocaleLowerCase()} will not be recoverable!`,
      }))
    ) {
      // If cancelled, return
      return
    }

    // Show a loading toast
    const toastId = toast.loading(`Deleting the ${fileTypeName}...`)

    // Delete the file
    return await fileApi.deleteFile(fileId, { toastId }).then(() => {
      // Deselect the item and then remove it visually
      fileTree.visualDelete(fileId as Uuid)
      selectedItem = null
      toast.success(`${fileTypeName} "${fileName}" successfully Deleted.`, {
        id: toastId,
      })
    })
  }

  /** Open the given directory */
  function openDirectory(fileId: DirectoryRef) {
    let returnData: OpenDirectoryData = {
      id: fileId,
      isHome: false,
      isPublic: false,
      isRoot: false,
      isShared: false,
      isUsers: false,
    }

    const _commonDirectoryName = getCommonDirectoryName(fileId)
    if (_commonDirectoryName) {
      returnData.id = commonDirectories[_commonDirectoryName] as Uuid
      returnData[`is${capitalize(_commonDirectoryName)}`] = true
    } else if (!isSet(fileId) || fileId == 'root') {
      returnData.id = null
      returnData.isRoot = true
    }

    directoryId = returnData.id
    dispatch('openDirectory', returnData)
  }

  function getCommonDirectoryName(directoryRef: DirectoryRef) {
    return isString(directoryRef) && commonDirectories?.[directoryRef ?? '---']
      ? (directoryRef as keyof FileDirectories)
      : null
  }

  /** Look up the parent directory and open it */
  async function openParent(fileId: Uuid) {
    const parentId = await getParentDirectory(fileId)
    openDirectory(parentId ?? 'root')
  }

  /** Get the parent directory of the given file */
  async function getParentDirectory(fileId: Uuid) {
    return (await fileApi.getDirectoryInfo(fileId, { fields: 'parent' }))
      .parent as Nullable<Uuid>
  }

  async function handleRename(fileId: Uuid, newName: string) {
    try {
      await fileApi.updateFile(fileId, {
        name: newName,
      })
      return true
    } catch (error) {
      throw displayError(error)
    }
  }

  onMount(async () => {
    commonDirectories = await fileApi.getDirectories()
    await tick()
    _ready = true
  })
</script>

<DynamicSplitView treshold={'50em'} rightSize={30}>
  <svelte:fragment slot="left">
    {#if _ready}
      <FileTree
        {parentId}
        showParent={!isHomeDirectory}
        bind:this={fileTree}
        bind:selected={selectedItem}
        bind:isSelectedNodeInFilter
        on:move={(event) =>
          moveFile(event.detail.fileId, event.detail.targetParent)}
        on:openDirectory={(event) => openDirectory(event.detail)}
        on:openParent={(event) => openParent(any(event.detail))}
        on:openFile
        renameFileHandler={handleRename}
        {fileTypeFilter}
      />
    {/if}
  </svelte:fragment>
  <svelte:fragment slot="right">
    {#if selectedItem}
      <FileInfo fileId={selectedItem.id} directory={selectedItem.directory} />
    {/if}
  </svelte:fragment>
</DynamicSplitView>

<FileUploadWindow
  bind:show={showUploader}
  targetDirectory={uploaderParent}
  on:reloadRequired={reload}
/>
