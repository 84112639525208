import { isArray } from '@amcharts/amcharts4/core'
import * as Generated from '@generated'
import { API } from '@lib/ApiHandler'
import { request, type ApiRequestOptions } from '@lib/ApiUtil'
import { isSet, isString, isUuid } from '@packages/util'
import type { File, FileDirectories } from '@models/api/ApiModels'

export type DirectoryAlias = keyof FileDirectories | 'root'
export type DirectoryRef = Uuid | DirectoryAlias

type ExtraOptions = Partial<
  Omit<ApiRequestOptions, 'url' | 'method' | 'body' | 'fullUrl'>
>

/** The url to the file endpoint */
const apiUrl = '%BASE%/file/%ID%/%ACTION%'

const directoryAliases: { [P in DirectoryAlias]: P } = {
  home: 'home',
  public: 'public',
  root: 'root',
  shared: 'shared',
  users: 'users',
}

export interface FileShare {
  exclude: boolean
  create: boolean
  read: boolean
  update: boolean
  delete: boolean
}

function getUrl(id?: string, action?: string, includeBaseUrl = false) {
  return apiUrl
    .replace(
      '%ID%',
      (isString(id) && directoryAliases[id]) || (isUuid(id) && id) || ''
    )
    .replace('%ACTION%', action ? action : '')
    .replaceAll(/\/{2,}/g, '/')
    .replace('%BASE%', includeBaseUrl ? API.baseURL : '')
    .replace(/\/$/, '')
}

/** Convert a permissions object to a string */
function getPermissions(permissions: FileShare): string {
  return ''.concat(
    permissions.exclude ? 'E' : '',
    permissions.create ? 'C' : '',
    permissions.read ? 'R' : '',
    permissions.update ? 'U' : '',
    permissions.delete ? 'D' : ''
  )
}

/** Convert string to a permissions object */
function parsePermissions(permissions: string): FileShare {
  if (!isSet(permissions) || typeof permissions != 'string')
    return {
      exclude: false,
      create: false,
      read: false,
      update: false,
      delete: false,
    }

  return {
    exclude: permissions.includes('E'),
    create: permissions.includes('C'),
    read: permissions.includes('R'),
    update: permissions.includes('U'),
    delete: permissions.includes('D'),
  }
}

export const fileApi = {
  getUrl,
  getPermissions,
  parsePermissions,

  /** Get the directory content (defaults to the user directory) */
  async getDirectory(fileId?: string, extraOptions?: ExtraOptions) {
    const item = await request<File[] | File>({
      ...extraOptions,
      url: getUrl(fileId),
      body: {
        expand: isSet(fileId) ? 'nodes' : '',
      },
    })

    return isArray(item) ? item : item.nodes
  },

  /** Get the file or directory information */
  getInfo(
    fileId: string,
    extraParams?: Record<any, any>,
    extraOptions?: ExtraOptions
  ) {
    return request<File>({
      ...extraOptions,
      url: getUrl(fileId),
      body: {
        expand: 'previewSize,size',
        ...extraParams,
      },
    })
  },

  /** Get the file or directory information */
  getDirectoryInfo(
    fileOrDirectoryRef: Uuid | DirectoryRef,
    extraParams?: Record<any, any>,
    extraOptions?: ExtraOptions
  ) {
    return request<File>({
      ...extraOptions,
      url: getUrl(null, 'get-directory'),
      body: {
        ...extraParams,
        id: fileOrDirectoryRef,
      },
    })
  },

  /** Get the file or directory information */
  getByPath(
    path: string,
    extraParams?: Record<any, any>,
    extraOptions?: ExtraOptions
  ) {
    return request<File[]>({
      ...extraOptions,
      url: getUrl(null, 'get-by-path'),
      body: {
        ...extraParams,
        path,
      },
    })
  },

  /** Download the given file, return it's content (Raw) */
  getFileData(fileId: string, extraOptions?: ExtraOptions): Promise<any> {
    return request({
      ...extraOptions,
      url: getUrl(fileId, 'download'),
      method: 'GET',
      returnResponse: true,
    })
  },

  /** Download the given file to the user's downloads directory */
  downloadFile(fileId: string): void {
    API.openDirectly(getUrl(fileId, 'download'))
  },

  /** Delete the given file */
  deleteFile(fileId: string, extraOptions?: ExtraOptions): Promise<void> {
    return request<void>({
      ...extraOptions,
      url: getUrl(fileId),
      method: 'DELETE',
    })
  },

  /** Update the given file data */
  updateFile(
    fileId: string,
    data: Partial<File>,
    extraOptions?: ExtraOptions
  ): Promise<File> {
    return request<File>({
      ...extraOptions,
      url: getUrl(fileId),
      method: 'PUT',
      body: data,
    })
  },

  /** Create the given file data */
  mkdir(
    name: string,
    parent?: Uuid,
    config?: File['config'],
    extraOptions?: ExtraOptions
  ): Promise<File> {
    return request<File>({
      ...extraOptions,
      url: getUrl(),
      method: 'POST',
      body: <Partial<File>>{
        config,
        name,
        parent,
      },
    })
  },

  /**
   * Share the given file from the given group
   * (Or update its permissions)
   */
  share(
    fileId: string,
    groupId: string,
    permissions: FileShare,
    extraOptions?: ExtraOptions
  ) {
    return request<Generated.File_Group>({
      ...extraOptions,
      url: getUrl(fileId, 'share'),
      method: 'POST',
      body: { groupId, permissions },
    })
  },

  /** Unshare the given file from the given group */
  unshare(fileId: string, groupId: string, extraOptions?: ExtraOptions) {
    return request<void>({
      ...extraOptions,
      url: getUrl(fileId, 'unshare'),
      method: 'DELETE',
      body: { groupId },
    })
  },

  /** Get the groups that the given file is shared with (including their permissions) */
  getShares(fileId: string, extraOptions?: ExtraOptions) {
    return request<Generated.File_Group[]>({
      ...extraOptions,
      url: getUrl(fileId, 'get-group-relations'),
    })
  },

  /** Get Common Directories */
  getDirectories(extraOptions?: ExtraOptions) {
    return request<FileDirectories>({
      ...extraOptions,
      url: getUrl(null, 'directories'),
    })
  },
}
