// GlobalReminder Component handles the changing of pages
// It reminds the user about unsaved changes before trying to change pages

import { isSet } from '@packages/util'
import { confirm } from '@packages/util/lib/CommonAlerts'
import {
  pop as defaultPop,
  push as defaultPush,
  replace as defaultReplace,
} from 'svelte-spa-router'
import { get, writable } from 'svelte/store'

export const hasUnsavedChanges = writable(false)

/** Internal refferer state, set to true to mark as coming from the same site */
let _refferer: Nullable<string | true> = document.referrer

/**
 * This attaches a page unload listener to display the native window when unloading the page
 */
export function attachPageUnloadListener() {
  window.addEventListener('beforeunload', function (e) {
    // Only apply when the page has unsaved changes
    if (!get(hasUnsavedChanges)) {
      return undefined
    }

    // This is ignored in most browsers, but set it anyways just in case
    let confirmationMessage = 'The current page has unsaved changes, Continue?'

    ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
    return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
  })
}

/**
 * Wrapper function to confirm before running
 * the function when the page has unsaved changes
 */
export async function confirmUnsavedChanges(func?: () => void) {
  if (!get(hasUnsavedChanges)) {
    func?.()
    hasUnsavedChanges.set(false)
    return true
  }

  const confirmed = await confirm({
    title: 'Unsaved Changes',
    content: 'The current page has unsaved changes, Continue?',
  })
  if (confirmed) {
    hasUnsavedChanges.set(false)
    func?.()
  }
  return confirmed
}

/**
 * (Overrides svelte-spa-router action)
 * Navigates to a new page programmatically.
 *
 * When executed, it will set an internal flag that makes the
 * `pop` action go to the previous page instead the default home page.
 *
 * @param location Path to navigate to (must start with `/` or '#/')
 * @returns Promise that resolves after the page navigation has completed
 */
export function push(location: string): Promise<void> {
  _refferer = true
  return defaultPush(location)
}

/**
 * (Overrides svelte-spa-router action)
 *
 * Navigates back in history (equivalent to pressing the browser's back button).
 *
 * When coming from a different site, it will go home instead
 *
 * @returns Promise that resolves after the page navigation has completed
 */
export function pop(): Promise<void> {
  if (
    isSet(_refferer) &&
    (_refferer === true ||
      new URL(_refferer).origin == document.location.origin)
  ) {
    return defaultPop()
  } else {
    return defaultReplace('/')
  }
}

/**
 * Wrapper for the pop function of spa-router that confirms
 */
export function safePop() {
  return confirmUnsavedChanges(pop)
}

/**
 * Wrapper for the replace function of spa-router that confirms
 */
export async function safeReplace(route: string) {
  return confirmUnsavedChanges(() => defaultReplace(route))
}

/**
 * Wrapper for the push function of spa-router that confirms
 */
export async function safePush(route: string) {
  return confirmUnsavedChanges(() => push(route))
}
