import * as Generated from '@generated'
import { inputColumn } from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.ProcessStep> = {
  columns: {
    name: () => inputColumn('name'),
  },
  filters: {
    description: 'string',
    name: 'string',
    number: 'numeric',
    processId: { model: 'process' },
    script: null,
  },
  defaultColumns: [],
  defaultSort: 'number',
  nameField: 'name',
}
