// Object with SVG Icons from https://materialdesignicons.com/

import { mdiProgressQuestion } from '@mdi/js'
import { cssUnit, isString } from '@packages/util'

export type IconOptions = {
  spacing: number
  opacity: number
  size: number
}

export type IconPath = Nullable<string>

/**
 * Get the SVG element containing the desired icon
 *
 * @param iconName The icon to render
 * @param width The width of the icon
 * @param height The height of the icon
 * @returns The SVG element for use in HTML
 */
export function getSvg(
  iconName: IconPath,
  width?: number | string,
  height?: number | string,
  style?: string,
  box_width?: number,
  box_height?: number
) {
  const _width = cssUnit(width) ?? '24px'
  const _height = cssUnit(height) ?? '24px'

  const _box_width = isNaN(box_width ?? NaN) ? 24 : box_width
  const _box_height = isNaN(box_height ?? NaN) ? 24 : box_height

  const path =
    !isString(iconName) || iconName == 'empty'
      ? ''
      : /^M\d*.*/.test(iconName)
        ? iconName
        : (() => {
            // TODO: Deprecate name
            console.error('Icon name used! This will be deprecated.')
            return mdiProgressQuestion
          })()

  return `<svg xmlns="http://www.w3.org/2000/svg" style="fill:currentColor;width:${_width};height:${_height};${
    style ?? ''
  }" viewBox="0 0 ${_box_width} ${_box_height}"><path d="${path}"></svg>`
}

/**
 * Get the SVG element encoded within a data URI (in base64)
 *
 * @param iconName The icon to convert
 * @param color The fill color
 * @returns The data URI with the encoded svg
 */
export function getSrc(iconName: IconPath, color?: string) {
  return `data:image/svg+xml;base64,${window.btoa(
    getSvg(iconName, null, null, `fill:${color ?? 'black'};`)
  )}`
}

export function getToolbarIcon(
  iconName: IconPath,
  text: string = null,
  options?: IconOptions
) {
  return `<div class="icon">${getSvg(
    iconName,
    options?.size,
    null,
    `margin-right: ${text ? options?.spacing ?? 8 : 0}px; opacity: ${
      options?.opacity ?? 1
    };`
  )}${text ?? ''}</div>`
}
