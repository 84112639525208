import type * as Generated from '@generated'
import { inputColumn } from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.Dashboard> = {
  columns: {
    name: () => inputColumn('name'),
  },
  filters: {
    config: null,
    name: 'string',
    userId: { model: 'user' },
  },
  defaultColumns: [],
  defaultSort: 'name',
  nameField: 'name',
}
