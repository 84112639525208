<!-- InputFilter Component -->
<script lang="ts" context="module">
  import {
    getOptions,
    type EnumEditorDerivedValue,
  } from '@components/FormControls/EnumEditorDerived.svelte'
  import { debounce, getId, isSet } from '@packages/util'
  import ComboboxAsync, { type ComboboxItem } from '../ComboboxAsync.svelte'
  import type { EnumEditorItemBase } from '@components/FormControls/EnumEditor.svelte'

  /** Magic value for null */
  const nullValue = getId()

  export interface EnumFilterDynamicConfig {
    options?: EnumEditorDerivedValue
  }
</script>

<script lang="ts">
  export let value: Nullable<string> = null // Selected item value
  export let config: EnumFilterDynamicConfig = {}

  const reloadText = debounce((_: unknown) => {
    if (_changed) return
    comboboxAsync?.invalidate()
  }, 100)

  let _changed = false
  let dataPromise: Nullable<Promise<EnumEditorItemBase[]>> = null
  let comboboxAsync: Nullable<ComboboxAsync>

  $: reloadText(value)

  async function getData(): Promise<ComboboxItem[]> {
    return [{ id: nullValue, value: '' }].concat(
      (await getDataPromise())?.map<ComboboxItem>((item) => ({
        id: item.value as any,
        value: item.name,
      }))
    )
  }

  function getDataPromise() {
    if (dataPromise) return dataPromise
    dataPromise = getOptions(config?.options)
    return dataPromise
  }

  async function getCurrent(id: string) {
    return (await getDataPromise()).find((item) => item.value == id)?.name ?? ''
  }

  /** Properly convert the null value on change */
  function handleChange(event: CustomEvent<string>) {
    const newValue = event.detail
    value = newValue === nullValue || !isSet(newValue) ? null : newValue
  }
</script>

<div class="entityFilter">
  <ComboboxAsync
    {getData}
    {getCurrent}
    selected={value}
    on:change={handleChange}
  />
</div>

<style lang="scss">
  .entityFilter {
    width: 100%;
    height: 100%;
    padding: 0.25em 0;

    display: flex;
    justify-content: center;
    align-items: center;

    :global(.combobox) {
      height: 100%;
      width: 100%;
    }
  }
</style>
