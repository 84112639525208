import type * as Generated from '@generated'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.DashboardWidget> = {
  columns: {},
  filters: {
    dashboardId: { model: 'dashboard' },
    type: 'string',
    posX: 'integer',
    posY: 'integer',
    width: 'integer',
    height: 'integer',
    config: null,
  },
  defaultColumns: [],
  defaultSort: '-modifiedOn',
  nameField: 'id',
}
