import type { EnumEditorDerivedValue } from '@components/FormControls/EnumEditorDerived.svelte'
import EnumDisplayDynamic from '@components/GridDisplay/EnumDisplayDynamic.svelte'
import type { DateTimeFilterConfig } from '@components/GridFilterControls/DateTimeFilter.svelte'
import type { EntityFilterConfig } from '@components/GridFilterControls/EntityFilter.svelte'
import type { EnumFilterDynamicConfig } from '@components/GridFilterControls/EnumFilterDynamic.svelte'
import type { SelectFilterConfig } from '@components/GridFilterControls/SelectFilter.svelte'
import { componentFilter } from '@components/PaginatedGrid.svelte'
import Button from '@components/Utility/Button.svelte'
import type { ICol, IHeader } from '@dhtmlx/ts-grid'
import * as Generated from '@generated'
import { mdiFileDownload } from '@mdi/js'
import { getGeneratedEnumItems } from '@models/api/ApiEnumLabels'
import type { ModelDereference, ModelReference } from '@models/api/BaseApi'
import type { ModelFilter, ModelSort } from '@models/api/BaseModel'
import { dateFormattingRules } from '@packages/locale/lib/DateFormatter'
import { dateFormatter } from '@packages/locale/lib/dateFormatterStore'
import { ComponentRenderConfig, createRender } from 'svelte-render'
import { get } from 'svelte/store'
import { API } from './ApiHandler'
import {
  camel2title,
  capitalize,
  fuzzyCompare,
  isSet,
  pushNew,
} from '@packages/util'
import { staticRender } from '@packages/util'
import type { StringKeyOf } from '../packages/util/lib/UtilityTypes'

export interface ColumnOptions {
  /**
   * Override title
   * Usually gets infered from the name (camelCased, [at]formattedText removed)
   */
  title?: string

  /**
   * Override field
   * Usually gets infered from the name (camelCased, [at]formattedText removed)
   */
  field?: string

  /**
   * Use native filter instead of the custom filter
   */
  useNativeFilter?: boolean

  /**
   * Disable the filter entirely
   */
  disableFilter?: boolean

  /**
   * Disable the sorting entirely
   */
  disableSorting?: boolean

  /**
   * Extra column options
   */
  config?: Partial<ICol>

  /**
   * Does the field require expanding in the backend?
   */
  requiresExpand?: boolean

  /**
   * The component to render for each displayed item
   */
  displayComponent?: (value: any) => ComponentRenderConfig

  /**
   * Extra display config for the `displayComponent`
   * (Requires `displayComponent` to be set)
   */
  displayComponentConfig?: {
    emptyRow?: boolean
    emptyCell?: boolean
    tooltip?: boolean
  }
}

export function formatDateTime(formatting?: Intl.DateTimeFormatOptions) {
  formatting ??= dateFormattingRules.fullNumeric
  return function (value: string) {
    if (!value) return ''
    return get(dateFormatter).getDerived(formatting).format(new Date(value))
  }
}

export function filterDateTime(item: any, value: string) {
  return get(dateFormatter).format(new Date(item)).startsWith(value)
}
//#endregion Formatters and Filters

function getTitle(name: string) {
  return capitalize(camel2title(name).replace(/^.*\//, '').replace(/\@.*/, ''))
}

function getField(name: string) {
  return name.replace(/^.*\//, '').replace(/\@.*/, '')
}

export function baseColumn(
  name: string,
  filter: IHeader,
  type: 'string' | 'number' | 'boolean' | 'date' | 'percent' = 'string',
  options?: ColumnOptions
): ICol {
  return {
    id: name,
    type: type,
    header: [
      { text: options?.title ?? getTitle(name) },
      ...(options?.disableFilter ? [] : [filter]),
    ],
    sortable: !options?.disableSorting,
    ...(options?.config ?? {}),
    //@ts-ignore Add custom field
    $requiresExpand: options?.requiresExpand ?? false,
    // Add static rendered component
    ...(options?.displayComponent
      ? {
          htmlEnable: true,
          tooltip: options?.displayComponentConfig?.tooltip ?? false,
          template: (cellValue, row) =>
            (row.$emptyRow && !options?.displayComponentConfig?.emptyRow) ||
            (!isSet(cellValue) && !options?.displayComponentConfig?.emptyCell)
              ? null
              : staticRender(options.displayComponent(cellValue)),
        }
      : {}),
  }
}

/** A column where you can filter by a matching value */
export function inputColumn(name: string, options?: ColumnOptions): ICol {
  return baseColumn(
    name,
    options?.useNativeFilter
      ? { content: 'inputFilter', customFilter: fuzzyCompare }
      : componentFilter(
          'InputFilter',
          undefined,
          options?.field ?? getField(name)
        ),
    'string',
    options
  )
}

/** A column without any filters or sorting available */
export function dummyColumn(name: string, options?: ColumnOptions): ICol {
  options ??= {}
  options.disableFilter = true
  options.disableSorting = true
  return baseColumn(name, null, 'string', options)
}

/** A column where you can filter by an entity */
export function entitySelectColumn<T extends ModelReference>(
  name: string,
  apiModel: T,
  options?: ColumnOptions & {
    valueField?: StringKeyOf<ModelDereference<T>>
    idField?: StringKeyOf<ModelDereference<T>>
    filter?: ModelFilter<ModelDereference<T>>
    sort?: ModelSort<ModelDereference<T>>
  }
): ICol {
  return baseColumn(
    name,
    options?.useNativeFilter
      ? { content: 'selectFilter' }
      : componentFilter(
          'EntityFilter',
          <EntityFilterConfig<any>>{
            apiModel,
            valueField: options?.valueField,
            idField: options?.idField,
            filter: options?.filter,
            sort: options?.sort,
          },
          options?.field ?? getField(name)
        ),
    'string',
    options
  )
}

/** A column where you can filter by a set of options */
export function selectColumn(
  name: string,
  items: SelectFilterConfig['options'],
  options?: ColumnOptions
): ICol {
  if (options?.useNativeFilter) {
    return {
      ...baseColumn(
        name,
        {
          content: 'comboFilter',
          filterConfig: {
            template(item) {
              return items.find((_item) => _item.value == item.value).content
            },
          },
        },
        'string',
        options
      ),
    }
  } else {
    return baseColumn(
      name,
      componentFilter(
        'SelectFilter',
        { options: items },
        options?.field ?? getField(name)
      ),
      'string',
      options
    )
  }
}

/** A column where you can filter by true or false */
export function booleanColumn(
  name: string,
  options?: ColumnOptions & {
    trueText: string
    falseText: string
  }
): ICol {
  const trueText = options?.trueText ?? 'True'
  const falseText = options?.falseText ?? 'False'

  return {
    ...selectColumn(
      name,
      [
        { value: '1', content: trueText },
        { value: '0', content: falseText },
      ],
      options
    ),
    template(cellValue: string) {
      return !!cellValue ? trueText : falseText
    },
    ...(options?.config ?? {}),
  }
}

// NOTE: Currently Unsupported
// export function innerStringColumn(
//   name: string,
//   field: string,
//   title: string = null,
//   fallback: string = ''
// ): ICol {
//   return {
//     id: name,
//     template: (value) => {
//       return value?.[field] ? value[field] : fallback
//     },
//     type: 'string',
//     header: [
//       { text: options?.title ?? getTitle(name) },
//       {
//         content: 'inputFilter',
//         customFilter(item, input) {
//           return fuzzyCompare(String(item[field]), input)
//         },
//       },
//     ],
//   }
// }

/** A column where you can filter by dateTime */
export function dateTimeColumn(
  name: string,
  options?: ColumnOptions & {
    formatting?: Intl.DateTimeFormatOptions
    noTime?: boolean
  }
): ICol {
  return {
    ...baseColumn(
      name,
      options?.useNativeFilter
        ? { content: 'inputFilter', customFilter: filterDateTime }
        : componentFilter(
            'DateTimeFilter',
            <DateTimeFilterConfig>{ noTime: options?.noTime ?? false },
            options?.field ?? getField(name)
          ),
      'string',
      options
    ),
    template: formatDateTime(options?.formatting),
    ...(options?.config ?? {}),
  }
}

/** A column that displays an image */
export function imageColumn(name: string, options?: ColumnOptions): ICol {
  options ??= {}
  options.disableFilter = true
  options.disableSorting = true
  return {
    ...baseColumn(name, undefined, 'string', options),
    template: (value) =>
      // Uses global style gridImageColumnItem. Hides on error
      `<img class="gridImageColumnItem" src="${value}" onerror="this.style.display='none'" />`,
    htmlEnable: true,
    width: 5 * 16,
    tooltip: false,
    ...(options?.config ?? {}),
  }
}

/** A column where you can filter by a specific user */
export function userColumn(name: string, options?: ColumnOptions) {
  return entitySelectColumn(name, 'user', {
    valueField: 'fullName',
    ...options,
  })
}

/**
 * A column where you can filter by a value based on an enum
 *
 * @param name The name of the column
 * @param enumItems The enum from the generated models
 * @param options Extra options and overrides
 * @returns The column definition
 */
export function generatedEnumColumn(
  name: string,
  enumItems: object,
  options?: ColumnOptions & {
    hide?: any[] // Hide the following enum values
    fallback?: string | number
  }
): ICol {
  const items = getGeneratedEnumItems(enumItems)
  return {
    ...selectColumn(
      name,
      items
        .map((item) => ({ value: String(item.value), content: item.label }))
        .filter(
          (item) => !(options?.hide?.some?.((_i) => _i == item.value) ?? false)
        ),
      options
    ),
    template(cellValue, row) {
      // Return nothing on empty row
      if (row.$empty) return
      // Return the label corresponding to the enum value
      return (
        items?.find((item) => item.value == cellValue)?.label ??
        (isSet(options?.fallback)
          ? // Defaults to the label of the fallback enum value if set
            items?.find((item) => item.value == options?.fallback)?.label
          : null)
      )
    },
    ...(options?.config ?? {}),
  }
}

export function dynamicEnumColumn(
  name: string,
  refOrItems: EnumEditorDerivedValue,
  options?: ColumnOptions
): ICol {
  return baseColumn(
    name,
    options?.useNativeFilter
      ? { content: 'selectFilter' }
      : componentFilter(
          'EnumFilterDynamic',
          <EnumFilterDynamicConfig>{
            options: refOrItems,
          },
          options?.field ?? getField(name)
        ),
    'string',
    {
      displayComponent: (value) =>
        createRender(EnumDisplayDynamic, { value, ref: refOrItems }),
      ...options,
    }
  )
}

export function downloadLatestReportRenderer(value: string) {
  const href = `${API.baseURL}/report/${value}/download`

  return createRender(Button, {
    value: 'Download',
    href,
    small: true,
    icon: mdiFileDownload,
  }).on('click', () => pushNew(href, true))
}

export const commonColumns: Record<string, ICol> = {
  modifiedBy: userColumn('modifiedBy@formattedText', { field: 'modifiedBy' }),
  modifiedOn: dateTimeColumn('modifiedOn'),
  createdBy: userColumn('createdBy@formattedText', { field: 'createdBy' }),
  createdOn: dateTimeColumn('createdOn'),
  status: generatedEnumColumn('status', Generated.BaseModel_STATUSEnum, {
    // Only the admin can see deleted items
    // hide: !API.userHas(apiAdminRolename) ? [Generated.BaseModel_STATUSEnum.deleted] : [],
    hide: [Generated.BaseModel_STATUSEnum.deleted],
  }),
  batchStatus: generatedEnumColumn('status', Generated.Batch_STATUSEnum, {
    // Only the admin can see deleted items
    hide: [Generated.Batch_STATUSEnum.deleted],
    // hide: !API.userHas(apiAdminRolename) ? [Generated.Batch_STATUSEnum.deleted] : [],
  }),
}

export const commonColumnsNative: Record<string, ICol> = {
  modifiedBy: userColumn('modifiedBy@formattedText', {
    field: 'modifiedBy',
    useNativeFilter: true,
  }),
  modifiedOn: dateTimeColumn('modifiedOn', { useNativeFilter: true }),
  createdBy: userColumn('createdBy@formattedText', {
    field: 'createdBy',
    useNativeFilter: true,
  }),
  createdOn: dateTimeColumn('createdOn', { useNativeFilter: true }),
  status: generatedEnumColumn('status', Generated.BaseModel_STATUSEnum, {
    useNativeFilter: true,
    // Only the admin can see deleted items
    // hide: !API.userHas(apiAdminRolename) ? [Generated.BaseModel_STATUSEnum.deleted] : [],
    hide: [Generated.BaseModel_STATUSEnum.deleted],
  }),
  batchStatus: generatedEnumColumn('status', Generated.Batch_STATUSEnum, {
    useNativeFilter: true,
    // Only the admin can see deleted items
    hide: [Generated.Batch_STATUSEnum.deleted],
    // hide: !API.userHas(apiAdminRolename) ? [Generated.Batch_STATUSEnum.deleted] : [],
  }),
}

/**
 * The columns related to any model extending from BaseModel
 */
export const baseModelColumns: ICol[] = [
  commonColumns.status,
  commonColumns.modifiedBy,
  commonColumns.modifiedOn,
  commonColumns.createdBy,
  commonColumns.createdOn,
]

export const baseModelColumnsNative: ICol[] = [
  commonColumnsNative.status,
  commonColumnsNative.modifiedBy,
  commonColumnsNative.modifiedOn,
  commonColumnsNative.createdBy,
  commonColumnsNative.createdOn,
]
