// https://www.npmjs.com/package/svelte-spa-router
import DefaultLayout from '@layouts/DefaultLayout.svelte'
import LoadingView from '@pages/LoadingView.svelte'
import type { WrappedComponent } from 'svelte-spa-router'
import { wrap as _wrap, type WrapOptions } from 'svelte-spa-router/wrap'
import { API } from './ApiHandler'
import type { Permission } from './Permissions'
import { isSet } from '@packages/util'

export interface Routes {
  index?: WrappedComponent
  [route: string]: Routes | WrappedComponent | undefined
}

function isRoute(
  obj: Routes | WrappedComponent | undefined
): obj is WrappedComponent {
  return (
    obj !== null &&
    obj !== undefined &&
    typeof obj == 'object' &&
    !!obj?.component
  )
}

export function flattenRoutes(obj: Routes, parent?: string) {
  let output: Record<string, WrappedComponent> = {}

  for (const prop in obj) {
    const propName = `${parent ?? ''}/${prop}`
      .replace(/\/index$/g, '') // Remove `/index`
      .replace(/\\index/, 'index') // Keep escaped `\\index` (renames to `index`)
      .replace(/^\s*$/, '/') // Replace empty string with `/`

    if (!isRoute(obj[prop]))
      output = { ...output, ...flattenRoutes(obj[prop] as Routes, propName) }
    else output[propName] = obj[prop] as WrappedComponent
  }

  return output
}

export const showRouteIf = <T extends Record<any, any>>(
  state: boolean,
  obj: T
): T | {} => (state ? obj : {})

/**
 * AsyncComponent that requires the user to be both logged in
 * and optionally have the required roles and/or permissions
 */
export function requirePermissions(
  asyncComponent: () => any,
  requiredPermission?: Permission | Permission[]
): () => Promise<any> {
  return async () => {
    if (!(await API.isUserLoggedIn())) {
      return import('../pages/errorViews/NotLoggedIn.svelte')
    }

    if (isSet(requiredPermission) && !API.userHas(requiredPermission, true)) {
      return import('../pages/errorViews/InsufficientPermissions.svelte')
    }

    return asyncComponent()
  }
}

/**
 * This function handles the login screen.
 * It tries to redirect the user if already logged in.
 */
export async function loginComponent(): Promise<any> {
  if (await API.isUserLoggedIn()) {
    return import('../pages/errorViews/AlreadyLoggedIn.svelte')
  } else {
    return import('../pages/Login.svelte')
  }
}

// Initialize with default values
export function wrap(args: WrapOptions) {
  return _wrap({
    // @ts-ignore
    loadingComponent: LoadingView,
    userData: {
      layout: DefaultLayout,
    },
    ...args,
  })
}
