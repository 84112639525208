import * as Generated from '@generated'
import { inputColumn, selectColumn, userColumn } from '@lib/CommonColumns'
import { camel2title } from '@packages/util'
import { baseApi, type ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.View> = {
  columns: {
    name: () => inputColumn('name'),
    entity: () =>
      selectColumn(
        'entity',
        Object.keys(baseApi).map((key) => ({
          content: camel2title(key),
          value: key,
        }))
      ),
    columns: () => inputColumn('columns'),
    owner: () => userColumn('owner@formattedText', { field: 'owner' }),
  },
  filters: {
    columns: 'string',
    entity: 'string',
    filter: null,
    name: 'string',
    owner: { model: 'user' },
    sortColumn: 'string',
    sortDirection: 'string',
  },
  defaultColumns: ['name', 'entity', 'owner'],
  defaultSort: 'name',
  nameField: 'name',
  customListSupport: true,
}
