<script lang="ts" context="module">
  import EditableGrid, {
    EditableGridColumnDataType,
  } from '@components/EditableGrid.svelte'
  import { onDestroy, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export interface EnumEditorConfig {
    labelColumnTitle?: string
    nameColumnTitle?: string
  }

  export const _name = 'EnumEditor'

  export interface EnumEditorItemBase {
    name: string
    value: string | number
  }
</script>

<script lang="ts">
  export let value: Nullable<EnumEditorItemBase[]> = []
  export let config: EnumEditorConfig = {}
  export let label = 'Form Editor'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null
  export let readOnly = false

  let editableGrid: Nullable<EditableGrid>
  let _ready = false

  export function validate() {
    return editableGrid?.validate() && Array.isArray(value) && value.length > 0
  }

  onMount(async () => {
    await tick()
    _ready = true
  })

  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <div class="editableGrid">
    {#if _ready}
      <EditableGrid
        {readOnly}
        bind:this={editableGrid}
        columns={[
          {
            label: config?.labelColumnTitle ?? 'Name',
            name: 'name',
            dataType: EditableGridColumnDataType.uniqueString,
          },
          {
            label: config?.nameColumnTitle ?? 'Value',
            name: 'value',
            dataType: EditableGridColumnDataType.uniqueString,
          },
        ]}
        bind:value
      />
    {/if}
  </div>
</FormElementBase>

<style lang="scss">
  .editableGrid {
    height: 20em;
  }
</style>
