<!-- New window component that uses a Proxy component instead. Allows for better integration with DHTMLX -->
<script lang="ts">
  import LocalToolbar from '@components/Toolbar/LocalToolbar.svelte'
  import { cssUnit } from '@packages/util'
  import type { MappedEvents } from '@packages/util'
  import { mdiClose } from '@mdi/js'
  import { createEventDispatcher, onMount, tick } from 'svelte'
  import ToolbarItem from './Toolbar/ToolbarItem.svelte'
  import ProxySource from './Utility/ProxySource.svelte'
  import { globalWindowTarget } from './Global/GlobalWindowTarget.svelte'

  interface $$Events {
    afterOpen: CustomEvent<never>
    beforeOpen: CustomEvent<never>
    afterClose: CustomEvent<never>
    beforeClose: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let hideToolbars = false
  export let noPadding = false
  export let show = false
  // export let popup = false // TODO: Add Popup mode

  /** width in pixels (number) or css units (string). Set to null to auto size */
  export let width: Nullable<number | string> = null

  /** height in pixels (number) or css units (string). Set to null to auto size */
  export let height: Nullable<number | string> = null

  /** max width in pixels (number) or css units (string). Set to null to auto size */
  export let maxWidth: Nullable<number | string> = null

  /** max height in pixels (number) or css units (string). Set to null to auto size */
  export let maxHeight: Nullable<number | string> = null

  /** Title to show when not using a custom top toolbar */
  export let title = 'Title Text'

  let _modalShown = false
  let _stateLock = true

  let _hasTopToolbar = false
  let _hasBottomToolbar = false

  $: state(show)

  function state(shown: boolean) {
    if (_stateLock) return
    if (shown) open()
    else close()
  }

  async function open() {
    if (!dispatch('beforeOpen', null, { cancelable: true })) return

    _stateLock = true
    _modalShown = true
    show = true

    await tick()

    _stateLock = false
    dispatch('afterOpen')
  }

  async function close() {
    if (!dispatch('beforeClose', null, { cancelable: true })) return

    _stateLock = true
    _modalShown = false
    show = false

    await tick()

    _stateLock = false
    dispatch('afterClose')
  }

  onMount(async () => {
    await tick()
    _stateLock = false
    if (show) open()
  })
</script>

{#if globalWindowTarget}
  <ProxySource components={globalWindowTarget}>
    {#if _modalShown}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <div
        class="window"
        on:click={(event) => {
          // Override close event
          close()
          event.preventDefault()
        }}
        class:noPadding
      >
        <div
          class="windowContentWrapper"
          style:width={cssUnit(width)}
          style:height={cssUnit(height)}
          style:max-width={cssUnit(maxWidth)}
          style:max-height={cssUnit(maxHeight)}
          on:click|stopPropagation={() => {}}
        >
          {#if !hideToolbars}
            <div class="topToolbar">
              <LocalToolbar on:ready={() => (_hasTopToolbar = true)}>
                <slot name="topToolbar">
                  <ToolbarItem value={title} type="title" css="toolbarTitle" />
                  <ToolbarItem spacer />
                  <slot name="extraTopButtons" />
                  <ToolbarItem
                    icon={mdiClose}
                    css="circular"
                    on:click={close}
                  />
                </slot>
              </LocalToolbar>
            </div>
          {/if}
          <div
            class="windowContent"
            class:windowPadding={!noPadding}
            class:windowPaddingTop={!noPadding && !_hasTopToolbar}
            class:windowPaddingBottom={!noPadding && !_hasBottomToolbar}
          >
            <slot />
          </div>
          {#if $$slots.bottomToolbar && !hideToolbars}
            <div class="bottomToolbar">
              <LocalToolbar on:ready={() => (_hasBottomToolbar = true)}>
                <slot name="bottomToolbar" />
              </LocalToolbar>
            </div>
          {/if}
        </div>
      </div>
    {/if}
  </ProxySource>
{/if}

<style lang="scss">
  @use '../theme/variables' as vars;

  $padding: 1em;

  .window {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    border: none;
    position: absolute;

    box-shadow: vars.$shadowMd;
    z-index: vars.$zId-popups;

    display: flex;
    align-items: center;
    justify-content: center;

    animation: show-backdrop 0.2s ease-in-out forwards;

    &.noPadding {
      padding: 0;
    }
  }

  .windowContentWrapper {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: $padding;

    animation: appear 0.2s ease-in-out forwards;
    box-shadow: vars.$shadowMd;
  }

  .windowContent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &.windowPadding {
      padding-left: $padding;
      padding-right: $padding;
    }

    &.windowPaddingTop {
      padding-top: $padding;
    }

    &.windowPaddingBottom {
      padding-bottom: $padding;
    }
  }

  :global(.toolbarTitle .dhx_navbar-title) {
    color: rgba(0, 0, 0, 0.7) !important;
    font-weight: 500 !important;
    font-size: 18px !important;
  }

  @keyframes show-backdrop {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
</style>
