<script lang="ts" context="module">
  import DOMPurify from 'dompurify'
  import FormElementBase from './FormElementBase.svelte'
  export const _name = 'TextDisplay'
</script>

<script lang="ts">
  export let value = ''
  export let label = 'Text Display'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  $: sanitizedValue = DOMPurify.sanitize(value)
  sanitizedValue = DOMPurify.sanitize(value)
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <div class:state_error={state == 'error'}>
    {@html sanitizedValue ?? ''}
  </div>
</FormElementBase>

<style lang="scss">
  @use '../../theme/variables' as vars;

  .state_error {
    color: vars.$error;
  }
</style>
