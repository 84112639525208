<script lang="ts">
  import LoadingSpinnerSmall from '@components/Utility/LoadingSpinnerSmall.svelte'
  import { fileApi } from '@models/api/FileApi'
  import FilePickerDialog from '@packages/fileManager/components/FilePickerDialog.svelte'
  import FilePreview from '@packages/fileManager/components/FilePreview.svelte'
  import { interact, isSet, isUuid, type MappedEvents } from '@packages/util'
  import { createEventDispatcher } from 'svelte'
  import type { StringFilter } from './FileTree.svelte'
  import { tooltip } from '@components/Global/GlobalTooltip.svelte'

  interface $$Events {
    change: CustomEvent<Uuid>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let value: Nullable<Uuid> = null
  export let title: Nullable<string> = null
  export let fileTypeFilter: StringFilter = null
  export let readOnly = false

  let show = false

  async function getName(fileId: Uuid) {
    if (!isSet(fileId)) return 'File Picker'
    if (!isUuid(fileId)) return '[Invalid File]'

    return fileApi
      .getInfo(fileId, null, { displayError: false })
      .then((data) => data.name)
      .catch(() => '[Error]')
  }

  function handleApply(event: CustomEvent<Uuid>): void {
    if (readOnly) return
    value = event.detail
    dispatch('change', value)
  }
</script>

<div
  class="filePicker"
  use:interact={{
    single: () => (show = true),
    disabled: readOnly,
  }}
  class:disabled={readOnly}
>
  <div class="previewWrapper">
    {#if !isSet(value)}
      <div class="placeholderText">Click to select a File</div>
    {:else if isUuid(value)}
      <FilePreview file={value} />
    {:else}
      <div class="placeholderText">Invalid File Selected</div>
    {/if}
  </div>
  <div class="fileName">
    {#await getName(value)}
      <span class="loading">
        <LoadingSpinnerSmall /> Loading...
      </span>
    {:then value}
      <span use:tooltip={value}>
        {value}
      </span>
    {/await}
  </div>
</div>

<FilePickerDialog bind:show on:apply={handleApply} {fileTypeFilter} {title} />

<style lang="scss">
  @use '../../../theme/variables' as vars;

  .placeholderText {
    text-align: center;
  }

  .filePicker {
    width: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: vars.$borderStyle;
    cursor: pointer;

    &.disabled {
      cursor: inherit;
    }
  }

  .previewWrapper {
    width: 100%;
    height: 10em;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .fileName {
    padding: 0.5em 1em;

    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.75;
    font-style: italic;
  }
</style>
