<!-- Sidebar Component -->
<script lang="ts" context="module">
  import { API, apiAdminRolename } from '@lib/ApiHandler'
  import type { IconPath } from '@lib/Icons'
  import { mdiMenu } from '@mdi/js'
  import { checkUnsavedChangesOnClick, isSet } from '@packages/util'
  import type { RenderConfig } from 'svelte-render'
  import { Render } from 'svelte-render'
  import _active from 'svelte-spa-router/active'
  import { writable } from 'svelte/store'
  import { tooltip } from './Global/GlobalTooltip.svelte'
  import Button from './Utility/Button.svelte'
  import Icon from './Utility/Icon.svelte'

  export interface SidebarLink {
    type?: Nullable<'link'>

    /** The target route */
    route: string

    /** The visual name of the link */
    name: string

    /** The icon to show (Defaults to nothing) */
    icon?: IconPath

    /** Matcher to apply for highlighting the link */
    activeMatch?: string | RegExp

    /** Required permissions to see this sidebar item (defaults to anyone) */
    permissions?: string[]

    /** Set to true to only require one of the given `permission` */
    hasSome?: boolean
  }

  export interface SidebarSpacer {
    type: 'spacer'
  }

  export interface SidebarSeparator {
    type: 'separator'
  }

  export interface SidebarCustom {
    type: 'custom'
    render: RenderConfig
  }

  export type SidebarItem =
    | SidebarLink
    | SidebarSpacer
    | SidebarSeparator
    | SidebarCustom

  /** Is the current item a link? */
  export function isLink(item: SidebarItem): item is SidebarLink {
    return !item['type'] || item['type'] == 'link'
  }

  /** Is the current item a spacer? */
  export function isSpacer(item: SidebarItem): item is SidebarSpacer {
    // return !!(item as any)?.spacer
    return item['type'] == 'spacer'
  }

  /** Is the current item a separator? */
  export function isSeparator(item: SidebarItem): item is SidebarSeparator {
    // return !!(item as any)?.separator
    return item['type'] == 'separator'
  }

  /** Is the current item a custom? */
  export function isCustom(item: SidebarItem): item is SidebarCustom {
    // return !!(item as any)?.custom
    return item['type'] == 'custom'
  }

  /** Return true based on the matches in the item's required permissions */
  export function canSeeItem(item: SidebarItem) {
    return (
      !isLink(item) ||
      !isSet(item.permissions) ||
      API.userHas(apiAdminRolename) ||
      API.userHas(item.permissions, item?.hasSome ?? false)
    )
  }
</script>

<script lang="ts">
  export let items: SidebarItem[]
  export let width = '200px'
  export let collapsible = false
  export let logo = false

  export let open = writable(true)

  /** Wrapper for `use:active` with improved path matching and correct styling */
  function active(node: HTMLElement, item: SidebarLink) {
    const matcher = new RegExp(`^${item.route}(\/.*)?`)

    return _active(node, {
      className: 'dhx_sidebar-button--active',
      path: item.activeMatch ?? matcher,
    })
  }
</script>

<aside class="sidebar">
  <nav class="dhx_widget dhx_sidebar">
    <ul
      class="dhx_navbar dhx_navbar--vertical"
      style:width={!$open ? 'inherit' : width}
    >
      <!-- Collapser and logo -->
      {#if collapsible || logo}
        <li class="sidebarHeader">
          {#if collapsible}
            <Button
              icon={mdiMenu}
              width={20}
              iconOnly
              circular
              on:click={() => ($open = !$open)}
            />
          {/if}
          <a href="#/home" hidden={!logo || !$open}>
            <img src="./img/logo.webp" alt="logo" />
          </a>
        </li>
      {/if}

      {#each items?.filter(canSeeItem) ?? [] as item}
        {#if isSpacer(item)}
          <!-- Spacer -->
          <li
            class="dhx_sidebar__item dhx_sidebar__item--spacer"
            role="none"
          ></li>
        {:else if isSeparator(item)}
          <!-- Spacer -->
          <li
            class="dhx_sidebar__item dhx_sidebar__item--separator"
            role="none"
          ></li>
        {:else if isLink(item)}
          <!-- Link -->
          {#key $open}
            <li class="dhx_sidebar__item" use:tooltip={$open ? '' : item.name}>
              <a
                class="dhx_button dhx_sidebar-button"
                href={'#' + item.route}
                aria-label={item.name}
                use:active={item}
                use:checkUnsavedChangesOnClick={item.route}
              >
                <!-- use:link disables ctrl+click -->
                <Icon name={item.icon} width="20" height="20" />
                <span aria-hidden={true} hidden={!$open}>
                  {item.name}
                </span>
              </a>
            </li>
          {/key}
        {:else if isCustom(item)}
          <Render of={item.render} />
        {:else}
          Unknown item type
        {/if}
      {/each}
    </ul>
  </nav>
</aside>

<style lang="scss">
  @use '../theme/variables' as vars;

  .sidebar {
    height: 100%;

    a {
      display: flex;
      gap: 0.75em;

      // Enforce hidden state
      &[hidden] {
        display: none;
      }
    }
  }

  .sidebarHeader {
    border-bottom: vars.$borderStyle;

    display: flex;
    align-items: center;
    padding: 0.5em;
    gap: 0.5em;

    a {
      flex-grow: 1;
      flex-basis: 0;
      height: 24px;
    }
  }
</style>
