<script lang="ts" context="module">
  import RichTextEditor from '@packages/richTextEditor'
  import FormElementBase from './FormElementBase.svelte'

  export const _name = 'RichTextEditor'

  export interface RichTextEditorConfig {
    height?: number
  }
</script>

<script lang="ts">
  export let value = ''
  export let label = 'Text Edit'
  export let config: RichTextEditorConfig = {}
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <RichTextEditor bind:value height={config?.height ?? '20em'} />
</FormElementBase>
