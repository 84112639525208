<script lang="ts" context="module">
  import EntitySelectModal from '@components/Modals/EntitySelectModal.svelte'
  import { commonStrings } from '@lib/CommonStrings'
  import { camel2title, isSet } from '@packages/util'
  import { baseApi, type ModelReference } from '@models/api/BaseApi'
  import type { AnyBaseModel } from '@models/api/BaseModel'
  import { onDestroy, onMount, tick } from 'svelte'
  import FormElementBase from './FormElementBase.svelte'

  export interface EntityMultiSelectConfig {
    type: ModelReference
    // filter?: Record<string, any>
    // sort?: string
    // allowEmpty?: boolean // Setting this to false will not allow an empty value to be set.
    minimal?: boolean
  }

  export const _name = 'EntityMultiSelect'
</script>

<script lang="ts">
  export let value: Nullable<string[]> = null // ID
  export let config: EntityMultiSelectConfig = {
    type: 'asset',
    minimal: false,
  }
  export let label = 'ComboBox'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _ready = false
  let _entityModel: Nullable<AnyBaseModel>
  let _showSelectionModal = false
  let _displayText = ''

  const limit = 2

  $: _state = state == 'error' && !isSet(value) ? state : null
  $: getNames(value, config).then((text) => (_displayText = text))
  $: endpointNamePlural = camel2title(_entityModel?.endpoint ?? 'entities')

  async function getNames(ids: string[], _: any) {
    const count = value?.length ?? 0

    if (config?.minimal)
      return `${count <= 0 ? 'No' : count} ${getEndpointName(count)}`
    if (count <= 0) return 'None'
    if (!_entityModel) return '---'

    const nameField = _entityModel.context.nameField

    try {
      const names = (
        await _entityModel.getPage({
          fields: [nameField],
          filter: {
            id: ids,
          },
          limit,
        })
      )?.results.map((item) => item[nameField])

      if (value.length > limit) {
        return `${names.join(', ')} and ${value.length - limit} more`
      } else {
        return `${names.join(', ')}`.replace(/\,([^\,]*)$/, ' and $1')
      }
    } catch (error) {
      return '---'
    }
  }

  function getApiModel() {
    const _apiModel = baseApi[config?.type] as AnyBaseModel | null

    if (!_apiModel)
      throw new Error(
        'EntityMultiSelect: apiModel must be set for this form item to work.'
      )

    _entityModel = _apiModel
    return _apiModel
  }

  function getEndpointName(count?: number) {
    const model = getApiModel()
    const plural = camel2title(model?.endpoint ?? 'entities')
    const singular = camel2title(model?.className ?? 'entity')
    return count == 1 ? singular : plural
  }

  onMount(async () => {
    await tick()
    _ready = true
    await tick()
    getApiModel()
  })
  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  state={_state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={errorMessage ?? commonStrings.valueRequired}
>
  <div
    class="entityMultiSelectWrapper dhx_input"
    on:click={() => (_showSelectionModal = true)}
    on:keypress={() => (_showSelectionModal = true)}
    role="button"
    tabindex="0"
  >
    <div class="select_text">Selected: {_displayText}</div>
    <div
      class="select_button dhx_button dhx_button--view_flat dhx_button--color_primary"
    >
      Select {endpointNamePlural}
    </div>
  </div>
</FormElementBase>

{#if _entityModel}
  <EntitySelectModal
    apiModel={_entityModel}
    bind:show={_showSelectionModal}
    bind:current={value}
    nameField={_entityModel.context.nameField}
    multiSelect
  />
{/if}

<style lang="scss">
  .entityMultiSelectWrapper {
    display: flex;
    padding: 0;

    cursor: pointer;

    .select_text {
      flex-grow: 1;
      padding: 0.5em;
    }

    .select_button {
      padding: 0 1em;
    }
  }
</style>
