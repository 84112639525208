<script lang="ts" context="module">
  import { getId, wrapReadableStore, type MaybeReadable } from '@packages/util'
  import type { HTMLInputAttributes } from 'svelte/elements'
  import FormElementBase from './FormElementBase.svelte'

  export interface _TextFieldConfig
    extends Omit<HTMLInputAttributes, `bind:${string}` | `on:${string}`> {}

  export type TextFieldConfig = MaybeReadable<_TextFieldConfig>

  export const _name = 'TextField'
</script>

<script lang="ts">
  export let value: Nullable<string> = ''
  export let config: TextFieldConfig = {}
  export let label = 'String'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _config = wrapReadableStore(config)
  const id = getId('input')
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
  forField={id}
>
  <input {id} bind:value type="text" class="dhx_input" {...$_config} />
</FormElementBase>
