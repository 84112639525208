import * as Generated from '@generated'
import { inputColumn } from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.Program> = {
  columns: {
    name: () => inputColumn('name'),
  },
  filters: {
    batchTypeId: { model: 'batchType' },
    description: 'string',
    name: 'string',
    owner: { model: 'user' },
    type: 'string',
    assetClassId: null,
    config: null,
  },
  defaultColumns: [],
  defaultSort: 'name',
  nameField: 'name',
  customListSupport: true,
}
