<!-- RichTextEditor Component -->
<script lang="ts">
  import HorizontalScrollContainer from '@components/Utility/HorizontalScrollContainer.svelte'
  import type { MappedEvents } from '@packages/util'
  import { cssUnit } from '@packages/util'
  import { Editor } from '@tiptap/core'
  import Link from '@tiptap/extension-link'
  import TextAlign from '@tiptap/extension-text-align'
  import Underline from '@tiptap/extension-underline'
  import StarterKit from '@tiptap/starter-kit'
  import { createEventDispatcher, tick } from 'svelte'
  import type { Action } from 'svelte/action'
  import RichTextEditorToolbar from './RichTextEditorToolbar.svelte'

  interface $$Events {
    change: CustomEvent<string>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let value = '<p>Lorem Ipsum</p>'

  export let height: Nullable<string | number> = null
  export let minHeight: Nullable<string | number> = '10em'
  export let maxHeight: Nullable<string | number> = '50em'

  let editor: Nullable<Editor>

  $: updateInternal(value)

  function updateInternal(value: string) {
    if (editor?.isFocused) return // Block updates if the user is editing the content

    // Update the content
    editor?.commands.setContent(value)
  }

  const initEditor: Action<Element> = (element, param) => {
    editor = new Editor({
      element: element,
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: 'whenNotEditable',
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      content: value,
      onTransaction() {
        // Force rerender for reactivity
        editor = editor
        const output = editor.getHTML()

        tick().then(() => {
          // Only update the external value when the user is editing and has changed
          if (editor?.isFocused && output != value) {
            value = output
            dispatch('change', output)
          }
        })
      },
    })

    return {
      destroy() {
        editor?.destroy()
      },
    }
  }
</script>

<div
  class="richTextEditor"
  style:height={cssUnit(height)}
  style:max-height={cssUnit(maxHeight)}
  style:min-height={cssUnit(minHeight)}
>
  <div class="editorToolbar">
    {#if editor}
      <HorizontalScrollContainer>
        <RichTextEditorToolbar {editor} />
      </HorizontalScrollContainer>
    {/if}
  </div>
  <div class="editor" use:initEditor />
</div>

<style lang="scss">
  @use '../../../theme/variables' as vars;

  .richTextEditor {
    border: vars.$borderStyle;
    display: flex;
    flex-direction: column;
    // height: 100%;
    width: 100%;

    .editorToolbar {
      border-bottom: vars.$borderStyle;
      background-color: vars.$gridHeader;
      padding: 0 0.5em;
    }

    .editor {
      padding: 1em;
      overflow: auto;
      flex-grow: 1;

      // Remove focus outline
      > :global(:focus-visible) {
        outline: none;
      }
    }
  }
</style>
