import * as Generated from '@generated'
import { generatedEnumColumn, inputColumn } from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.Process> = {
  columns: {
    name: () => inputColumn('name'),
    interval: () =>
      generatedEnumColumn('sampleInterval', Generated.Tag_INTERVALEnum),
  },
  filters: {
    programId: { model: 'program' },
    name: 'string',
    description: 'string',
    interval: { enum: Generated.Tag_INTERVALEnum },
    type: 'string',
    externalController: 'boolean',
    externalControllerTagId: { model: 'tag' },
  },
  defaultColumns: [],
  defaultSort: 'name',
  nameField: 'name',
  customListSupport: true,
}
