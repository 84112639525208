import type { Dictionary, TimeUnit } from '@amcharts/amcharts4/core'
import { get } from 'svelte/store'
import type FormatterInterface from './FormatterInterface'
import { dateFormatter } from './dateFormatterStore'

type AvailableFormatterOverrides =
  | undefined
  | 'dhtmlx'
  | 'dhtmlxScheduler'
  | 'amcharts'

/** The available precision levels */
export type DateFormattingRuleKeys =
  | 'millisecond'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'month'
  | 'year'
  | 'date'
  | 'fullNumeric'
  | 'fullNumericMsec'
  | 'default'

/** Rules for time */
const baseDateFormattingRules_time: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

/** Rules for date and time */
const baseDateFormattingRules_date: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

/** Rules for date and time */
const baseDateFormattingRules_dateTime: Intl.DateTimeFormatOptions = {
  ...baseDateFormattingRules_time,
  ...baseDateFormattingRules_date,
}

/** Date formatting rules for each precision level */
export const dateFormattingRules = {
  /** hh:mm:ss.vvv */
  millisecond: {
    ...baseDateFormattingRules_time,
    fractionalSecondDigits: 3,
  },
  /** hh:mm:ss */
  second: baseDateFormattingRules_time,
  /** hh:mm */
  minute: {
    hour: '2-digit',
    minute: '2-digit',
  },
  /** hh:mm */
  hour: {
    hour: '2-digit',
    minute: '2-digit',
  },
  /** Jan DD (Jan = 3 letter month name) */
  day: {
    month: 'short',
    day: '2-digit',
  },
  /** Jan (Jan = 3 letter month name) */
  month: {
    month: 'short',
  },
  /** YYYY */
  year: {
    year: 'numeric',
  },
  /** YYYY-MM-DD */
  date: baseDateFormattingRules_date,
  /** YYYY-MM-DD hh:mm:ss */
  fullNumeric: baseDateFormattingRules_dateTime,
  /** YYYY-MM-DD hh:mm:ss.vvv */
  fullNumericMsec: {
    ...baseDateFormattingRules_dateTime,
    fractionalSecondDigits: 3,
  },
  /** YYYY-MM-DD hh:mm:ss.vvv (same as fullNumericMsec) */
  default: {
    ...baseDateFormattingRules_dateTime,
    fractionalSecondDigits: 3,
  },
} as const satisfies Record<DateFormattingRuleKeys, Intl.DateTimeFormatOptions>

export default class DateFormatter implements FormatterInterface {
  private readonly _defaultLocale: string | string[] = undefined // 'en-US'

  private _formatter: Intl.DateTimeFormat
  private _config: Intl.DateTimeFormatOptions
  private _locale: string | string[]

  constructor(config?: Intl.DateTimeFormatOptions, locale?: string | string[]) {
    this._config = config ?? dateFormattingRules.default
    this._locale = locale ?? this._defaultLocale

    this._formatter = new Intl.DateTimeFormat(this._locale, this._config)
  }

  get config() {
    return this._config
  }

  set config(dateTimeFormatOptions: Intl.DateTimeFormatOptions) {
    this._config = dateTimeFormatOptions
    this._formatter = new Intl.DateTimeFormat(
      this._locale,
      dateTimeFormatOptions
    )
  }

  get locale() {
    return this._locale
  }

  set locale(locale: string | string[]) {
    this._locale = locale
    this._formatter = new Intl.DateTimeFormat(locale, this._config)
  }

  /** Get the DateFormatter with different options */
  getDerived(dateTimeFormatOptions: Intl.DateTimeFormatOptions): DateFormatter {
    return new DateFormatter(dateTimeFormatOptions, this._locale)
  }

  /** Format the value */
  format(value: number | Date) {
    try {
      return this._formatter.format(value)
    } catch (error) {
      return '####'
    }
  }

  /** Format a range between two values */
  formatRange(startValue: number | Date, endValue: number | Date) {
    try {
      return this._formatter.formatRange(startValue, endValue)
    } catch (error) {
      return '####'
    }
  }

  /** Get the formatting rules or string for the given type */
  getFormatterConfig(type: AvailableFormatterOverrides) {
    // const seperator = getDecimalSeparator(this.locale)
    // const ampmTime = using12hTime(this.locale)
    switch (type) {
      case 'dhtmlx':
        throw new Error('Not Implemented')
      case 'dhtmlxScheduler':
        throw new Error('Not Implemented')
      case 'amcharts':
        // return `dd-MM-yyyy ${ampmTime ? 'hh' : 'HH'}:mm:ss${seperator}SSS`
        return dateFormattingRules.fullNumericMsec
      default:
        return this.config
    }
  }

  /** Apply the dateFormattingRules on each AmChart time unit */
  amChartsFormats(
    formats: Dictionary<TimeUnit, string | Intl.DateTimeFormatOptions>
  ) {
    // -> Defaults: https://www.amcharts.com/docs/v4/concepts/axes/date-axis/#List_of_available_time_units
    formats.setKey('millisecond', dateFormattingRules.millisecond)
    formats.setKey('second', dateFormattingRules.second)
    formats.setKey('minute', dateFormattingRules.minute)
    formats.setKey('hour', dateFormattingRules.hour)
    formats.setKey('day', dateFormattingRules.day)
    formats.setKey('month', dateFormattingRules.month)
    formats.setKey('year', dateFormattingRules.year)

    // const seperator = getDecimalSeparator(this.locale)
    // const ampmTime = using12hTime(this.locale)
    // formats.setKey('millisecond', `${ampmTime ? 'hh' : 'HH'}:mm:ss${seperator}nnn${ampmTime ? ' a' : ''}`)
    // formats.setKey('week', '---');
  }
}

/** Parse and then format the given time */
export function reformatTime(time: string) {
  let date: Date
  try {
    date = new Date(time)
  } catch (_) {
    return null
  }
  const out = get(dateFormatter).format(date)
  return out.includes('#') ? null : out
}
