<!-- FormDialog Component, Provides a simple way to add form prompts to components -->
<script lang="ts">
  import Form from '@components/Form.svelte'
  import ToolbarItem from '@components/Toolbar/ToolbarItem.svelte'
  import Window from '@components/Window.svelte'
  import type { IFormConfig } from '@dhtmlx/ts-form'
  import type { FormConfig, FormEntity } from '@lib/FormUtil'
  import type { MappedEvents } from '@packages/util'
  import { createEventDispatcher, onDestroy } from 'svelte'

  interface $$Events {
    change: CustomEvent<{ name: string; value: any }>
    apply: CustomEvent<Record<string, any>>
    cancel: CustomEvent<never>
    afterClose: CustomEvent<never>
    afterOpen: CustomEvent<never>
    customContainersRender: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let formConfig: IFormConfig = {}
  export let data: Record<string, any> = null
  export let show: boolean = false
  export let form: FormEntity = null
  export let manualClose = false

  export let width: Nullable<number | string> = '95%'
  export let height: Nullable<number | string> = '95%'
  export let maxWidth: Nullable<number | string> = '40em'
  export let maxHeight: Nullable<number | string> = '50em'

  export let windowContentCss = ''

  let title: Nullable<string> = null

  let _beforeResolve: Nullable<<T>(data: T) => Promise<boolean>>
  let _resolvePromise: Nullable<(value: any) => void>
  let _formConfig: FormConfig = {
    config: formConfig,
  }
  let _loading = false

  $: _formConfig = reassignConfig({ config: formConfig })

  function reassignConfig(
    newConfig: FormConfig,
    currentConfig: FormConfig = _formConfig
  ) {
    return {
      ...currentConfig,
      ...newConfig,
    }
  }

  function handleReady() {
    if (data) form.setValue(data)
  }

  function windowShown() {
    show = true

    _formConfig.config = {
      ...formConfig,
    }
  }

  function windowHidden() {
    show = false
    resolvePromise(null)
    _beforeResolve = null
  }

  async function handleApply() {
    data = form.getValue()
    if (form.dhx.validate()) {
      _loading = true
      let success = false
      try {
        success = await (_beforeResolve ?? (async (_) => true))(data)
      } catch (e) {
        // TODO: Do something with the error
        console.error(e)
      }
      _loading = false
      if (!success) return

      dispatch('apply', data)
      if (!manualClose) show = false
      resolvePromise(data)
    }
  }

  function handleCancel() {
    dispatch('cancel')
    if (!manualClose) show = false
    else resolvePromise(null)
  }

  /**
   * This will open a prompt with the given form configuration
   * @param _title The title of the form
   * @param _data The form data to use
   * @param _formConfig The DHTMLX Form configuration
   * @param _onApply (Optional) The action to run when the apply button has been clicked
   *  (Used for long running actions that can fail)
   *
   * @return The result value promise (null if cancelled)
   */
  export function promptForm<T extends Record<string, any>>(
    _title: string,
    _data: Nullable<T>,
    _formConfig: IFormConfig,
    _onApply?: typeof _beforeResolve<T>
  ): Promise<Nullable<T>> {
    title = _title
    data = _data ?? data
    formConfig = _formConfig ?? formConfig
    _beforeResolve = _onApply as typeof _beforeResolve

    let promptPromise = new Promise<T>((res, rej) => {
      _resolvePromise = res
    })
    show = true

    return promptPromise
  }

  /** Cancel the current operation */
  export function cancel() {
    handleCancel()
  }

  function resolvePromise(value: any) {
    _resolvePromise?.(value)
    _resolvePromise = undefined
  }

  onDestroy(() => {
    form?.dhx?.destructor()
  })
</script>

<Window
  bind:show
  {title}
  bind:width
  bind:height
  bind:maxWidth
  bind:maxHeight
  on:afterClose={windowHidden}
  on:afterOpen={windowShown}
  on:afterClose
  on:afterOpen
>
  <svelte:fragment slot="bottomToolbar">
    <slot name="bottomToolbar" />
    <ToolbarItem spacer />
    <ToolbarItem value="Cancel" type="button" on:click={handleCancel} />
    <ToolbarItem
      value="Apply"
      type="button"
      on:click={handleApply}
      loading={_loading}
    />
  </svelte:fragment>
  <div class="formWindow {windowContentCss}">
    <Form
      bind:form
      formConfig={_formConfig}
      on:customContainersRender={handleReady}
      on:change
      on:customContainersRender
      disabled={_loading}
    />
  </div>
</Window>

<style lang="scss">
  .formWindow {
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
</style>
