<script lang="ts" context="module">
  import FormattedValue from '@packages/locale/components/FormattedValue.svelte'
  import { getId, wrapReadableStore, type MaybeReadable } from '@packages/util'
  import FormElementBase from './FormElementBase.svelte'

  export interface _NumberFieldConfig {
    min?: number
    max?: number
    placeholder?: string
    readonly?: boolean
    step?: number
  }

  export type NumberFieldConfig = MaybeReadable<_NumberFieldConfig>

  export const _name = 'NumberField'
</script>

<script lang="ts">
  export let value: Nullable<number> = 0
  export let config: NumberFieldConfig = {}
  export let label = 'Number'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _config = wrapReadableStore(config)
  const id = getId('input')
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
  forField={id}
>
  <div class="input_pair">
    <input
      {id}
      bind:value
      type="number"
      class="physical_input dhx_input"
      {...$_config}
    />
    <div class="display_value dhx_input">
      <FormattedValue {value} hideNaN />
    </div>
  </div>
</FormElementBase>

<style lang="scss">
  .input_pair {
    &:focus-within .display_value {
      opacity: 0;
    }
  }

  .physical_input {
    opacity: 0;

    // On focus, it should overlap the display value
    &:focus {
      opacity: 1;
    }
  }

  // Position the display value over the real input
  .display_value {
    pointer-events: none;
    position: absolute;
    user-select: none;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
</style>
