<!-- ButtonLink Component. Similar to Button, but allows for the standard link actions -->
<script lang="ts">
  import type { IconPath } from '@lib/Icons'
  import type { MappedEvents } from '@packages/util'
  import { checkUnsavedChangesOnClick, isSet } from '@packages/util'
  import { createEventDispatcher } from 'svelte'
  import { tooltip as useTooltip } from '../Global/GlobalTooltip.svelte'
  import Icon from './Icon.svelte'

  interface $$Events {
    click: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let icon: Nullable<IconPath> = null
  export let value: Nullable<string> = null
  export let loading = false
  export let link = false // flat when false, link when true
  export let color: 'primary' | 'secondary' | 'danger' | 'success' = link
    ? 'primary'
    : 'secondary'
  export let small = false // medium when false, small when true
  export let width = null
  export let iconOnly = false
  export let circular = false
  export let disabled = false
  export let hidden = false
  export let tooltip: Nullable<string> = null
  export let css: Nullable<string> = null
  export let route: Nullable<string> = null
  export let href: Nullable<string> = null

  function interceptClick(event: Event) {
    !disabled && dispatch('click')
    if (disabled || !isSet(route)) event.preventDefault()
  }
</script>

{#if !hidden}
  <a
    href={href ?? (route ? `#${route}` : null)}
    class="dhx_button dhtmlxButton
  {color ? 'dhx_button--color_' + color : ''} 
  {small ? 'dhx_button--size_small' : 'dhx_button--size_medium'} 
  {link ? 'dhx_button--view_flat' : 'dhx_button--view_link'}
  {css ?? ''}"
    class:dhx_button--loading={loading}
    class:withIcon={icon && value && !iconOnly}
    class:iconOnly
    class:circular
    style:width={!iconOnly && width}
    class:dhx_button--disabled={disabled}
    on:click={interceptClick}
    use:useTooltip={tooltip}
    use:checkUnsavedChangesOnClick={route}
  >
    {#if icon}
      <span class="dhx_button__icon"><Icon name={icon} height={20} /></span>
    {/if}
    {#if loading}
      <span class="dhx_button__loading-icon dxi dxi-loading"></span>
    {/if}
    {#if value && !iconOnly}
      <!-- The "Send text" button-->
      <span class="dhx_button__text">
        <slot>
          {value ?? ''}
        </slot>
      </span>
    {/if}
  </a>
{/if}

<style lang="scss">
  $iconBoxSize: 32px;

  .withIcon {
    padding-right: $iconBoxSize;
  }

  .iconOnly {
    padding: 0 !important;
    width: $iconBoxSize;
    height: $iconBoxSize;
  }

  :global(.dhtmlxButton.dhx_button--loading .dhx_button__text) {
    visibility: visible !important;
    margin-left: 0.5em;
  }

  .circular { 
    border-radius: 999px;
  }
</style>
