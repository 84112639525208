import { API } from '@lib/ApiHandler'
import { isSet, parseMimeType } from '@packages/util'
import type { File } from '@models/api/ApiModels'
import type { ComponentType } from 'svelte'

export const previewMap: PreviewMapItem[] = [
  {
    name: 'Image',
    component: () => import('./PreviewImage.svelte'),
    fileTypes: [
      'image/apng',
      'image/avif',
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/svg+xml',
      'image/webp',
      'image/bmp',
      'image/x-icon',
      'image/tiff',
      'image/vnd.microsoft.icon',
      'image/xbm',
      'image/x-bitmap',
    ],
  },
  {
    name: 'Media',
    component: () => import('./PreviewMedia.svelte'),
    fileTypes: [
      // Audio
      'audio/3gp2',
      'audio/3gpp',
      'audio/3gpp2',
      'audio/aac',
      'audio/flac',
      'audio/mp4',
      'audio/mpeg',
      'audio/mpeg',
      'audio/ogg',
      'audio/wav',
      'audio/wave',
      'audio/webm',
      'audio/x-flac',
      'audio/x-pn-wav',
      'audio/x-wav',

      // Video
      'video/3gp2',
      'video/3gpp',
      'video/3gpp2',
      'video/mp4',
      'video/mpeg',
      'video/ogg',
      'video/quicktime',
      'video/webm',
    ],
  },
  {
    name: 'Pdf',
    component: () => import('./PreviewPdf.svelte'),
    extensions: ['pdf'],
    fileTypes: ['application/pdf'],
  },
  {
    name: 'Markdown',
    component: () => import('./PreviewMarkdown.svelte'),
    extensions: ['md', 'markdown'],
    fileTypes: ['text/markdown', 'text/x-markdown'],
  },
  {
    name: 'Html',
    component: () => import('./PreviewHtml.svelte'),
    extensions: ['htm', 'html'],
    fileTypes: ['text/html'],
  },
  {
    name: 'PlainText',
    component: () => import('./PreviewPlainText.svelte'),
    extensions: ['txt'],
    fileTypes: ['text/plaintext'],
  },
]

type ImportFn = () => Promise<{ default: ComponentType }>

export const fallbackPreview: ImportFn = () =>
  import('./PreviewFallback.svelte')

export interface PreviewMapItem {
  name: string
  component: ImportFn
  fileTypes: string[]
  extensions?: string[]
}

/** Find the corresponding previewer for the given file */
export function resolvePreview(file: File): Nullable<PreviewMapItem> {
  // Return nothing if the input is invalid
  if (
    !isSet(file) ||
    !!file.directory ||
    !isSet(file.mimeType) ||
    !isSet(file.extension) ||
    !isSet(file.id)
  ) {
    return null
  }

  // Check the extension first
  const foundByExtension = previewMap.find(
    (item) =>
      item.extensions &&
      !!item.extensions.find((_ext) => _ext == file.extension)
  )

  // Return if found
  if (foundByExtension) return foundByExtension

  // Parse the mime type
  const mimeType = parseMimeType(file.mimeType)
  if (!mimeType) return null

  // Find the correct preview component
  return previewMap.find(
    (item) =>
      !!item.fileTypes.find(
        (_mime) => _mime == `${mimeType.type}/${mimeType.subType}`
      )
  )
}

export function getFileDownloadUrl(fileId: string) {
  return `${API.baseURL}/file/${fileId}/download`
}
