import { numberFormatter } from '@packages/locale/lib/numberFormatterStore'
import { isSet } from '@packages/util'
import { get } from 'svelte/store'

/** Get the minimum of a list of values (of any comparible type) */
export function anyMin<T>(...values: T[]): T {
  return values.reduce((a, b) => (a < b ? a : b))
}

/** Get the maximum of a list of values (of any comparible type) */
export function anyMax<T>(...values: T[]): T {
  return values.reduce((a, b) => (a > b ? a : b))
}

/** Limit a number between two values (min and max) */
export function clamp(value: number, min = 0, max = Infinity) {
  if (typeof value != 'number' || !isSet(value)) return NaN
  return Math.min(Math.max(value, min), max)
}

/** Parses a float if the input is a string */
export function parseFloatFromAny(value: any) {
  switch (typeof value) {
    case 'string':
      return parseFloat(value)
    case 'number':
      return value
    default:
      return NaN
  }
}

/** Parses a int if the input is a string */
export function parseIntFromAny(value: any) {
  switch (typeof value) {
    case 'string':
      return parseInt(value)
    case 'number':
      return Math.round(value)
    default:
      return NaN
  }
}

/** Turn a rough estimate range to a pretty range */
export function roundRange(
  min: number,
  max: number
): { min: Nullable<number>; max: Nullable<number> } {
  // Return null min and max if either aren't set
  if (!isSet(min) || !isSet(max)) return { min: null, max: null }

  // The amount of digits to "prettify" to
  const maxDigits = 2

  // Get the multiplier to get the value to a fixed "pretty" number
  const multiplier = getRoundedValueMultiplier(max ?? 0 - min ?? 0, maxDigits)

  // Round the min and max values to the "pretty" number
  return {
    min: Math.floor(min * multiplier) / multiplier,
    max: Math.ceil(max * multiplier) / multiplier,
  }
}

/** Get the multiplier to get the value to a fixed "pretty" number */
export function getRoundedValueMultiplier(
  value: number,
  digitCount: number
): number {
  const stringified = value.toString()
  if (value < 1) {
    // Count 0s
    let counter = digitCount - 1

    // Walk through the string
    for (let i of stringified) {
      if (i == '.') continue
      if (i != '0') break
      counter++
    }

    // Return 10^counter
    return 10 ** counter
  } else {
    // Count to dot
    let counter = 0

    // Walk through the string
    for (let i of stringified) {
      if (i == '.') break
      counter++
    }

    if (counter > digitCount) {
      // To prevent floating point errors, we need to create a string and then parse it :(
      // (Basically does `0.1^(counter - digitCount)`)
      return parseFloat('0.' + '0'.repeat(counter - digitCount - 1) + '1')
    } else {
      // Return 10^counter
      return 10 ** counter
    }
  }
}

/** Same as getRoundedValueMultiplier, but returns decimal amount instead */
export function getRoundedValueDecimalCount(
  value: number,
  digitCount: number
): number {
  const stringified = value.toString()
  if (value < 1) {
    // Count 0s
    let counter = digitCount - 1

    // Walk through the string
    for (let i of stringified) {
      if (i == '.') continue
      if (i != '0') break
      counter++
    }

    return counter
  } else {
    // Count to dot
    let counter = 0

    // Walk through the string
    for (let i of stringified) {
      if (i == '.') break
      counter++
    }

    return counter > digitCount ? 0 : counter
  }
}

/**
 * Check if a given value is a number or int and within a range
 * @param value The value to test
 * @param min The minimum value (null = no minimum)
 * @param max The maximum value (null = no maximum)
 */
export function validateNumber(
  value: number,
  min?: Nullable<number>,
  max?: Nullable<number>,
  requireInteger = false
) {
  value = get(numberFormatter).parse(value)

  if (requireInteger && !Number.isSafeInteger(value)) {
    return false
  }

  return (
    !Number.isNaN(value) &&
    value >= (min ?? Number.MIN_SAFE_INTEGER) &&
    value <= (max ?? Number.MAX_SAFE_INTEGER)
  )
}
