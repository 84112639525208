import { features } from '@lib/Features'
import { permission, type Permission } from '@lib/Permissions'
import type { baseApi } from '@models/api/BaseApi'

export interface PageDetails {
  component: () => Promise<any>
  overrideName?: string
  enable?: boolean
  permissions?: Permission | Permission[]
}

export const pages: Partial<Record<keyof typeof baseApi, PageDetails>> = {
  asset: {
    component: () => import('./Asset/Page.svelte'),
    permissions: [
      permission.viewAsset,
      permission.equipmentManagement,
      permission.equipmentView,
    ],
  },
  batch: {
    component: () => import('./Batch/Page.svelte'),
  },
  batchType: {
    component: () => import('./BatchType/Page.svelte'),
  },
  channel: {
    component: () => import('./Channel/Page.svelte'),
  },
  processStep: {
    component: () => import('./ProcessStep/Page.svelte'),
    enable: features.processEditor,
  },
  process: {
    component: () => import('./Process/Page.svelte'),
    enable: features.processEditor,
  },
  program: {
    component: () => import('./Program/Page.svelte'),
    enable: features.processEditor,
  },
  report: {
    component: () => import('./Report/Page.svelte'),
    enable: features.reports,
  },
  tag: {
    component: () => import('./Tag/Page.svelte'),
  },
  appFunction: {
    component: () => import('./AppFunction/Page.svelte'),
    overrideName: 'function',
  },
  assetClass: {
    component: () => import('./AssetClass/Page.svelte'),
  },
  group: {
    component: () => import('./Group/Page.svelte'),
  },
  user: {
    component: () => import('./User/Page.svelte'),
  },
  enum: {
    component: () => import('./Enum/Page.svelte'),
  },
}
