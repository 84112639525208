// @ts-nocheck <- Hide the errors
// Utilities that make use of internal functions, which might break in the future
import type { ComponentProps, SvelteComponent } from 'svelte'
import { bind } from 'svelte/internal'

/**
 * Exposes svelte's internal function for binding to component prop changes.
 *
 * @param component The Component instance
 * @param propName The property to bind to
 * @param callback The function that gets called when the given prop changes
 * @returns (unknown) The function to unbind?
 */
export function bindComponentProp<
  T extends SvelteComponent,
  Prop = keyof ComponentProps<T>,
>(component: T, propName: Prop, callback: any) {
  return bind(component, propName, callback)
}
