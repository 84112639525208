<!-- Form element that supports Editing an enum OR selecting from an already existing enum -->
<script lang="ts" context="module">
  import ComboboxAsync, {
    type ComboboxItem,
  } from '@components/ComboboxAsync.svelte'
  import FormElementBase from '@components/FormControls/FormElementBase.svelte'
  import { baseApi } from '@models/api/BaseApi'
  import { awaitRedraw, isSet, isString, isUuid, single } from '@packages/util'
  import { onDestroy, onMount, tick } from 'svelte'
  import type { EnumEditorItemBase } from './EnumEditor.svelte'
  import EnumEditor from './EnumEditor.svelte'

  // export interface EnumEditorDerivedConfig {}

  export const _name = 'EnumEditorDerived'

  /** Must be reference (ID to enum table) OR items (list of items) (Preffers reference) */
  export type EnumEditorDerivedValue = string | EnumEditorItemBase[]

  /** Resolve the options from the given EnumEditorDerivedValue */
  export async function getOptions(
    value: EnumEditorDerivedValue
  ): Promise<EnumEditorItemBase[]> {
    // Return empty array if nothing is given
    if (!isSet(value)) return []

    // Return items if array (empty items filtered out)
    if (Array.isArray(value)) return value.filter(isSet)

    // Return items from API if reference
    if (isUuid(value))
      return (
        (await baseApi.enum.getOne(value, { fields: ['config'] }))
          .config as EnumEditorItemBase[]
      ).filter(isSet)

    // Return first search result by name if given a string
    if (isString(value))
      return (
        (single(
          (
            await baseApi.enum.getPage({
              fields: ['config'],
              limit: 1,
              filter: { name: value },
            })
          ).results
        )?.config as EnumEditorItemBase[]) ?? []
      ).filter(isSet)

    // Return empty array if nothing is matched
    return []
  }

  const customItemId = 'custom'
  const customItemName = '-- Custom --'
</script>

<script lang="ts">
  export let value: Nullable<EnumEditorDerivedValue> = []
  // export let config: EnumEditorConfig = {}
  export let label = 'Form Editor'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let _ready = false
  let _valueLock = false
  let _selected = customItemId
  let _items = Array.isArray(value) && value
  let loading = false

  $: updateInternal(value)
  $: updateExternal(_selected, _items)

  /** Update the internal value */
  function updateInternal(newValue: typeof value) {
    if (!_ready || _valueLock) return

    if (isReference(newValue)) {
      // Reference
      _selected = newValue
      load(_selected)
    } else {
      // Custom items
      _selected = customItemId
      _items = newValue
    }
  }

  /** Update the External value */
  function updateExternal(selected: string, items: EnumEditorItemBase[]) {
    _valueLock = true
    value = isReference(selected) ? selected : items
    awaitRedraw().then(() => (_valueLock = false))
  }

  function isReference(_value: typeof value): _value is string {
    return typeof _value == 'string' && isUuid(_value)
  }

  async function getData() {
    return [
      { id: customItemId, value: customItemName },
      ...(
        await baseApi.enum.getPage({ limit: 1000, fields: ['name', 'id'] })
      ).results?.map<ComboboxItem>((item) => ({
        id: item.id,
        value: item.name,
      })),
    ]
  }

  async function getCurrent(id: string) {
    switch (true) {
      // Not set
      case !isSet(id):
        return '-- Not Set --'
      // Custom items
      case id == customItemId:
        return customItemName
      // Not an UUID
      case !isUuid(id):
        return '-- Invalid Selection --'
      // Fetch name
      default:
        return (
          (await baseApi.enum.getOne(id, { fields: ['name'] }))?.name ??
          '-- Error Fetching Current Item --'
        )
    }
  }

  function handleChange(event: CustomEvent<string>) {
    const id = event.detail
    load(id)
  }

  async function load(id: string) {
    const isCustom = id == customItemId

    if (!isCustom) {
      loading = true
      const items = await getOptions(id).finally(() => (loading = false))
      _items = items
    }

    loading = false
  }

  onMount(async () => {
    await tick()
    _ready = true
    await tick()
    updateInternal(value)
    await tick()
  })

  onDestroy(() => (_ready = false))
</script>

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  {errorMessage}
>
  <ComboboxAsync
    {getData}
    {getCurrent}
    bind:selected={_selected}
    on:change={handleChange}
  />
  <div class="enumEditorContainer" class:loading>
    <EnumEditor
      bind:value={_items}
      label={_selected != customItemId ? 'Preview' : 'Edit Options'}
      readOnly={_selected != customItemId}
    />
  </div>
</FormElementBase>

<style lang="scss">
  @use '../../theme/variables' as vars;

  .enumEditorContainer {
    margin-top: 1em;

    &.loading {
      opacity: 0.5;
    }
  }
</style>
