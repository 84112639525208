<!-- InputFilter Component -->
<script lang="ts" context="module">
  import { isSet } from '@packages/util'
  import type { StringKeyOf } from '@packages/util'
  import {
    baseApi,
    type ModelDereference,
    type ModelReference,
  } from '@models/api/BaseApi'
  import type { ModelFilter, ModelSort } from '@models/api/BaseModel'
  import ComboboxAsync, { type ComboboxItem } from '../ComboboxAsync.svelte'

  export interface EntityFilterConfig<T extends ModelReference = any> {
    apiModel?: T
    idField?: StringKeyOf<ModelDereference<T>>
    valueField?: StringKeyOf<ModelDereference<T>>
    sort?: ModelSort<ModelDereference<T>>
    filter?: ModelFilter<ModelDereference<T>>
  }
</script>

<script lang="ts">
  export let value: string = ''
  export let config: EntityFilterConfig = {}

  async function getData(): Promise<ComboboxItem[]> {
    checkConfig()

    return [
      { id: '', value: '-- None --' }, // TODO: Allow for none selection
      ...(
        await getApi().getPage({
          limit: 1000,
          fields: [
            config.idField ?? 'id',
            config.valueField ?? ('name' as any),
          ],
          sort: config.sort ?? ('-modifiedOn' as any),
          ...(config.filter ? { filter: config.filter } : {}),
        } as any)
      ).results.map(
        (item) =>
          ({
            id: String(item[config.idField ?? 'id']),
            value: String(item[config.valueField ?? 'name']),
          }) as ComboboxItem
      ),
    ]
  }

  async function getCurrent(selected: string) {
    checkConfig()

    if (!isSet(selected)) return null

    return String(
      (
        await getApi().getOne(selected, {
          fields: [config.idField ?? 'id', config.valueField ?? 'name'] as any,
        })
      )[config.valueField]
    )
  }

  function getApi() {
    return typeof config.apiModel == 'string'
      ? baseApi[config.apiModel]
      : config.apiModel
  }

  function checkConfig() {
    if (!getApi())
      throw new Error(
        'config.apiModel is required for the EntityFilter to work.'
      )
  }
</script>

<div class="entityFilter">
  <ComboboxAsync {getData} {getCurrent} bind:selected={value} />
</div>

<style lang="scss">
  .entityFilter {
    width: 100%;
    height: 100%;
    padding: 0.25em 0;

    display: flex;
    justify-content: center;
    align-items: center;

    :global(.combobox) {
      height: 100%;
      width: 100%;
    }
  }
</style>
