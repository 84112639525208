import type { Batch } from '@generated'
import * as Generated from '@generated'
import { push } from '@lib/GlobalPageChangeHandler'
import { baseApi, type ModelReference } from '@models/api/BaseApi'
import type { ExtractBaseModelType } from '@models/api/BaseModel'
import {
  camel2kebab,
  encodePHPQueryParams,
  isSet,
  isUuid,
  kebab2camel,
  pushNew,
  uncapitalize,
} from '@packages/util'
import { replace } from 'svelte-spa-router'
import {
  hasUnsavedChanges,
  safePush,
  safeReplace,
} from './GlobalPageChangeHandler'

/**
 * Open an item based on the type and data given.
 */
export async function openItemPage<T extends ModelReference>(
  type: T,
  data: ExtractBaseModelType<(typeof baseApi)[T]> | 'new' | Uuid,
  openType: 'newTab' | 'replace' | 'force' | 'forceReplace' | null = null,
  queryParams?: Nullable<Record<any, any> | string>,
  currentColumn?: string
): Promise<void> {
  const url = getOpenItemPageHref(type, data, currentColumn, queryParams)
  if (!url) return

  switch (openType) {
    case 'newTab':
      pushNew(url)
      return
    case 'replace':
      await safeReplace(url)
      return
    case 'force':
      hasUnsavedChanges.set(false)
      await push(url)
      return
    case 'forceReplace':
      hasUnsavedChanges.set(false)
      await replace(url)
      return
    default:
      await safePush(url)
      return
  }
}

export function getOpenItemPageHref<T extends ModelReference>(
  type: T,
  data: ExtractBaseModelType<(typeof baseApi)[T]> | 'new' | Uuid,
  column?: string,
  queryParams?: Nullable<Record<any, any> | string>
) {
  const isNew = data == 'new'
  const isId = isUuid(data)
  const isNewOrId = isNew || isId

  if (column?.startsWith('_')) return
  if (!baseApi[type]) throw new Error('Unsupported entity type: ' + type)
  if (!isNewOrId && !isUuid(data.id)) return

  let url = ''

  switch (type) {
    case 'processStep':
      url = `/process-step/${isNewOrId ? data : data.id}${!isNew ? '/edit' : ''}`
      break
    case 'batch':
      url = isNewOrId ? `/batch/${data}` : getBatchUrl(data as Batch)
      break
    case 'appFunction':
      url = `/function/${isNewOrId ? data : data.id}`
      break
    default:
      url = `/${camel2kebab(type)}/${isNewOrId ? data : data.id}`
      break
  }

  return url + getParams(queryParams)
}

export function findBaseApiName(name: string) {
  const keys = Object.keys(baseApi) as ModelReference[]
  const kebab2camelName = kebab2camel(name)
  const uncapitalizedName = uncapitalize(name)

  return keys.find(
    (key) => key == name || key == kebab2camelName || key == uncapitalizedName
  )
}

function getParams(value: Nullable<Record<any, any> | string>) {
  if (!isSet(value)) return ''
  if (typeof value == 'string') return value
  return '?' + encodePHPQueryParams(value)
}

function getBatchUrl(batch: Batch) {
  switch (batch.status) {
    case Generated.Batch_STATUSEnum.cancelled:
    case Generated.Batch_STATUSEnum.planned:
    case Generated.Batch_STATUSEnum.deleted:
    case Generated.Batch_STATUSEnum.pending:
    case Generated.Batch_STATUSEnum.running:
    case Generated.Batch_STATUSEnum.notOk:
      return `/batch/${batch.id}`
    default:
      return `/batch/${batch.id}/dataviewer`
  }
}
