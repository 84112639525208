import type { Chain } from '@packages/util/lib/UtilityTypes'
import { buildConfig } from 'BUILDCONFIG'

export function getBuild() {
  // @ts-ignore BUILD_DATE is defined in vite.config.ts
  return `Revision: ${buildConfig.revision}, Date: ${buildConfig.buildDate}`
}

/**
 * For use in svelte components to convert the given value to `any`.
 * Will become useless when svelte supports typescript outside `<script>` tags
 */
export function any(value: any): any {
  return value
}

export function runWhenSet<T>(value: T) {
  let stack = []

  type Fn<T> = (value: NonNullable<T>) => void

  const run = () => {
    if (!isSet(value)) return
    stack.forEach((fn) => {
      fn(value)
    })
  }

  let obj = {
    stack,
    step: (fn: Fn<T>) => {
      stack.push(fn)
      return obj
    },
    run,
  }

  return obj
}

/**
 * Perform a set of functions in order, without allowing nulls to be passed in.
 * Good for functions that depend on a value to not be null
 */
export function chain<T>(value: Nullable<T>): Chain<Nullable<T>> {
  const obj: (curr: Nullable<T>) => Chain<Nullable<T>> = (curr) => ({
    map: (fn) => {
      if (curr === null || curr === undefined) return obj(null)
      return obj(fn(curr) as any) as any
    },
    debug: () => {
      if (curr === null || curr === undefined) {
        import.meta.env.DEV && console.log('None')
        return obj(null)
      }
      import.meta.env.DEV && console.log('Some:', curr)
      return obj(curr)
    },
    collect: () => curr,
  })
  return obj(value)
}

/**
 * Checks if the given value is not empty
 */
export function isSet<T>(value: T | null | undefined): value is NonNullable<T> {
  if (value === null || value === undefined) {
    return false
  }

  // On arrays, it will check if it has any items
  if (Array.isArray(value)) {
    return value.length > 0
  }

  switch (typeof value) {
    case 'string': // On strings, it will check if it has any content
      return value.length > 0
    case 'number': // On numbers, it will check if it is a valid number
    case 'bigint':
      return !Number.isNaN(value)
    case 'object': // On objects, it will check if it has any keys
      return Object.keys(value).length > 0
    case 'boolean': // These are always something
    case 'function':
    case 'symbol':
      return true
  }

  return false
}

/** Check if the given value is a string */
export function isString(value: unknown): value is string {
  return value !== null && value !== undefined && typeof value == 'string'
}

/** Check if the given value can be converted to a string */
export function isStringable(
  value: unknown
): value is { toString: typeof Object.prototype.toString } {
  return value.toString && isString(value.toString())
}

/**
 * Simple promised sleep function (ms)
 */
export function sleep(
  delay: number,
  abortSignal?: AbortSignal,
  silentAbort = false
): Promise<void> {
  return new Promise((res) =>
    setTimeout(() => {
      if (abortSignal?.aborted && silentAbort) return
      if (abortSignal?.aborted && !silentAbort) abortSignal?.throwIfAborted()
      res()
    }, delay)
  )
}

/** Loop through a function until it returns something truthy. */
export async function wait<T>(
  fn: () => T,
  delay?: number,
  limit?: number,
  abortSignal?: AbortSignal,
  silent?: true
): Promise<Nullable<T>>
export async function wait<T>(
  fn: () => T,
  delay?: number,
  limit?: number,
  abortSignal?: AbortSignal,
  silent?: false
): Promise<NonNullable<T>>
export async function wait<T>(
  fn: () => T,
  delay?: number,
  limit?: number,
  abortSignal?: AbortSignal,
  silent?: true | false
): Promise<T> {
  // For loop with a limit on how many tries it can do (defaults to 100)
  for (let count = 0; count < Math.min(100, limit); count++) {
    // Implementation for the abortController
    if (abortSignal?.aborted && silent) return null
    if (abortSignal?.aborted && !silent) abortSignal?.throwIfAborted()
    // Run the function and get its result
    const result = fn()
    if (!!result) return result
    // Sleep for at least 1ms
    await sleep(Math.max(1, delay))
  }
  // Return or throw depending on the `silent` state
  if (silent) return null
  else throw new Error('Timeout while waiting for result')
}

/**
 * Creates a debounced function
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  timeout = 300
) {
  let timer: NodeJS.Timeout

  return (...args: Parameters<T>) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      //@ts-ignore
      func.apply(this, args)
    }, timeout)
  }
}

/**
 * Creates a debounced function with a collector
 */
export function collectedDebounce<T>(
  func: (collection: T[]) => void,
  timeout = 300
) {
  let timer: NodeJS.Timeout
  let collection = new Map()
  let counter = -1

  return (item: T) => {
    collection.set(++counter, item)
    clearTimeout(timer)
    timer = setTimeout(() => {
      //@ts-ignore
      func.apply(this, [Array.from(collection.values())])
      collection.clear()
    }, timeout)
  }
}
