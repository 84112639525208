<!-- FilterEditNode Component -->
<script lang="ts">
  import type { DateBetweenConfig } from '@components/FormControls/DateBetween.svelte'
  import type { EntityMultiSelectConfig } from '@components/FormControls/EntityMultiSelect.svelte'
  import type { EnumSelectConfig } from '@components/FormControls/EnumSelect.svelte'
  import FormSingle from '@components/FormSingle.svelte'
  import type {
    IDatePickerConfig,
    IInputConfig,
    IItemConfig,
  } from '@dhtmlx/ts-form'
  import {
    FilterBase,
    FilterBetween,
    FilterFalse,
    FilterNone,
    FilterSome,
    FilterTrue,
  } from '@lib/Filter/FilterTypes'
  import { getFilterTypeName } from '@lib/Filter/FilterUtil'
  import type { ICustomContainerConfig } from '@lib/FormUtil'
  import { dhtmlxDateTimeFormat, isSet } from '@packages/util'
  import { isInteger, isNumber } from '@lib/Validation'
  import type {
    FilterType,
    FilterTypeEnum,
    FilterTypeModel,
  } from '@models/api/BaseApi'
  import { tick } from 'svelte'

  export let value: FilterBase

  let _lastFilterType: Nullable<FilterType> = undefined
  let _formReady = false

  $: type = value?.constructor.name

  function getItem(value: FilterBase): Nullable<IItemConfig> {
    if (!value) return null

    const isBetween = value instanceof FilterBetween

    // Show nothing if the filter doesn't require any values
    if (
      [FilterSome, FilterNone, FilterTrue, FilterFalse].some(
        (filterClass) => value instanceof filterClass
      )
    ) {
      return null
    }

    if (_lastFilterType != value?.filterType) {
      _formReady = false
      tick().then(() => {
        _lastFilterType = value?.filterType
        _formReady = true
      })
    }

    // Show a different editor based on the given value
    switch (getFilterTypeName(value?.filterType)) {
      case 'string':
        return {
          type: 'input',
          placeholder: 'Value',
        }
      case 'boolean':
        return null
      case 'datetime':
        return valueDate(isBetween, true)
      case 'date':
        return valueDate(isBetween, false)
      case 'time':
        return {
          type: 'timepicker',
          // TODO: Range
        }
      case 'numeric':
        return valueNumber(isBetween, false)
      case 'integer':
        return valueNumber(isBetween, true)
      case 'model':
        if (!isSet(value.filterType?.['model'])) throw new Error('Invalid Type')
        return {
          type: 'container',
          html: '@EntityMultiSelect',
          config: <EntityMultiSelectConfig>{
            type: (value.filterType as FilterTypeModel).model,
            minimal: true,
          },
        }
      case 'enum':
        if (!isSet(value.filterType?.['enum'])) throw new Error('Invalid Type')
        return {
          type: 'container',
          html: '@EnumSelect',
          config: <EnumSelectConfig>{
            enum: (value.filterType as FilterTypeEnum).enum,
            multiselect: true,
          },
        }
    }
  }

  function valueNumber(isBetween = false, _isInteger = false) {
    const validation = (_isInteger ? isInteger : isNumber) as any
    return isBetween
      ? <ICustomContainerConfig>(<any>{
          type: 'container',
          html: '@NumberBetween',
          validation,
        })
      : <IInputConfig>{
          type: 'input',
          inputType: 'number',
          validation,
        }
  }

  function valueDate(isBetween = false, withTime = false) {
    return isBetween
      ? <ICustomContainerConfig>(<any>{
          type: 'container',
          html: '@DateBetween',
          config: <DateBetweenConfig>{
            timeSelect: !withTime,
          },
        })
      : <IDatePickerConfig>{
          type: 'datepicker',
          dateFormat: dhtmlxDateTimeFormat,
          timePicker: withTime,
        }
  }

  function getValue(value: FilterBase) {
    return value?.value
  }

  function handleSetValue(event: CustomEvent<any>) {
    value.value = event.detail
  }
</script>

<div class="filterEditNode">
  {#key type}
    {#if type}
      {@const item = getItem(value)}
      {#if _formReady}
        <FormSingle
          {item}
          value={getValue(value)}
          on:change={handleSetValue}
          config={{ css: 'filterEditNodeBox' }}
        />
      {/if}
    {/if}
  {/key}
</div>

<style lang="scss">
  .filterEditNode {
    width: 100%;
  }
</style>
