<script lang="ts">
  import type { Grid, IGridConfig } from '@dhtmlx/ts-grid'
  import { DhxGrid } from '@lib/dhtmlx'
  import type {
    GridCellClickEvent,
    GridCellDblClickEvent,
  } from '@packages/util'
  import { awaitRedraw, type MappedEvents } from '@packages/util'
  import { createEventDispatcher, onDestroy } from 'svelte'
  import Container from './Utility/Container.svelte'

  interface $$Events {
    cellDblClick: CustomEvent<GridCellDblClickEvent>
    cellClick: CustomEvent<GridCellClickEvent>
    headerCellDblClick: CustomEvent<GridCellDblClickEvent>
    footerCellDblClick: CustomEvent<GridCellDblClickEvent>
    headerCellClick: CustomEvent<GridCellClickEvent>
    footerCellClick: CustomEvent<GridCellClickEvent>
    gridRender: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()
  const gridId = `grid-${Math.random().toString(16).slice(2)}`

  export let gridConfig: IGridConfig = {}
  export let grid: Grid = undefined

  let gridElement: HTMLDivElement
  let width: number
  let height: number

  function setGridEvents() {
    grid.events.on('cellDblClick', function (row, col, e) {
      // Unselect text that gets selected when double clicking
      if (window.getSelection) {
        // Chrome
        if (window.getSelection().empty) {
          window.getSelection().empty()
        } else if (window.getSelection().removeAllRanges) {
          // Firefox
          window.getSelection().removeAllRanges()
        }
      }

      dispatch('cellDblClick', {
        row,
        col,
        e,
      })
    })

    grid.events.on('cellClick', function (row, col, e) {
      document.querySelectorAll(`#${gridId} .selected`).forEach((el) => {
        el.classList.remove('selected')
      })

      e.composedPath().forEach((el) => {
        if ((el as HTMLElement)?.classList?.contains('dhx_grid-cell')) {
          ;(el as HTMLElement)?.classList.add('selected')
        }
      })

      dispatch('cellClick', {
        row,
        col,
        e,
      })
    })

    grid.events.on('headerCellClick', (col, e) =>
      dispatch('headerCellClick', { row: null, col, e })
    )
    grid.events.on('headerCellDblClick', (col, e) =>
      dispatch('headerCellClick', { row: null, col, e })
    )
    grid.events.on('footerCellClick', (col, e) =>
      dispatch('footerCellClick', { row: null, col, e })
    )
    grid.events.on('footerCellDblClick', (col, e) =>
      dispatch('footerCellClick', { row: null, col, e })
    )
  }

  function updateSize(clientWidth: number, clientHeight: number) {
    if (clientWidth < 0 || clientHeight < 0) return
    if (!grid) return initGrid(clientWidth, clientHeight)
    grid.config.width = clientWidth
    grid.config.height = clientHeight
    grid.paint()
  }

  async function initGrid(clientWidth: number, clientHeight: number) {
    grid = new DhxGrid(gridElement, {
      autoWidth: true,
      autoHeight: true,
      width: clientWidth,
      height: clientHeight,
      ...gridConfig,
    })

    await awaitRedraw()

    setGridEvents()
    dispatch('gridRender')
  }

  onDestroy(() => {
    grid?.destructor()
    grid = undefined
  })
</script>

<Container
  on:resize={(event) => updateSize(event.detail.width, event.detail.height)}
  bind:width
  bind:height
>
  <slot />
  <div class="grid" id={gridId} bind:this={gridElement}></div>
</Container>

<!-- <style lang="scss">
  @use '../theme/variables' as vars;

  .grid {
    :global(.selected) {
      outline: 1px solid rgba($color: vars.$dhxPrimary, $alpha: 0.5);
      outline-offset: -1px;
    }
  }
</style> -->
