import { dateFormatter } from '@packages/locale/lib/dateFormatterStore'
import { numberFormatter } from '@packages/locale/lib/numberFormatterStore'
import { get } from 'svelte/store'
import { getDecimalSeparator } from './BrowserUtils'
import { parseIntFromAny } from './CalculationUtils'
import { isDate } from './ParsingUtils'
import { isSet } from './RuntimeUtils'

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param useSI True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param decimalCount Number of decimal places to display.
 *
 * @return Formatted string.
 *
 * @see https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 */
export function formatByteSize(
  bytes: number,
  useSI = true,
  decimalCount = 2
): Nullable<string> {
  // Parse the integer
  bytes = parseIntFromAny(bytes)
  if (typeof bytes != 'number' || !isSet(bytes)) return null

  const threshold = useSI ? 1000 : 1024

  if (Math.abs(bytes) < threshold) {
    return bytes + 'B'
  }

  const units = useSI
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let unitIndex = -1
  const decimalRounder = 10 ** decimalCount

  do {
    bytes /= threshold
    ++unitIndex
  } while (
    Math.round(Math.abs(bytes) * decimalRounder) / decimalRounder >=
      threshold &&
    unitIndex < units.length - 1
  )

  return (
    bytes.toFixed(decimalCount).replace('.', getDecimalSeparator()) +
    units[unitIndex]
  )
}

/** Format the value to something the user can more easily read */
export function getDisplayValue(value: any) {
  if (value === null) return '[Null]'
  if (typeof value == 'string' && value.length <= 0) return '[Empty String]'
  if (Array.isArray(value) && value.length <= 0) return '[Empty Array]'
  if (isDate(value)) return get(dateFormatter).format(value)
  switch (typeof value) {
    case 'bigint':
      return String(value)
    case 'string':
      return value
    case 'number':
      if (isNaN(value)) return '[NaN]'
      return get(numberFormatter).format(value)
    case 'boolean':
      return value ? 'True' : 'False'
    case 'symbol':
      return '[' + value.toString() + ']'
    case 'undefined':
      return '[Undefined]'
    case 'function':
      return '[Function]'
    case 'object':
      return '[Object]'
  }
}
