<!-- A simple container that shows the user that this section of the page is still loading.
  It also has a button to cancel the current operation if cancellable is set to true -->
<script lang="ts">
  import type { MappedEvents } from '@packages/util'
  import { createEventDispatcher } from 'svelte'
  import LoadingSpinner from './LoadingSpinner.svelte'

  interface $$Events {
    /** Retry button clicked */
    retryClick: CustomEvent<never>
    /** Cancel button clicked @deprecated Use cancelClick instead */
    click: CustomEvent<never>
    /** Cancel button clicked */
    cancelClick: CustomEvent<never>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  export let contentRect: Nullable<DOMRectReadOnly>
  export let loading: boolean
  export let message = 'Loading Data...'
  export let abortController: AbortController = null
  export let cancellable = !!abortController
  export let error = null
  export let hideRetry = false
  export let zIndex: Nullable<number> = null

  export { css as class }
  let css = ''

  function cancel() {
    dispatch('click')
    dispatch('cancelClick')
    if (abortController) {
      abortController.abort()
      abortController = new AbortController()
      loading = false
    }
  }
</script>

{#if loading || error}
  <div
    class="loadingOverlay {css}"
    style:width={`${contentRect?.width ?? 0}px`}
    style:height={`${contentRect?.height ?? 0}px`}
    style:z-index={zIndex}
  >
    {#if !error}
      <LoadingSpinner />
      <span class="loadingMessage">{message}</span>
      {#if cancellable}
        <button
          class="dhx_button dhx_button--size_medium dhx_button--view_flat"
          type="button"
          on:click={cancel}
        >
          <span class="dhx_button__text">Cancel</span>
        </button>
      {/if}
    {:else}
      <slot name="error">
        <h2 class="errorTitle">An Error has Occured:</h2>
        <p class="errorText">{error}</p>
        <button
          class="dhx_button dhx_button--size_medium dhx_button--view_flat"
          type="button"
          on:click={() => dispatch('retryClick')}
          class:hidden={hideRetry}
        >
          <span class="dhx_button__text">Retry</span>
        </button>
      </slot>
    {/if}
  </div>
{/if}

<style lang="scss">
  @use '../../theme/variables' as vars;

  .loadingOverlay {
    z-index: vars.$zId-overlay !important;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loadingMessage {
      padding-bottom: 1em;
    }

    .dhx_button__text {
      color: var(--text);
    }

    .errorTitle {
      margin: 0;
      font-size: xx-large;
      user-select: none;
    }

    .errorText {
      margin: 0.5em;
      opacity: 0.75;
      font-style: italic;
      user-select: all;
    }
  }
</style>
