<script lang="ts" context="module">
  import { wrap, type Routes, requirePermissions } from '@lib/Router'

  // Define dev-routes here
  export const devRoutes: Routes = {
    dev: wrap({
      asyncComponent: requirePermissions(
        () => import('@pages/developerPages/DevIndex.svelte')
      ),
    }),
    'permissions-list': wrap({
      asyncComponent: requirePermissions(
        () => import('@pages/developerPages/PermissionsList.svelte')
      ),
    }),
    sandbox: wrap({
      asyncComponent: requirePermissions(
        () => import('@pages/developerPages/Sandbox.svelte')
      ),
    }),
    sandbox2: wrap({
      asyncComponent: requirePermissions(
        () => import('@pages/developerPages/Sandbox2.svelte')
      ),
    }),
    sandbox3: wrap({
      asyncComponent: requirePermissions(
        () => import('@pages/developerPages/Sandbox3.svelte')
      ),
    }),
    sandbox4: wrap({
      asyncComponent: requirePermissions(
        () => import('@pages/developerPages/Sandbox4.svelte')
      ),
    }),
    sandbox5: wrap({
      asyncComponent: requirePermissions(
        () => import('@pages/developerPages/Sandbox5.svelte')
      ),
    }),
  }
</script>

<ul>
  {#each Object.keys(devRoutes).filter((item) => item != 'dev') as route}
    <!-- content here -->
    <li>
      <a href="#/{route}">{route}</a>
    </li>
  {/each}
</ul>
