<script lang="ts">
  import '@theme/_splitpanes-theme.scss'

  import { convertCssUnit } from '@packages/util'
  import { onMount } from 'svelte'
  import { Pane, Splitpanes } from 'svelte-splitpanes'

  /**
   * The treshold to change the orientation to horizontal (eg. on mobile)
   *
   * Takes any valid CSS unit
   */
  export let treshold: string | number = 800

  /**
   * Minimum panel size in percentage
   *
   * Must be under 50%,
   * Defaults to 10%
   */
  export let minPanelSize: number = 10

  /**
   * Size of the left panel in percentage
   */
  export let leftSize: Nullable<number> = null

  /**
   * Size of the right panel in percentage
   */
  export let rightSize: Nullable<number> = null

  /**
   * CSS Class of the left panel
   */
  export let leftClass: Nullable<string> = null

  /**
   * CSS Class of the right panel
   */
  export let rightClass: Nullable<string> = null

  /**
   * SnapSize of both panels in percentage
   *
   * (Setting this will override left and right snap size)
   */
  export let snapSize: Nullable<number> = null

  /**
   * SnapSize of the left panel in percentage
   */
  export let leftSnapSize: Nullable<number> = null

  /**
   * SnapSize of the right panel in percentage
   */
  export let rightSnapSize: Nullable<number> = null

  let contentRect: Nullable<DOMRectReadOnly>

  $: horizontal =
    (contentRect?.width ?? Number.MAX_SAFE_INTEGER) <
    convertCssUnit(treshold ?? 800)

  onMount(() => {
    // FIX: Adjust the size of one pane ever so slightly to force a recalculation (if extra panes are defined)
    if ($$slots.extraLeft || $$slots.extraRight || $$slots.extraMiddle)
      leftSize += 0.0000001
  })
</script>

<div class="resize-watcher" bind:contentRect></div>

<Splitpanes
  {horizontal}
  theme="customTheme"
  on:resize
  on:ready
  on:resized
  on:splitter-click
  on:pane-click
  on:pane-maximize
>
  <slot name="extraLeft" {horizontal} />
  <Pane
    minSize={minPanelSize}
    bind:size={leftSize}
    snapSize={snapSize ?? leftSnapSize}
    class={leftClass ?? ''}
  >
    <slot name="left" {horizontal} />
  </Pane>
  <slot name="extraMiddle" {horizontal} />
  <Pane
    minSize={minPanelSize}
    bind:size={rightSize}
    snapSize={snapSize ?? rightSnapSize}
    class={rightClass ?? ''}
  >
    <slot name="right" {horizontal} />
  </Pane>
  <slot name="extraRight" {horizontal} />
</Splitpanes>
<slot {horizontal} />
