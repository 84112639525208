// Helper functions

import { isSet } from '@packages/util'
import type { FilterType } from '@models/api/BaseApi'
import { getAvailableFilters } from './FilterDefinitions'
import type { FilterBase } from './FilterTypes'

export type FilterTypeName = Extract<FilterType, string> | 'model' | 'enum'

/** Convert the column filter type to the filter name */
export function getFilterTypeName(
  filterType: FilterType | FilterTypeName
): FilterTypeName | null {
  if (isSet(filterType?.['model'])) {
    return 'model'
  } else if (isSet(filterType?.['enum'])) {
    return 'enum'
  } else {
    return (filterType as FilterTypeName) ?? 'string'
  }
}

/** Return the given input, running a function if necessary */
export function resolve<T>(input: T | ((value: any) => T), value: any): T {
  if (typeof input == 'function') return (input as any)(value)
  else return input
}

/**
 * Fint the filter by name
 */
export function findFilterByName(name: string): typeof FilterBase | null {
  return getAvailableFilters().find((filter) => filter.name == name)
}
