<script lang="ts" context="module">
  import type { Combobox, IComboboxConfig } from '@dhtmlx/ts-combobox'
  import { parseError } from '@lib/ErrorHandler'
  import { DhxCombobox } from '@lib/dhtmlx'
  import {
    awaitRedraw,
    isValidInt,
    parseIntFromAny,
    type MappedEvents,
  } from '@packages/util'
  import { createEventDispatcher, onDestroy, onMount } from 'svelte'
  import Popup from './Popup.svelte'

  export interface ComboboxItem {
    id: string
    value: string
  }
</script>

<script lang="ts">
  interface $$Events {
    change: CustomEvent<string>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()
  const id = `combobox-${Math.random().toString(16).slice(2)}`

  export let comboboxConfig: IComboboxConfig = {}
  export let combobox: Nullable<Combobox> = null

  export let selected: Nullable<string> = null
  export let disabled: boolean = false

  export let getData: () => Promise<ComboboxItem[]> = async () => []
  export let getCurrent: (id: string) => Promise<string> = async () => ''

  export let state: null | 'success' | 'error' = null

  /** Set to true to convert strings that look like integers to integer */
  export let autoConvert = false

  let isOpen = false
  let manuallyChanged = false
  let previousValue: any

  let cachedData: ComboboxItem[] = null

  let loadingPopup: Popup
  let loadingPopupRect: Nullable<DOMRectReadOnly>
  let loadingPopupLock = false
  let loadingPopupText = ''

  $: if (disabled) {
    combobox?.disable()
  } else {
    combobox?.enable()
  }

  // $: if (cachedData) {
  //   combobox?.data?.parse(structuredClone(cachedData))
  // }

  $: if (!isOpen) {
    combobox?.setValue(selected)
  }

  /**
   * Directly set the (fake) input box's value
   */
  export function setVisualText(value: string) {
    const element = combobox.getRootNode()?.querySelector('input')
    if (element) element.value = value
  }

  /** Unfocus the element */
  export function blur() {
    combobox?.blur()
  }

  /** Unset the value */
  export function unset() {
    selected = null
  }

  /** Invalidate the cached data */
  export async function invalidate() {
    cachedData = null
    await awaitRedraw()
    await updateCurrentText()
  }

  async function loadDataBeforeOpen() {
    loadingPopupLock = true
    loadingPopupText = 'Loading...'

    // Load the loading popup
    loadingPopup.showPopup(combobox.getRootNode())

    // Try to get new data
    try {
      cachedData = await getData()
    } catch (error) {
      // Handle the error and return
      const _err = parseError(error, [
        'ComboboxAsync',
        'loadDataBeforeOpen',
        'getData',
      ])

      loadingPopupLock = false
      loadingPopupText = _err.title + ': ' + _err.description

      throw error
    }

    combobox.data.parse(cachedData)

    // Hide the loading popup and show the real one again
    loadingPopup.hidePopup(true)
    await awaitRedraw()
    combobox.popup.show(combobox.getRootNode())

    // Continue the opening sequence
    beforeActualOpen()

    loadingPopupLock = false
  }

  /**
   * Store the previous value and
   * clear the input before opening the combobox
   */
  function beforeActualOpen() {
    previousValue = combobox.getValue()

    combobox.clear()

    manuallyChanged = false
    isOpen = true

    awaitRedraw().then(attachEmptyOptionClickHandler)
  }

  /**
   * Handle the opening action of the combobox
   */
  function handleOpen() {
    if (loadingPopupLock) return false
    if (!cachedData) {
      loadDataBeforeOpen()
      return false
    } else {
      beforeActualOpen()
    }
  }

  /**
   * Improve the functionality of the combobox
   */
  function enhanceControls() {
    // Save the previous value and clear the input on opening
    combobox.events.on('beforeOpen', handleOpen)

    // Restore the value if it hasn't been manually changed
    combobox.events.on('afterClose', () => {
      isOpen = false

      if (!manuallyChanged) {
        combobox.setValue(previousValue)
      } else {
        // The value has changed
        const selectedId = combobox.getValue()
        const value = String(selectedId)

        if (autoConvert && isValidInt(value)) {
          selected = parseIntFromAny(selectedId) as any
        } else {
          selected = String(selectedId)
        }

        dispatch('change', selectedId as string)
      }
    })

    // Mark the element as changed if done so manually
    combobox.events.on('change', () => {
      if (isOpen) {
        manuallyChanged = true
      }
    })
  }

  /**
   * Manually attach a click handler for the first item.
   * On click, it forces the value to be set to that item.
   */
  function attachEmptyOptionClickHandler() {
    const popupElement = combobox.popup.getRootNode()
    const element = popupElement?.querySelector(
      '.dhx_list .dhx_list-item[data-dhx-id=""]'
    ) as HTMLLIElement

    // Add click event handler
    element?.addEventListener('click', async () => {
      manuallyChanged = true
      combobox.popup.hide()
      combobox.setValue('')
      await awaitRedraw()
      setVisualText('')

      // The value has changed
      const selectedId = ''
      selected = String(selectedId)
      dispatch('change', selectedId)
    })

    // Add class to the popup
    awaitRedraw().then(() =>
      popupElement.classList.add('forceComboboxUnsetHeight')
    )
  }

  async function updateCurrentText() {
    await awaitRedraw()

    if (getCurrent) {
      try {
        setVisualText(await getCurrent(selected))
      } catch (error) {
        setVisualText('<Error>')
        throw error
      }
    } else {
      setVisualText(selected)
    }
  }

  onMount(async () => {
    combobox = new DhxCombobox(id, {
      ...comboboxConfig,
      disabled,
      data: undefined,
    })
    enhanceControls()
    await awaitRedraw()

    if (selected) combobox.setValue(selected)

    updateCurrentText()
  })

  onDestroy(() => {
    combobox?.destructor()
  })
</script>

<div
  class="combobox"
  class:dhx_form-group--state_error={state == 'error'}
  {id}
  bind:contentRect={loadingPopupRect}
></div>

<Popup
  bind:this={loadingPopup}
  popupConfig={{ css: '' }}
  lock={loadingPopupLock}
>
  <div
    class="dhx_widget dhx_layout dhx_layout-rows dhx_combobox-options dhx_combobox__options"
    style="width: {loadingPopupRect?.width ?? 1}px;"
  >
    <div class="dhx_layout-cell">
      <ul class="dhx_widget dhx_list">
        <li class="dhx_list-item">
          <span class="dhx_combobox-options__value">{loadingPopupText}</span>
        </li>
      </ul>
    </div>
  </div>
</Popup>

<style lang="scss">
  :global(.dhx_combobox.noMargin) {
    margin: 0 !important;
  }

  // This style is used to force the combobox to not have a js-controlled height
  :global(.forceComboboxUnsetHeight) {
    height: unset !important;
  }
</style>
