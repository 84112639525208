<!-- FilterEditNode Component -->
<script lang="ts" context="module">
  import Combobox from '@components/Combobox.svelte'
  import Button from '@components/Utility/Button.svelte'
  import { groupTypes } from '@lib/Filter/FilterDefinitions'
  import type FilterGroup from '@lib/Filter/FilterGroup'
  import type FilterNode from '@lib/Filter/FilterNode'
  import { mdiDelete, mdiPlus } from '@mdi/js'
  import { any } from '@packages/util'
  import { confirm } from '@packages/util/lib/CommonAlerts'
  import FilterEditItem from './FilterEditItem.svelte'

  const maxDepth = 5
</script>

<script lang="ts">
  export let value: FilterGroup
  export let __depth = 0

  $: columnOptions = value?.getColumnOptions?.()
  $: limitReached = __depth >= maxDepth
  $: limitAlmostReached = __depth >= maxDepth - 1

  function getSelection(node: FilterNode, _: any) {
    return columnOptions.find((item) => item.id == node.getKey())?.id
  }

  async function handleColumnChange(event: CustomEvent<string>, index: number) {
    value.children[index] = value.children[index] as FilterGroup

    // Await confirmation if switching away from a group filter
    if (
      !limitAlmostReached &&
      !groupTypes.includes(event.detail as any) &&
      !(await confirmSignificantChange(index))
    ) {
      return
    }

    value.swapItem(index, event.detail)
    value = value
  }

  /** Add a new item at the end */
  function addItem() {
    value.addChild()
    value = value
  }

  /** Remove the item at the given index */
  async function removeItem(index: number) {
    // Await confirmation if removing a group filter
    if (!(await confirmSignificantChange(index))) return
    value.removeChild(index)
    value = value
  }

  /** Show a confirmation popup if the given index is a group with items in it */
  async function confirmSignificantChange(index: number) {
    const current_asGroup = value.children[index] as FilterGroup

    return !(
      current_asGroup?.isGroup() &&
      current_asGroup.children?.length > 0 &&
      !(await confirm({
        title: 'Clear Filters',
        content: 'This action will remove all filters in the group. Continue?',
      }))
    )
  }
</script>

<!-- For each child in group -->
{#each value?.children ?? [] as child, index}
  <div class="filterEditNode" class:vertical={child.isGroup()}>
    {#if child}
      <!-- The delete button and entry selection -->
      <div class="columnSelection">
        <!-- Delete -->
        <Button icon={mdiDelete} iconOnly on:click={() => removeItem(index)} />
        <!-- Entry selection -->
        <Combobox
          selected={getSelection(child, value)}
          data={columnOptions?.filter(
            (item) =>
              (!value.children.find((_item) => item.id == _item.getKey()) ||
                item.id == child.getKey()) &&
              (!groupTypes.includes(any(item.id)) || !limitAlmostReached)
          )}
          comboboxConfig={{
            css: 'noMargin columnCobobox',
            placeholder: 'Select Column',
          }}
          on:change={(event) => handleColumnChange(event, index)}
        />
      </div>
      <!-- Is the entry set? -->
      {#if child?.getKey()}
        <!-- Check if the child is either a group or item -->
        {#if child.isGroup()}
          <!-- Recursive component -->
          <svelte:self bind:value={child} __depth={__depth + 1} />
        {:else if child.isItem()}
          <!-- Filter type -->
          <FilterEditItem bind:value={child} />
        {/if}
      {/if}
    {/if}
  </div>
{:else}
  <div class="placeholderText">
    No filters defined. Click on "Add Item" and select a column or expression to
    begin creating a filter.
  </div>
{/each}

<!-- Add item button -->
<Button
  icon={mdiPlus}
  width="10em"
  link
  value="Add Item"
  hidden={limitReached}
  on:click={addItem}
  tooltip={'Add item'}
  css="buttonMarginTop"
/>

<style lang="scss">
  @use '../../theme/variables' as vars;

  $vertical-margin: 1em;
  $horizontal-margin: 0.5em;
  $step-size: 1em;

  .filterEditNode {
    display: flex;

    &.vertical {
      $vertical-margin-half: calc($vertical-margin / 2);
      $step-size-half: calc($step-size / 2);

      flex-direction: column;
      border: vars.$borderStyle;
      padding: $vertical-margin-half $step-size-half;

      margin-top: $vertical-margin-half;
      margin-left: $step-size-half;
    }

    .columnSelection {
      display: flex;
    }

    margin-top: $vertical-margin;
    margin-left: $step-size;

    :global(.columnCobobox) {
      min-width: 12em;
      flex-grow: 1;
      max-width: 15em;
    }
  }

  :global(.addCombobox) {
    height: 0;
  }

  :global(.buttonMarginTop) {
    margin-top: $vertical-margin;
  }
</style>
