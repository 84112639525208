import { displayError } from '@components/Global/StatusMessage.svelte'
import { encodePHPQueryParams, isSet } from '@packages/util'
import { get } from 'svelte/store'
import type { BooleanState } from '../packages/util/lib/UtilityTypes'
import { API, enableHardDelete, enableShowSoftDeletedItems } from './ApiHandler'
import {
  parseError,
  wrapFetchError,
  wrapXMLHttpError,
  type TraceBack,
} from './ErrorHandler'

/** Methods supported by the API */
export type HttpMethod = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS'

export interface ApiRequestOptions<ReturnResponse extends boolean = false> {
  /**
   * The URL, giving an array will join it with `/`.
   *
   * Required
   */
  url: string | string[]
  /**
   * The body of the request
   * in `GET` mode: The contents will be URL Encoded
   */
  body?: BodyInit | Record<any, any>
  /**
   * The headers for the request
   */
  headers?: HeadersInit
  /**
   * The AbortSignal (for canceling requests)
   */
  signal?: AbortSignal
  /**
   * The HTTP method.
   * @default 'GET'
   */
  method?: HttpMethod
  /**
   * The allowed response codes
   * @default '[200, 201, 204]'
   */
  allowResponseCodes?: number[]
  /**
   * Set to false to not display an error to the end user
   * @default true
   */
  displayError?: boolean
  /**
   * (Only when displayError is enabled) Define the toast to update when an error occurs
   */
  toastId?: string | number
  /**
   * The extra fetch options
   */
  fetchOptions?: RequestInit //Omit<RequestInit, 'method' | 'body' | 'headers' | 'signal'>
  /**
   * Set to true to not insert the base URL
   * @default false
   */
  fullUrl?: boolean
  /**
   * Set to true to disable JSON parsing and return the response
   * @default false
   */
  returnResponse?: ReturnResponse
}

export function apiBuildUrl(
  url: string | string[],
  queryParams?: string | Record<any, any>,
  fullUrl = false
) {
  let outputUrl = ''

  // Add base url if not fullUrl.
  outputUrl += fullUrl
    ? ''
    : (API.baseURL + '/')
        // Remove trailing slashes
        .replace(/\/\/$/, '')

  // Join all url parts
  outputUrl += (Array.isArray(url) ? url.join('/') : url)
    // Remove double slashes
    .replaceAll('//', '/')
    // Remove leading/trailing slashes
    .replaceAll(/(^\/+|\/+$)/g, '')

  // Parse Query Params
  outputUrl += isSet(queryParams)
    ? typeof queryParams == 'string'
      ? `?${queryParams}`
      : `?${encodePHPQueryParams(queryParams)}`
    : ''

  // Remove trailing / and ?
  return outputUrl.replace(/(\/|\?)*$/, '')
}

/** Build the request body, returns the body itself and the query params */
export function apiBuildBody(
  body: any,
  method?: HttpMethod
): [body: any, queryParams: any] {
  const _method = String(method ?? 'GET').toUpperCase()

  if (!isSet(body) || _isFetchNativeData(body)) {
    // Return fetch native data
    return [body, null]
  } else if (_method == 'GET') {
    // If it is a GET request, parse to a query string
    return [null, body]
  } else if (typeof body == 'object') {
    // Try to parse the JSON request
    try {
      return [JSON.stringify(body ?? '{}'), null]
    } catch (error) {
      console.warn(
        'Cannot parse request body to JSON. Using raw body data',
        error
      )
      return [body, null]
    }
  } else {
    // Last resort
    return [body, null]
  }
}

/** Check if the given data is one of the fetch-native types */
function _isFetchNativeData(data: any) {
  return (
    data instanceof FormData ||
    data instanceof URLSearchParams ||
    data instanceof Blob ||
    data instanceof ReadableStream ||
    data instanceof ArrayBuffer ||
    ArrayBuffer.isView(data)
  )
}

/**
 * Extended local fetch that also can decode the JSON response
 *
 * This promise can also later be used for deduplication in a request queue
 * @todo Create a request queue
 * @todo Deduplicate request
 * @todo Create a cache
 * @todo Implemement rate limiting
 */
export async function request<T = any, ReturnResponse extends boolean = false>(
  options: ApiRequestOptions<ReturnResponse>
): Promise<BooleanState<ReturnResponse, Response, T>> {
  const [_body, _queryParams] = apiBuildBody(
    options.body ?? options.fetchOptions?.body,
    options.method ?? (options.fetchOptions?.method as any)
  )
  const _url = apiBuildUrl(options.url, _queryParams, options.fullUrl)
  const _displayError = isSet(options.displayError)
    ? options.displayError
    : true
  const _allowCodes = [200, 201, 204, ...(options.allowResponseCodes ?? [])]
  const _method = (options?.method ?? 'GET').toUpperCase()
  const _errorObj = options?.toastId ? { id: options.toastId } : {}
  const _headers = new Headers(options?.headers ?? {})
  const _signal = options?.signal ?? options?.fetchOptions?.signal

  let trace: TraceBack = ['ApiHandler', 'request', options]

  // Add headers from the fetchOptions (if not set in the main options)
  const _otherHeaders = new Headers(options?.fetchOptions?.headers ?? {})
  _otherHeaders.forEach(
    (key, value) => !_headers.has(key) && _headers.set(key, value)
  )

  // Add More Headers
  // TODO: Find a better solution later
  get(enableHardDelete) && _headers.set('X-Hard-Delete', '1')
  get(enableShowSoftDeletedItems) &&
    _headers.set('X-Show-Soft-Deleted-Items', '1')

  // Fetch data
  let response: Response
  try {
    trace.push('fetch')
    response = await fetch(_url, {
      ...(options.fetchOptions ?? {}),
      method: _method,
      body: _body,
      headers: _headers,
      signal: _signal,
    })
  } catch (error) {
    trace.push('fetchError')
    throw displayError(
      wrapFetchError({
        type: 'fetch',
        error,
        ignore: !_displayError,
      }),
      trace,
      _errorObj
    )
  }

  let returnData: unknown = null

  // Does the request expect a JSON response?
  if (options.returnResponse == true) {
    // Set the return data to the response itself
    returnData = response
  } else if (
    // does not have headers that does not contain data
    !['HEAD', 'OPTIONS'].includes(_method) &&
    // does not have one of the following status codes
    response.status != 204 &&
    // is Content-Type JSON
    response.headers?.get('Content-Type')?.includes('application/json')
  ) {
    // Parse response to json and set the return data
    try {
      returnData = response.status == 204 ? null : await response.json()
    } catch (error) {
      trace.push('parseError')
      throw displayError(
        wrapFetchError({
          error,
          response,
          type: 'parse',
          ignore: !_displayError,
        }),
        trace,
        _errorObj
      )
    }
  }

  // Check if the response code is allowed
  if (_allowCodes.find((code) => code == response.status)) {
    // @ts-expect-error Type gets correctly applied
    return returnData
  } else {
    trace.push('responseCode')
    throw displayError(
      wrapFetchError({
        error: returnData,
        data: returnData,
        response,
        type: 'code',
        ignore: !_displayError,
      }),
      trace,
      _errorObj
    )
  }
}

export function parseXMLHttpResponse(request: XMLHttpRequest) {
  // Request not ready to be parse
  if (request.readyState != request.DONE) {
    console.warn('Attempting to parse response on an incomplete request.')
    return
  }

  // No Errors to parse
  if (request.status < 400) return

  // Try to parse the response
  let _errorObj: any = null
  if (['json', 'text', ''].includes(request.responseType)) {
    try {
      _errorObj = JSON.parse(request.responseText)
    } catch (_) {
      // Ignore Error
    }
  }

  return parseError(
    wrapXMLHttpError({
      type: 'xmlHttp',
      response: request,
      error: _errorObj,
      data: _errorObj,
    })
  )
}
