// Generated on /permissions-list
export type Permission = keyof typeof permission

export const permission = {
  assignPermission: 'assignPermission',
  createAccount: 'createAccount',
  createAppFunction: 'createAppFunction',
  createAsset: 'createAsset',
  createAsset_BatchType: 'createAsset_BatchType',
  createAssetClass: 'createAssetClass',
  createAssetLog: 'createAssetLog',
  createBatch: 'createBatch',
  createBatchType: 'createBatchType',
  createCemAlarm: 'createCemAlarm',
  createCemCause: 'createCemCause',
  createCemCause_CemEffect: 'createCemCause_CemEffect',
  createCemEffect: 'createCemEffect',
  createChannel: 'createChannel',
  createController: 'createController',
  createDashboard: 'createDashboard',
  createDashboardWidget: 'createDashboardWidget',
  createDatapoint: 'createDatapoint',
  createEndpoint: 'createEndpoint',
  createEnum: 'createEnum',
  createFile_Group: 'createFile_Group',
  createGroup: 'createGroup',
  createGroup_AppFunction: 'createGroup_AppFunction',
  createIndicator: 'createIndicator',
  createProcess: 'createProcess',
  createProcessStep: 'createProcessStep',
  createProgram: 'createProgram',
  createReport: 'createReport',
  createReport_Batch: 'createReport_Batch',
  createSchedule: 'createSchedule',
  createTag: 'createTag',
  createTag_Indicator: 'createTag_Indicator',
  createUser: 'createUser',
  createUser_Asset: 'createUser_Asset',
  createUser_Group: 'createUser_Group',
  createView: 'createView',
  deleteAccount: 'deleteAccount',
  deleteAppFunction: 'deleteAppFunction',
  deleteAsset: 'deleteAsset',
  deleteAsset_BatchType: 'deleteAsset_BatchType',
  deleteAssetClass: 'deleteAssetClass',
  deleteAssetLog: 'deleteAssetLog',
  deleteBatch: 'deleteBatch',
  deleteBatchType: 'deleteBatchType',
  deleteCemAlarm: 'deleteCemAlarm',
  deleteCemCause: 'deleteCemCause',
  deleteCemCause_CemEffect: 'deleteCemCause_CemEffect',
  deleteCemEffect: 'deleteCemEffect',
  deleteChannel: 'deleteChannel',
  deleteController: 'deleteController',
  deleteDashboard: 'deleteDashboard',
  deleteDashboardWidget: 'deleteDashboardWidget',
  deleteDatapoint: 'deleteDatapoint',
  deleteEnum: 'deleteEnum',
  deleteFile_Group: 'deleteFile_Group',
  deleteGroup: 'deleteGroup',
  deleteGroup_AppFunction: 'deleteGroup_AppFunction',
  deleteIndicator: 'deleteIndicator',
  deleteProcess: 'deleteProcess',
  deleteProcessStep: 'deleteProcessStep',
  deleteProgram: 'deleteProgram',
  deleteReport: 'deleteReport',
  deleteReport_Batch: 'deleteReport_Batch',
  deleteSchedule: 'deleteSchedule',
  deleteTag: 'deleteTag',
  deleteTag_Indicator: 'deleteTag_Indicator',
  deleteUser: 'deleteUser',
  deleteUser_Asset: 'deleteUser_Asset',
  deleteUser_Group: 'deleteUser_Group',
  deleteView: 'deleteView',
  equipmentManagement: 'equipmentManagement',
  equipmentView: 'equipmentView',
  fileAccess: 'fileAccess',
  fileAdmin: 'fileAdmin',
  fileManagement: 'fileManagement',
  fileSharing: 'fileSharing',
  fileUpload: 'fileUpload',
  getAssetStructure: 'getAssetStructure',
  getEndpoint: 'getEndpoint',
  mqttAccessAllTopics: 'mqttAccessAllTopics',
  mqttAccessSystemTopics: 'mqttAccessSystemTopics',
  renewEndpointToken: 'renewEndpointToken',
  reportDerive: 'reportDerive',
  reportDownload: 'reportDownload',
  reportTemplateManagement: 'reportTemplateManagement',
  updateAccount: 'updateAccount',
  updateAppFunction: 'updateAppFunction',
  updateAsset: 'updateAsset',
  updateAsset_BatchType: 'updateAsset_BatchType',
  updateAssetClass: 'updateAssetClass',
  updateAssetLog: 'updateAssetLog',
  updateBatch: 'updateBatch',
  updateBatchType: 'updateBatchType',
  updateCemAlarm: 'updateCemAlarm',
  updateCemCause: 'updateCemCause',
  updateCemCause_CemEffect: 'updateCemCause_CemEffect',
  updateCemEffect: 'updateCemEffect',
  updateChannel: 'updateChannel',
  updateController: 'updateController',
  updateDashboard: 'updateDashboard',
  updateDashboardWidget: 'updateDashboardWidget',
  updateEnum: 'updateEnum',
  updateFile_Group: 'updateFile_Group',
  updateGroup: 'updateGroup',
  updateGroup_AppFunction: 'updateGroup_AppFunction',
  updateIndicator: 'updateIndicator',
  updateProcess: 'updateProcess',
  updateProcessStep: 'updateProcessStep',
  updateProgram: 'updateProgram',
  updateReport: 'updateReport',
  updateReport_Batch: 'updateReport_Batch',
  updateSchedule: 'updateSchedule',
  updateTag: 'updateTag',
  updateTag_Indicator: 'updateTag_Indicator',
  updateUser: 'updateUser',
  updateUser_Asset: 'updateUser_Asset',
  updateUser_Group: 'updateUser_Group',
  updateView: 'updateView',
  viewAccount: 'viewAccount',
  viewApiDocumentation: 'viewApiDocumentation',
  viewAppFunction: 'viewAppFunction',
  viewAsset: 'viewAsset',
  viewAsset_BatchType: 'viewAsset_BatchType',
  viewAssetClass: 'viewAssetClass',
  viewAssetLog: 'viewAssetLog',
  viewBatch: 'viewBatch',
  viewBatchType: 'viewBatchType',
  viewCemAlarm: 'viewCemAlarm',
  viewCemCause: 'viewCemCause',
  viewCemCause_CemEffect: 'viewCemCause_CemEffect',
  viewCemEffect: 'viewCemEffect',
  viewChannel: 'viewChannel',
  viewController: 'viewController',
  viewDashboard: 'viewDashboard',
  viewDashboardWidget: 'viewDashboardWidget',
  viewDatapoint: 'viewDatapoint',
  viewEnum: 'viewEnum',
  viewFile_Group: 'viewFile_Group',
  viewGroup: 'viewGroup',
  viewGroup_AppFunction: 'viewGroup_AppFunction',
  viewHistory: 'viewHistory',
  viewIndicator: 'viewIndicator',
  viewPermission: 'viewPermission',
  viewProcess: 'viewProcess',
  viewProcessStep: 'viewProcessStep',
  viewProgram: 'viewProgram',
  viewReport: 'viewReport',
  viewReport_Batch: 'viewReport_Batch',
  viewSchedule: 'viewSchedule',
  viewTag: 'viewTag',
  viewTag_Indicator: 'viewTag_Indicator',
  viewUser: 'viewUser',
  viewUser_Asset: 'viewUser_Asset',
  viewUser_Group: 'viewUser_Group',
  viewView: 'viewView',
  Administrators: 'Administrators',
  Default: 'Default',
  Installation: 'Installation',
} as const
