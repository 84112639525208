import { buildConfig } from './Features'

/**
 * A list of common strings, which can use template literal string like entries
 *
 * Format: "Hello ${name}" (note the use of quoting, don't use a backtick <`>!)
 */
export const commonStrings = {
  valueRequired: 'This value is required.',
} satisfies Record<string, string>

export const nameOfBatch = buildConfig.instanceConfig.batchName.singular
export const nameOfBatches = buildConfig.instanceConfig.batchName.plural
