<!-- Special form item specifically made for BaseReportCard -->
<script lang="ts" context="module">
  import Grid from '@components/Grid.svelte'
  import type { Grid as GridModel, IGridConfig } from '@dhtmlx/ts-grid'
  import type { Batch } from '@generated'
  import { dhtmlxGridRowHeight } from '@lib/CommonConstants'
  import { commonStrings } from '@lib/CommonStrings'
  import { getSrc, getSvg } from '@lib/Icons'
  import { mdiDelete, mdiFormatAlignLeft, mdiFormatAlignRight } from '@mdi/js'
  import { dateFormatter } from '@packages/locale/lib/dateFormatterStore'
  import {
    alphaSort,
    awaitRedraw,
    camel2title,
    cssVar,
    debounce,
    flatten,
    fuzzyCompare,
    isCtrlHeld,
    isSet,
    sleep,
  } from '@packages/util'
  import { getContext, onDestroy, onMount, tick } from 'svelte'
  import { type Writable } from 'svelte/store'
  import FormElementBase from './FormElementBase.svelte'

  export interface ReportInfoCardFieldsConfig {}

  export interface ReportInfoCardData {
    alignRight: boolean
    fields: ReportInfoCardField[]
  }

  export interface ReportInfoCardField {
    path: string
    name: string
  }

  export const _name = 'ReportInfoCardFields'

  const pathField = 'path'
  const nameField = 'name'
  const sampleField = 'sample'
  const deleteField = '_delete'

  const iso8601matcher =
    /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?(Z|[+-]\d{2}:?\d{2})?/
</script>

<script lang="ts">
  // Get contextual data like the form and batches
  const batches = getContext<Nullable<Writable<Batch[]>>>('batches')

  // Define the columns
  const gridConfig: IGridConfig = {
    columns: [
      // Path Column
      {
        id: pathField,
        header: [{ text: 'Element' }],
        editorType: 'combobox',
        editorConfig: {
          // @ts-ignore Combobox has a filter config option
          filter(item: { value: string }, target: string) {
            return fuzzyCompare(item.value, target)
          },
        },
        editable: true,
        mark(cellValue, columnCells, row, column) {
          return isSet(cellValue) || row.$emptyRow ? '' : 'validationError'
        },
        tooltipTemplate: (_value) => formatPath(_value, true),
        template(cellValue, row, col) {
          return (
            `<div class="dhx_combobox-input__icon">` +
            `<span class="dxi dxi-menu-down"></span></div>` +
            `<span class="pathDisplay">${formatPath(cellValue, true)}</span>`
          )
        },
        htmlEnable: true,
      },
      // Name Column
      {
        id: nameField,
        header: [{ text: 'Name' }],
        editorType: 'input',
        editable: true,
        mark(cellValue, columnCells, row, column) {
          return isSet(cellValue) || row.$emptyRow ? '' : 'placeholderLabel'
        },
        template(cellValue, row, col) {
          return isSet(cellValue) || row.$emptyRow
            ? cellValue
            : formatPath(row.path ?? '')
        },
        tooltipTemplate(cellValue, row, col) {
          return isSet(cellValue) || row.$emptyRow
            ? cellValue
            : 'Double click and type to override the name'
        },
      },
      {
        id: sampleField,
        header: [{ text: 'Preview' }],
        editable: false,
        template(_, row) {
          return formatPreview(flatten(batch ?? {})?.[row?.path ?? '---'])
        },
        tooltipTemplate(_, row) {
          return formatPreview(flatten(batch ?? {})?.[row?.path ?? '---'])
        },
      },
      {
        id: deleteField,
        header: [{ text: '' }],
        editable: false,
        width: 50,
        htmlEnable: true,
        template(cellValue, row, col) {
          return row?.$emptyRow
            ? ''
            : getSvg(mdiDelete, '100%', null, 'cursor:pointer;')
        },
        tooltipTemplate() {
          return 'Click to delete this row'
        },
      },
    ],
    height: 300,
    rowHeight: dhtmlxGridRowHeight,
    autoHeight: false,
    sortable: false,
    editable: true,
    autoEmptyRow: true,
    selection: 'cell',
    dragItem: 'row',
    css: 'customBorder',
  }

  export let value: Nullable<ReportInfoCardField[] | ReportInfoCardData> = []
  export let config: ReportInfoCardFieldsConfig = {}
  export let label = 'Fields'
  export let state: null | 'success' | 'error' = null
  export let helpMessage: string | null = null
  export let preMessage: string | null = null
  export let successMessage: string | null = null
  export let errorMessage: string | null = null

  let grid: Nullable<GridModel>

  // Changing this will set the current batch index
  let _batchIndex: Nullable<number> = null
  let _ready = false
  let _valueLock = false
  let _isEditing = false
  let _isEditingLock = false

  let _alignRight = false

  $: batch = updateBatch($batches?.[_batchIndex ?? 0])
  $: updateData(value, [grid, batch])

  // Update the internal value safely
  function updateData(data: typeof value, __reactive__: any) {
    if (_valueLock) return
    _valueLock = true

    // Initialize data
    data ??= []
    setAlignRight(Array.isArray(data) ? false : data.alignRight)
    grid?.data.parse(data ? (Array.isArray(data) ? data : data.fields) : [])

    tick().then(() => (_valueLock = false))
  }

  // Format paths into a human readable format
  function formatPath(path: string, fullPath = false) {
    const output = path
      .replaceAll(/@(formattedText|databind)/gi, '')
      .split('/')
      .map(camel2title)

    return fullPath ? output.join(' / ') : output.at(-1)
  }

  // Reload the path list on batch update
  function updateBatch(batch: Batch) {
    awaitRedraw().then(() => updatePathList())
    return batch
  }

  function updatePathList() {
    if (grid) {
      // Set options
      grid.getColumn(pathField).options = Object.keys(flatten(batch))
        .sort(alphaSort) // Sort alphabetically
        .filter((value, _, array) => {
          // Filter out paths that are already used
          if (getData().fields.find((item) => item.path == value)) return false

          const noIdValue = value.replace(/id$/i, '')

          return (
            value != 'id' &&
            !value.endsWith('/id') &&
            !array.find(
              (inner) =>
                inner == `${value}@formattedText` ||
                inner == `${noIdValue}@formattedText` ||
                inner == `${value}@databind` ||
                inner == `${noIdValue}@databind` ||
                (inner != value && inner == noIdValue)
            )
          )
        })
        .map((item) => {
          return {
            id: item,
            value: formatPath(item, true),
          }
        })
    }
  }

  function getData(): ReportInfoCardData {
    return {
      alignRight: _alignRight,
      fields: (grid?.data?.getRawData(0, -1) ?? [])
        .filter((item) => !item?.$emptyRow)
        .map<ReportInfoCardField>((item) => ({
          name: isSet(item.name) ? item.name : formatPath(item.path ?? ''),
          path: item.path ?? '',
        })),
    }
  }

  // Allow path fields to be single clicked to edit
  function handleClick(event: CustomEvent) {
    const columnId = event?.detail?.col?.id
    const rowId = event?.detail?.row?.id
    const isEmpty = !!event?.detail?.row?.$emptyRow

    // Edit the cell when clicked on the path field
    if (columnId && rowId && columnId == pathField) {
      grid.editCell(rowId, columnId)
    }

    // Delete the row when the delete button has been clicked
    if (columnId && rowId && !isEmpty && columnId == deleteField) {
      grid.data.remove(rowId)
    }
  }

  // Allow path fields to edited by pressing enter
  function handleWindowKeyDown(event: KeyboardEvent) {
    if (event.key != 'Enter') return

    if (!_isEditingLock && !_isEditing) {
      const selectedCol = grid.selection?.getCell()?.column?.id
      if (selectedCol == pathField) {
        _isEditingLock = true
        _isEditing = true
        const selectedRow = grid.selection?.getCell()?.row?.id
        grid.editCell(selectedRow, pathField)
        // Release the lock
        sleep(10).then(() => (_isEditingLock = false))
      }
    }

    if (!_isEditing && isCtrlHeld(event)) {
      _isEditing = false
      _isEditingLock = false
      // apply()
    }

    if (!_isEditingLock) {
      _isEditingLock = true
      _isEditing = false
      grid.editEnd()
      // Release the lock
      sleep(10).then(() => (_isEditingLock = false))
    }
  }

  function formatPreview(cellValue: Nullable<string>): string {
    if (!isSet(cellValue)) return '---'
    if (iso8601matcher.test(cellValue))
      return $dateFormatter.format(new Date(cellValue))
    return cellValue
  }

  function initGrid() {
    if (!grid) throw new Error('Grid not initialized!')

    // Apply grid events
    grid.events.on(
      'change',
      debounce(() => {
        updatePathList()
        _valueLock = true
        value = getData()
        tick().then(() => (_valueLock = false))
      }, 25)
    )

    grid.events.on('headerCellClick', (col) => {
      if (col.id != sampleField) return
      if (!Array.isArray(value) && isSet(value?.alignRight)) {
        value.alignRight = !value.alignRight
        value = value
      }
    })
  }

  onMount(async () => {
    await tick()
    _ready = true
  })

  function setAlignRight(alignRight: boolean) {
    _alignRight = alignRight
    if (alignRight) grid?.getRootNode()?.classList.add('alignValues')
    else grid?.getRootNode()?.classList.remove('alignValues')
  }

  onDestroy(() => {
    _ready = false
  })

  // Unused for now
  config
</script>

<!-- This handles pressing enter when editing -->
<svelte:window on:keydown={handleWindowKeyDown} />

<FormElementBase
  {label}
  {state}
  {helpMessage}
  {preMessage}
  {successMessage}
  errorMessage={errorMessage ?? commonStrings.valueRequired}
>
  <Grid
    {gridConfig}
    bind:grid
    on:cellClick={handleClick}
    on:gridRender={initGrid}
    --iconLeft="url({getSrc(mdiFormatAlignLeft, cssVar('text'))})"
    --iconRight="url({getSrc(mdiFormatAlignRight, cssVar('text'))})"
  />
</FormElementBase>

<style lang="scss">
  @use '../../theme/variables' as vars;

  $iconSize: 16px;

  // Alignment switch (left, default)
  :global(.dhx_grid-header-cell[data-dhx-id='sample']) {
    cursor: pointer;

    &:after {
      content: var(--iconLeft);
      height: $iconSize;
      width: $iconSize;
      fill: currentColor;
    }
  }

  // Alignment switch (right)
  :global(.alignValues .dhx_grid-header-cell[data-dhx-id='sample']) {
    &:after {
      content: var(--iconRight);
      height: $iconSize;
      width: $iconSize;
      fill: currentColor;
    }
  }

  // Text alignment when switched
  :global(.alignValues .dhx_grid-cell[data-dhx-col-id='sample']) {
    justify-content: flex-end;
  }

  :global(.placeholderLabel) {
    font-style: italic;
    opacity: 0.5;

    &:focus-within {
      opacity: 1;
    }
  }

  :global(.validationError) {
    background-color: rgb(255, 222, 222);
    color: vars.$error;
  }

  // Apply ellipsis on long paths
  :global(
      .dhx_combobox-options.dhx_combobox__options .dhx_combobox-options__value,
      .pathDisplay
    ) {
    display: block;
    text-align: left;
    direction: rtl;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  // Remove some width for the dxi-menu-down icon
  :global(.pathDisplay) {
    width: 90%;
  }
</style>
