<script lang="ts">
  import type { IPopupConfig, IShowConfig, Popup } from '@dhtmlx/ts-popup'
  import { DhxPopup } from '@lib/dhtmlx'
  import { getId } from '@packages/util'
  import { createEventDispatcher, onDestroy } from 'svelte'

  const dispatchEvent = createEventDispatcher()
  const id = getId('pupup')
  const defaultConfig: IPopupConfig = {
    css: 'dhx_widget--bordered',
  }

  export let popupConfig: IPopupConfig = {}
  export let showConfig: IShowConfig = {}
  export let popupModel: Popup = undefined
  export let show = false
  export let lock = false

  $: if (show) showPopup()
  else hidePopup()

  let popupContent: HTMLElement
  let _force = false

  function bindPopupEvents() {
    popupModel.events.on('click', () => dispatchEvent('click'))
    popupModel.events.on('beforeShow', () => dispatchEvent('beforeShow'))
    popupModel.events.on('afterShow', () => dispatchEvent('afterShow'))
    popupModel.events.on('beforeHide', () => {
      dispatchEvent('beforeHide')
      // Ignore the lock if the force flag is true
      if (_force) {
        _force = false
        return true
      }
      return !lock
    })
    popupModel.events.on('afterHide', () => {
      dispatchEvent('afterHide')
      show = false
      popupModel.destructor()
    })
  }

  export function showPopup(overrideContainer?: HTMLElement) {
    if (popupContent) {
      popupModel = new DhxPopup({
        ...defaultConfig,
        ...popupConfig,
      })
      bindPopupEvents()
      popupModel.getContainer().appendChild(popupContent)

      popupModel.show(
        overrideContainer ?? document.getElementById(id),
        showConfig
      )
    }
  }

  export function hidePopup(force = false) {
    _force = force
    popupModel?.hide()
  }

  onDestroy(() => {
    popupModel?.destructor()
  })
</script>

<div class="popup_container" {id}></div>
<div style="display:none">
  <div bind:this={popupContent}>
    <slot />
  </div>
</div>
