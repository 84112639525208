import { isSet, isValidFloat, isValidInt, validateNumber } from '@packages/util'

function validateIfRequired<T>(
  required: boolean,
  value: T,
  fn: (value: T) => boolean
): boolean {
  return required ? isSet(value) && fn(value) : !isSet(value) || fn(value)
}

export function minLength(count: number) {
  return function (value: any) {
    return typeof value == 'string' && value.length >= count
  }
}

export function maxLength(count: number) {
  return function (value: any) {
    return typeof value == 'string' && value.length <= count
  }
}

export function lengthBetween(min: number, max: number) {
  return function (value: any) {
    return (
      typeof value == 'string' && value.length >= min && value.length <= max
    )
  }
}

export function minValue(min: number) {
  return function (value: any) {
    return isValidFloat(value) && value >= min
  }
}

export function maxValue(max: number) {
  return function (value: any) {
    return isValidFloat(value) && value <= max
  }
}

export function validNumber(
  min?: Nullable<number>,
  max?: Nullable<number>,
  require = false,
  requireInteger = false
) {
  return function (value: any) {
    return validateIfRequired(require ?? false, value, (value) =>
      validateNumber(value, min, max, requireInteger ?? false)
    )
  }
}

export function isValidEmail() {
  return function (email: any) {
    return typeof email == 'string' &&
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ? true
      : false
  }
}

export function isNumber() {
  return function (value: any) {
    return isValidFloat(value)
  }
}

export function isInteger() {
  return function (value: any) {
    return isValidInt(value)
  }
}

export function isParseableToDate() {
  return function (value: any) {
    return !isNaN(new Date(value).getTime())
  }
}

export function isValidJSONString() {
  return function (value: any) {
    value = typeof value !== 'string' ? JSON.stringify(value) : value
    try {
      value = JSON.parse(value)
    } catch (e) {
      return false
    }
    return typeof value === 'object' && value !== null
  }
}
