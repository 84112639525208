import * as Generated from '@generated'
import { generatedEnumColumn, inputColumn } from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.AppFunction> = {
  columns: {
    name: () => inputColumn('name'),
    objectType: () =>
      generatedEnumColumn('objectType', Generated.AppFunction_TYPEEnum),
  },
  filters: {
    name: 'string',
    objectType: {
      enum: Generated.AppFunction_TYPEEnum,
    },
  },
  defaultColumns: ['name', 'objectType', 'status'],
  defaultSort: 'name',
  nameField: 'name',
}
