import {
  getFirstKeyValue,
  isSet,
  parseFloatFromAny,
  single,
} from '@packages/util'
import type { FilterType } from '@models/api/BaseApi'
import type { ModelFilterCmp } from '@models/api/BaseModel'
import { getAvailableFilters } from './FilterDefinitions'
import { getFilterTypeName, type FilterTypeName } from './FilterUtil'

// Native Filters
/** Filter Type Alias List */
export const filterType = {
  all: '*',
  nonBoolean: [
    'string',
    'datetime',
    'date',
    'time',
    'numeric',
    'integer',
    'model',
    'enum',
  ],
  string: ['string'],
  boolean: ['boolean'],
  ord: ['date', 'datetime', 'time', 'integer', 'numeric'],
  time: ['date', 'datetime', 'time'],
} as const satisfies Record<string, FilterTypeName[] | '*'>

/** Is the given filter type a DateTime type */
function isDateTimeType(
  _filterType: FilterType
): _filterType is (typeof filterType.time)[number] {
  return filterType.time.includes(getFilterTypeName(_filterType) as any)
}

/** The base filter class */
export class FilterBase {
  static supportedFilterTypes: FilterTypeName[] | '*' = []
  static label: string | ((value: any) => string)
  implicitValue = false

  type: Nullable<string> = null
  value: any
  filterType: FilterType

  constructor(filterType: FilterType) {
    this.filterType = filterType
  }

  serialize(): ModelFilterCmp<any> {
    return { [this.type]: this.value }
  }

  deserialize(value: ModelFilterCmp<any>): boolean {
    const [_key, _value] = getFirstKeyValue(value)
    this.value = _value
    return _key == this.type
  }

  /** Find the first matching filter class and instantiate it with the given value */
  static findAndInstantiate(
    filterType: FilterType,
    value: ModelFilterCmp<any>
  ): InstanceType<ReturnType<typeof getAvailableFilters>[number]> | null {
    // Normalize value (If value == Array, Assume `IN`)
    if (Array.isArray(value)) {
      value = { in: value }
    } else {
      switch (typeof value) {
        case 'string':
        case 'number':
        case 'bigint':
        case 'boolean':
          value = { in: [value] }
      }
    }

    for (let filterClass of getAvailableFilters()) {
      if (this.matchFilterType(filterType, filterClass.supportedFilterTypes)) {
        let instance = new filterClass(filterType)
        if (instance.deserialize(value)) return instance
      } else {
        continue
      }
    }

    return null
  }

  /** Check if the current class allows the given filter type (Or check against a given list) */
  static matchFilterType(
    filterType: FilterType | FilterTypeName,
    supportedFilterTypes?: typeof this.supportedFilterTypes
  ) {
    supportedFilterTypes ??= this.supportedFilterTypes
    return (
      supportedFilterTypes == '*' ||
      (supportedFilterTypes as string[]).includes(getFilterTypeName(filterType))
    )
  }

  valueToString() {
    return this.value
  }
}

/** Filter: Value is less than `x` */
export class FilterLessThan extends FilterBase {
  static supportedFilterTypes = filterType.ord
  static label = (filterType: any) =>
    isDateTimeType(filterType) ? 'Is before' : 'Is less than'
  type = 'lt' as const
}

/** Filter: Value is less than or equal to `x` */
export class FilterLessThanOrEqual extends FilterBase {
  static supportedFilterTypes = filterType.ord
  static label = (filterType: any) =>
    isDateTimeType(filterType) ? 'Is on or before' : 'Is less than or equal to'
  type = 'lte' as const
}

/** Filter: Value is greater than `x` */
export class FilterGreaterThan extends FilterBase {
  static supportedFilterTypes = filterType.ord
  static label = (filterType: any) =>
    isDateTimeType(filterType) ? 'Is after' : 'Is greater than'
  type = 'gt' as const
}

/** Filter: Value is greater than or equal to `x` */
export class FilterGreaterThanOrEqual extends FilterBase {
  static supportedFilterTypes = filterType.ord
  static label = (filterType: any) =>
    isDateTimeType(filterType)
      ? 'Is on or after'
      : 'Is greater than or equal to'
  type = 'gte' as const
}

/** Filter: Value is equal to `x` */
export class FilterEqual extends FilterBase {
  static supportedFilterTypes = filterType.nonBoolean
  static label = 'Equals'
  type = 'eq' as const
}

/** Filter: Value is not equal to `x` */
export class FilterNotEqual extends FilterBase {
  static supportedFilterTypes = filterType.nonBoolean
  static label = 'Does not equal'
  type = 'neq' as const
}

/** Filter: Value is equal to one of `x[]` (Array of possible values) */
export class FilterIn extends FilterBase {
  static supportedFilterTypes = filterType.nonBoolean
  static label = 'Equals'
  type = 'in'

  serialize(): ModelFilterCmp<any> {
    // Make sure the output is an array
    return {
      [this.type]: (Array.isArray(this.value)
        ? this.value
        : [this.value]
      ).filter(isSet),
    }
  }

  valueToString() {
    return (Array.isArray(this.value) ? this.value : [this.value]).length > 1
      ? 'one or more items'
      : single(this.value)
  }
}

/** Filter: Value is not equal to one of `x[]` (Array of possible values) */
export class FilterNotIn extends FilterIn {
  static supportedFilterTypes = filterType.nonBoolean
  static label = 'Does not equal'
  type = 'nin'
}

/** Filter: Value is like `x` (String only) */
export class FilterLike extends FilterBase {
  static supportedFilterTypes = filterType.string
  static label = 'Matches Expression'
  type = 'like' as const
}

/** Filter: Value contains any data (Not `NULL`) */
export class FilterSome extends FilterBase {
  static supportedFilterTypes = filterType.all
  static label = 'Contains data'
  type = 'set'

  serialize(): ModelFilterCmp<any> {
    return { [this.type]: true }
  }

  deserialize(value: ModelFilterCmp<any>): boolean {
    const [_key, _value] = getFirstKeyValue(value)
    this.value = _value
    return _key == this.type && !!_value
  }

  valueToString() {
    return ''
  }
}

/** Filter: Value does not contain any data (`NULL`) */
export class FilterNone extends FilterSome {
  static supportedFilterTypes = filterType.all
  static label = 'Does not contain data'
  type = 'null'
}

// Custom Filters

/** Filter: Value is true (Boolean only) */
export class FilterTrue extends FilterBase {
  static supportedFilterTypes = filterType.boolean
  static label = 'Is true'
  implicitValue = true
  type = 'eq'
  _value = true

  deserialize(value: ModelFilterCmp<any>): boolean {
    const [_key, _value] = getFirstKeyValue(value)
    this.value = this._value
    return _key == this.type && _value === this._value
  }

  valueToString() {
    return ''
  }
}

/** Filter: Value is false (Boolean only) */
export class FilterFalse extends FilterTrue {
  static label = 'Is false'
  _value = false
}

/** Filter: Value is between `x` and `y` (Two element array, Ordinal only) */
export class FilterBetween extends FilterBase {
  static supportedFilterTypes = filterType.ord
  static label = 'Is between'

  declare value: [number | Date, number | Date]

  deserialize(value: ModelFilterCmp<any>): boolean {
    const range = this.parseRange(value)
    this.value = range
    return range ? range[1] >= range[0] : false
  }

  serialize(): ModelFilterCmp<any> {
    return { lte: this.value?.[1], gte: this.value?.[0] }
  }

  /**
   * Convert the object value to a range
   */
  parseRange(value: any): typeof this.value | null {
    const lte = value?.lte ?? value?.lt
    const gte = value?.gte ?? value?.gt

    if (!(isSet(lte) && isSet(gte))) return null

    if (
      (<FilterType[]>['date', 'time', 'datetime']).includes(this.filterType)
    ) {
      const lteVal = new Date(lte)
      const gteVal = new Date(gte)
      return [gteVal, lteVal]
    }

    if ((<FilterType[]>['integer', 'numeric']).includes(this.filterType)) {
      const lteVal = parseFloatFromAny(lte)
      const gteVal = parseFloatFromAny(gte)
      return [gteVal, lteVal]
    }

    return null
  }

  valueToString() {
    return `${this.value?.[0] ?? '---'} and ${this.value?.[1] ?? '---'}`
  }
}

/** Filter: Value starts with `x` (String only) */
export class FilterStartsWith extends FilterBase {
  static supportedFilterTypes = filterType.string
  static label = 'Starts With'
  endsWith = false
  includes = false

  declare value: Nullable<string>

  deserialize(value: ModelFilterCmp<any>): boolean {
    const [_key, _value] = getFirstKeyValue(value)
    this.value =
      typeof _value == 'string' ? _value.replaceAll(/^\%|\%$/g, '') : ''
    return _key == 'like' && this.isValid(_value)
  }

  serialize(): ModelFilterCmp<any> {
    let like = this.endsWith || this.includes ? '%' : ''
    like += String(this.value)
    like += !this.endsWith || this.includes ? '%' : ''
    return { like }
  }

  isValid(value: any) {
    if (typeof value != 'string') return

    const startsWith = value.startsWith('%')
    const endsWith = value.endsWith('%')

    if (this.endsWith) return !endsWith && startsWith
    else if (this.includes) return endsWith && startsWith
    else return endsWith && !startsWith
  }
}

/** Filter: Value ends with `x` (String only) */
export class FilterEndsWith extends FilterStartsWith {
  static label = 'Ends With'
  endsWith = true
}

/** Filter: Value includes `x` (String only) */
export class FilterIncludes extends FilterStartsWith {
  static label = 'Includes'
  includes = true
}
