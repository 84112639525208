import type { SvelteComponent } from 'svelte'

import DateTimeFilter, {
  type DateTimeFilterConfig,
} from './DateTimeFilter.svelte'
import EntityFilter, { type EntityFilterConfig } from './EntityFilter.svelte'
import EnumFilterDynamic, {
  type EnumFilterDynamicConfig,
} from './EnumFilterDynamic.svelte'
import InputFilter from './InputFilter.svelte'
import SelectFilter, { type SelectFilterConfig } from './SelectFilter.svelte'

export const GridFilterControlList = {
  InputFilter,
  SelectFilter,
  EntityFilter,
  DateTimeFilter,
  EnumFilterDynamic,
} as const satisfies Record<string, typeof SvelteComponent<any>>

// Add the config type to the corresponding component here
export type GridFilterControlListConfig = {
  InputFilter: any
  SelectFilter: SelectFilterConfig
  EntityFilter: EntityFilterConfig<any>
  DateTimeFilter: DateTimeFilterConfig
  EnumFilterDynamic: EnumFilterDynamicConfig
}

type Check<T extends keyof typeof GridFilterControlList> =
  GridFilterControlListConfig[T]
