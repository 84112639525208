import type * as Generated from '@generated'
import { inputColumn } from '@lib/CommonColumns'
import type { ModelContext } from '../BaseApi'

export const __context__: ModelContext<Generated.Enum> = {
  columns: {
    name: () => inputColumn('name'),
  },
  filters: {
    name: 'string',
    config: null,
  },
  defaultColumns: ['name', 'modifiedBy', 'status'],
  defaultSort: 'name',
  nameField: 'name',
  customListSupport: true,
}
