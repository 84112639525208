<script lang="ts" context="module">
  import type { ILazyDataProxy } from '@dhtmlx/ts-data'
  import type { Grid, IGridConfig, IHeader, IRow } from '@dhtmlx/ts-grid'
  import type { Pagination } from '@dhtmlx/ts-pagination'
  import type { IApiLazyDataProxy } from '@lib/ApiDataProvider'
  import { dhtmlxGridRowHeightMedium } from '@lib/CommonConstants'
  import { DhxGrid, DhxPagination } from '@lib/dhtmlx'
  import type {
    GridCellClickEvent,
    GridCellDblClickEvent,
  } from '@packages/util'
  import {
    awaitRedraw,
    bindComponentProp,
    debounce,
    getId,
    isSet,
    sleep,
    type MappedEvents,
  } from '@packages/util'
  import {
    SvelteComponent,
    createEventDispatcher,
    onDestroy,
    onMount,
  } from 'svelte'
  import {
    GridFilterControlList,
    type GridFilterControlListConfig,
  } from './GridFilterControls/GridFilterControlList'
  import Container from './Utility/Container.svelte'
  import LoadingContainer from './Utility/LoadingContainer.svelte'

  export type IHeaderComponent = IHeader & { config?: unknown; field?: string }

  export interface SortingState {
    column: string
    direction: 'asc' | 'desc'
  }

  // Used for defining a header item that points to a component name
  export function componentFilter<T extends keyof typeof GridFilterControlList>(
    name: T,
    config?: GridFilterControlListConfig[T],
    field?: string
  ): IHeaderComponent {
    return {
      text: '',
      css: `componentFilter x-component x-component-${name}`,
      headerSort: false,
      config,
      field,
    }
  }
</script>

<script lang="ts">
  interface $$Events {
    cellDblClick: CustomEvent<GridCellDblClickEvent>
    cellClick: CustomEvent<GridCellClickEvent>
    pageChange: CustomEvent<number>
    filterChange: CustomEvent<Record<string, any>>
    sortChange: CustomEvent<SortingState>
  }

  const dispatchEvent = createEventDispatcher<MappedEvents<$$Events>>()
  const gridId = `grid-${Math.random().toString(16).slice(2)}`
  const paginationId = `pagination-${Math.random().toString(16).slice(2)}`

  const debouncedApplyFilter = debounce(
    () => dispatchEvent('filterChange', filterState),
    100
  )

  export let dataProxy: ILazyDataProxy | IApiLazyDataProxy<any>
  export let gridConfig: IGridConfig = {}
  export let grid: Grid = undefined
  export let loading = true
  export let initialPage = 1

  // Visual states (Does not change (real) internal state on change)
  export let filterState: Record<string, any> = {} // The bound values of each custom filter component
  export let sortingState: SortingState = null // The visual sorting state of the grid (DHTMLX)

  /**
   * The currently selected item
   * @readonly
   */
  export let selection: Nullable<any> = null

  export let pagination: Nullable<Pagination>
  let gridElement: Nullable<HTMLDivElement>

  let paginationElement: Nullable<HTMLDivElement>
  let paginationHeight: Nullable<number>

  let _loading = true
  let _emptyList = false
  let _emptyColumns = false

  let _displayWidth: Nullable<number>
  let _displayHeight: Nullable<number>
  let _displayTop: Nullable<number>
  let _displayLeft: Nullable<number>

  // Used for the custom component filters
  let componentMap = new Map<string, SvelteComponent>()
  let filterCooldown = true

  function setGridEvents() {
    grid.events.on('cellDblClick', function (row, col, e) {
      dispatchEvent('cellDblClick', {
        row,
        col,
        e,
      })
    })

    // @ts-ignore Grid selection events exist
    grid.selection.events.on('afterSelect', (row: IRow) => {
      selection = row
    })

    // @ts-ignore Grid selection events exist
    grid.selection.events.on('beforeUnSelect', (row: IRow) => {
      selection = null
    })

    // This gets fired when trying to sort with a custom dataloader attached
    grid.events.on('customSort', function (rule, config) {
      dispatchEvent('sortChange', {
        column: rule.by,
        direction: rule.dir,
      })
    })

    grid.events.on('cellClick', function (row, col, e) {
      dispatchEvent('cellClick', {
        row,
        col,
        e,
      })
    })

    grid.data.events.on('beforeLazyLoad', () => {
      setLoadingState(true)
      filterCooldown = true
    })

    grid.data.events.on('afterLazyLoad', () => {
      // Load after Page switch
      setLoadingState(false)
      sleep(100).then(() => (filterCooldown = false))
      afterLoad()
    })

    grid.data.events.on('load', () => {
      // Initial load
      setLoadingState(false)
      pagination.setPage(initialPage)
      sleep(100).then(() => (filterCooldown = false))
      afterLoad()
    })

    pagination.events.on('change', (page: number | string) =>
      dispatchEvent(
        'pageChange',
        typeof page == 'number' ? page : parseInt(page)
      )
    )
  }

  /**
   * Function to allow for adding custom svelte components
   *
   * Usage:
   * Add `componentFilter('InputFilter')` as a header item
   */
  async function assignCustomContainers() {
    await awaitRedraw()

    // Find all html elements with the componentFilter set
    const componentElements = grid
      .getRootNode()
      .querySelectorAll('.dhx_header-rows .x-component')

    // Go through each component
    componentElements.forEach((_element) => {
      // Prepare the required variables
      // Get the DHTMLX item id or generate a new one
      const _id = _element.getAttribute('data-dhx-id') ?? getId('component')

      // Get the header cell
      const _gridHeaderCell = grid
        .getColumn(_id)
        ?.header?.find((item) =>
          item.css?.includes('x-component')
        ) as IHeaderComponent

      // Extract the component name from the class
      const _componentName = Array.from(_element.classList)
        .find((_class) => _class.startsWith('x-component-'))
        ?.replace('x-component-', '') // Remove x-component-
        ?.replaceAll(/[^\w\-]/g, '') // Sanitize input

      // Extract the extra config
      const _config = _gridHeaderCell?.config

      // Extract the filter field name
      const _field = _gridHeaderCell?.field ?? _id

      // Return if no component name is set
      if (!isSet(_componentName)) return

      // Set element id
      _element.id = _id

      // Return if component has not been found
      if (
        !Object.keys(GridFilterControlList).find(
          (value) => value == _componentName
        )
      )
        return

      // Remove the initial content
      _element.innerHTML = null

      // Actually initialize the component
      const component = new GridFilterControlList[_componentName]({
        target: _element,
      }) as SvelteComponent

      // Set props safely
      if (component.value !== undefined) component.value = filterState[_field]
      if (component.config !== undefined) component.config = _config

      // Attach events to component
      // component.$on('update', (event) => {})

      // Apply value binding
      bindComponentProp(component, 'value', (newValue: unknown) => {
        if (filterCooldown) return
        filterState[_field] = newValue
        // Emit the change event
        debouncedApplyFilter()
      })

      // Insert it into the loaded component map
      componentMap.set(_id, component)
    })

    return
  }

  function setLoadingState(state: boolean) {
    loading = state
    _loading = state
  }

  function updateSize(clientWidth: number, clientHeight: number) {
    if (!grid) return

    // Also check the column count
    _emptyColumns = !grid.config?.columns?.length

    grid.config.width = clientWidth
    grid.config.height = clientHeight - paginationHeight
    grid.paint()
  }

  function afterLoad() {
    _emptyList = (grid?.data?.getLength() ?? 0) <= 0
  }

  onMount(async () => {
    grid = new DhxGrid(gridElement, {
      autoWidth: true,
      autoHeight: false,
      headerRowHeight: dhtmlxGridRowHeightMedium,
      rowHeight: dhtmlxGridRowHeightMedium,
      width: _displayWidth,
      height: _displayHeight,
      ...gridConfig,
    })

    grid.data.load(<any>dataProxy)

    pagination = new DhxPagination(paginationElement, {
      data: <any>grid.data,
      pageSize: 20,
      css: 'border-top',
      page: initialPage,
    })

    // Properly apply the height
    await awaitRedraw()

    grid.config.height = _displayHeight - paginationHeight
    grid.paint()

    await awaitRedraw()

    setGridEvents()
    assignCustomContainers()

    // If the sorting state is set, update the visual states
    if (sortingState) {
      //@ts-ignore Set the hidden property
      grid._sortBy = sortingState.column
      //@ts-ignore Set the hidden property
      grid._sortDir = sortingState.direction
    }
  })

  function cancelLoad() {
    if ('abortAll' in dataProxy) {
      setLoadingState(false)
      dataProxy.abortAll()
    }
  }

  onDestroy(() => {
    grid?.destructor()
    pagination?.destructor()
    grid = undefined
    pagination = undefined
  })
</script>

<LoadingContainer
  bind:loading={_loading}
  cancellable={'abortAll' in dataProxy}
  on:click={cancelLoad}
>
  <Container
    on:resize={(event) => updateSize(event.detail.width, event.detail.height)}
    bind:width={_displayWidth}
    bind:height={_displayHeight}
    bind:top={_displayTop}
    bind:left={_displayLeft}
  >
    <div
      class="grid"
      id={gridId}
      bind:this={gridElement}
      hidden={_emptyColumns}
    ></div>
    <div
      class="pagination"
      id={paginationId}
      bind:this={paginationElement}
      bind:clientHeight={paginationHeight}
      hidden={_emptyColumns}
    ></div>
  </Container>
  {#if _emptyList || _emptyColumns}
    <div
      class="emptyListOverlay"
      style:top="{_displayTop}px"
      style:left="{_displayLeft}px"
      style:width="{_displayWidth}px"
      style:height="{_displayHeight}px"
    >
      {_emptyColumns
        ? 'This Table has no Visible Columns.'
        : 'No Items to Display.'}
    </div>
  {/if}
</LoadingContainer>

<style lang="scss">
  @use '../theme/variables' as vars;

  .emptyListOverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: vars.$zId-overlay;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.75;
    font-style: italic;
    pointer-events: none;
    user-select: none;
  }
</style>
