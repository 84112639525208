<!-- ToolbarItem Component, Must be a child of ToolbarHook -->
<script lang="ts" context="module">
  import type { IToolbarElement } from '@dhtmlx/ts-toolbar'
  import { getToolbarIcon, type IconPath } from '@lib/Icons'
  import type { Toolbar } from '@models/Toolbar'
  import type { MappedEvents } from '@packages/util'
  import { awaitRedraw, chain, isSet, sleep } from '@packages/util'
  import { createEventDispatcher, getContext, onDestroy, onMount } from 'svelte'
  import { get, type Writable } from 'svelte/store'
  import { globalToolbarStore } from './GlobalToolbar.svelte'

  // Functions for storing the list of mounted toolbar items
  let registeredItems = new Map<string, string>()

  function getId(name: string) {
    return registeredItems.get(name)
  }

  function register(name: string, id: string) {
    registeredItems.set(name, id)
  }

  function deregister(name: string) {
    registeredItems.delete(name)
  }

  function getFirstId(items: string | string[]) {
    const foundItem = (typeof items == 'string' ? [items] : items).find(
      (item) => isSet(getId(item))
    )
    return foundItem ? getId(foundItem) : undefined
  }
</script>

<script lang="ts">
  interface $$Events {
    click: CustomEvent<MouseEvent>
  }

  const dispatch = createEventDispatcher<MappedEvents<$$Events>>()

  const contextId = getId('ToolbarItem')
  const toolbarStore = getContext<Writable<Toolbar>>('toolbarStore')
  const id = `${Math.random().toString(16).slice(2)}`
  const fullId = `${contextId}:${id}`

  let element: HTMLDivElement
  let registered = false

  let _toolbar: Nullable<Toolbar> = null

  // Props used for positioning (Applied on register)
  export let name: string = id
  export let after: string | string[] = null
  export let parent: string = null

  // Toolbar item configuration (Applied on register)
  export let icon: IconPath = null
  export let value: string = null
  export let tooltip: string = null
  export let config: IToolbarElement = {}
  export let spacer = false
  export let separator = false
  export let type: IToolbarElement['type'] = null
  export let loading = false
  export let css = ''

  // Dynamic props (Can be changed any time)
  export let active = null
  export let disabled = false
  export let hidden = false
  export let dummy = false
  export let custom = false // Don't add extra button styling

  $: {
    if (registered) {
      if (disabled) {
        _toolbar?.dhx.disable(fullId)

        // Always add a `disabled` class, regardless of type
        let item = _toolbar?.dhx.data.getItem(fullId)

        if (typeof item.css === 'string') {
          item.css = <string>item.css + ' disabled'
        } else {
          ;(<string[]>item.css).push('disabled')
        }
      } else {
        _toolbar?.dhx.enable(fullId)

        // Remove `disabled` class
        let item = _toolbar?.dhx.data.getItem(fullId)

        if (typeof item.css === 'string') {
          item.css = (<string>item.css).replace(' disabled', '')
        } else {
          item.css = (<string[]>item.css).filter(
            (cssItem) => cssItem != 'disabled'
          )
        }
      }

      if (hidden) _toolbar?.dhx.hide(fullId)
      else _toolbar?.dhx.show(fullId)

      if (active !== null) _toolbar?.dhx.setState({ [fullId]: active })
    }
  }

  $: chain(_toolbar?.dhx?.data.getItem(fullId)).map((item) => {
    if (item.loading != loading) {
      item.loading = loading
      _toolbar.dhx.paint()
    }
  })

  function getType(): { type?: IToolbarElement['type'] } {
    if (dummy) return { type: 'customHTML' }
    if (custom) return { type: 'customHTML' }
    if (spacer) return { type: 'spacer' }
    if (separator) return { type: 'separator' }
    if (type) return { type }
    return {}
  }

  function getHtml(): string {
    if (dummy) return ''
    if (icon) {
      return getToolbarIcon(icon, value)
    } else if (value) {
      return value
    } else {
      return config?.html ?? element?.innerHTML
    }
  }

  function waitForToolbarStoreToBecomeAvailable() {
    return new Promise<Toolbar>(async (res, rej) => {
      // Default to the globalToolbarStore if the local one isn't set
      if (!toolbarStore) {
        res(get(globalToolbarStore))
        return
      }

      // Get the current value, return if set
      const value = get(toolbarStore)
      if (value) {
        res(value)
        return
      }

      console.log('Toolbar store not populated yet!')

      // Wait for the first value to be pushed
      const abortController = new AbortController()
      toolbarStore.subscribe((value) => {
        if (value) {
          abortController.abort('ok')
          res(value)
        }
      })

      // Apply a timeout
      sleep(1000, abortController.signal, true)
        .then(() => rej(new Error('timeout')))
        .catch((err) => {})
    })
  }

  onMount(async () => {
    _toolbar = await waitForToolbarStoreToBecomeAvailable()

    if (!_toolbar) return

    _toolbar?.add(
      {
        id: fullId,
        // type: 'customHTML',
        tooltip,
        css,
        ...config,
        ...(<any>getType()),
        html: getHtml(),
        disabled,
        hidden,
      },
      after ? getFirstId(after) : undefined,
      parent ? getId(parent) : undefined
    )

    _toolbar?.dhx.events.on(
      'click',
      (id, event) => {
        if (id == fullId) dispatch('click', event as MouseEvent)
      },
      fullId
    )

    if (
      $$slots.default && // Default slot is set AND...
      element && // There is an element available AND...
      !(value || icon) && // If neither the value or icon prop isn't set AND...
      !config?.html && // If the config html item isn't set AND...
      config?.type != 'separator' && // The item is not a separator AND...
      config?.type != 'spacer' // The item is not a spacer...
    )
      // THEN Replace the item with the actual component
      awaitRedraw().then(() => {
        let toolbarElement = document.querySelector(`[data-dhx-id="${fullId}"]`)
        toolbarElement.textContent = null
        toolbarElement.innerHTML = null
        toolbarElement.appendChild(element)
      })

    register(name, fullId)
    registered = true
  })

  onDestroy(() => {
    _toolbar?.remove(fullId)

    // @ts-ignore Click event can be detached
    _toolbar?.dhx.events.detach('click', fullId)

    deregister(name)
    registered = false
  })
</script>

{#if $$slots.default}
  <div hidden>
    <div class="toolbarItemContent" bind:this={element}>
      <slot {disabled} />
    </div>
  </div>
{/if}
