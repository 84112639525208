<script lang="ts" context="module">
  import GlobalTooltip from '@components/Global/GlobalTooltip.svelte'
  import Container from '@components/Utility/Container.svelte'
  import GlobalToolbar from '@components/Toolbar/GlobalToolbar.svelte'
</script>

<div class="page-container">
  <div class="header">
    <GlobalToolbar />
  </div>
  <GlobalTooltip />

  <div class="container">
    <Container>
      <div class="content-slot">
        <slot />
      </div>
    </Container>
  </div>
</div>

<style lang="scss">
  @use '../theme/variables' as vars;

  .header {
    background-color: vars.$primary;
    border-bottom: 1px solid vars.$border;
    padding: 0 4px;
  }

  .page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    min-width: 768px;
  }

  .container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .content-slot {
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }
</style>
